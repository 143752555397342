<template>
  <div>
    <div class="card p-3 ">
      <p class="fw-bold border-bottom border-1 pb-2">クレジットカード登録</p>
      <div class="row align-items-center">
        <div class="col-sm-4"><span>ご利用可能なカード</span></div>
        <div class="col"><img alt="card logo" src="@/assets/images/cardLogo.png" style="max-width:350px;"></div>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="col-sm-5"><span class="badge bg-danger me-2 lh-sm">必須</span>カード番号</div>
        <div class ="col-sm-4 p-0"><input class="form-control form-control-sm p-1" type="text" maxlength="19"
            @input="$_inputCardNumber" :value="cardNumberDisplayed" @blur="$_blurCardNumber" />
        </div>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="col-sm-5"><span><span class="badge bg-danger me-2 lh-sm">必須</span>カード有効期限</span></div>
        <div class="col-sm-7 px-0 d-flex">
          <select v-model="info.month" class="form-select form-select-sm month-width me-2">
            <option v-for="item in months" :key="item.month" :value="item.month">{{ item.month }}
            </option>
          </select>
          <span>月 /</span>
          <select v-model="info.year" class="form-select form-select-sm mx-2 year-width">
            <option v-for="item in years" :key="item.year" :value="item.year">{{ item.year }}
            </option>
          </select>
          <span>年</span>
        </div>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="col-sm-5"><span><span class="badge bg-danger me-2 lh-sm">必須</span>カードの名義人</span></div>
        <div class="col-sm-5 p-0"><input class="form-control form-control-sm p-1" type="text" maxlength="64"
            v-model="info.holderName" /></div>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="col-sm-5"><span><span class="badge bg-danger me-2 lh-sm">必須</span>セキュリティコード</span></div>
        <div class="col-sm-auto ps-0"><input class="form-control form-control-sm p-1 text-center card-info" type="text" maxlength="4" 
            v-model="info.securityCode" />
        </div>
        <span class="col-auto">※カード裏面の3-4桁の数字</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { cmnGetMonthList, cmnGetYearList } from '@/assets/js/common'

  export default {
    name: 'credit-info',

    data() {
      return {
        info: {
          cardNumber: null,
          month: null,
          year: null,
          holderName: null,
          securityCode: null
        },
        cardNumberDisplayed: null,
      }
    },

    computed: {
      /* 有効期限の年のlistbox取得 */
      years: function() {
        const yearListYY = cmnGetYearList(21)
        yearListYY.forEach(elm => {
          elm.year = elm.year.toString(10).slice(2, 4)
        })
        return yearListYY
      },

      /* 有効期限の月のlistbox取得 */
      months: function() {
        return cmnGetMonthList()
      }

    },

    methods: {
      /* 入力時にカード番号欄の表示を調整 */
      $_inputCardNumber(event) {
        this.info.cardNumber = String(event.target.value).replace(/\s/g, '')
        this.cardNumberDisplayed = this.info.cardNumber.slice(0, 4) + ' ' + this.info.cardNumber.slice(4, 8) + ' ' + this.info.cardNumber.slice(8, 12) + ' ' + this.info.cardNumber.slice(12, 16)
        this.cardNumberDisplayed = this.cardNumberDisplayed.trimEnd()
      },

      /* フォーカスアウト時にカード番号欄の表示を調整 */
      $_blurCardNumber() {
        if(!this.info.cardNumber) return
        if(this.info.cardNumber.length === 14 || this.info.cardNumber.length === 15) {
          this.cardNumberDisplayed = this.cardNumberDisplayed.replace(/\s/g, '')
          this.cardNumberDisplayed = this.cardNumberDisplayed.slice(0, 4) + ' ' + this.cardNumberDisplayed.slice(4, 10) + ' ' + this.cardNumberDisplayed.slice(10)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card-info {
    width: 55px;
    text-align: center;
  }

  .month-width {
    width: 70px;
  }

  .year-width {
    width: 70px;
  }

  img {
    width: 100%;
    height: 100%;
  }
</style>
<template>
  <div class="d-flex flex-column no-scrollbar position-fixed h-100">
    <header-section></header-section>
    <div class="px-4 py-3">
      <nav class="nav nav-fill flex-column py-3">
        <button class="btn btn-outline-primary fw-bold" v-bind:class="[this.$route.path === '/userList' ? 'active' : '']" @click="signUpPage">ユーザ登録</button>
        <button class="btn btn-outline-primary fw-bold mt-3" v-bind:class="[this.$route.path === '/registrationInfoCheck' ? 'active' : '']" @click="registrationInfoCheck">登録情報確認・修正</button>
        <button class="btn btn-outline-primary fw-bold mt-3" v-bind:class="[this.$route.path === '/billingInfo' ? 'active' : '']" @click="billingInfoPage">請求情報</button>
      </nav>
    </div>
    <div class="mt-auto py-3">
      <footer-section></footer-section>
    </div>
  </div>
</template>

<script>
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'

export default {
  name: 'UserMenuSection',

  components: {
    HeaderSection,
    FooterSection,
  },

  data() {
    return {
      user: this.$store.state.user,
    }
  },

  methods: {
    /* ユーザ登録 */
    signUpPage() {
      this.$store.commit('setUserListPrevPage', null)
      this.$router.push({ path: 'userList' })
    },
    /* 請求情報 */
    billingInfoPage(){
      this.$router.push('/billingInfo')
    },
    /* 登録情報確認・修正 */
    registrationInfoCheck(){
      this.$router.push({ path: 'registrationInfoCheck'})
    },
    
  }
}
</script>

<style lang="scss" scoped>
  .no-scrollbar {
    overflow-y: hidden;
    width: 350px;
  }
</style>
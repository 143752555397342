/* 建物形状 */
class E_building_shape {
  constructor() {
    this.is_box = false
    this.is_slope = false
    this.is_ramp = false
    this.box = null
    this.slope = null
    this.ramp = null
  }
}
/* BOXパラメータ */
class E_box {
  constructor() {
    this.is_hisashi = false
    this.hisashi_length = 0
    this.mochidashi_length = 0
  }
}
/* スロープパラメータ */
class E_slope {
  constructor() {
    this.is_one_two = false
    this.is_one_three = false
  }
}
/* ランプパラメータ */
class E_ramp {
  constructor() {
    this.is_single = false
    this.is_double = false
  }
}

let initBuildingShape = function() {
  let buildingShape = new E_building_shape()
  buildingShape.box = new E_box()
  buildingShape.slope = new E_slope()
  buildingShape.ramp = new E_ramp()
  return buildingShape
}

export {
  initBuildingShape
}

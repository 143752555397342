<template>
  <div id="modal-adjust-building-parent">
    <div id="modal-adjust-building" class="modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modal-adjust-building-label">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title" id="modal-adjust-building-label">建物調整</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-start text-nowrap">
              <div class="nav nav-pills flex-column me-3" id="pills-tab" role="tablist" aria-orientation="vertical">
                <button class="nav-link active" id="pills-01-tab" data-bs-toggle="pill" data-bs-target="#pills-01" type="button" role="tab" aria-controls="pills-01" aria-selected="true">建物規模の変更</button>
                <button class="nav-link" id="pills-02-tab" data-bs-toggle="pill" data-bs-target="#pills-02" type="button" role="tab" aria-controls="pills-02" aria-selected="false">出入口の変更</button>
                <button class="nav-link" id="pills-03-tab" data-bs-toggle="pill" data-bs-target="#pills-03" type="button" role="tab" aria-controls="pills-03" aria-selected="false">建物移動</button>
              </div>
              <div class="tab-content flex-fill" id="pills-tab-content">
                <div class="tab-pane fade show active" id="pills-01" role="tabpanel" aria-labelledby="pills-01-tab">
                  <div class="card">
                    <div class="card-header px-3 py-1 alert-primary">階数変更</div>
                    <div class="card-body p-2">
                      <div class="container px-sm-0 px-lg-3">
                        <div class="row align-items-center">
                          <div class="col-auto col-lg-2">想定階</div>
                          <div class="col-auto col-lg-2">{{ orgFloor }} 階 →</div>
                          <div class="col-auto col-lg-3 d-flex ps-sm-0 align-items-center">
                            <select v-model="floor" class="form-select form-select-sm" aria-label=".form-select-sm">
                              <option v-for="item in floorList" :key="item" :value="item">
                                {{ item }}
                              </option>
                            </select>
                            <span class="ms-2">階</span>
                          </div>
                          <div class="col col-lg-5 text-end"><button type="button" class="btn btn-sm btn-primary" :disabled="isFloorChangeButtonDisabled" @click="$_update">変更</button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header px-3 py-1 alert-primary">建物制限</div>
                    <div class="card-body p-2">
                      <div class="container px-sm-0 px-lg-3">
                        <div class="row align-items-center">
                          <div class="col-auto col-lg-6">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="check-is-shape" v-model="isShape">
                              <label class="form-check-label" for="check-is-shape">平面形状の制限（整形）</label>
                            </div>
                          </div>
                          <div class="col col-lg-6 text-end"><button type="button" class="btn btn-sm btn-primary" :disabled="isReCalcButtonDisabled" @click="$_buildingLimitRecalc">建物再計算</button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-02" role="tabpanel" aria-labelledby="pills-02-tab">
                  <div class="card">
                    <div class="card-header px-3 py-1 alert-primary">トラック進入口</div>
                    <div class="card-body p-2">
                      <div class="container px-sm-0 px-lg-3">
                        <div class="row align-items-center mb-2">
                          <div class="col-4 col-lg-3">出入口タイプ</div>
                          <div class="col-auto">{{ entranceType }}</div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-4 col-lg-3">道路情報</div>
                          <div class="col-auto">道路 {{entryPoint}} (幅員{{ roadW }}m)</div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="check-is-road-rounding" v-model="isRoadRrounding">
                              <label class="form-check-label" for="check-is-road-rounding">トラックは周回させる</label>
                            </div>
                          </div>
                          <div class="col text-end">
                            <button type="button" class="btn btn-sm btn-primary" @click="$_truckDoorwayRecalc">建物再計算</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header px-3 py-1 alert-primary">出入口の変更</div>
                    <div class="card-body p-2">
                      <div class="container px-sm-0 px-lg-3">
                        <div class="row align-items-center mb-2">
                          <div class="col-4 col-lg-3">境界線</div>
                          <div class="col-5 col-lg-4">
                            <select v-model="lCondNo" class="form-select form-select-sm" aria-label=".form-select-sm" @change="$_changelCondNo" >
                              <option v-for="item in lCondNoList" :key="item.no" :value="item.no">
                                境界線{{ item.no }}
                              </option>
                            </select>
                            </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-4 col-lg-3">位置（方位）</div>
                          <div class="col-5 col-lg-4">
                            <select v-model="lCondDirection" class="form-select form-select-sm" aria-label=".form-select-sm">
                              <option v-for="item in lCondDirectionList" :key="item.code" :value="item.code">
                                {{ item.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="check-is-maintain-berth-direction" v-model="isMaintainBerthDirection">
                              <label class="form-check-label" for="check-is-maintain-berth-direction">バース向きを維持する</label>
                            </div>
                          </div>
                          <div class="col text-end">
                            <button type="button" class="btn btn-sm btn-primary" @click="$_install">設置</button>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-03" role="tabpanel" aria-labelledby="pills-03-tab">
                  <div class="card">
                    <div class="card-header px-3 py-1 alert-primary">建物移動</div>
                    <div class="card-body p-2">
                      <div class="container px-sm-0 px-lg-3">
                        <div class="row align-items-center mb-2">
                          <div class="col-4 col-lg-3">方位</div>
                          <div class="col-5 col-lg-4">
                            <select v-model="direction" class="form-select form-select-sm" aria-label=".form-select-sm">
                              <option v-for="item in moveDirectionNoList" :key="item.code" :value="item.code">
                                {{ item.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-4 col-lg-3">距離</div>
                          <div class="col-5 col-lg-4">
                            <input class="form-control py-0 text-end col-5 col-lg-4" v-model="moveDistance" style="height: 31px" @blur="$_moveDistanceFormat"/>
                          </div>
                          <div class="col-auto ps-0">
                              <span>m</span>
                          </div>
                          <div class="col-12 col-lg text-end pt-2 pt-lg-0">
                            <button type="button" class="btn btn-sm btn-primary" @click="$_moveBuildingRecalc">建物再計算</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show mx-auto mb-3" role="alert">
              <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
            </div>
            <button type="button" class="btn btn-sm btn-secondary" @click="$_cancel">閉じる</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" :prop-messages="message" parent-id="modal-adjust-building-parent" />
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { initAdjustBuilding } from '@/assets/js/dto/E_auto_design'
import { initBuildingSummary } from '@/assets/js/dto/E_building_summary'
import { directions, cdChangeArrayToHash } from '@/assets/js/code'
import { cmnDivide, cmnMultiply, cmnCheckNumFormat, cmnDecimalFormat, cmnChangeStrToNum } from '@/assets/js/common'
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'


export default {
  name: 'ModalAdjustBuilding',
  components: {
    ModalBlockMessage
  },
    props: {
     propUrl: {
      type: String,
      default: ''
    }
  },  
  data() {
    return {
      myModal: null,
      dragElement: null,
      project: this.$store.state.params.data,
      buildingSummary: initBuildingSummary(),
      orgFloor: null,
      floorList: [],
      floor: null,
      isShape: null,
      originIsShape: null,
      entranceType: null,
      entryPoint: null,
      roadW: null,
      isRoadRrounding: null,
      isMaintainBerthDirection: null, 
      lCondNo: null,
      lCondNoList: [],
      lCondDirection: null,
      lCondDirectionList: [],
      direction: null,
      moveDistance: null,
      isInitTabBuildingScale: false,
      isInitTabChangeDoorway: false,
      isInitTabMoveBuilding: false,
      message: [],
      url: '',
      errors: []
    }
  },
  computed: {
    isFloorChangeButtonDisabled: function()  {
      return this.orgFloor === this.floor
    },
    isReCalcButtonDisabled: function()  {
      return this.isShape === this.originIsShape
    },
    moveDirectionNoList() {
      return directions
    }
  },
    watch: {
    propUrl: function(newVal) {
      this.url = newVal
    }
  },       
  mounted() {
    this.dragElement = document.getElementById('modal-adjust-building').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
    const self = this
    document.getElementById('pills-01-tab').addEventListener("shown.bs.tab", function () {
      self.$_initTabBuildingScale()
    })
    document.getElementById('pills-02-tab').addEventListener("shown.bs.tab", function () {
      self.$_initTabChangeDoorway()
    })
    document.getElementById('pills-03-tab').addEventListener("shown.bs.tab", function () {
      self.$_initTabMoveBuilding()
    })
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.$_setData()
      this.myModal = new Modal(document.getElementById('modal-adjust-building'), {})
      this.myModal.show()
    },
    /* データ設定 */
    async $_setData() {
      this.isInitTabBuildingScale = false
      this.isInitTabChangeDoorway = false
      this.isInitTabMoveBuilding = false
      this.errors = []
      this.buildingSummary = this.$store.state.params.building_check.building_summary
      // 表示中のタブを初期化
      const tabId = document.querySelector('button.nav-link.active').id
      if (tabId === 'pills-01-tab')
        this.$_initTabBuildingScale()
      else if (tabId === 'pills-02-tab')
        this.$_initTabChangeDoorway()
      else
        this.$_initTabMoveBuilding()
    },
    /* [建物規模の変更]タブ初期化 */
    async $_initTabBuildingScale() {
      if (this.isInitTabBuildingScale) return
      this.buildingSummary = this.$store.state.params.building_check.building_summary
      this.message = ['処理中…']
      this.$refs.modalBlockMessage.init()
      await this.$store.dispatch('getAutoDesignList').then(()=>{
        this.$_getFloorList()
      }).catch(() => {
        this.$refs.modalBlockMessage.close()
      })
      await this.$store.dispatch('getAutoDesignInfo').then(()=>{
        this.isShape = this.$store.state.params.auto_design.building_base_setting.is_shape
        this.originIsShape = this.isShape
      }).catch(() => {
        this.$refs.modalBlockMessage.close()
      })
      this.$refs.modalBlockMessage.close()
      // 初期値設定
      this.orgFloor = this.buildingSummary.floor
      this.floor = this.buildingSummary.floor
      // 初期化完了
      this.isInitTabBuildingScale = true
    },
    /* [出入口の変更]タブ初期化 */
    async $_initTabChangeDoorway() {
      if (this.isInitTabChangeDoorway) return
      this.message = ['処理中…']
      this.$refs.modalBlockMessage.init()
      await this.$store.dispatch('getLcondList').then((data) => {
        this.$_getLCondNoList(data.l_cond_list)
        this.$_getDirectionNoList()
        }).catch(() => {
          this.$refs.modalBlockMessage.close()
      })
      this.$refs.modalBlockMessage.close()
      // 初期値設定
      this.entranceType = '出入口' 
      this.isRoadRrounding = false
      this.lCondDirection = 0
      this.isMaintainBerthDirection  = true
      this.$_changeEntryPoint()
      let roadWList = []
      for(let i=0, len= this.project.site_planning.l_cond.l_cond_data_list.length; i<len; i++){
        if (this.project.site_planning.l_cond.l_cond_data_list[i].direction === this.project.site_planning.entry_point)
          roadWList.push(this.project.site_planning.l_cond.l_cond_data_list[i].road_w)
      }
      this.roadW = cmnDivide(Math.max.apply(null, roadWList ), 1000, -2)
      // 初期化完了
      this.isInitTabChangeDoorway = true
    },
    /* [建物移動]タブ初期化 */
    $_initTabMoveBuilding() {
      if (this.isInitTabMoveBuilding) return
      // 初期値設定
      this.direction = 0
      this.moveDistance = 0 
      // 初期化完了
      this.isInitTabMoveBuilding = true
    },
    /* 階数のlistbox取得 */
    $_getFloorList() {
      let floorLengthList=[]
      for(let i=0,len = this.$store.state.lists.cross_sections.length ; i <len; i++){
        floorLengthList.push(this.$store.state.lists.cross_sections[i].floor_list.length)
      }
      this.floorList = []
      for(let i=1, len= Math.max.apply(null,floorLengthList);i <= len; i++){
        this.floorList.push(i)
      }
    },
    /* 境界線のlistbox取得 */
    $_getLCondNoList(l_cond_list) {
      this.lCondNoList = []
      for(let i=0, len = l_cond_list.length; i < len; i++){
        if(l_cond_list[i].type === 0) this.lCondNoList.push(l_cond_list[i])
      }
      this.lCondNo = this.lCondNoList[0].no
    },
    /* 方位のlistbox取得 */
    $_getDirectionNoList() {
      let gate = this.lCondNoList.find((item) => {
        return item.no === this.lCondNo
      }).gate
      this.lCondDirectionList = directions.filter((direction) => {
        return direction.gate === gate
      })
      this.lCondDirection = this.lCondDirectionList[0].code
    },
    /*建物移動-距離数値フォーマット*/
    $_moveDistanceFormat(){
      if(!isNaN(cmnChangeStrToNum(this.moveDistance))) 
        this.moveDistance = cmnDecimalFormat(cmnChangeStrToNum(this.moveDistance), 2)
    },
    /* entryPoint変換 */
    $_changeEntryPoint() {
      let directionList = cdChangeArrayToHash(directions, 'code', 'text')
      let entryPointNo = this.project.site_planning.entry_point
      this.entryPoint = directionList[entryPointNo]
    },
    /*建物調整処理*/
    async $_adjustBuilding(payload, closeModal) {
      return new Promise((resolve, reject) => {
        this.message = ['自動計算中…']
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('setAdjustBuilding', payload).then((data) => {
          this.$emit('set-viewer-to-inputsection', data.plan_view_url)
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          if(closeModal) this.$refs.modalBlockMessage.close()
          resolve()
        }).catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
          reject(e)
        })
      })
    },
    /*建物規模の変更-階数変更-変更*/
    $_update() {
      this.errors = []
      let payload =  { adjust_building: initAdjustBuilding() }
      payload.adjust_building.calc_type = 0
      payload.adjust_building.change_building_scale.calc_type = 0
      payload.adjust_building.change_building_scale.floor = this.floor
      this.$_adjustBuilding(payload, false).then(() => {
        this.message = ['処理中…']
        // this.$refs.modalBlockMessage.init()
        this.$store.dispatch('getBuildingSummary').then(()=>{
          this.$refs.modalBlockMessage.close()
          this.isInitTabBuildingScale = false
          this.$_initTabBuildingScale()
        })
      }).catch((e) => {
        this.$refs.modalBlockMessage.close()
        console.log(e)
      })
    },
    /*建物規模の変更-建物制限-建物再計算*/
    $_buildingLimitRecalc() {
      this.errors = []
      let payload =  { adjust_building: initAdjustBuilding() }
      payload.adjust_building.calc_type = 0
      payload.adjust_building.change_building_scale.calc_type = 1
      payload.adjust_building.change_building_scale.is_shape = this.isShape
      this.$_adjustBuilding(payload, true)
    },
    /*出入口の変更-トラック進入口-建物再計算*/
    $_truckDoorwayRecalc() {
      this.errors = []
      let payload =  { adjust_building: initAdjustBuilding() }
      payload.adjust_building.calc_type = 1
      payload.adjust_building.change_doorway.calc_type = 0
      payload.adjust_building.change_doorway.is_road_rounding = this.isRoadRrounding
      this.$_adjustBuilding(payload, true)
    },
    /*出入口の変更-出入口の変更-設置*/
    $_install() {
      this.errors = []
      let payload =  { adjust_building: initAdjustBuilding() }
      payload.adjust_building.calc_type = 1
      payload.adjust_building.change_doorway.calc_type = 1
      payload.adjust_building.change_doorway.gate_l_cond_no = this.lCondNo
      payload.adjust_building.change_doorway.gate_l_cond_direction = this.lCondDirection
      payload.adjust_building.change_doorway.is_maintain_berth_direction = this.isMaintainBerthDirection
      this.$_adjustBuilding(payload, true)
    },
    /*建物移動-建物移動-建物再計算*/
    $_moveBuildingRecalc() {
      if (!this.$_validation()) return;
      let payload =  { adjust_building: initAdjustBuilding() }
      payload.adjust_building.calc_type = 2
      payload.adjust_building.move_building.direction = this.direction
      payload.adjust_building.move_building.move_distance = cmnMultiply(this.moveDistance, 1000)
      this.$_adjustBuilding(payload, true)
    },
    /*境界線ロストフォーカス時再描画 */
    $_changelCondNo(){
      /*
      this.$_getDirectionNoList()

      this.errors = []
      let payload =  { adjust_building: initAdjustBuilding() }
      payload.adjust_building.calc_type = 1
      payload.adjust_building.change_doorway.calc_type = 2
      payload.adjust_building.change_doorway.gate_l_cond_no = this.lCondNo
      payload.adjust_building.change_doorway.gate_l_cond_direction = this.lCondDirection
      payload.adjust_building.change_doorway.is_maintain_berth_direction = this.isMaintainBerthDirection

      this.$refs.modalBlockMessage.init()
      this.$store.dispatch('setAdjustBuilding', payload).then((data) => {
        this.$emit('set-viewer-to-inputsection', data.plan_view_url)
        this.$refs.modalBlockMessage.close()
      }).catch((e) => {
        this.$refs.modalBlockMessage.close()
        console.log(e)
      })
      */
    },
    /* バリデーションチェック */
    $_validation() {
      this.errors = []
      if (!cmnCheckNumFormat(this.moveDistance)) {
            this.errors.push('距離は数値を入力してください。');
            return false
      }
      return true
    },
    /* Cancel */
    $_cancel() {
      this.myModal.hide()
      /* 
      this.$emit('set-viewer-to-inputsection', this.url)
      */
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div class="h-100 overflow-hidden" id="map-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">計画敷地の入力</p>
        <p class="d-block mb-0">物件管理ID：{{ project.project_no }}</p>
        <p class="d-block mb-0">プロジェクト名称：{{ project.project_name }}</p>
      </div>
      <div class="my-overflow-y p-2">
        <div class="mb-4 container" style="font-size:15px;">
          <div class="row">
            <div class="col-5 text-nowrap">所在地：</div>
            <div class="col px-0">{{ project.address_pref + project.address_city + project.address_ooaza + project.address_aza }}</div>
          </div>
          <div class="row mt-1">
            <div class="col-5 text-nowrap">プロジェクト名称：</div>
            <div class="col px-0">{{ project.project_name }}</div>
          </div>          
        </div>
        <div>
          <p class="mb-4">右の地図から地番を選択、<br>または入力して次へを押してください。</p>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="check-display-usage" v-model="isVisibleYouto" @change="$emit('change-youto-visible', isVisibleYouto)">
            <label class="form-check-label" for="check-display-usage">用途地域表示</label>
          </div>
          <div class="d-flex mt-3">
            <span>地番がない場合</span>
            <button class="btn btn-primary btn-sm ms-auto py-1 px-3" type="button" @click="$_onClickDrawStart">入力</button>
            <button class="btn btn-primary btn-sm ms-2 py-1 px-3" type="button" @click="$emit('click-delete-draw-area')" v-if="!isDraw">クリア</button>            
            <button class="btn btn-primary btn-sm ms-2 py-1 px-3" type="button" @click="$_onClickDrawEnd" v-if="isDraw">終了</button>
          </div>
          <div class="d-flex mt-5">
        <div class="gx-0 container mt-sm-0">
          <div class="row w-100">
            <div class="col-5 text-nowrap">受領敷地面積：</div>
            <div class="col px-0 text-end">{{ this.project.site_area }} ㎡</div>
          </div>
          <div class="row mt-1 w-100">
            <div class="col-5 text-nowrap">作図敷地面積：</div>
            <div class="col px-0 text-end">{{ this.totalArea }} ㎡</div>
          </div>
          <div class="row mt-1 w-100">
            <div class="col-5 text-nowrap">差異：</div>
            <div class="col px-0 text-end">{{ this.gapArea }} ㎡</div>
          </div>
          <div class="col px-0 text-end" style="font-size: 13px">(差異割合：<span :class="{'text-danger': this.gapPercent < 0 }"> {{ this.project.site_area > 0 ? this.gapPercent : "-" }} </span> % )</div>          
        </div>
          </div>          
        </div>
      </div>
      <div class="mt-auto mx-2 d-grid gap-3">
        <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show my-4 mb-2" role="alert">
            <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
        </div>
        <button block class="mt-3 btn btn-primary" @click="$_onClickNext">次へ</button>
        <button type="button" class="btn btn-primary" @click="$_prevPage">戻る</button>
      </div>
      <footer-section></footer-section>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="map-input-section" />
    <modal-message ref="modalMessage" :prop-messages="messages" parent-id="map-input-section" :is-show-okay-btn="true"/>
    <modal-confirm-address ref="modalConfirmAddress" :prop-parcel-no-list="parcelNoList" v-on:next="$emit('build-around-map')" />
  </div>
</template>

<script>

import ModalConfirmAddress from '@/views/map/ModalConfirmAddress'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import ModalMessage from '@/views/common/ModalMessage'
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'
import { cmnAdd, cmnSubtract, cmnDecimalFormat,cmnChangeStrToNum } from '@/assets/js/common'
// cmnDivide
// const LABEL_SITE_AREA_IS_BLANK = '（計画値より算出）'

export default {
  name: 'InputSection',
  components: {
    ModalConfirmAddress,
    ModalBlockMessage,
    ModalMessage,
    HeaderSection,
    FooterSection
  },
  data() {
    return {
      project: this.$store.state.params.data,
      isVisibleYouto: false,
      isDraw: false,
      parcelNoList: [],
      messages: [],
      errors: [],
      totalArea : 0,
      gapArea: 0,
      gapPercent : 0,
    }
  },
  // computed: {
  //   labelSiteAreaIsBlank: function() {
  //     return LABEL_SITE_AREA_IS_BLANK
  //   },
  // },
  mounted() {
    this.$_init()
  },
  methods: {
    /* 初期化 */
    $_init() {
      this.errors = []
      this.messages = []
      this.messages.push('目的の地番を選択してください。')
      this.$refs.modalMessage.init()
      this.totalArea = cmnDecimalFormat(cmnChangeStrToNum(this.totalArea), 2)
      this.gapArea = cmnDecimalFormat(cmnChangeStrToNum(this.gapArea), 2)
      this.$_setData()
    },
    /* データ設定 */
    $_setData() {
      this.isVisibleYouto = this.$store.state.youtoFlag
      this.project.site_area = cmnDecimalFormat(cmnChangeStrToNum(this.project.site_area), 2)
    },
    /* 入力 */
    $_onClickDrawStart() {
      this.isDraw = true
      this.$emit('click-draw-start')
    },
    /* 終了 */
    $_onClickDrawEnd() {
      this.isDraw = false
      this.$emit('click-draw-end')
    },
    /* 地番情報のセット */
    setParcelNoList(list) {
      this.parcelNoList = list
      this.$_calSiteArea(this.parcelNoList);
    },
    $_calSiteArea(parcelNoList) {
      this.totalArea = 0;
      if(parcelNoList.length > 0){
      for (let i = 0; i < parcelNoList.length; i++) {
        this.totalArea = cmnDecimalFormat(cmnAdd(this.totalArea, parcelNoList[i].siteArea),2);
      }}
      this.gapArea = cmnDecimalFormat(cmnSubtract(this.totalArea, this.project.site_area),2)
      this.gapPercent = this.project.site_area > 0 ? cmnDecimalFormat((this.gapArea / this.project.site_area) * 100, 2) : "-"
    },
    /* 次へ */
    $_onClickNext() {
      if (!this.$_validate()) return
      
      // 住所変更確認
      if (this.project.address_code !== this.parcelNoList[0].addressCode) {
        this.$refs.modalConfirmAddress.init()
        return
      }
        this.$emit('build-around-map')
    },
    /* バリデーション */
    $_validate() {
      this.errors = []
      if (this.isDraw) {
        this.errors = ['敷地の入力中です。', '終了ボタンで敷地を確定してください。']
        return false
      }
      if (this.parcelNoList.length === 0) {
        this.errors = ['敷地を目的の位置に配置してください。']
        return false
      }
      return true
    },
    /* 戻る */
    $_prevPage() {
      this.$store.commit('setShowModalProject', true)
      this.$router.push({ path: 'top'})
    },
    /* エラーのセット */
    setErrors(errors) {
      this.errors = []
      this.errors = errors
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .table>:not(:first-child) {
    border-top: 1;
    border-top-color: gainsboro;
  }
</style>
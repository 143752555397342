<template>
  <div class="h-100">
    <div class="d-flex flex-column h-100" id="top-input-section">
      <header-section></header-section>
      <p class="mx-2 mt-2 mb-4 d-block">ユーザID：{{ user.userEmail }}</p>
      <div class="my-overflow-y p-2">
        <button class="btn btn-link my-text-color my-text-hover px-0" id="lnk-show-all" @click="$_setShowType($event)">すべてを表示</button>
        <br />
        <button class="btn btn-link my-text-color my-text-hover px-0 mt-1" id="lnk-show-favorite" @click="$_setShowType($event)">お気に入りを表示</button>
        <br />
        <div class="mt-2 ms-1 form-check">
          <input class="form-check-input" type="radio" name="search-type" id="search-type-01" value="1" v-model="searchType">
          <label class="form-check-label" for="search-type-01" >
            プロジェクト名 検索
          </label>
        </div>
        <div class="input-group input-group-sm mt-2">
          <input type="text" v-model="keywordName" class="form-control" :disabled="!isSearchType" autocomplete="off" id="keyword-name" name="keyword-name">
          <button class="btn btn-primary" :disabled="!isSearchType" id="find-by-name" @click="$_setShowType($event)"><font-awesome-icon icon="search" class="pe-none" /></button>
        </div>
        <div class="mt-3 ms-1 form-check">
          <input class="form-check-input" type="radio" name="search-type" id="search-type-02" value="2" v-model="searchType">
          <label class="form-check-label" for="search-type-02">
            住所 検索
          </label>
        </div>
        <div class="input-group input-group-sm mt-2">
          <input type="text" v-model="keywordAddr" class="form-control" :disabled="isSearchType" autocomplete="off" id="keyword-address" name="keyword-address">
          <button class="btn btn-primary" :disabled="isSearchType" id="find-by-addr" @click="$_setShowType($event)"><font-awesome-icon icon="search" class="pe-none" /></button>
        </div>
      </div>
      <div class="my-3 overflow-auto" style="">
        <section v-html="news"></section>
      </div>
      <div class="mt-auto d-grid gap-2">
        <div v-if="isOverCnt" class="alert alert-danger d-flex align-items-center mb-0" role="alert">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          100件までしか登録できません。
        </div>
        <button class="btn btn-primary" style="height:5rem;" type="button" @click="$_startProject()" :disabled="isLimitedUser">プロジェクト新規作成</button>
      </div>
      <footer-section></footer-section>
      <modal-block-message ref="modalBlockMessage" parent-id="top-input-section" />
    </div>
    <!-- 編集モーダル -->
    <modal-project ref="modalProject" v-on:next-page="nextPage" />
  </div>     
</template>

<script>
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'
import ModalProject from '@/views/project/ModalProject'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { PROJECT_MAX_COUNT } from '@/assets/js/const'

export default {
  name: 'InputSection',
  props: {
    propCnt: {
      type: Number
    }
  },
  components: {
    HeaderSection,
    FooterSection,
    ModalProject,
    ModalBlockMessage
  },
  data() {
    return {
      user: this.$store.state.user,
      company: this.$store.state.company,
      searchType: null,
      keywordName: null,
      keywordAddr: null,
      isOverCnt: false,
      isLimitedUser: false,
      showModalProject: this.$store.state.showModalProject,
      news: ''
    }
  },
  computed: {
    isSearchType: function () {
      return !(this.searchType === "2")
    }
  },
  mounted() {
    this.$_getCrossSection()
    this.$_setData()
    this.$_isCheckedModalStatus()
    this.$_loadNewsHtml()
  },
  methods: {
    /* 初期化 */
    $_init() {
    },
    /* データ設定 */
    $_setData() {
      this.searchType = "1"
      // ログインユーザー確認
      if (!(this.user.license === 1) || !(this.company.approvedFlag === 1 || this.company.approvedFlag === 2)) {
        this.isLimitedUser = true
      }
    },
    /* 断面リストの取得 */
    $_getCrossSection() {
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch('getCrossSectionList')
        .then(()=>{
          this.$refs.modalBlockMessage.close()
        })
        .catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
        });
    },
    /* [すべてを表示]クリック, [お気に入りを表示]クリック, [検索アイコン]クリック */
    $_setShowType (evt) {
      let payload = {
        user_id: this.user.userId,  //ユーザID
        project_name: null,         //プロジェクト名
        address: null,              //住所
        bookmark: null,             //お気に入り
      }
      switch(evt.target.id) {
        case 'lnk-show-favorite':  // [お気に入りを表示]クリック
          payload.bookmark = 1
          break;
        case 'find-by-name':  // [検索アイコン]（プロジェクト名）クリック 
          payload.project_name = this.keywordName
          break;
        case 'find-by-addr':  // [検索アイコン]（住所）クリック
          payload.address = this.keywordAddr
          break;
        default: // [すべてを表示]クリック 
        }
      this.$emit('set-show-type', payload)
    },
    /* [プロジェクト新規作成]クリック */
    $_startProject() {
      this.isOverCnt = false
      if(this.propCnt >= PROJECT_MAX_COUNT) {
        this.isOverCnt = true
        return
      }
      // 状態ストアの断面リスト候補を変数に取得
      const crossLists = this.$store.state.lists.cross_sections
      // 状態ストアの初期化
      // (a)楽ロジデータ, (b)ビューアURL, (c)リスト, (d)表示モード
      this.$store.dispatch('initParams')
      this.$store.dispatch('initViewerUrl')
      this.$store.dispatch('initLists')
      this.$store.dispatch('initSettingButtons')
      // 断面リスト候補を再度状態ストアに保存
      const lists = this.$store.state.lists
      lists.cross_sections = crossLists
      this.$store.commit('setLists', lists)
      // 敷地概要と建物計画要望入力画面をポップアップで開く
      this.$refs.modalProject.init()
    },
    /* 画面遷移 */
    nextPage() {
      this.$router.push({path: 'map'})
    },
    /* ModalProject状態チェック */
    $_isCheckedModalStatus(){
      if(this.showModalProject === true){
        this.$refs.modalProject.init()
      }
      this.showModalProject = false
      this.$store.commit('setShowModalProject', this.showModalProject)
    },

    /* お知らせhtmlの読み込み */
    $_loadNewsHtml() {
      fetch('/news.html',{cache: "no-store"}).then(res => {
        res.text().then(html => {
          // console.log(html)
          this.news = html
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 250px;
  }
.my-text-color{
  color: black;
  text-decoration: none;
}
.my-text-hover:hover{
  color: black;
  text-decoration: underline;
}
</style>

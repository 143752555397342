<template>
  <div class="d-flex flex-wrap m-5" id="warehouse-select-plan-output-section">
    <div class="row mt-3">
      <div class="card p-3 m-3 position-relative building" v-for="item in planList" :key="item.no" style="width: 300px;">
        <div class="card-body">
          <div class="card-img-top text-center">
            <img :src="'/api/autoDesign/' + paramsData.project_no + '/planPicture?filePath=' + item.file_path_thumbnail + '&v=' + now " class="card-img-top my-building-image" />
          </div>
          <div class="mt-4">
            <span>プランID 【{{ item.no }}】</span>
            <div class="d-flex">
              <span>容積消化率 {{ item.capacity_digest_rate }} %</span>
              <button class="btn btn-primary btn-sm" style="margin-left: auto;"
                @click="$_nextPage(item)">選択</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <setting-btn icon-color="text-secondary" :show-top-link="true"></setting-btn>
    <modal-block-message ref="modalBlockMessage" parent-id="warehouse-select-plan-output-section" />
  </div>
</template>

<script>
import SettingBtn from '@/views/common/SettingBtn'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { initAutoDesign } from '@/assets/js/dto/E_auto_design'
import { cmnDecimalFormat,cmnChangeFormatDate } from "@/assets/js/common"

export default {
  name: "OutputSectionSelectPlan",
  components: {
    SettingBtn,
    ModalBlockMessage
  },
  data() {
    return {
      paramsData: this.$store.state.params.data,
      planList: [],
      now: null
    }
  },
  mounted() {
    this.$_init()
  },
  methods: {
    /* 初期化 */
    $_init() {
      this.$_setData()
    },
    /* データ設定 */
    $_setData() {
      const dt = new Date()
      this.now = cmnChangeFormatDate(dt,'date','YYYYMMDDHH24MISS')
      // 自動設計実行結果取得サービス
      this.$store.dispatch("getAutoDesignResult")
        .then((resultData) => {
          // 受け取った値を画面の状態ストアに保存する
          let params = this.$store.state.params
          if (params.auto_design === null) params.auto_design = initAutoDesign()
          params.auto_design.auto_design_result_list = []
          params.auto_design.auto_design_result_list = resultData.auto_design_result_list
          this.$store.commit('setParams', params)
          // 容積消化率の設定
          this.planList = this.$store.state.params.auto_design.auto_design_result_list
          for (let i = 0, len = this.planList.length; i < len; i++) {
            this.planList[i].capacity_digest_rate = cmnDecimalFormat(this.planList[i].capacity_digest_rate, 2)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* [選択]クリック */
    $_nextPage(item) {
      this.$refs.modalBlockMessage.init()
      // 自動設計実行結果保存サービス
      this.$store.dispatch("saveAutoDesignResult", { no: item.no })
        .then((resultData) => {
          console.log(resultData)
          // 受け取った値を画面の状態ストアに保存する
          this.$store.dispatch('setViewerUrl', resultData)
          // 共通処理
          this. $_updateCommon()
          this.$refs.modalBlockMessage.close()
          // 状態ストアの表示モード更新
          let payload = this.$store.state.settingButtons
          payload.warehouse = false
          this.$store.commit('setSettingButtons', payload)
          // 自動設計結果表示画面（通常モード）へ遷移
          this.$router.push({ path: 'warehouse'})
        })
        .catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
        })
    },
    /* 共通処理 */
    $_updateCommon() {
      // プランデータ更新日更新, 利用時間登録
      this.$store.dispatch('updateProjectUpdateDate')
      this.$store.dispatch('updateUsersUsageTimes')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-building-image {
  height: 150px;
  width: 150px;
}
</style>
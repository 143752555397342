<template>
  <div class="my-container text-center d-flex align-items-center flex-column">
    <form class="my-form-signin">
      <header-section></header-section>
      <h1 class="h3 mb-4 fw-normal lh-lg">ログイン</h1>
      <input type="email" class="form-control mb-3" v-model="userEmail" placeholder="Email address" id="email" name="email" required> 
      <input type="password" class="form-control mb-4" v-model="password" placeholder="Password" id="password" name="password" required>
      <div v-if="errors.length" class="alert alert-danger fade show mb-3" role="alert">
        <div><span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span></div>
      </div>
      <div class="d-grid gap-3">
        <button class="btn btn-lg btn-primary btn-block" @click="$_login">ログイン</button>
        <span><button class="btn btn-link" @click="$_sctPage">特定商取引法に基づく表記</button></span>
      </div>
    </form>
    <footer-section class="my-footer"></footer-section>
  </div>
</template>

<script>
import { user } from '@/assets/js/dto/user'
import { RESULT_CODE } from '@/assets/js/const'
import { fncCheckAppVersionMatching } from '@/assets/js/function.js'
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'

export default {
  name: 'Login',
  components: {
    HeaderSection,
    FooterSection
  },
  data() {
    return {
      userEmail: null,
      password: null,
      isEnabaleLogin: true,
      errors: []
    }
  },
  // beforeCreate() {
  //   console.log('ログイン')
  // },
  mounted() {
    this.$_init()
  },
  methods: {
    /* 初期化 */
    $_init() {
      fncCheckAppVersionMatching()
        .then(res => {
          if(res) {
            console.log('アプリは最新です。')
          } else {
            console.log('アプリの変更を検知しました。リロードします。')
            const href = window.location.href
            window.location.href = href

          }

        })
    },
    /* ログイン */
    $_login(e) {
      e.preventDefault();
      if (this.$_validation()){
        this.$_tryLogin()
      }
    },
      /* バリデーションチェック */
    $_validation() {
      this.errors = []
      if (!this.userEmail) {
        this.errors.push('メールアドレスを入力してください')
      } else if (!this.userEmail.match(/.+@.+\..+/)) {
        this.errors.push('メールアドレス形式で入力してください');
      }
      if (!this.password) {
        this.errors.push('パスワードを入力してください');
      }
      if(!this.errors.length) {
        return true
      }
      return false
    },
    /* ログイン情報問合せ */
    $_tryLogin(){
      // ログインボタンの二重送信を回避
      if (!this.isEnabaleLogin) return
      this.isEnabaleLogin = false
      let payload = new user()
        payload.userEmail = this.userEmail,
        payload.password = this.password
      this.$store.dispatch('findLoginInfo', payload)
        .then((resultCode) => {
          // console.log('resultCode', resultCode)
          if(resultCode === RESULT_CODE.OK) {
            this.$_nextPage()
          } else if (resultCode === RESULT_CODE.NG)  {
            this.errors.push('メールアドレスあるいはパスワードに間違いがあります')
          }
          this.isEnabaleLogin = true
        }).catch(() => {
          this.isEnabaleLogin = true
        })
    },
    /* 画面遷移 */
    $_nextPage() {
      this.$router.push({ path: 'top' })
    },
    /* [特定商取引法に基づく表記] クリック*/
    $_sctPage() {
      let route = this.$router.resolve({ path: 'specified-commercial-transactions' })
      window.open(route.href, '_blank', 'noreferrer')
    },
  }
}
</script>

<style lang="scss" scoped>
.my-container {
  min-height: 100%;
  &:before, .my-footer {
    content: "";
    display: block;
    flex-grow: 1;
    height: 25px;
    width: 100%;
  }
  .my-form-signin {
    display: block;
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    flex-shrink: 0;
    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      &:focus {
        z-index: 2;
      }
    }
  }
}
</style>
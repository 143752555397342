<template>
  <div id="modal-input-rename-parent">
    <div id="modal-input-rename" class="modal" tabindex="-1" data-bs-keyboard="false" aria-labelledby="modal-input-rename">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title fs-5" id="modal-input-rename">プロジェクト名変更</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <p>プロジェクト名を変更してください。</p>
              </div>
              <div class="row px-2 mb-2">
                <input class="form-control" v-model="project.projectName">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-primary" @click="$_ok">OK</button>
            <button type="button" class="btn btn-sm btn-secondary" @click="$_cancel">Cancel</button>  
          </div>
        </div>
      </div>
    </div>
    <modal-block-message ref="modalBlockMessage" parent-id="modal-input-rename-parent" />
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import cloneDeep from 'lodash/cloneDeep'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { project } from '@/assets/js/dto/project'
import { cmnIsBlank } from '@/assets/js/common'
import { drgAddEvent, drgRemoveEvent, drgMouseUp  } from '@/assets/js/draggable'
import { fncAdjustModalLayer, fncMakeProjectName } from '@/assets/js/function'

export default {
  name: 'ModalInputRename',
  components: {
    ModalBlockMessage
  },
  props: {
    propProject: {
      type: Object
    }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      project: new project()
    }
  },
  watch: {
    propProject: {
      handler: function(newVal) {
        this.project = cloneDeep(newVal)
      },
      deep : true,
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-input-rename').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.myModal = new Modal(document.getElementById('modal-input-rename'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* [OK]クリック */
    $_ok() {
      // [プロジェクト名] = nullのとき
      if (cmnIsBlank(this.project.projectName)) {
        this.project.projectName = fncMakeProjectName(this.project.address)
      }
      // 敷地情報取得
      let payload = this.project.projectNo
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("getBuildingSites", payload)
        .then(() => {
          // プラン情報更新
          this.$_updateProjectData()
        })
        .catch((e) => {
            console.log(e)
            this.$refs.modalBlockMessage.close()
        })
    },
    /* プラン情報更新 */
    $_updateProjectData() {
      let eData = { data: this.$store.state.params.data}
      eData.data.project_name = this.project.projectName
      let payload = {
        engine: eData,
        db: this.project
      }
      this.$store.dispatch("changeProjectData", payload)
        .then((resultData) => {
          // 共通処理
          this.$_updateCommon()
          this.$refs.modalBlockMessage.close()
          // 更新したDBデータを起動元の画面へ返す
          this.$emit('set-prj-name', resultData)
          this.myModal.hide()
        }).catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
        });
    },
    /* 共通処理 */
    $_updateCommon() {
      // プランデータ更新日更新、利用時間登録
      this.$store.dispatch('updateProjectUpdateDate')
      this.$store.dispatch('updateUsersUsageTimes')
    },
    /* [Cancel]クリック */
    $_cancel() {
      this.project = cloneDeep(this.propProject)
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div id="modal-user-info-frame">
    <div class="modal" id="modal-user-info" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ユーザ情報</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <messages ref="messages"></messages>
            <div class="container" v-bind:class="[isUpdate||isRestore ? 'mb-4' : 'my-4']">
              <div class="row text-end" v-if="isUpdate"><p>登録日: {{ formatRegistDate }}</p></div>

              <div class="d-flex justify-content-between" v-if="isRestore">
                <div class="ms-2">
                  <div>ユーザ 「 {{ userInfo.userName }} 」を復元するには、新しいパスワードを入力して[復元]ボタンを押してください。</div>
                  <div>ユーザを削除してから30日以内であれば、作成したプロジェクトも復元されます。</div>
                </div>
              </div>
              <div class="row align-items-center" v-if="!isRestore">
                <div class="col-sm-5 col-md-4 text-nowrap"><span class="badge bg-danger lh-sm">必須</span><label class="ms-3 lh-lg">メールアドレス</label></div>
                <div class="col-sm-7 col-md-8"><input class="form-control form-control-sm" type="text"
                    :disabled="isRestore" v-model="userInfo.userEmail"></div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-sm-5 col-md-4 text-nowrap"><span class="badge bg-danger lh-sm">必須</span><label class="ms-3 lh-lg">パスワード</label></div>
                <div class="col-sm-7 col-md-8"><input class="form-control form-control-sm" type="password"
                    :disabled="isUpdate" v-model="userInfo.password"></div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-sm-5 col-md-4 text-nowrap"><span class="badge bg-danger lh-sm">必須</span><label class="ms-3 lh-lg">パスワード確認</label></div>
                <div class="col-sm-7 col-md-8"><input class="form-control form-control-sm" type="password"
                    :disabled="isUpdate" v-model="userInfo.passwordConfirmation"></div>
              </div>
              <div class="row align-items-center mt-3" v-if="!isRestore">
                <div class="col-sm-5 col-md-4 text-nowrap"><span class="badge bg-danger lh-sm">必須</span><label class="ms-3 lh-lg">名前</label></div>
                <div class="col-sm-7 col-md-8"><input class="form-control form-control-sm" type="text"
                    v-model="userInfo.userName"></div>
              </div>
              <div class="row align-items-center mt-3" v-if="!isRestore">
                <div class="col-sm-5 col-md-4 text-nowrap"><span class="badge bg-danger lh-sm">必須</span><label class="ms-3 lh-lg">権限</label></div>
                <div class="col-sm-3 col-md-3">
                  <select class="form-select form-select-sm" v-model="userInfo.authority" :disabled="isMyself">
                    <option v-for="item in authorities" :key="item.code" :value="item.code">{{item.text}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!isRestore" class="btn btn-primary me-2" @click="$_registrationBtn()">登録</button>
            <button v-if="isRestore" class="btn btn-primary me-2" @click="$_restoreBtn()">復元</button>
            <button class="btn btn-secondary" @click="$_close()">閉じる</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="modal-user-info-frame" v-on:return-confirm-result="returnConfirmResult" />
  </div>
</template>

<script>
  import ModalConfirm from '@/views/common/ModalConfirm'
  import Messages from '@/views/common/Messages'
  import { Modal } from 'bootstrap'
  import cloneDeep from 'lodash/cloneDeep'
  import { authorityList } from '@/assets/js/code'
  import { cmnChangeFormatDate, cmnHasBlank } from '@/assets/js/common'
  import { RESULT_CODE, REGEXP } from '@/assets/js/const'
  import { user } from '@/assets/js/dto/user'

  export default {
    name: 'ModalUserInfo',
    
    components: {
      ModalConfirm,
      Messages,
    },

    data() {
      return {
        myModal: null,
        isUpdate: false,
        isRestore: false,
        isMyself: false,
        confirmMessages: [],
        userInfo: new user(),
      }
    },

    computed: {
      authorities: function() {
        return authorityList
      },
      formatRegistDate: function() {
        return cmnChangeFormatDate(new Date (Date.parse(this.userInfo.registDate)), 'date', 'YYYY/MM/DD')
      },
      formatUpdateDate: function() {
        return cmnChangeFormatDate(new Date (Date.parse(this.userInfo.updateDate)), 'date', 'YYYY/MM/DD')
      }
    },

    methods: {
      /* モーダルウィンドウを開く */
      open(userInfo) {
        if(userInfo && userInfo.deletedFlag === 0) {  // 確認・変更
          this.isUpdate = true
          this.isRestore = false
          const copiedUserInfo = cloneDeep(userInfo)
          copiedUserInfo.password = ''
          this.userInfo = copiedUserInfo
          if(this.userInfo.userId === this.$store.state.user.userId){
            this.isMyself = true
          } else {
            this.isMyself = false
          }
        } else if(userInfo && userInfo.deletedFlag === 1) {  // 復元
          this.isUpdate = false
          this.isRestore = true
          const copiedUserInfo = cloneDeep(userInfo)
          copiedUserInfo.password = ''
          this.userInfo = copiedUserInfo
          
        } else {    // 新規登録
          this.userInfo = new user()
          this.isUpdate = false
          this.isRestore = false
          this.userInfo.companyId = this.$store.state.user.companyId
          this.userInfo.passwordConfirmation = ''
          this.isMyself = false
        }
        this.myModal = new Modal(document.getElementById('modal-user-info'), {})

        this.myModal.show()
      },

      /* 登録ボタン */
      $_registrationBtn() {
        this.$refs.messages.clearAllMsg()
        if(this.$_validation()) return

        this.confirmMessages = []
        if(this.isUpdate) {
          this.confirmMessages.push('ユーザ情報を更新します。', 'よろしいですか？')
        } else {
          this.confirmMessages.push('ユーザ情報を新規登録します。', 'よろしいですか？')
        }
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()
      },

      /* 復元ボタン */
      $_restoreBtn() {
        this.$refs.messages.clearAllMsg()
        if(this.$_validation()) return

        this.confirmMessages = []
        this.confirmMessages.push('ユーザを復元します。', 'よろしいですか？')
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()
      },
      
      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.NG) {
          return false
        } else {
          if(this.isRestore) {
            this.$_restore()
          } else {
            this.$_registration()
          }
          return true
        }
      },

      /* 登録 */
      $_registration() {
        this.$emit('userRegistStarted')
        const payload = this.userInfo
        this.$store.dispatch('registUserInfo', payload)
          .then((res) => {
            this.$emit('userRegistCompleted')
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            this.$_close()
          })
          .catch((e) => {
            console.log(e)
          })
        
      },
      
      /* 復元 */
      $_restore() {
        this.$emit('userRegistStarted')
        const payload = this.userInfo
        this.$store.dispatch('restoreUserInfo', payload)
          .then((res) => {
            this.$emit('userRegistCompleted')
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            this.$_close()
          })
          .catch((e) => {
            console.log(e)
          })
      },

      /* モーダルウィンドウを閉じる */
      $_close() {
        this.$refs.messages.clearAllMsg()
        this.myModal.hide()
      },

      /* バリデーション */
      $_validation() {
        let invalid = false
        
        /* 必須チェック */
        let required = []
        if(this.isUpdate) {
          required = ['userEmail', 'userName', 'authority']
        } else if(this.isRestore) {
          required = ['password', 'passwordConfirmation']
        } else {
          required = ['userEmail', 'password', 'passwordConfirmation', 'userName', 'authority']
        }
        invalid = cmnHasBlank(this.userInfo, required)
        
        if(invalid) {
          this.$refs.messages.addErrorMsg('未入力の必須項目があります。')
          return invalid
        }
        
        if(!this.isRestore){
          /* メールアドレス形式チェック */
          invalid = !REGEXP.EMAIL.test(this.userInfo.userEmail)
          if(invalid) {
            this.$refs.messages.addErrorMsg('メールアドレスを正しい形式で入力してください。')
            return invalid
          }
        }

        if(!this.isUpdate){
          /* パスワード一致チェック */
          invalid = this.userInfo.password !== this.userInfo.passwordConfirmation
          if(invalid) {
            this.$refs.messages.addErrorMsg('パスワードが一致しません。')
            return invalid
          }

          /* パスワード文字数チェック */
          invalid = (this.userInfo.password.length < 8 || this.userInfo.password.length > 60)
          if (invalid) {
            this.$refs.messages.addErrorMsg('パスワードは8文字以上60文字以下に設定してください。')
            return invalid
          }

          /* パスワード要件チェック */
          invalid = !REGEXP.PASSWORD.test(this.userInfo.password)
          if (invalid) {
            this.$refs.messages.addErrorMsg('パスワードには英大文字, 小文字, 数字の各文字種を1文字以上含むように設定してください。')
            return invalid
          }

        }
        return invalid
      },
    }
  }
</script>
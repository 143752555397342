<template>
  <div id="modal-confirm-address-parent">
    <div id="modal-confirm-address" class="modal" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">住所変更確認画面</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="my-2">
              <p class="m-2">{{ parcelNoList.length > 0 ? parcelNoList[0].parcelNoName : '' }}が選択されました。</p>
              <p class="m-2">住所とプロジェクト名を設定してください。</p>
              <label>所在地 <span class="badge bg-danger p-1">必須</span></label>
              <div class="d-flex mt-2">
                <select class="form-select" v-model="prefCode" @change="$_onPrefChange">
                  <option value=""></option>
                  <option v-for="item in prefList" :key="item.adcd" :value="item.adcd">{{ item.kanjiPref }}</option>
                </select>
                <select class="form-select ms-1" v-model="cityCode" @change="$_onCityChange">
                  <option value=""></option>
                  <option v-for="item in cityList" :key="item.adcd" :value="item.adcd">{{ item.kanjiCity }}</option>
                </select>
                <select class="form-select ms-1" v-model="azaCode" @change="$_onAzaChange">
                  <option value=""></option>
                  <option v-for="item in azaList" :key="item.adcd" :value="item.adcd">{{ item.kanjiOoaza + item.kanjiAza }}</option>
                </select>
              </div>
              <div class="d-flex justify-content-between mt-3 mb-2">
                <label class="">プロジェクト名称</label>
                <div>
                  <button class="btn btn-primary btn-sm" :disabled="!isProjectNameEdit" @click="$refs.modalParcelNoList.init()">
                    <font-awesome-icon icon="table"/>
                  </button>
                </div>
              </div>
              <div class="d-flex mb-3">
                <input class="form-check-input mt-2" type="checkbox" v-model="isProjectNameEdit" @change="$_onChangeIsProjectNameEdit"/>
                <input class="form-control ms-2" type="text" v-model="projectName" :disabled="!isProjectNameEdit" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show mx-auto mb-3" role="alert">
              <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
            </div>
            <button class="btn btn-primary btn-sm" @click="$_next">次へ</button>
            <button class="btn btn-secondary btn-sm" @click="$_cancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-confirm-address-parent" />
    <modal-parcel-no-list ref="modalParcelNoList" :prop-parcel-no-list="parcelNoList" @set-parcel-no="setParcelNo" />
  </div>  
</template>

<script>
import { Modal } from 'bootstrap'
import ModalParcelNoList from '@/views/map/ModalParcelNoList'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { project } from '@/assets/js/dto/project.js'
import { cmnIsBlank } from '@/assets/js/common'
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable' 
import { fncMakeProjectName } from '@/assets/js/function'
import { initSitePlanning } from '@/assets/js/dto/E_site_planning.js'

export default {
  name: 'ModalConfirmAddress',
  components: {
    ModalParcelNoList,
    ModalBlockMessage
  },
  props: {
    propParcelNoList: {
      type: Array
    }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      paramsData: this.$store.state.params.data,
      parcelNoList: [],
      prefCode: null,
      cityCode: null,
      azaCode: null,
      selectedPref: null,
      selectedCity: null,
      selectedAza: null,
      prefList: [],
      cityList: [],
      azaList: [],
      isProjectNameEdit: false,
      projectName: null,
      errors: []
    }
  },
  watch: {
    propParcelNoList: {
      handler: function(newVal) {
        this.parcelNoList = newVal
      },
      deep : true,
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-confirm-address').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
    this.$_getPrefList()
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.$_setData()
      this.myModal = new Modal(document.getElementById('modal-confirm-address'), {})
      this.myModal.show()
    },
    /* データの設定 */
    async $_setData() {
      this.$refs.modalBlockMessage.init()
      this.errors = []
      this.prefCode = this.parcelNoList[0].addressCode.substring(0, 2)
      await this.$_onPrefChange()
      this.cityCode = this.parcelNoList[0].addressCode.substring(0, 5)
      await this.$_onCityChange()
      this.azaCode = (this.parcelNoList[0].addressCode.length == 11 ? this.parcelNoList[0].addressCode : '')
      await this.$_onAzaChange()
      this.isProjectNameEdit = false
      this.projectName = this.paramsData.project_name
      this.$refs.modalBlockMessage.close()
    },
    /* 都道府県の取得 */
    $_getPrefList() {
      let params = { adcd: null, getLevel: 1 }
      this.$store.dispatch('addressDirectory', params)
        .then((list) => {
          this.prefList = list
        })
    },
    /* 都道府県変更 */
    async $_onPrefChange() {
      this.cityCode = ''
      this.selectedCity = null
      this.azaCode = ''
      this.selectedAza = null
      this.azaList = []
      if (cmnIsBlank(this.prefCode)) {
        this.cityList = []
        this.selectedPref = null
      } else {
        let params = { adcd: this.prefCode, getLevel: 2 }
        await this.$store.dispatch('addressDirectory', params)
          .then((list) => {
            this.cityList = list
          })
        this.selectedPref = this.prefList.find((item) => {
          return item.adcd === this.prefCode
        })
      }
      this.$_onChangeIsProjectNameEdit()
    },
    /* 市区町村変更 */
    async $_onCityChange() {
      this.azaCode = ''
      this.selectedAza = null
      if (cmnIsBlank(this.cityCode)) {
        this.azaList = []
        this.selectedCity = null
      } else {
        let params = { adcd: this.cityCode, getLevel: 3 }
        await this.$store.dispatch('addressDirectory', params)
          .then((list) => {
            this.azaList = list
            // console.log('azaList', this.azaList)
          })
        this.selectedCity = this.cityList.find((item) => {
          return item.adcd === this.cityCode
        })
      }
      this.$_onChangeIsProjectNameEdit()
    },
    /* 大字町丁目変更 */
    async $_onAzaChange() {
      if (cmnIsBlank(this.azaCode))
        this.selectedAza = null
      else {
        this.selectedAza = this.azaList.find((item) => {
          return item.adcd === this.azaCode
        })
      }
      this.$_onChangeIsProjectNameEdit()
    },
    /* プロジェクト名のON・OFF変更 */
    $_onChangeIsProjectNameEdit() {
      if (this.isProjectNameEdit) {
        let prjName = ''
        // 大字町丁目まで選択されている場合のみ
        if (this.selectedAza !== null) 
          prjName = fncMakeProjectName(this.selectedAza.kanjiPref + this.selectedAza.kanjiCity + this.selectedAza.kanjiOoaza + this.selectedAza.kanjiAza)
        this.projectName = prjName
      } else {
        this.projectName = this.paramsData.project_name
      }
    },
    /* 選択地番からプロジェクト名を設定 */
    setParcelNo(selectedData) {
      this.projectName = fncMakeProjectName(selectedData.parcelNoName)
    },
    /* 次へ */
    $_next() {
      if (!this.$_validation()) return

      this.paramsData.project_name = this.projectName
      this.paramsData.address_code = this.azaCode
      this.paramsData.address_pref = this.selectedAza.kanjiPref
      this.paramsData.address_city = this.selectedAza.kanjiCity
      this.paramsData.address_ooaza = this.selectedAza.kanjiOoaza
      this.paramsData.address_aza = this.selectedAza.kanjiAza
      let params = this.$store.state.params
      params.data = this.paramsData
      if(params.data.site_planning == null){
        params.data.site_planning = initSitePlanning()
      }
      this.$store.commit('setParams', params)
      let dbProject = new project()
      dbProject.projectNo = this.paramsData.project_no
      dbProject.projectName = this.projectName
      dbProject.addressCode = this.azaCode
      dbProject.address = this.selectedAza.kanjiPref + this.selectedAza.kanjiCity + this.selectedAza.kanjiOoaza + this.selectedAza.kanjiAza

      let payload = {
        engine: {data: this.paramsData},
        db: dbProject
      }
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch('changeProjectData', payload)
        .then(() => {
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          this.$refs.modalBlockMessage.close()
          this.$emit('next')
          this.myModal.hide()
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close()
        })

    },
    /* バリデーション */
    $_validation() {
      this.errors = []
      if (cmnIsBlank(this.prefCode))
        this.errors.push('都道府県を選択してください')
      if (cmnIsBlank(this.cityCode))
        this.errors.push('市区町村を選択してください')
      if (cmnIsBlank(this.azaCode))
        this.errors.push('大字町丁目を選択してください')
      if (cmnIsBlank(this.projectName))
        this.errors.push('プロジェクト名称を入力してください')
      if (this.errors.length > 0)
        return false
      return true
    },
    /* Cancel */
    $_cancel() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>

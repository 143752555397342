<template>
  <div id="modal-deregulation-settings-parent">
    <div class="modal" id="modal-deregulation-settings" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">規制緩和設定</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <span>建蔽率</span>
            </div>
            <div class="row ps-3">
              <div class="col-8 form-check my-font">
                <input v-model="deregulation.coverage_corner_relax" class="form-check-input" type="checkbox" id="check01"
                  @change="$_onChangeCornerRelax">
                <label class="form-check-label" for="check01">角敷地等による緩和</label>
              </div>
              <div class="col my-font ps-0">
                <span>{{deregulation.coverage_corner_relax_text}}</span>
              </div>
            </div>
            <div class="row ps-3">
              <div class="col-8 form-check my-font">
                <input v-model="deregulation.coverage_fire_proof_building" class="form-check-input" type="checkbox"
                  id="check02" @change="$_onChangeFireProofBuilding">
                <label class="form-check-label" for="check02">耐火建築物による緩和&nbsp;</label>
                <span class="text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true"
                  title="耐火建築物による緩和を設定します。防火地域内、準防火地域内は建蔽緩和が受けられます。">
                  <font-awesome-icon icon="question-circle" />
                </span>
              </div>
              <div class="col my-font ps-0">
                <span>{{deregulation.coverage_fire_proof_building_text}}</span>
              </div>
            </div>
            <div class="row ps-3">
              <div class="col-8 form-check my-font">
                <input v-model="deregulation.coverage_limit" class="form-check-input" type="checkbox" id="check03"
                  @change="$_onChangeCovLimit">
                <label class="form-check-label" for="check03">建蔽率の制限</label>
              </div>
              <div class="col my-font ps-0">
                <input type="text" class="w-75" v-model="deregulation.coverage_limit_rate" :disabled="!isCovLimit"
                  @blur="$_onChangeCovLimitRate">
                <span class="ps-1">%</span>
              </div>
            </div>
            <div class="row ps-1">
              <div class="col-8 my-font">
                <span>許容建蔽率</span>
              </div>
              <div class="col my-font ps-1">
                <span class="text-danger" v-if="showCalcValue">{{ formatCalcValue }} ⇒ </span><span>{{ formatCoverage }}
                  %</span>
              </div>
            </div>
            <div class="row mt-3">
              <span>用途地域一覧</span>
            </div>
            <hr class="my-1">
            <div v-for="(item, index) in itemList.slice(0,4)" :key="item.no">
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>No</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ index + 1 }}</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>用途地域</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ item.usage_name }}</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>建蔽率</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ $_decimalFormat(item.coverage, 2) }} %</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>角地</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ $_decimalFormat(item.connerRelax, 2) }} %</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>耐火</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ $_decimalFormat(item.fireProofRelax, 2) }} %</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>防火地区</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ item.fire_name }}</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-5 my-font">
                  <span>敷地面積</span>
                </div>
                <div class="col-4 my-font">
                  <span>{{ $_decimalFormat(item.site_area, 2) }} ㎡</span>
                </div>
              </div>
              <hr class="my-1">
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show mx-auto mb-3" role="alert">
              <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
            </div>
            <button class="btn btn-sm btn-primary" @click="$_ok">OK</button>
            <button class="btn btn-sm btn-secondary" @click="$_cancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-deregulation-settings-parent"/>
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import { E_deregulation } from '@/assets/js/dto/E_deregulation'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { cmnDecimalFormat, cmnDivide, cmnMultiply, cmnAdd, cmnCheckNumFormat, cmnChangeStrToNum } from '@/assets/js/common'
  import { USAGE_NO } from '@/assets/js/const'
  import { drgAddEvent, drgRemoveEvent,drgMouseUp } from '@/assets/js/draggable'

  export default {
    name: 'ModalDeregulationSettings',
    components: {
      ModalBlockMessage
    },
    data() {
      return {
        myModal: null,
        dragElement: null,
        coverage: null,
        itemList: [],
        deregulation: new E_deregulation(),
        project: {},
        useCovLimit: false,
        errors: [],
        showCalcValue: false,
        calcValue: null
      }
    },
    computed: {
      formatCalcValue: function () {
        return cmnDecimalFormat(this.calcValue, 2)
      },
      formatCoverage: function () {
        return cmnDecimalFormat(this.coverage, 2)
      },
      isCovLimit: function () {
        if (this.useCovLimit) return true
        else return false
      }
    },
    mounted() {
      this.dragElement = document.getElementById('modal-deregulation-settings').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
    },
    beforeUnmount() {
      drgRemoveEvent(this.dragElement)
    },
    methods: {
      /* 初期化 */
      init() {
        this.$_setData()
        this.myModal = new Modal(document.getElementById('modal-deregulation-settings'), {})
        this.myModal.show()
      },
      /* データ設定 */
      $_setData() {
        this.project = this.$store.state.params.data
        this.deregulation = this.project.site_planning.deregulation
        const usageAreaList = this.$store.state.params.data.site_planning.usage.usage_area_list
        this.itemList = []
        for (let i = 0, len = usageAreaList.length; i < len; i++) {
          this.itemList.push({ ...usageAreaList[i], cornerRelax: null, fireProofRelax: null })
        }
        this.$_onChangeCornerRelax()
        this.$_onChangeFireProofBuilding()
        this.$_onChangeCovLimit()
        this.$_onChangeCovLimitRate()
      },
      /* 角敷地等による緩和変更 */
      $_onChangeCornerRelax() {
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          this.itemList[i].connerRelax = this.deregulation.coverage_corner_relax ? '+10.00' : '0.00'
        }
        this.$_allowCoverage()
      },
      /* 耐火建築物による緩和変更 */
      $_onChangeFireProofBuilding() {
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          if (this.deregulation.coverage_fire_proof_building) {
            this.itemList[i].fireProofRelax = this.itemList[i].usage_no !== USAGE_NO.SHOGYO ? '+10.00' : '+100.00'
          } else {
            this.itemList[i].fireProofRelax = '0.00'
          }
        }
        this.$_allowCoverage()
      },
      /* 建蔽率の制限変更 */
      $_onChangeCovLimit() {
        if (this.deregulation.coverage_limit) this.useCovLimit = true
        else this.useCovLimit = false
        this.$_allowCoverage()
      },
      /* 制限建蔽率の変更 */
      $_onChangeCovLimitRate() {
        this.deregulation.coverage_limit_rate = cmnDecimalFormat(cmnChangeStrToNum(this.deregulation.coverage_limit_rate), 2) === 'NaN' ? this.deregulation.coverage_limit_rate : cmnDecimalFormat(cmnChangeStrToNum(this.deregulation.coverage_limit_rate), 2)
        this.$_allowCoverage()
      },
      /* 許容建蔽率の算出 */
      $_allowCoverage() {
        let totalSiteArea = 0
        let totalIncreaseSiteArea = 0
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          //➀各敷地の建蔽率 = [建蔽率] + [角地] + [耐火]（max100%とする)
          let cov = cmnAdd(cmnAdd(this.itemList[i].coverage, Number(this.itemList[i].connerRelax)), Number(this.itemList[i].fireProofRelax))
          cov = cov > 100 ? 1 : cmnDivide(cov, 100, -2)
          //➁合計敷地面積 = SUM([敷地面積])
          totalSiteArea = cmnAdd(totalSiteArea, this.itemList[i].site_area)
          //➂合計加重敷地面積 = SUM(➀各敷地の建蔽率 * [敷地面積])
          totalIncreaseSiteArea = cmnAdd(totalIncreaseSiteArea, cmnMultiply(cov, this.itemList[i].site_area))
        }
        //➃許容建蔽率 = ➂合計加重敷地面積 / ➁合計敷地面積
        this.coverage = cmnMultiply(cmnDivide(totalIncreaseSiteArea, totalSiteArea, -4), 100)
        if (this.deregulation.coverage_limit && this.coverage >= this.deregulation.coverage_limit_rate) {
          this.showCalcValue = true
          this.calcValue = this.coverage
          this.coverage = this.deregulation.coverage_limit_rate
        } else this.showCalcValue = false
      },
      /* 数値のフォーマット */
      $_decimalFormat(siteArea, digit) {
        return cmnDecimalFormat(siteArea, digit)
      },
      /* OK */
      $_ok() {
        if (!this.$_validation()) {
          return
        }
        let params = this.$store.state.params
        params.data.site_planning.deregulation.coverage_corner_relax = this.deregulation.coverage_corner_relax
        params.data.site_planning.deregulation.coverage_fire_proof_building = this.deregulation.coverage_fire_proof_building
        params.data.site_planning.deregulation.coverage_limit = this.deregulation.coverage_limit
        params.data.site_planning.deregulation.coverage_limit_rate = this.deregulation.coverage_limit_rate
        this.$store.commit('setParams', params)
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('updateBuildingSite', { data: params.data })
        .then((returnData) => {
          let params = this.$store.state.params
          params.data.site_planning = returnData.data.site_planning
          this.$store.commit('setParams', params)
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          this.$refs.modalBlockMessage.close()
          this.myModal.hide()
        })
          .catch(() => {
            this.$refs.modalBlockMessage.close()
          })
      },
      /* Cancel */
      $_cancel() {
        this.$store.dispatch('updateUsersUsageTimes')
        this.myModal.hide()
      },
      /* クローズ時の処理 */
      $_onClickCloseButton() {
        drgMouseUp()
      },
      /* バリデーションチェック */
      $_validation() {
        this.errors = []
        if (!cmnCheckNumFormat(this.deregulation.coverage_limit_rate)) this.errors.push("制限建蔽率は数値で入力してください。")
        if (this.deregulation.coverage_limit_rate < 10 || this.deregulation.coverage_limit_rate > 100)
          this.errors.push("制限建蔽率は10以上100以下の範囲で入力してください。")
        if (!this.errors.length) {
          return true
        }
        return false
      }
    }
  }

</script>

<style lang="scss" scoped>
  .my-font {
    font-size: 14px;
  }

  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div class="d-block mw-100">
    <div class="card m-3 my-mx-width">
      <div class="card-header alert-primary">マスタテンプレートアップロード</div>
      <div class="card-body container">
        <div class="input-group row m-0 align-items-center">
          <span class="col-sm-12 col-lg-auto mb-sm-2 mb-lg-0">アップロード対象ファイル：</span>
          <input @change="pickedFile" type="file" class="form-control col-sm-10 col-lg-auto" id="inputGroupFile">
          <button @click="uploadFileSubmit" class="input-group-text col-sm-auto col-lg-auto btn-primary" type="submit" id="inputGroupFileUpload">アップロード</button>
        </div>
      </div>
    </div>
    <div class="card m-3 my-mx-width">
      <div class="card-header alert-primary">マスタテンプレートダウンロード</div>
      <div class="card-body container">
        <div class="row m-0 ps-0 align-items-center justify-content-between">
            <span class="col-sm-12 col-lg-auto">現在使用中のマスタテンプレート登録日時：</span>
            <div class="col pe-0 d-flex align-items-center justify-content-between">
              <span class=""> {{ currentUpdated }} </span>
              <button @click="downloadFile" class="input-group-text btn-primary">ダウンロード</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// import {  } from '@/assets/js/common'
// import axios from 'axios';
  export default {
    name: "OutputSection",
    data() {
      return {
        uploadFile: null,
        currentUpdated: "2021/10/12 14:29:54" //後ほど書き換えてください
      }
    },
    // methods: {
    //   pickedFile: function(e) {
    //     console.log('testPickedFile');
    //     e.preventDefault();
    //     let files = e.target.files;
    //     this.uploadFile = files[0];
    //   },
    //   uploadFileSubmit: function() {
    //     console.log('testUploadFile');
    //     let formData = new FormData;
    //     formData.append('file', this.uploadFile);
    //     axios.post('/path',formData)
    //     .then((response) => {
    //             console.log(response.data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    //   },
    //   downloadFile: function() {
    //     console.log('testDownloadFile');
    //     axios
    //       .get("/path", {
    //         responseType: "blob",
    //       })
    //       .then((res) => {
    // 
    //       })
    //       .catch((error) => {
    //         console.log(error.message);
    //       });
    //   }
    // }
  }
</script>

<style lang="scss" scoped>
  .my-mx-width {
    max-width: 1000px;
  }
</style>
import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import Top from '../views/Top.vue'
import Map from '../views/Map.vue'
import BorderSettings from '../views/BorderSettings.vue'
import RoadAndEntrance from '../views/RoadAndEntrance.vue'
import RegulationSettings from '../views/RegulationSettings.vue'
import ExceptedArea from '../views/ExceptedArea.vue'
import Volume from '../views/Volume.vue'
import Warehouse from '../views/Warehouse.vue'
import WarehouseSelectPlan from '../views/WarehouseSelectPlan.vue'
import FileOutput from '../views/FileOutput.vue'
import MasterMaintenance from '../views/masterMaintenance/MasterMaintenance.vue' //マスタメンテナンス
import PreRegistration from '../views/PreRegistration.vue'
import UserSignUp from '../views/UserSignUp.vue'
import UserList from '../views/UserList.vue'
import Registered from '../views/Registered.vue'
import RegistrationInfoCheck from '../views/RegistrationInfoCheck.vue'
import BillingInfo from '../views/BillingInfo'
import Inquiry from '../views/Inquiry'
import SpecifiedCommercialTransactions from '../views/SpecifiedCommercialTransactions'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/top',
    name: 'Top',
    component: Top
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/border-settings',
    name: 'BorderSettings',
    component: BorderSettings
  },
  {
    path: '/road-and-entrance',
    name: 'RoadAndEntrance',
    component: RoadAndEntrance
  },
  {
    path: '/regulation-settings',
    name: 'RegulationSettings',
    component: RegulationSettings
  },
  {
    path: '/excepted-area',
    name: 'ExceptedArea',
    component: ExceptedArea
  },
  {
    path: '/volume',
    name: 'Volume',
    component: Volume
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: Warehouse
  },
  {
    path: '/warehouse-select-plan',
    name: 'WarehouseSelectPlan',
    component: WarehouseSelectPlan
  },
  {
    path: '/file-output',
    name: 'FileOutput',
    component: FileOutput
  },
  {
    path: '/masterMaintenance',
    name: 'MasterMaintenance',
    component: MasterMaintenance
  },
  {
    path: '/userSignUp',
    name: 'UserSignUp',
    component: UserSignUp
  },
  {
    path: '/preRegistration',
    name: 'PreRegistration',
    component: PreRegistration
  },
  {
    path: '/userList',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/registered',
    name: 'Registered',
    component: Registered
  },
  {
    path: '/registrationInfoCheck',
    name: 'RegistrationInfoCheck',
    component: RegistrationInfoCheck
  },
  {
    path: '/billingInfo',
    name: 'BillingInfo',
    component: BillingInfo
  },
  {
    path: '/inquiry',
    name: 'Inquiry',
    component: Inquiry
  },
  {
    path: '/specified-commercial-transactions',
    name: 'SpecifiedCommercialTransactions',
    component: SpecifiedCommercialTransactions
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

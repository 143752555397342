<template>
  <div class="my-container">
    <div class="my-side-contents">
      <menu-section></menu-section>
    </div>
    <div class="my-main-contents">
      <main-section></main-section>
    </div>
  </div>
</template>

<script>
  import MenuSection from '@/views/UserMenuSection'
  import MainSection from '@/views/billingInfo/MainSection'

  export default {
    name: "BillingInfo",
    components: {
      MenuSection,
      MainSection
    },
  }
</script>
    
<style lang="scss" scoped>
  @import "../assets/style/style.scss";

</style>
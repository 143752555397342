<template>
  <div id="user-list-main" class="my-overflow-y mx-sm-3 mx-5 mt-4">
    <h4 class="mb-4">ユーザ一覧</h4>
    <messages ref="messages"></messages>
    <div class="container pe-sm-0 pe-xl-3">
      <div class="row justify-content-xl-between align-items-xl-end">
        <div class="row row-cols-sm-2 col-xl-9 pe-sm-0 pe-xl-3">
          <span class="d-flex align-items-center col-sm-3 border py-1 my-bg-color fw-bold">会社名</span>
          <span class="col-sm-9 border py-1" >{{ customerInfo.companyName }}</span>
          <span class="col-sm-3 border border-top-0 py-1 my-bg-color fw-bold">ライセンス</span>
          <span class="col-sm-9 border border-top-0 py-1">割当済み件数 {{ customerInfo.usedLicenseNum }} 件 / 所持件数 {{ customerInfo.licenseNum }} 件</span>
          <span class="col-sm-3 border border-top-0 py-1 my-bg-color fw-bold">ユーザ数</span>
          <span class="col-sm-9 border border-top-0 py-1">{{ userCount }} 人</span>
        </div>
        <div class="row col-xl-auto mt-4 mt-xl-0">
          <button type="button" class="col-sm-2 col-xl px-xl-4 py-2 btn btn-primary" style="min-width:120px;" @click="$_newUser()">新規追加</button>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <h5>ライセンスありユーザ</h5>
      <table class="table table-bordered table-sm small table-hover mt-3 align-middle text-break">
        <thead class="table-light align-middle">
          <tr>
            <th v-for="header in userListHeader" :key="header.key">{{ header.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in withLicenseList" :key="index">
            <td id="td1">{{ user.userEmail }}</td>
            <td id="td2">{{ user.userName }}</td>
            <td id="td3">{{ $_authorityCodeToText(user.authority) }}</td>
            <td class="text-center" id="td4"><button class="btn btn-primary btn-sm"
                @click="$_remove(user)">解除</button></td>
            <td class="text-center" id="td5"><button class="btn btn-primary btn-sm"
                @click="$_detail(user)">詳細</button></td>
            <td id="td6"><button class="btn btn-danger btn-sm"
                @click="$_delete(user)" v-if="!$_isSupervisor(user) && !$_isMyself(user)">削除</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4">
      <h5>ライセンスなしユーザ</h5>
      <table class="table table-bordered table-sm small table-hover mt-3 align-middle text-break">
        <thead class="table-light align-middle">
          <tr>
            <th v-for="header in userListHeader" :key="header.key">{{ header.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in withoutLicenseList" :key="index">
            <td id="td1">{{ user.userEmail }}</td>
            <td id="td2">{{ user.userName }}</td>
            <td id="td3">{{ $_authorityCodeToText(user.authority) }}</td>
            <td id="td4"><button class="btn btn-primary btn-sm"
                @click="$_attach(user)">割当</button></td>
            <td id="td5"><button class="btn btn-primary btn-sm"
                @click="$_detail(user)">詳細</button></td>
            <td id="td6"><button class="btn btn-danger btn-sm"
                @click="$_delete(user)" v-if="!$_isSupervisor(user) && !$_isMyself(user)">削除</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="mt-4">
      <div class="d-flex align-items-center deleted-user" @click="$_switchDeletedUserList">
        <div class="mx-2" :class="[{closed: !showDeletedUserList}, {opened: showDeletedUserList}]"></div>
        <h5 class="text-muted">削除したユーザ<span class="ms-2 font-weight-bold"></span></h5>
        
      </div>
      <table class="table table-bordered table-sm small table-hover mt-3 align-middle text-break" v-if="showDeletedUserList">
        <thead class="table-light align-middle">
          <tr>
            <th v-for="header in deletedUserListHeader" :key="header.key">{{ header.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in deletedList" :key="index">
            <td id="td1">{{ user.userEmail }}</td>
            <td id="td2">{{ user.userName }}</td>
            <td id="td3">{{ $_authorityCodeToText(user.authority) }}</td>
            <td id="td-deleted-date">{{ $_formatUpdateDate(user) }}</td>
            <td id="td-restorebtn"><button class="btn btn-success btn-sm"
                @click="$_restore(user)">復元</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <!-- Modal -->
    <modal-user-info ref="modalUserInfo" @userRegistStarted="$_userRegistStarted" @userRegistCompleted="$_userRegistCompleted"/>
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="user-list-main" v-on:return-confirm-result="returnConfirmResult" :focus-no-btn="true" />
    <modal-block-message ref="modalBlockMessage" parent-id="user-list-main" />
    <setting-btn icon-color="text-secondary" :show-top-link="true"></setting-btn>
  </div>
</template>

<script>
  import ModalUserInfo from '@/views/userList/ModalUserInfo'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import ModalConfirm from '@/views/common/ModalConfirm'
  import SettingBtn from '@/views/common/SettingBtn'
  import Messages from '@/views/common/Messages'
  import { authorityList, codeToText } from '@/assets/js/code'
  import { FLAG, RESULT_CODE } from '@/assets/js/const'
  import { cmnChangeFormatDate } from '@/assets/js/common'

  export default {
    name: 'MainSection',

    components: {
      ModalUserInfo,
      ModalBlockMessage,
      ModalConfirm,
      SettingBtn,
      Messages,
    },

    data() {
      return {
        user: this.$store.state.user,
        customerInfo: {
          companyId: null,
          companyName: null,
          usedLicenseNum: null,
          licenseNum: null,
        },
        userCount: null,
        withLicenseList: [],
        withoutLicenseList: [],
        deletedList: [],
        confirmMessages: [],
        showDeletedUserList: false,
        deletedUser: null,

        userListHeader: [
          { key: 'mailAddress', label: 'メールアドレス' },
          { key: 'userName', label: '名前' },
          { key: 'authority', label: '権限' },
          { key: 'licenseBtn', label: 'ライセンス' },
          { key: 'detailBtn', label: 'ユーザ情報' },
          { key: 'deleteBtn', label: 'ユーザ削除'}
        ],
        
        deletedUserListHeader: [
          { key: 'mailAddress', label: 'メールアドレス' },
          { key: 'userName', label: '名前' },
          { key: 'authority', label: '権限' },
          { key: 'licenseBtn', label: '削除日' },
          { key: 'detailBtn', label: 'ユーザの復元' }
        ],
      }
    },

    mounted() {
      this.$_viewUserList()
    },

    methods: {
      /* ユーザ一覧の取得 */
      $_viewUserList() {
        this.$refs.modalBlockMessage.init()
        let payload = {
          company_id: this.user.companyId,
        }
        this.$store.dispatch('getUserList', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)

            let cstInfo = this.customerInfo
            cstInfo.companyName = res.company.companyName
            cstInfo.usedLicenseNum = 0  // devideUserListで設定
            cstInfo.licenseNum = res.company.numberOfLicenses
            this.userCount = res.users.length
            this.$_devideUserList(res.users)
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          }) 
      },

      /* ライセンスの有無の振り分けと割当済み件数の設定 */
      $_devideUserList(userList) {
        this.withoutLicenseList.length = 0
        this.withLicenseList.length = 0
        this.deletedList.length = 0

        this.withoutLicenseList = userList.filter((item) => {
          return item.deletedFlag === 0 && item.license === FLAG.OFF
        })
        this.withLicenseList = userList.filter((item) => {
          return item.deletedFlag === 0 && item.license === FLAG.ON
        })
        this.deletedList = userList.filter((item) => {
          return item.deletedFlag === 1
        })
        this.customerInfo.usedLicenseNum = this.withLicenseList.length
      },

      /* 権限の変換 */
      $_authorityCodeToText(authority) {
        return codeToText(authorityList, authority)
      },

      $_isSupervisor(user) {
        return user.supervisorFlag === 1
      },

      $_isMyself(user) {
        return (user.userId === this.user.userId)
      },

      $_formatUpdateDate(user){
        return cmnChangeFormatDate(new Date (Date.parse(user.updateDate)), 'date', 'YYYY/MM/DD')
      },

      /* 新規追加ボタン*/
      $_newUser() {
        this.$refs.messages.clearAllMsg()
        setTimeout(() => {
          this.$refs.modalUserInfo.open()
        }, 1)
      },

      /* 解除ボタン */
      $_remove(user) {
        this.$refs.modalBlockMessage.init()
        this.$refs.messages.clearAllMsg()
        if(this.customerInfo.usedLicenseNum <= 1) {
          this.$refs.messages.addErrorMsg('すべてのユーザからライセンスを解除することはできません。')
          this.$refs.modalBlockMessage.close()
          return
        }
        const payload = {
          user_id: user.userId
        }
        this.$store.dispatch('removeLicense', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            
            this.$_viewUserList()
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          })
      },

      /* 割当ボタン */
      $_attach(user) {
        this.$refs.modalBlockMessage.init()
        this.$refs.messages.clearAllMsg()
        if(this.customerInfo.usedLicenseNum >= this.customerInfo.licenseNum) {
          this.$refs.messages.addErrorMsg("これ以上ライセンスを割り当てることはできません。使用していないライセンスを解除するか、ライセンスを追加してください。")
          this.$refs.modalBlockMessage.close()
          return
        }
        const payload = {
          user_id: user.userId
        }
        this.$store.dispatch('attachLicense', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            this.$_viewUserList()
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          })
      },
      
      /* 詳細ボタン*/
      $_detail(user) {
        this.$refs.messages.clearAllMsg()
        setTimeout(() => {
          this.$refs.modalUserInfo.open(user)
        }, 1)
      },

      /* 削除ボタン */
      $_delete(user) {
        // 確認ダイアログを表示する
        this.confirmMessages = []
        this.confirmMessages.push(`ユーザ「${user.userName}」を削除します。`)
        this.confirmMessages.push('よろしいですか？')

        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()
        this.deletedUser = user
      },

      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.NG) {
          this.deletedUser = null
          return false
        } else {
          this.$_deleteUser(this.deletedUser)
          return true
        }
      },

      /* ユーザ削除実行 */
      $_deleteUser(user) {
        this.$refs.modalBlockMessage.init()
        this.$refs.messages.clearAllMsg()
        const payload = {
          user_id: user.userId
        }
        this.$store.dispatch('deleteUser', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            this.$_viewUserList()
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          })

      },
      
      /* 復元ボタン */
      $_restore(user) {
        this.$refs.messages.clearAllMsg()
        setTimeout(() => {
          this.$refs.modalUserInfo.open(user)
        }, 1)
      },

      /* ユーザ登録処理が始まった */
      $_userRegistStarted() {
        this.$refs.modalBlockMessage.init()
      },
      
      /* ユーザ登録処理が終わった */
      $_userRegistCompleted() {
        this.$refs.modalBlockMessage.close()
        this.$_viewUserList()
      },

      /* 削除済ユーザの表示/非表示 */
      $_switchDeletedUserList() {
        this.showDeletedUserList = !this.showDeletedUserList
      }

    }
  }
</script>

<style lang="scss" scoped>
.my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 1320px;
}
.my-bg-color {
  background: rgba(128, 128, 128, 0.3);
}
  .table>:not(:first-child) {
    border-top: 1;
    border-top-color: gainsboro;
  }

  th:nth-child(1),
  #td1 {
    width: 33%;
  }

  th:nth-child(2),
  #td2 {
    width: 20%;
  }

  th:nth-child(3),
  #td3 {
    width: 10%;
  }

  th:nth-child(4),
  #td4 {
    width: 7%;
    min-width: 75px;
    text-align: center;
  }

  th:nth-child(5),
  #td5 {
    width: 7%;
    min-width: 75px;
    text-align: center;
  }
  
  th:nth-child(6),
  #td6 {
    width: 7%;
    min-width: 75px;
    text-align: center;
  }

  #td-deleted-date {
    width: 14%;
    min-width: 75 * 2px;
    text-align: center;
  }

  #td-restorebtn {
    width: 7%;
    min-width: 75 * 1px;
    text-align: center;
  }

  button {
    white-space: nowrap;
  }
  .closed {
  margin-top: -10px;
  border-bottom: solid 2px gray;
  border-right: solid 2px gray;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  }
  
  .opened {
  margin-top: 0px;
  border-bottom: solid 2px gray;
  border-right: solid 2px gray;
  width: 10px;
  height: 10px;
  transform: rotate(225deg);
  }
</style>
<template>
  <div class="h-100" id="volume-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">ボリューム > トラック出入口&thinsp;・&thinsp;バース向き設定</p>
        <p class="d-block mb-0">物件管理ID：{{ paramsData.project_no}}</p>
        <p class="d-block mb-0">プロジェクト名称：{{ paramsData.project_name}}</p>
      </div>
      <div class="my-overflow-y p-2">
        <div>
          <p class="mb-1">出入口</p>
          <div class="d-flex align-items-center">
            <div class="col-5"><span class="ms-3">境界線</span></div>
            <div class="col-5">
              <select v-model="gateNo" @change="$_onChangeGateNo()" class="form-select form-select-sm">
              <option v-for="item in gateList" :key="item.no" :value="item.no">
                {{ "境界線 " + item.no }}
              </option>
            </select>
            </div>
          </div>
          <div class="mt-2 d-flex align-items-center">
            <div class="col-5"><span class="ms-3">位置（方位）</span></div>
            <div class="col-5">
              <select v-model="gateDirectionNo" @change="$_onChangeGateTruckBerth()" class="form-select form-select-sm">
                <option v-for="item in gateDirectionList" :key="item.code" :value="item.code">
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <p class="mb-1">トラックバース</p>
          <div class="d-flex align-items-center">
            <div class="col-5"><span class="ms-3">基準線</span></div>
            <div class="col-5">
              <select v-model="truckBerthNo" @change="$_onChangeGateTruckBerth()" class="form-select form-select-sm">
                <option v-for="item in truckBerthList" :key="item.no" :value="item.no">
                  {{ "境界線 " + item.no }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-2 d-flex align-items-center">
            <div class="col-5"><span class="ms-3">向き（方位）</span></div>
            <div class="col-5">
              <select v-model="truckdirectionNo" @change="$_onChangeGateTruckBerth()" class="form-select form-select-sm">
                <option v-for="item in directionList" :key="item.code" :value="item.code">
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <p class="d-flex align-items-center">※<span class="my-icon-arrow"><span class="my-arrow-front"></span></span>方向はバース向き</p>
        </div>
      </div>
      <div class="d-grid gap-3 mt-auto mx-2">
        <div v-if="isError" class="alert alert-danger d-flex align-items-center mt-3 mb-0" role="alert">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" viewBox="0 0 16 16">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          <div>
            <span>反映処理が異常終了しました。<br>再度、反映ボタンより実行してください。</span>
          </div>
        </div>
        <button type="button" class="btn btn-primary" v-bind:class="[isError ? 'mt-0' : 'mt-3']" @click="$_applySetting">反映</button>
        <button type="button" class="btn btn-primary" @click="$_prevPage">戻る</button>
      </div>
      <footer-section></footer-section>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="volume-input-section" />
  </div>
</template>

<script>
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { initVolume } from '@/assets/js/dto/E_volume'
import { directions } from '@/assets/js/code'
import { GATE } from '@/assets/js/const'

export default {
  name: 'InputSection',
  components: {
    HeaderSection,
    FooterSection,
    ModalBlockMessage
  },
  data() {
    return {
      paramsData: this.$store.state.params.data,
      paramsVolume: this.$store.state.params.volume,
      gateNo: null,
      gateList: [],
      gateDirectionNo: null,
      gateDirectionList: [],
      truckBerthNo: null,
      truckBerthList: [],
      truckdirectionNo: null,
      isError: false
    }
  },
  mounted() {
    this.$_init()
  },
  computed: {
    directionList: function () {
      return directions
    }
  },
  methods: {
    /* 初期化 */
    $_init() {
      this.$_setData()
    },
    /* データ設定 */
    $_setData() {
      // console.log(this.paramsVolume)
      // 境界線リスト取得サービス
      this.$store.dispatch("getLcondList")
        .then((resultData) => {  
          let lConds = resultData.l_cond_list
          // console.log(lConds)
          // [出入口]-[境界線]
          for (let i = 0, len = lConds.length; i < len; i++) {
            if (lConds[i].type === 0) {
              this.gateList.push(lConds[i])
            }
          }
          this.paramsVolume === null ? this.gateNo = this.gateList[0].no : this.gateNo = this.paramsVolume.gate_l_cond_no
          // [出入口]-[位置（方位）]
          this.$_setDefaultGateDirectionNo()
          // [トラックバ－ス]-[基準線]
          this.truckBerthList = lConds
          this.paramsVolume === null ? this.truckBerthNo =  this.truckBerthList[0].no : this.truckBerthNo = this.paramsVolume.truckberth_l_cond_no
          // [トラックバース]-[向き（方位）]
          this.paramsVolume === null ? this.truckdirectionNo = this.directionList[0].code : this.truckdirectionNo = this.paramsVolume.truckberth_l_cond_direction
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* データ設定 [出入口]-[位置（方位）] */
    async $_setDefaultGateDirectionNo() {
      this.gateDirectionList = await this.$_setGateDirectionList()
      this.paramsVolume === null ? this.gateDirectionNo = this.gateDirectionList[0].code : this.gateDirectionNo = this.paramsVolume.gate_l_cond_direction
    },
    /* [出入口]-[境界線]変更 */
    async $_onChangeGateNo(){
      await this.$_setGateDirectionNo()
      this.$_setGateTruckBerth()
    },
    /* [出入口]-[位置(方位）]変更 */
    /* [トラックバース]-[基準線]変更 */
    /* [トラックバース]-向き（方位）]変更 */
    $_onChangeGateTruckBerth(){
      this.$_setGateTruckBerth()
    },
    /* 91.	処理	
    (a)	[位置（方位）]リスト設定 */				
    $_setGateDirectionList() {
      let selected = this.gateList.find((v) => v.no === this.gateNo)
      let dirZero = directions.filter((v) => v.gate === 0)
      let dirOne = directions.filter((v) => v.gate === 1)
      // console.log(selected)
      return selected.gate === GATE.EAST_WEST ?  dirZero : dirOne
    },
    async $_setGateDirectionNo () {
      this.gateDirectionList = await this.$_setGateDirectionList()
      this.gateDirectionNo = this.gateDirectionList[0].code
    },
    /* 91.	処理	
    (b)	出入口トラックバース指定実行 */				
    $_setGateTruckBerth() {
      // 画面の入力値を状態ストアに保存する
      this.$_commitParams()
      // 出入口指定サービス
      let payload = {
        gate_l_cond_no: this.paramsVolume.gate_l_cond_no,
        gate_l_cond_direction: this.paramsVolume.gate_l_cond_direction,
        truckberth_l_cond_no: this.paramsVolume.truckberth_l_cond_no,
        truckberth_l_cond_direction: this.paramsVolume.truckberth_l_cond_direction
      }
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("setGateTruckBerth", payload)
        .then((resultData) => {
          // ビューアを再表示する
          this.$emit('set-viewer', resultData.plan_view_url)
          this.$refs.modalBlockMessage.close()
          // 共通処理
          this.$_updateCommon()
        })
        .catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
        })
    },
    /* [反映]クリック */
    $_applySetting(e) {
      e.preventDefault();
      this.isError = false
      // 画面の入力値を状態ストアに保存する
      this.$_commitParams()
      // トラック出入口実行サービス
      let payload = {
        gate_l_cond_no: this.paramsVolume.gate_l_cond_no,
        gate_l_cond_direction: this.paramsVolume.gate_l_cond_direction,
        truckberth_l_cond_no: this.paramsVolume.truckberth_l_cond_no,
        truckberth_l_cond_direction: this.paramsVolume.truckberth_l_cond_direction,
        calc_type: 1
      }
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("setVolume", payload)
        .then((resultData) => {
          if(resultData.status_code === "210200") {
            //計算完了
            // 受け取った値を画面の状態ストア
            this.paramsVolume.volume_result = resultData.volume_result
            let params = this.$store.state.params
            params.volume = this.paramsVolume
            this.$store.commit('setParams', params)
            // 共通処理
            this.$_updateCommon()
            this.$refs.modalBlockMessage.close()
            //接道及び出入口設定の確認画面（条件変更モード）へ遷移
            this.$router.push({ path: 'road-and-entrance' })
          } else {
            //異常終了
            this.isError = true
            this.$refs.modalBlockMessage.close()
          }
        })
        .catch((e) => {
          console.log(e);
          this.$refs.modalBlockMessage.close();
        });
    },
    /* 画面の入力値を状態ストアに保存する */
    $_commitParams() {
      if (this.paramsVolume === null) {
        this.paramsVolume = initVolume()
      }
      this.paramsVolume.gate_l_cond_no = this.gateNo                         // 出入口.境界線
      this.paramsVolume.gate_l_cond_direction = this.gateDirectionNo         // 出入口.位置（方位）
      this.paramsVolume.truckberth_l_cond_no = this.truckBerthNo             // トラックバース.基準線
      this.paramsVolume.truckberth_l_cond_direction = this.truckdirectionNo  // トラックバース.向き（方位）
      let params = this.$store.state.params
      params.volume = this.paramsVolume
      this.$store.commit('setParams', params)
    },
    /* [戻る]クリック */
    $_prevPage() {
      // 共通処理
      this.$_updateCommon(1)
      // 接道及び出入口設定の確認画面（条件変更モード）へ遷移
      this.$router.push({ path: 'road-and-entrance' })
    },
    /* 共通処理 */
    $_updateCommon(num) {
      switch(num) {
        case 1:  // 利用時間登録
          this.$store.dispatch('updateUsersUsageTimes')
          break;
        default: // 利用時間登録, プランデータ更新日更新
          this.$store.dispatch('updateUsersUsageTimes')
          this.$store.dispatch('updateProjectUpdateDate')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.my-icon-arrow {
  background: #90ee90;
  margin: 5px;
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  position: relative;
  z-index: -1;
  &::before{
    content: "";
    display: block;
    width: 12px;
    border-bottom: 6px solid #222;
    transform: translate(5px, -50%);
    position: absolute;
    top: 50%;
    z-index: 0;
  }
  &::after{
    content: "";
    display: block;
    border-style: solid;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent #222;
    transform: translate(-35%, -50%);
    position: absolute;
    top: 50%;
    right: 0%;
    z-index: 0;
  }
  .my-arrow-front {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
     &::before{
    content: "";
    display: block;
    width: 15px;
    border-bottom: 6px solid #bee1c4;
    transform: translate(-9px, -50%) scale(0.7);
    position: absolute;
    top: 50%;
    z-index: 0;
    }
    &::after{
      content: "";
      display: block;
      border-style: solid;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent #bee1c4;
      transform: translate(10px, -50%) scale(0.70);
      position: absolute;
      top: 50%;
      right: 0%;
      z-index: 0;
    }
  }
}
</style>
/* 規制緩和 */
class E_deregulation {
  constructor() {
    this.coverage_corner_relax = false
    this.coverage_corner_relax_text = ''
    this.coverage_fire_proof_building = false
    this.coverage_fire_proof_building_text = ''
    this.coverage_limit = false
    this.coverage_limit_rate = 0
    
  }
}

export {
  E_deregulation
}

<template>
  <div id="modal-change-password-frame">
    <div class="modal" id="modal-change-password" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">パスワード変更</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body m-2">
            <messages ref="messages"></messages>
            <div class="d-flex align-items-center">
              <div class="col-3"><label class="ms-1" style="padding-left: 2.2rem;">メールアドレス</label></div>
              <div class="col-4"><label>{{ User.userEmail }}</label></div>
            </div>
            <div class="d-flex align-items-center mt-3">
              <div class="col-3"><label class="ms-1" style="padding-left: 2.2rem;">名前</label>
              </div>
              <div class="col-3"><label>{{ User.userName }}</label></div>
            </div>
            <div class="d-flex align-items-center mt-3">
              <div class="col-3"><label class="ms-1"><span class="badge bg-danger p-1 me-1">必須</span>パスワード</label></div>
              <div class="col-3"><input class="form-control form-control-sm" type="password" v-model="User.password"></div>
            </div>
            <div class="d-flex align-items-center mt-3">
              <div class="col-3"><label class="ms-1"><span class="badge bg-danger p-1 me-1">必須</span>パスワード確認</label></div>
              <div class="col-3"><input class="form-control form-control-sm" type="password" v-model="passwordConfirmation"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary me-2" @click="$_updateBtn()">更新</button>
            <button class="btn btn-primary" @click="$_close()">閉じる</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="modal-change-password-frame" @return-confirm-result="returnConfirmResult"/>
  </div>
</template>

<script>
  import ModalConfirm from '@/views/common/ModalConfirm'
  import Messages from '@/views/common/Messages'
  import { Modal } from 'bootstrap'
  import { user } from '@/assets/js/dto/user'
  import { RESULT_CODE, REGEXP } from '@/assets/js/const'
  import { drgAddEvent, drgRemoveEvent, drgMouseUp  } from '@/assets/js/draggable'

  export default {
    name: 'ModalChangePassword',

    components: {
      ModalConfirm,
      Messages,
    },

    data() {
      return {
        myModal: null,
        dragElement: null,
        User: new user(),
        passwordConfirmation: '',
        confirmMessages: [],
      }
    },
    mounted() {
    this.dragElement = document.getElementById('modal-change-password').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
    },
    beforeUnmount() {
      drgRemoveEvent(this.dragElement)
    },
    methods: {
      /* 初期化 */
      init() {
        this.myModal = new Modal(document.getElementById('modal-change-password'), {})
        this.User.userId = this.$store.state.user.userId
        this.User.userName = this.$store.state.user.userName
        this.User.userEmail = this.$store.state.user.userEmail
        this.User.password = ''
        this.passwordConfirmation = ''

        this.myModal.show()
      },

      /* 更新ボタン */
      $_updateBtn() {
        this.$refs.messages.clearAllMsg()
        if(this.$_validation()) return

        this.confirmMessages = []
        this.confirmMessages.push('パスワードを更新します。', 'よろしいですか？')
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()
      },
      
      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.NG) return false
          this.$_update()
          return true
      },

      /* 更新処理 */
      $_update() {
        if(this.$_validation()) return

        const payload = this.User
        this.$store.dispatch('changePassword', payload)
          .then(res => {
            if(res.message) return this.$refs.messages.addErrorMsg(res.message) 
            this.$_close()
          })
      },

      /* バリデーション */
      $_validation() {
        let invalid = false

        /* 必須チェック */
        invalid = (!this.User.password || !this.passwordConfirmation)

        if(invalid) {
          this.$refs.messages.addErrorMsg('未入力の必須項目があります。')
          return invalid
        }
        
        /* パスワード一致チェック */
        invalid = this.User.password !== this.passwordConfirmation
        if(invalid) {
          this.$refs.messages.addErrorMsg('パスワードが一致しません。')
          return invalid
        }

       /* パスワード文字数チェック */
        invalid = (this.User.password.length < 8 || this.User.password.length > 60)
        if (invalid) {
          this.$refs.messages.addErrorMsg('パスワードは8文字以上60文字以下に設定してください。')
          return invalid
        }

        /* パスワード要件チェック */
        invalid = !REGEXP.PASSWORD.test(this.User.password)
        if (invalid) {
          this.$refs.messages.addErrorMsg('パスワードには英大文字, 小文字, 数字の各文字種を1文字以上含むように設定してください。')
          return invalid
        }
        return invalid
      },

      /* 閉じる */
      $_close() {
        this.$refs.messages.clearAllMsg()
        this.myModal.hide()
      },
      /* クローズ時の処理 */
      $_onClickCloseButton() {
        drgMouseUp()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div name="modal-message" class="modal" tabindex="-1" data-bs-keyboard="false" aria-labelledby="modal-message" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <span class="d-block lh-base" v-for="(message, index) in messages" :key="index">{{ message }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="isShowCloseBtn || isShowOkayBtn">
          <button v-if="isShowOkayBtn" type="button" class="btn btn-sm btn-primary" @click="close">OK</button>
          <button v-if="isShowCloseBtn" type="button" class="btn btn-sm btn-secondary" @click="close">閉じる</button> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { cmnIsBlank } from '@/assets/js/common'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalMessage',
  props: {
    propMessages: {
      type: Array
    },
    parentId: {
      type: String,
      default: ''
    },
    isShowCloseBtn: {
      type: Boolean
    },
    isShowOkayBtn: {
      type: Boolean
    }
  },
  data() {
    return {
      myModal: null,
      messages: []
    }
  },
  watch: {
    propMessages: {
      handler: function(newVal) {
        this.messages = newVal
      },
      deep : true,
    }
  },
  methods: {
    /* 初期化 */
    init() {
      this.messages = this.propMessages
      if (cmnIsBlank(this.parentId))
        this.myModal = new Modal(document.getElementsByName('modal-message')[0], {})
      else
        this.myModal = new Modal(document.getElementById(this.parentId).querySelector('div[name="modal-message"]'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* [閉じる]クリック */
    close() {
      this.myModal.hide()
      fncAdjustModalLayer()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
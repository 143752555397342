//=================================================================================================
//  変換など
//------------------------------------------
//  数値を0埋めに変換
//  引数
//    str : 変換対象の文字列
//    len : 桁数
//  戻り値
//    変換後の文字列
//------------------------------------------
function cmnAddZero(str, len) {
  str = new String(str);
  for (let i = 1; i <= len; i++) {
      if (str.length < len)
          str = '0' + str;
  }
  return str;
}

//------------------------------------------
//  nullのとき指定の値に変換
//  引数
//    val         : 値
//    replace_val : nullのときに変換する値
//  戻り値
//    変換後の値
//------------------------------------------
function cmnChangeBlank(val, replace_val) {
  if (cmnIsBlank(val))
      return replace_val;
  return val;
}

//------------------------------------------
//  数値フォーマット
//  引数
//    num : 数値
//    val : 小数点以下桁数
//  戻り値
//    変換後の値
//------------------------------------------
function cmnDecimalFormat(num, val) {
    if (typeof num !== 'number') return num;
    return num.toFixed(val)
}

//------------------------------------------
//  数値を文字列に変換
//  引数
//    num   : 変換対象の数値
//    type  : 変換形式  [0: 変換のみ, 1: 変換 + 3桁区切り, 2: %表示]
//    point : 小数点以下の桁数 [default 0]
//  戻り値
//    変換後の文字列
//------------------------------------------
function cmnChangeNumToStr(num, type, point) {
  point = typeof point === 'undefined' ? 0 : Number(point);
  if (num === null)
      return '';
  if (type === 2)
      num = num * 100;
  let str = String(num);
  str = str.replace(/,/g, '');
  if (point !== 0)
      str = Number(str).toFixed(point);
  if (type === 1)
      while (str !== (str = str.replace(/^(-?\d+)(\d{3})/, '$1,$2')));
  if (type === 2)
      str += '%';
  return str;
}

//------------------------------------------
//  文字列を数値に変換
//  引数
//    str   : 変換対象の文字列
//    type  : 初期値形式  [0: 0をセット, 1: null をセット]
//  戻り値
//    変換後の数値 (変換に失敗した場合はそのまま返す)
//------------------------------------------
function cmnChangeStrToNum(str, type) {
  try {
      if (cmnIsBlank(str))
          return type === 0 ? 0 : null;
      if (typeof str === 'number') str = str + '';
      let num = str.replace(/,/g, '');
      return Number(num);
  } catch (e) {
      return str;
  }
}

//-----------------------------------------------
//  バイト数取得
//  引数
//    str : チェックする文字列
//  戻り値
//    文字列のバイト数 (空文字は-1)
//-----------------------------------------------
function cmnGetStrLenB(str) {
   if (str === '')
       return -1;
   let r = 0;
   for (let i = 0, len = str.length; i < len; i++) {
       let c = str.charCodeAt(i);
       // Unicode : 0x0 ～ 0x80, 0xf8f0, 0xff61 ～ 0xff9f, 0xf8f1 ～ 0xf8f3
       if ((c >= 0x0 && c <= 0x80) || (c === 0xf8f0) || (c >= 0xff61 && c <= 0xff9f) || (c >= 0xf8f1 && c <= 0xf8f3))
           r += 1;
       else
           r += 2;
   }
   return r;
}

////------------------------------------------
////  文字列の繰り返し
////  引数
////    str : 繰り返し対象の文字列
////    cnt : 繰り返し回数
////  戻り値
////    作成した文字列
////------------------------------------------
//function cmnRepeatStr(str, cnt) {
//    let str1 = '';
//    if (cnt < 0)
//        return str1;
//    while (cnt) {
//        if (cnt & 1)
//            str1 += str;
//        cnt = cnt >> 1;
//        str += str;
//    }
//    return str1;
//}

////------------------------------------------
////  配列の重複削除
////  引数
////    arr : 配列
////  戻り値
////    重複削除後の配列
////------------------------------------------
//function cmnChangeUnique(arr) {
//    let storage = {};
//    let uniqueArr = [];
//    let i, val;
//    for (i = 0, len = arr.length; i < len; i++) {
//        val = arr[i];
//        if (!(val in storage)) {
//            storage[val] = true;
//            uniqueArr.push(val);
//        }
//    }
//    return uniqueArr;
//}

////------------------------------------------
////  数字をアルファベットに変換
////  引数
////    num   : 変換対象の数値
////  戻り値
////    変換後のアルファベット
////------------------------------------------
//function cmnChangeNumToAlpha(num) {
//    let alpha = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

//    if (num < 26)
//        return alpha[num - 1];
//     else
//        return ""
//}

//------------------------------------------------
//  GET変数の取り出し
//  引数
//    key_name : 変数名
//------------------------------------------------
// function cmnGetVars(key_name) {
//   let var_str = window.location.search;
//   let varArray;
//   var_str = var_str.substring(1, var_str.length);

//   if (var_str)
//       varArray = var_str.split('&');

//   if (varArray) {
//       let tempArray;
//       for (let i = 0; i < varArray.length; i++) {
//           tempArray = varArray[i].split('=');
//           if (tempArray[0] === key_name)
//               return decodeURIComponent(tempArray[1]);
//       }
//   }
//   return '';
// }

//=================================================================================================
//  計算など
//-----------------------------------------------
//  小数点以下の桁数取得
//  引数
//    num  : 数値
//  戻り値
//    小数点以下桁数
//-----------------------------------------------
function cmnGetDecimalLen(num) {
  let temp = (num + '').split('.');
  if (temp.length === 1)
      return 0;
  else
      return temp[1].length;
}

//-----------------------------------------------
//  小数の足し算 
//    num1 + num2
//  引数
//    num1  : 数値
//    num2  : 数値
//  戻り値
//    計算値
//-----------------------------------------------
function cmnAdd(num1, num2) {
  let d1 = cmnGetDecimalLen(num1);
  let d2 = cmnGetDecimalLen(num2);
  let d = Math.max(d1, d2);

  let num = ((num1 * Math.pow(10, d)) + (num2 * Math.pow(10, d))) / Math.pow(10, d);
  return num;
}

////-----------------------------------------------
////  小数の引き算 
////    num1 - num2
////  引数
////    num1  : 数値
////    num2  : 数値
////  戻り値
////    計算値
////-----------------------------------------------
function cmnSubtract(num1, num2) {
   let d1 = cmnGetDecimalLen(num1);
   let d2 = cmnGetDecimalLen(num2);
   let d = Math.max(d1, d2);

   let num = ((num1 * Math.pow(10, d)) - (num2 * Math.pow(10, d))) / Math.pow(10, d);
   if (Number.isNaN(num))
       return null;
   return num;
}

//-----------------------------------------------
//  小数の掛け算 
//    num1 * num2
//  引数
//    num1  : 数値
//    num2  : 数値
//  戻り値
//    計算値
//-----------------------------------------------
function cmnMultiply(num1, num2) {
   let d1 = cmnGetDecimalLen(num1);
   let d2 = cmnGetDecimalLen(num2);
   let num = Math.round(num1 * Math.pow(10, d1)) * Math.round(num2 * Math.pow(10, d2)) / Math.pow(10, d1 + d2);
   if (Number.isNaN(num))
       return null;
   return num;
}

//-----------------------------------------------
//  小数の割り算 
//    num1 / num2
//  引数
//    num1  : 数値
//    num2  : 数値
//    point : 計算後有効桁位置 負数は小数点 (指定がない場合はnum1, num2 の有効桁に従う)
//  戻り値
//    計算値
//-----------------------------------------------
function cmnDivide(num1, num2, point) {
   let d1 = cmnGetDecimalLen(num1);
   let d2 = cmnGetDecimalLen(num2);
   let d = typeof point === 'undefined' ? (d1 > d2 ? d1 : d2) * -1 : point;

   let num = num1 / num2;
   if (Number.isNaN(num))
       return null;
   num = cmnExRound(num, d);
   return num;
}

////-----------------------------------------------
////  切り上げ
////  引数
////    num   : 数値
////    point : 切り上げ後有効桁位置 (default:0) +:整数位置, 0:整数, -:小数位置
////  戻り値
////    計算した数値
////-----------------------------------------------
//function cmnExCeil(num, point) {
//    point = typeof point === 'undefined' ? 0 : Number(point);
//    if (num === null)
//        return num;
//    let minus_sig = false;
//    if (num < 0) {
//        minus_sig = true;
//        num = num * -1;
//    }
//    let p = Math.pow(10, (minus_sig ? point * -1 : point));

//    if (point === 0)
//        num = Math.ceil(num);
//    else if (point > 0)
//        num = Math.ceil((num / p)) * p;
//    else if (point < 0)
//        num = Math.ceil((num * p)) / p;
//    if (Number.isNaN(num))
//        return null;
//    return (minus_sig ? num * -1 : num);
//}

//-----------------------------------------------
//  切り捨て
//  引数
//    num   : 数値
//    point : 切り捨て後有効桁位置 (default:0) +:整数位置, 0:整数, -:小数位置
//  戻り値
//    計算した数値
//-----------------------------------------------
function cmnExFloor(num, point) {
   point = typeof point === 'undefined' ? 0 : Number(point);
   if (num === null)
       return num;
   let minus_sig = false;
   if (num < 0) {
       minus_sig = true;
       num = num * -1;
   }

   let p = Math.pow(10, (minus_sig ? point * -1 : point));

   if (point === 0)
       num = Math.floor(num);
   else if (point > 0)
       num = Math.floor((num / p)) * p;
   else if (point < 0)
       num = Math.floor((num * p)) / p;
   if (Number.isNaN(num))
       return null;
   return (minus_sig ? num * -1 : num);
}

//-----------------------------------------------
//  四捨五入
//  引数
//    num   : 数値
//    point : 四捨五入後有効桁位置 (default:0) 負数は小数点
//  戻り値
//    計算した数値
//-----------------------------------------------
function cmnExRound(num, point) {
  point = typeof point === 'undefined' ? 0 : Number(point);
  if (num === null)
    return num;
  let minus_sig = false;
  if (num < 0) {
    minus_sig = true;
    num = num * -1;
  }
   
  let p = Math.pow(10, (num < 1 ? point * -1 : point));
  
  if (point === 0)
    num = Math.round(num);
  else if (num >= 1)
    num = Math.round(num / p) * p;
  else 
    num = Math.round(num * p) / p;

  if (Number.isNaN(num))
    return null;
  return (minus_sig ? num * -1 : num);
}
//-----------------------------------------------
//  郵便番号の分割
//  引数
//    postalCode   : 郵便番号
//  戻り値
//    [郵便番号の上3桁, 郵便番号の下4桁]
//-----------------------------------------------
function cmnDevidePostalCode(postalCode) {
    if(typeof postalCode !== "string") {
        postalCode = postalCode.toString()
    }
    const devidedPostalCode = []
    devidedPostalCode.push(postalCode.slice(0, 3))
    devidedPostalCode.push(postalCode.slice(3, 7))
    return devidedPostalCode
}
//-----------------------------------------------
//  指定桁数毎の区切り文字の挿入
//  引数
//    str   : 文字列
//    letter   : 区切り文字(列)
//    num      : 区切る文字数
//  戻り値
//    文字数ごとに区切り文字(列)で区切られた文字列
//-----------------------------------------------
function cmnInsertDelimiter(str, delimiter, num) {
    if(typeof str !== 'string' ||
        typeof delimiter !== 'string' ||
        typeof num !== 'number') {    
        return str
        
    } else {
        let result = str.slice(num*-1)
            for(let i =num; i<str.length; i+=num){
                let temp = str.slice( i*-1 -num, i*-1)
                result = temp + delimiter + result
            }
        return result
        
    }
    
}

//=================================================================================================
//  チェック関連
//-----------------------------------------------
//  空文字チェック
//  引数
//    str : 文字列
//  戻り値
//    true or false
//-----------------------------------------------
function cmnIsBlank(str) {
  // str = String(str);
  if (str === '' || str === null)
      return true;
  return false;
}

//-----------------------------------------------
//  空文字チェック
//  引数
//    str : 文字列
//  戻り値
//    true or false
//-----------------------------------------------
function cmnIsNotBlank(str) {
  return !cmnIsBlank(str);
}
//-----------------------------------------------
//  オブジェクト内の空文字チェック
//  引数
//    obj : オブジェクト
//  戻り値
//    true or false
//-----------------------------------------------
function cmnHasBlank(obj, keys) {
    for(const key in obj) {
        if(keys.indexOf(key) < 0) continue
        if(cmnIsBlank(obj[key])) {
            return true
        }
    }
    return false
}
//-----------------------------------------------
//  数字チェック
//  引数
//    str       : 文字列
//    chk_type  : チェック形式 [0: 文字チェックのみ, 1: 文字チェック + 文字数チェック] (default:0)
//    len       : 文字数 (default:1)
//  戻り値
//    true or false
//-----------------------------------------------
function cmnIsNumStr(str, chk_type, len) {
   return cmnIsHankakuStr(str, chk_type, len, '[0-9]');
}

////-----------------------------------------------
////  英数字チェック
////  引数
////    str       : 文字列
////    chk_type  : チェック形式 [0: 文字チェックのみ, 1: 文字チェック + 文字数チェック] (default:0)
////    len       : 文字数 (default:1)
////  戻り値
////    true or false
////-----------------------------------------------
//function cmnIsAlphaNumStr(str, chk_type, len) {
//  return cmnIsHankakuStr(str, chk_type, len, '[0-9A-Za-z]');
//}

//-----------------------------------------------
//  半角チェック
//  引数
//    str         : 文字列
//    chk_type    : チェック形式 [0: 文字チェックのみ, 1: 文字チェック + 文字数チェック] (default:0)
//    len         : 文字数 (default:1)
//    chk_pattern : チェックパターン[正規表現]
//  戻り値
//    true or false (空文字はtrue)
//-----------------------------------------------
function cmnIsHankakuStr(str, chk_type, len, chk_pattern) {
   if (cmnIsBlank(str))
       return true;
   str = String(str);
   chk_type = typeof chk_type === 'undefined' ? 0 : chk_type;
   len = typeof len === 'undefined' ? 1 : len;
   //  文字チェック
   let str_len = str.length;
   let str_lenB = cmnGetStrLenB(str);
   if (str_len !== str_lenB)
       return false;
   //  文字数チェック
   if (chk_type === 1) {
       if (str_len !== len)
           return false;
   }
   //  形式チェック
   if (typeof chk_pattern !== 'undefined') {
       let reg = new RegExp(chk_pattern);
       for (let i = 0, len2 = str.length; i < len2; i++) {
           let c = str.substring(i, i + 1);
           if (!c.match(reg))
               return false;
       }
   }
   return true;
}

////------------------------------------------
//// 全角数字０～９を半角の0～9に変換(-.,も含む)
////  引数
////    str : 変換対象の文字列
////  戻り値
////    変換後の文字列
////------------------------------------------
//function cmnChangeNumStrFullToHalf(str) {
//    let han = '1234567890-.,';
//    let zen = '１２３４５６７８９０?．，';
//    let ret = '';
//    for (let i = 0, cnt = str.length; i < cnt; i++) {
//        let c = str.substr(i, 1);
//        let p = zen.indexOf(c);
//        if (p > -1) {
//            ret += han.substr(p, 1);
//        } else {
//            ret += c;
//        }
//    }
//    return ret;
//}

//-----------------------------------------------
//  数値範囲・形式チェック
//  引数
//    str       : 文字列
//    min       : 最小値
//    max       : 最大値
//    int_flag  : 整数のみ許可 (default:true)
//  戻り値
//    true or false
//-----------------------------------------------
function cmnIsNumValue(str, min, max, int_flag) {
  int_flag = typeof int_flag === 'undefined' ? true : int_flag;
  if (typeof str === 'number') str = String(str)
  if (!cmnCheckNumRange(str, min, max))
      return false;
  if (int_flag) {
    if (!String(str).match(/^(-?[0-9]*)$/))
      return false;
  }
  return true;
}

//-----------------------------------------------
//  数値範囲チェック
//  引数
//    str       : 文字列
//    min       : 最小値
//    max       : 最大値
//  戻り値
//    true or false
//-----------------------------------------------
function cmnCheckNumRange(str, min, max) {
  if (typeof str !== 'string') str = String(str)
  if (!cmnCheckNumFormat(str))
    return false;
  str = str.replace(/,\+/g, '');
  let num = Number(str);
  if (min > num || max < num)
    return false;
  return true;
}

////-----------------------------------------------
////  開始・終了整合性チェック
////  引数
////    start       : 開始
////    end         : 終了
////    equal_flag  : イコールを許可
////  戻り値
////    true or false
////-----------------------------------------------
//function cmnCompareStartAndEnd(start, end, equal_flag) {
//    if (equal_flag) {
//        if (parseInt(start, 10) > parseInt(end, 10))
//            return false;
//    } else {
//        if (parseInt(start, 10) >= parseInt(end, 10))
//            return false;
//    }
//    return true;
//}

//-----------------------------------------------
//  数値チェック (+-,.を許可)
//  引数
//    str : 文字列
//  戻り値
//    true or false
//-----------------------------------------------
function cmnCheckNumFormat(str) {
   if  (cmnIsBlank(str)) return true
   str = String(str);
   //  半角チェック
   if (!cmnIsHankakuStr(str, 0, 1, '[0-9-+.,]'))
       return false;
   //  数値形式のチェック (+-,.許可)
   if (str.match(/^[-+]?((0|[1-9][0-9]{0,2}(,[0-9]{3})*)|(0|[1-9][0-9]+))(\.[0-9]*)?$'/))
       return false;
   //  NaN判定
   if (isNaN(cmnChangeStrToNum(str, 0) * 1))
       return false;
   return true;
}

//=================================================================================================
//  日付時刻関連
//-----------------------------------------------
//  月リスト取得
//  引数
//    なし
//  戻り値
//    1桁表示の月のリスト
//-----------------------------------------------
function cmnGetMonthList() {
    const monthList = [
        { month: '01' },
        { month: '02' },
        { month: '03' },
        { month: '04' },
        { month: '05' },
        { month: '06' },
        { month: '07' },
        { month: '08' },
        { month: '09' },
        { month: '10' },
        { month: '11' },
        { month: '12' }
    ]
    return monthList
}

//-----------------------------------------------
//  年リスト取得
//  引数
//    取得年数
//  戻り値
//    西暦4桁表示の年リスト
//-----------------------------------------------
function cmnGetYearList(num) {
    const now = new Date().getFullYear()
    let yearList = []
    for(let addedYear = 0; addedYear < num; addedYear++) {
        yearList.push(
            { year: (now + addedYear)}
        )
    }
    return yearList
}
//-----------------------------------------------
//  日付時刻形式チェック
//  引数
//    str : 日付時刻
//    fmt : 型
//  戻り値
//    true or false
//-----------------------------------------------
function cmnCheckDateTimeFormat(str, fmt) {
  //空欄はOK
  if (cmnIsBlank(str))
      return true;

  //形式チェック
  if (fmt === 'date') {
      if (Object.prototype.toString.call(str).slice(8, -1) !== 'Date')
          return false;
  } else if (fmt === 'array') {
      if (!Array.isArray(str))
          return false;
  } else if (fmt === 'YYYYMM') {
      if (!str.match(/^\d{6}$/))
          return false;
  //} else if (fmt === 'YYYY/MM') {
  //    if (!str.match(/^\d{4}\/\d{2}$/))
  //        return false;
  } else if (fmt === 'YYYY/MM/DD') {
      if (!str.match(/^\d{4}\/\d{2}\/\d{2}$/))
          return false;
  //} else if (fmt === 'YYYY/MM/DD HH24:MI') {
  //    if (!str.match(/^\d{4}\/\d{2}\/\d{2}\s\d{2}\:\d{2}$/))
  //        return false;
  //} else if (fmt === 'YYYY/MM/DD HH24:MI:SS') {
  //    if (!str.match(/^\d{4}\/\d{2}\/\d{2}\s\d{2}\:\d{2}\:\d{2}$/))
  //        return false;
  }

  //妥当性チェック
  let dd = cmnReturnDateArray(str, fmt);
  if (dd[0] !== '') {
      if (!cmnCheckRightDate(dd[0], dd[1] - 1, dd[2]))
          return false;
  }
  if (dd[3] !== '') {
      if (!cmnCheckRightTime(dd[3], dd[4], dd[5]))
          return false;
  }
  return true;
}

//-----------------------------------------------
//  日付の妥当性チェック
//  引数  year  : 西暦
//        month : 月(0-11)
//        day   : 日
//  戻り値      : true or false
//-----------------------------------------------
function cmnCheckRightDate(year, month, day) {
  if (month >= 0 && month <= 11 && day >= 1 && day <= 31) {
      let dt = new Date(year, month, day);
      if (isNaN(dt))
          return false;
      else if (dt.getFullYear() === year && dt.getMonth() === month && dt.getDate() === day)
          return true;
      else
          return false;
  } else {
      return false;
  }
}

//-----------------------------------------------
//  時刻の妥当性チェック
//  引数  hour    : 時
//        minute  : 分
//        second  : 秒
//  戻り値        : true or false
//-----------------------------------------------
function cmnCheckRightTime(hour, minute, second) {
  if (hour < 0 || hour > 24)
      return false;
  if (minute < 0 || minute > 60)
      return false;
  if (second < 0 || second > 60)
      return false;
  return true;
}

// ////-----------------------------------------------
// ////  期間チェック (スラッシュ, 半角スペース, コロンを削除して比較)
// ////  引数  from  : 開始期間
// ////        to    : 終了期間
// ////  戻り値      : true or false
// ////-----------------------------------------------
// //function cmnCheckDateBetween(from, to) {
// //  from = from.replace(/\//g, '').replace(/\s/g, '').replace(/:/g, '');
// //  to = to.replace(/\//g, '').replace(/\s/g, '').replace(/:/g, '');
// //  if(parseInt(from, 10) > parseInt(to, 10))
// //    return false;
// //  return true;
// //}

//------------------------------------------
//  日付の形式変換 (fmt1->fmt2)
//  引数
//    str   : 日付
//    fmt1  : 引数の日付の形式
//    fmt2  : 戻り値の日付の形式
//  戻り値
//    fmt2の形式の日付
//------------------------------------------
function cmnChangeFormatDate(str, fmt1, fmt2) {
  //空欄、不正な形式のときは、空文字を返す
  if (cmnIsBlank(str) || !cmnCheckDateTimeFormat(str, fmt1)) {
      if (fmt2 === 'date')
          return null;
      else if (fmt2 === 'array')
          return new Array('', '', '', '', '', '', '', '');
      else
          return '';
  }

  let dd = cmnReturnDateArray(str, fmt1);

  //fmt1が見つからなかった場合
  if (dd[0] === 0 || dd[0] === '') {
      if (fmt2 === 'date')
          return null;
      else if (fmt2 === 'array')
          return new Array('', '', '', '', '', '', '', '');
      else
          return '';
  }

  //fmt2でreturn
  return cmnReturnFormatDate(dd, fmt2);
}

//-----------------------------------------------
//  日付を配列で返す
//  引数
//    dt  : 日付
//    fmt : 日付の形式  ('date'はJavaScript の date 型)
//  戻り値
//    日付 (array: 年, 月, 日, 時, 分, 秒, ミリ秒, 曜日)
//-----------------------------------------------
function cmnReturnDateArray(dt, fmt) {
  let dd = new Array(8);
  if (fmt === 'date') {
      dd[0] = dt.getFullYear();
      dd[1] = dt.getMonth() + 1;
      dd[2] = dt.getDate();
      dd[3] = dt.getHours();
      dd[4] = dt.getMinutes();
      dd[5] = dt.getSeconds();
      dd[6] = dt.getMilliseconds();
  } else if (fmt === 'array') {
      dd = dt;
  } else if (fmt === 'YYYYMM') {
      dd[0] = parseInt(dt.substring(0, 4), 10);
      dd[1] = parseInt(dt.substring(4, 6), 10);
      dd[2] = 1;
      dd[3] = 0;
      dd[4] = 0;
      dd[5] = 0;
      dd[6] = 0;
  //} else if (fmt === 'YYYY/MM') {
  //    dd[0] = parseInt(dt.substring(0, 4), 10);
  //    dd[1] = parseInt(dt.substring(5, 7), 10);
  //    dd[2] = 1;
  //    dd[3] = 0;
  //    dd[4] = 0;
  //    dd[5] = 0;
  //    dd[6] = 0;
  //} else if (fmt === 'YYYYMMDD') {
  //    dd[0] = parseInt(dt.substring(0, 4), 10);
  //    dd[1] = parseInt(dt.substring(4, 6), 10);
  //    dd[2] = parseInt(dt.substring(6, 8), 10);
  //    dd[3] = 0;
  //    dd[4] = 0;
  //    dd[5] = 0;
  //    dd[6] = 0;
  //} else if (fmt === 'YYYY/MM/DD') {
  //    dd[0] = parseInt(dt.substring(0, 4), 10);
  //    dd[1] = parseInt(dt.substring(5, 7), 10);
  //    dd[2] = parseInt(dt.substring(8, 10), 10);
  //    dd[3] = 0;
  //    dd[4] = 0;
  //    dd[5] = 0;
  //    dd[6] = 0;
  //} else if (fmt === 'YYYY/MM/DD HH24:MI:SS.FFF') {
  //    dd[0] = parseInt(dt.substring(0, 4), 10);
  //    dd[1] = parseInt(dt.substring(5, 7), 10);
  //    dd[2] = parseInt(dt.substring(8, 10), 10);
  //    dd[3] = parseInt(dt.substring(11, 13), 10);
  //    dd[4] = parseInt(dt.substring(14, 16), 10);
  //    dd[5] = parseInt(dt.substring(17, 19), 10);
  //    dd[6] = parseInt(dt.substring(20, 23), 10);
  }
  let dt1 = new Date(dd[0], dd[1] - 1, dd[2]);
  dd[7] = dt1.getDay(); //0:日曜～6:土曜
  return dd;
}

//------------------------------------------
//  配列から指定形式の日付を返す
//  引数
//    dd  : 日付 (array: 年, 月, 日, 時, 分, 秒, ミリ秒, 曜日)
//    fmt : 日付の形式  ('date'はJavaScript の date 型)
//  戻り値
//    fmtの形式の日付 
//------------------------------------------
function cmnReturnFormatDate(dd, fmt) {
  //不正な形式、日付が空欄のときは、空文字を返す
  if (!cmnCheckDateTimeFormat(dd, 'array') || dd[0] === 0 || dd[0] === '') {
      if (fmt === 'date')
          return null;
      else if (fmt === 'array')
          return new Array(8);
      else
          return '';
  }

  //fmtでreturn
  //    if (fmt === 'date')
  //        return new Date(dd[0], dd[1], dd[2], dd[3], dd[4], dd[5], dd[6]);
  //if (fmt === 'array')
  //    return dd;
  //else if (fmt === 'M')
  //    return dd[1];
  //else if (fmt === 'MM/DD')
  //    return cmnAddZero(dd[1], 2) + '/' + cmnAddZero(dd[2], 2);
  //else if (fmt === 'YYYYMM')
  //    return cmnAddZero(dd[0], 4) + '' + cmnAddZero(dd[1], 2);
  if (fmt === 'YYYY/MM')
      return cmnAddZero(dd[0], 4) + '/' + cmnAddZero(dd[1], 2);
  else if (fmt === 'YYYY/MM/DD')
      return cmnAddZero(dd[0], 4) + '/' + cmnAddZero(dd[1], 2) + '/' + cmnAddZero(dd[2], 2);
  //else if (fmt === 'YYYY/MM/DD HH24:MI')
  //    return cmnAddZero(dd[0], 4) + '/' + cmnAddZero(dd[1], 2) + '/' + cmnAddZero(dd[2], 2) + '&nbsp;' + cmnAddZero(dd[3], 2) + ':' + cmnAddZero(dd[4], 2);
  //else if (fmt === 'YYYY/MM/DD HH24:MI:SS')
  //    return cmnAddZero(dd[0], 4) + '/' + cmnAddZero(dd[1], 2) + '/' + cmnAddZero(dd[2], 2) + '&nbsp;' + cmnAddZero(dd[3], 2) + ':' + cmnAddZero(dd[4], 2) + ':' + cmnAddZero(dd[5], 2);
  else if (fmt === 'YYYYMMDDHH24MISS')
     return cmnAddZero(dd[0], 4) + cmnAddZero(dd[1], 2) + cmnAddZero(dd[2], 2) + cmnAddZero(dd[3], 2) + cmnAddZero(dd[4], 2) + cmnAddZero(dd[5], 2);
  //else if (fmt === 'YYYY/MM/DD HH24:MI:SS.FFF')
  //    return cmnAddZero(dd[0], 4) + '/' + cmnAddZero(dd[1], 2) + '/' + cmnAddZero(dd[2], 2) + '&nbsp;' + cmnAddZero(dd[3], 2) + ':' + cmnAddZero(dd[4], 2) + ':' + cmnAddZero(dd[5], 2) + "." + cmnAddZero(dd[6], 3);
  else
      return '';
}

// ////-----------------------------------------------
// ////  日数の加算
// ////  引数
// ////    str : 日付
// ////    fmt : 日付形式
// ////    add : 加算日数
// ////  戻り値
// ////    加算後の日付
// ////-----------------------------------------------
// //function cmnAddDay(str, fmt, add) {
// //    //  空欄、不正な形式のときは、そのまま
// //    if (cmnIsBlank(str)) return '';
// //    if (!cmnCheckDateTimeFormat(str, fmt)) return str;

// //    let dd1 = cmnReturnDateArray(str, fmt);
// //    let dd2 = cmnReturnDateArray(new Date(dd1[0], dd1[1] - 1, dd1[2] + add), 'date');

// //    return cmnReturnFormatDate(dd2, fmt);
// //}

// ////-----------------------------------------------
// ////  月数の加算
// ////  引数
// ////    str : 日付
// ////    fmt : 日付形式
// ////    add : 加算月数
// ////  戻り値
// ////    加算後の日付
// ////-----------------------------------------------
// //function cmnAddMonth(str, fmt, add) {
// //    //  空欄、不正な形式のときは、そのまま
// //    if (cmnIsBlank(str)) return '';
// //    if (!cmnCheckDateTimeFormat(str, fmt)) return str;

// //    let dd1 = cmnReturnDateArray(str, fmt);
// //    let dd2 = cmnReturnDateArray(new Date(dd1[0], dd1[1] - 1 + add, dd1[2] ), 'date');

// //    return cmnReturnFormatDate(dd2, fmt);
// //}

// ////=================================================================================================
// ////  その他
// ////-----------------------------------------------
// ////  GET変数の取り出し
// ////  引数
// ////    key_name : 変数名
// ////-----------------------------------------------
// //function cmnGetVars(key_name) {
// //    let var_str = window.location.search;
// //    var_str = var_str.substring(1, var_str.length);

// //    let varArray = [];
// //    if (var_str)
// //        varArray = var_str.split('&');

// //    if (varArray.length > 0) {
// //        let tempArray;
// //        for (let i = 0; i < varArray.length; i++) {
// //            tempArray = varArray[i].split('=');
// //            if (tempArray[0] == key_name)
// //                return decodeURIComponent(tempArray[1]);
// //        }
// //    }
// //    return '';
// //}

export { 
  cmnAdd,
  cmnChangeBlank, 
  cmnChangeNumToStr,
  cmnChangeStrToNum,
  cmnGetMonthList,
  cmnGetYearList,
  cmnChangeFormatDate,
  cmnDecimalFormat, 
  cmnCheckNumFormat,
  cmnDivide,
  cmnSubtract,
  cmnExFloor,
  cmnExRound,
  cmnDevidePostalCode,
  cmnInsertDelimiter,
  cmnIsBlank,
  cmnIsNotBlank,
  cmnIsNumStr,
  cmnIsNumValue,
  cmnHasBlank,
  cmnMultiply
}
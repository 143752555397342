import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { RESULT_CODE } from '@/assets/js/const'
import { cmnIsBlank, cmnExRound } from '@/assets/js/common'
import { project } from '@/assets/js/dto/project'
import { usersUsageTime } from '@/assets/js/dto/usersUsageTime'

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export default createStore({
  state: {
    user: null,
    company: null,
    lastUsageTime: null,
    params: {
      data: null,
      volume: null,
      auto_design: null,
      building_edit: null,
      building_check: null,
      file_output: null
    },
    viewerUrl: {
      plan_view_url: null,
      pers_view_url: null
    },
    lists: {
      cross_sections: [],
      l_cond_types: [],
      usage_params: [],
      fire_types: [],
      height_params: [],
      shadow_calc_types: [],
      base_spans: []
    },
    projectName: {
      prefix: null,
      suffix: null
    },
    settingButtons: {
      roadAndEntrance: false,
      warehouse: false
    },
    prevPage: {
      map: null,
      roadAndEntrance: null,
      warehouse: null,
      fileOutput: null
    },
    showModalProject: false,
    prefectureList:null,
    authKey: null,
    youtoFlag: false,
    //Apiエラー判定用
    errorCode: null,
    errorMessageDetail: null,
    errorStatus: null,
    // ダミー
    dummy: null,
    // plan_size_before:0,
    userListPrevPage: null,
    adminFlag: null,
    
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setCompany(state, payload) {
      state.company = payload
    },
    setLastUsageTime(state, payload) {
      state.lastUsageTime = payload
    },
    setParams(state, payload) {
      state.params = payload
    },
    setViewerUrl(state, payload) {
      state.viewerUrl = payload
    },
    setLists(state, payload){
      state.lists = payload
    },
    setProjectName(state, payload) {
      state.projectName = payload
    },
    setSettingButtons(state, payload) {
      state.settingButtons = payload
    },
    setPrevPage(state, payload) {
      state.prevPage = payload
    },
    setAuthKey(state, payload) {
      state.authKey = payload
    },
    setErrorCode(state, payload) {
      state.errorCode = payload
    },
    setErrorMessageDetail(state, payload) {
      state.errorMessageDetail = payload
    },
    setErrorStatus(state, payload) {
      state.errorStatus = payload
    },
    // 上記へ移動
    setUserListPrevPage(state, payload) {
      state.userListPrevPage = payload
    },
    setYoutoFlag(state, payload) {
      state.youtoFlag = payload
    },
    setSettingButtonsTmp(state, payload) {
      state.settingButtonsTmp = payload
    },
    setShowModalProject(state, payload) {
      state.showModalProject = payload
    },
    setPrefectureList(state, payload) {
      state.prefectureList = payload
    },
    setDummy(state, payload) {
      state.dummy = payload
    },
    setAdminFlag(state, payload) {
      state.adminFlag = payload
    },    
  },
  actions: {
    /* ログイン処理 */
    async findLoginInfo({ commit }, payload) {
      try {
        var res = await axios.post('/api/login/', payload)
        if (res.status === 200 || res.status === 204) {
          if (cmnIsBlank(res.data.user)) 
            return RESULT_CODE.NG
          commit('setUser', res.data.user)
          commit('setCompany', res.data.company)
          const dt = new Date()
          commit('setLastUsageTime', dt)
          return RESULT_CODE.OK
        } else {
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      }
    },
    /* 住所階層検索（住所コード指定） */
    async addressDirectory({ commit }, payload) {
      try {
        if(payload.getLevel === 1
          && this.state.prefectureList !== null 
          && this.state.prefectureList !== undefined
          && this.state.prefectureList.length > 0) {
          return this.state.prefectureList
        }
        var res = await axios.get('/api/common/addressDirectory', {
                    params: {
                      adcd: payload.adcd,
                      getLevel: payload.getLevel
                    }
                  })
        if (res.status === 200 || res.status === 204) {
          if(payload.getLevel === 1) {
            commit('setPrefectureList', res.data)
          }
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 住所階層検索（住所指定） */
    async searchAddress({ commit }, payload) {
      try {
        var res = await axios.get('/api/common/addressSearch', { params: payload })
        if (res.status === 200 || res.status === 204) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 利用時間登録 */
    async updateUsersUsageTimes({ commit }) {
      // ユーザ情報
      const userId = this.state.user.userId
      // 利用時間算出(s)
      const lastUsageTime = new Date(this.state.lastUsageTime)
      const dt = new Date();
      const sec = cmnExRound((dt.getTime() - lastUsageTime.getTime()) / 1000)
      
      let payload = new usersUsageTime()
      payload.userId = userId
      payload.usageTime = sec
      let res = await axios.put('/api/common/usersUsageTimes', payload)
      if (res.status === 200) {
        commit('setLastUsageTime', dt)
      }
    },
    /* プランデータ更新日更新 */
    async updateProjectUpdateDate({ commit }) {
      const params = this.state.params
      if (params == null) return;
      let payload = new project()
      payload.projectNo = params.data.project_no
      await axios.put('/api/common/projectUpdateDate', payload)
      commit('setDummy', null)
    },
    /* プランステータス更新 */
    async updateProjectStatus({ commit }, payload) {
      const params = this.state.params
      if (params == null) return;
      let prm = new project()
      prm.projectNo = params.data.project_no
      prm.projectStatus = payload.planStatus
      await axios.put('/api/common/projectStatus', prm)
      commit('setDummy', null)
    },
    /* CDSAPI認証キーを獲得 */
    async getCdsApiKey({ commit }) {
      try {
        var res = await axios.get('/api/common/cdsApiKey')
        if (res.status === 200) {
          commit('setAuthKey', res.data)
          // axios.defaults.headers.Authorization = token
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* GeospaceAPIユーザを獲得 */
    async getGeospaceApiUser({ commit }) {
      try {
        var res = await axios.get('/api/common/geospaceApiUser')
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* ダウンロード（ROOKファイル） */
    async rookDownload({ commit }, payload) {
      try {
        var res = await axios.get('/api/common/rookDownload', { params: payload, responseType : 'arraybuffer' })
        if (res.status === 200) {
          return res
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 問い合わせ送信サービス */
    async sendInquiry({commit}, payload) {
      try {
        var res = await axios.post('/api/common/sendInquiry', payload)
        if (res.status === 200) {
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },
    /* 物件一覧取得 */
    async getProjectList({commit}, payload) {
      try {
        var res = await axios.get('/api/project/db', {
                    params:payload
                  })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* お気に入り更新 */
    async changeFavStatus({commit}, payload) {
      try {
        var res = await axios.put('/api/project/db/bookmark', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* プロジェクト新規作成 */
    async createProjectData({commit}, payload) {
      try {
        var res = await axios.post('/api/project/', payload)
        if (res.status === 200) {
          let params = this.state.params
          params.data = res.data.data
          commit('setParams', params)
          // todo berthType
          this.dispatch('setViewerUrl', res.data)
          this.dispatch('setListsForSite', res.data)
          console.log(res.data.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* プラン情報更新 */
    async changeProjectData({commit}, payload) {
      try {
        var res = await axios.put('/api/project/' + payload.engine.data.project_no, payload)
        if (res.status === 200) {
          let params = this.state.params
          // 敷地情報は元の情報を引き継ぐ
          let sitePlannning = cloneDeep(params.data.site_planning)
          params.data = res.data.data
          params.data.site_planning = sitePlannning
          commit('setParams', params)
          this.dispatch('setViewerUrl', res.data)
          this.dispatch('setListsForSite', res.data)
          console.log(res.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 物件複写サービス */
    async copyProjectData({commit}, payload) {
      try {
        var res = await axios.post('/api/project/' + payload.projectNo + '/copy')
        if (res.status === 200) {
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 物件を削除する */
    async deleteProjectData({commit}, payload) {
      try {
        var res = await axios.delete('/api/project/' + payload.projectNo)
        if (res.status === 200) {
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 断面リスト取得 */
    async getCrossSectionList({ commit }){
      try {
        var res = await axios.get('/api/project/crossSectionList')
        if (res.status === 200) {
          // console.log(res.data)
          let lists = this.state.lists
          lists.cross_sections = res.data.cross_section_list
          commit('setLists', lists)
          let projectName = {
            prefix: res.data.project_name_prefix,
            suffix: res.data.project_name_suffix
          }
          commit('setProjectName', projectName)
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* 地番情報取得（選択敷地）*/
    async getParcelNo({ commit }, payload) {
      try {
        var res = await axios.get('/api/map/getParcelNo', { params: payload })
        if (res.status === 200 || res.status === 204) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 住所情報取得（手書き敷地） */
    async getAddress({ commit }, payload) {
      try {
        var res = await axios.get('/api/map/getAddress', { params: payload })
        if (res.status === 200 || res.status === 204) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    // /* 入力敷地登録 */
    // async updateMap({ commit }, payload) {
    //   try {
    //     var res = await axios.put('/api/map/' + this.state.params.data.project_no, payload)
    //     if (res.status === 200 || res.status === 204) {
    //       let params = this.state.params
    //       params.data.site_planning = res.data.data.site_planning
    //       commit('setParams', params)
    //     } else {
    //       console.log(res.data)
    //       throw res.statusText
    //     }
    //   } catch(e) {
    //     this.dispatch('handleError', e)
    //     throw e
    //   } 
    // },
    /* 周辺地図作成 */
    async buildAroundMap({ commit }, payload) {
      try {
        var res = await axios.post('/api/map/' + this.state.params.data.project_no + '/buildAroundMap', payload)
        if (res.status === 200 || res.status === 204) {
          let params = this.state.params
          params.data.site_planning = res.data.data.site_planning
          commit('setParams', params)
        }else{          
          console.log(res.data)
          throw res.statusText
          }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 敷地情報取得 */
    async getBuildingSites({ commit }, payload){
      try {
        var res = await axios.get('/api/buildingSites/' + payload)
        if (res.status === 200) {
          // console.log(res.data)
          let params = this.state.params
          params.data = res.data.data
          commit('setParams', params)
          this.dispatch('setViewerUrl', res.data)
          this.dispatch('setListsForSite', res.data)
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 敷地データ設定 */
    async updateBuildingSite({ commit }, payload) {
      try {
        let res = await axios.put('/api/buildingSites/'+ this.state.params.data.project_no, payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          this.dispatch('setListsForSite', res.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 敷地辺情報登録 */
    async updateBuildingSiteLCode ({ commit }, payload) {
      try {
        let res = await axios.put('/api/buildingSites/'+ this.state.params.data.project_no + '/lCond', payload)
        if (res.status === 200) {
          let params = this.state.params
          params.data.site_planning.deregulation = res.data.deregulation
          commit('setParams', params)
          this.dispatch('setViewerUrl', res.data)
          this.dispatch('setListsForSite', res.data)
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 接道と出入口登録サービス */
    async setLCondGate({ commit }, payload) {
      try {
        let params = this.state.params
        var res = await axios.put('/api/buildingSites/' + params.data.project_no + '/lCondGate', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 範囲外領域設定サービス */
    async setExceptedArea({ commit }, payload) {
      try {
        let params = this.state.params
        var res = await axios.put('/api/buildingSites/' + params.data.project_no + '/exceptedArea', payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          this.dispatch('setListsForSite', res.data)
          // return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
        throw e
      } 
    },
    /* 境界線リスト取得サービス */
    async getLcondList({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/volume/' + params.data.project_no + '/lCondList')
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 出入口指定サービス */
    async setGateTruckBerth({ commit }, payload) {
      try {
        let params = this.state.params
        var res = await axios.post('/api/volume/' + params.data.project_no + '/gateTruckberth', payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 出入口実行サービス */
    async setVolume({ commit }, payload) {
      try {
        let params = this.state.params
        var res = await axios.post('/api/volume/' + params.data.project_no, payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 自動設計候補リスト取得 */
    async getAutoDesignList({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/autoDesign/' + params.data.project_no + '/autoDesignList')
        if (res.status === 200) {
          params.data.floor_list = res.data.floor_list
          commit('setParams', params)
          let lists = this.state.lists
          lists.base_spans = res.data.auto_design_data.base_span_list
          lists.cross_sections = res.data.auto_design_data.cross_section_list
          commit('setLists', lists)
          // return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 自動設計実行サービス */
    async startAutoDesign({ commit }, payload) {
      try {
        let params = this.state.params
        var res = await axios.post('/api/autoDesign/' + params.data.project_no, payload)
        if (res.status === 200) {
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 自動設計実行結果取得サービス */
    async getAutoDesignResult({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/autoDesign/' + params.data.project_no)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 自動設計結果保存サービス */
    async saveAutoDesignResult({ commit }, payload) {
      try {
        let params = this.state.params
        var res = await axios.put('/api/autoDesign/' + params.data.project_no, payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 自動設計実行キャンセルサービス */
    async cancelAutoDesign({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.delete('/api/autoDesign/' + params.data.project_no)
        if (res.status === 200) {
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 自動設計情報取得サービス */
    async getAutoDesignInfo({ commit }) {
      try {
        var res = await axios.get('/api/autoDesign/' + this.state.params.data.project_no + '/buildingBaseSetting')
        if (res.status === 200) {
          let params = this.state.params
          params.auto_design = res.data
          commit('setParams', params)
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* 建物調整サービス */
    async setAdjustBuilding({ commit }, payload) {
      try {
        var res = await axios.put('/api/autoDesign/' + this.state.params.data.project_no + '/adjustBuilding', payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          return res.data
        } else {
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 基準スパン変更基本設定取得 */
    async getBaseSpan({ commit }, payload){
      try {
        var res = await axios.get('/api/buildingEdit/' + this.state.params.data.project_no + '/baseSpan', { params: payload })
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 基準スパン変更 */
    async calcBaseSpan({ commit }, payload){
      try {
        var res = await axios.post('/api/buildingEdit/' + this.state.params.data.project_no + '/baseSpan', payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 断面調整 */
    async setFloor({ commit }, payload){
      try {
        var res = await axios.post('/api/buildingEdit/' + this.state.params.data.project_no + '/floor', payload)
        if (res.status === 200) {
          this.dispatch('setViewerUrl', res.data)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 建物概要取得 */
    async getBuildingSummary({ commit }){
      try {
        var res = await axios.get('/api/buildingCheck/' + this.state.params.data.project_no + '/buildingSummary')
        if (res.status === 200) {
          let params = this.state.params
          if(params.building_check === null) params.building_check = {building_summary: null, law_check: null}
          params.building_check.building_summary = res.data.building_summary
          commit('setParams', params)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* 法規チェック */
    async execLawCheck({ commit }) {
      try {
        var res = await axios.post('/api/buildingCheck/' + this.state.params.data.project_no + '/lawCheck')
        if (res.status === 200) {
          let params = this.state.params
          params.building_check.law_check = res.data.law_check
          commit('setParams', params)
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* プラン図出力サービス */
    async outputPlanFigure({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/fileOutput/' + params.data.project_no + '/planFigure')
        if (res.status === 200) {
          params.file_output.plan_figure_output = res.data.plan_figure_output
          commit('setParams', params)
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* 計画地敷地取得サービス */
    async getSitePlan({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/fileOutput/' + params.data.project_no + '/sitePlan')
        if (res.status === 200) {
          params.file_output.area_table_output.site_line_list = res.data.site_line_list
          params.file_output.area_table_output.center_points = res.data.center_points
          commit('setParams', params)
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        this.dispatch('handleError', e)
      } 
    },
    /* 計画地地図作成サービス */
    async buildSitePlanImage({ commit }, payload) {
      try {
        var res = await axios.post('/api/fileOutput/' + this.state.params.data.project_no + '/sitePlanImage', payload)
        if (res.status === 200) {
          return RESULT_CODE.OK
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 計画地地図取得サービス */
    async getSiteMapImage({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/fileOutput/' + params.data.project_no + '/siteMapImage', { responseType : 'arraybuffer' } )
        if (res.status === 200) {
          const file = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream' }))
          return file
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 面積表出力サービス */
    async outputAreaTable({ commit }) {
      try {
        let params = this.state.params
        var res = await axios.get('/api/fileOutput/' + params.data.project_no + '/areaTable')
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 物件出力サービス */
    async setRookPlan({ commit }, payload) {
      try {
        var res = await axios.get('/api/fileOutput/' + payload.projectNo + '/rookPlan', { responseType : 'arraybuffer' })
        if (res.status === 200) {
          return res
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },
    /* 状態ストアの初期化 */
    // (a)-1 楽ロジデータ
    initParams ({ commit }) {
      let params = this.state.params
      params.data = null
      params.volume = null
      params.auto_design = null
      params.building_edit = null
      params.building_check = null
      params.file_output = null
      commit('setParams', params)
    },
    // (a)-2 敷地情報
    initParamsSitePlanning ({ commit }) {
      let params = this.state.params
      if (params.data !== null) {
        params.data.site_planning = null
      }
      params.volume = null
      params.auto_design = null
      params.building_edit = null
      params.building_check = null
      params.file_output = null
      commit('setParams', params)
    },
    // (b)ビューアURL
    initViewerUrl({ commit }) {
      let viewerUrl = {
        plan_view_url: null,
        pers_view_url: null
      }
      commit('setViewerUrl', viewerUrl)
    },
    // (c)リスト
    initLists({ commit }) {
      let lists = {
        cross_sections: [],
        l_cond_types: [],
        usage_params: [],
        fire_types: [],
        height_params: [],
        shadow_calc_types: [],
        base_spans: []
      }
      commit('setLists', lists)
    },
    // (d)表示モード
    initSettingButtons({ commit }) {
      let settingButtons = {
        roadAndEntrance: false,
        warehouse: false
      }
      commit('setSettingButtons', settingButtons)
    },
    // (e)プロジェクト名
    initProjectName({ commit }) {
      let projectName = {
        prefix: null,
        suffix: null
      }
      commit('setProjectName', projectName)
    },
    // (f)遷移元画面
    initPrevPage({ commit }) {
      let prevPage = {
        map: null,
        roadAndEntrance: null,
        warehouse: null,
        fileOutput: null
      }
      commit('setPrevPage', prevPage)
    },
    /* ビューアURLの更新 */
    setViewerUrl({ commit }, data) {
      let viewerUrl = {
        plan_view_url: data.plan_view_url,
        pers_view_url: data.pers_view_url
      }
      commit('setViewerUrl', viewerUrl)
    },
    /* リストの更新 */
    setListsForSite({ commit }, data) {
      // 対象外のリストはそのまま残す
      let lists = this.state.lists
      lists.l_cond_types = data.l_cond_type_list
      lists.usage_params = data.usage_param_list
      lists.fire_types = data.fire_type_list
      lists.height_params = data.height_param_list
      lists.shadow_calc_types = data.shadow_calc_type_list
      commit('setLists', lists)
    },
    /* エラー処理 */
    async handleError({ commit }, e) {
      commit('setErrorStatus', e.response.status)
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.status === 400){
          if (typeof e.response.data === 'object') {
            commit('setErrorCode', 'warning')
            commit('setErrorMessageDetail', e.response.data.message )
          } else {
            commit('setErrorCode', 'failure')
            commit('setErrorMessageDetail', e.response.data )
          }
        } else if (e.response.status === 401 || e.response.status === 409) {
          // 多重ログインまたはセッション切れの場合
          commit('setErrorCode', 'warning')
          commit('setErrorMessageDetail', e.response.data)
        } else {
          commit('setErrorCode', 'failure')
          commit('setErrorMessageDetail', e.response.data)
        }
      } else {
        commit('setErrorCode', 'failure')
        commit('setErrorMessageDetail', e.message)
      }
    },
    
    /* 顧客管理システム用 */

    /* 仮登録 */
    async preRegistration({ commit }, payload) {
      try {
        var res = await axios.post('/api/userSignUp/preRegistration', null, {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* メールアドレス認証 */
    async validateEmail({ commit }, payload) {
      try {
        var res = await axios.get('/api/userSignUp/validateEmail', {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* ライセンス単価取得 */
    async getPricesOfLicense({ commit }, payload) {
      try {
        var res = await axios.get('/api/userSignUp/getChargingPlan', {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* 顧客登録 */
    async sendRegistrationInfo({ commit }, payload) {
      try {
        var res = await axios.post('/api/userSignUp/signUp', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
      
    },
    
    /* ショップIDの取得 */
    async getShopId({ commit }) {
      try {
        var res = await axios.get('/api/userSignUp/getShopId') 
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
        
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },
    
    /* ユーザ一覧取得 */
    async getUserList({ commit }, payload) {
      try {
        var res = await axios.get('/api/userList', {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      } 
    },

    /* ライセンス解除 */
    async removeLicense({ commit }, payload) {
      try {
        var res = await axios.put('/api/userList/removeLicense', null, {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* ライセンス割当 */
    async attachLicense({ commit }, payload) {
      try {
        var res = await axios.put('/api/userList/attachLicense', null, {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* ユーザ削除 */
    async deleteUser({ commit }, payload) {
      try {
        var res = await axios.put('/api/userList/deleteUserInfo', null, {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* ユーザ情報登録・更新 */
    async registUserInfo({ commit }, payload) {
      try {
        var res = await axios.post('/api/userList/registUserInfo', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* ユーザ情報復元 */
    async restoreUserInfo({ commit }, payload) {
      try {
        var res = await axios.put('/api/userList/restoreUserInfo', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* 登録情報取得 */
    async getRegistrationInfo({ commit }, payload) {
      try {
        var res = await axios.get('/api/registrationInfoCheck', {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* 登録情報修正 */
    async modifyRegistrationInfo({ commit }, payload) {
      try {
        var res = await axios.put('/api/registrationInfoCheck', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* 料金確認情報取得 */
    async confirmChargeInfo({ commit }, payload) {
      try {
        var res = await axios.get('/api/registrationInfoCheck/confirmChargeInfo', {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* 請求情報取得 */
    async findBillingInfo({ commit }, payload) {
      try {
        var res = await axios.get('/api/billingInfo', {params: payload})
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

    /* パスワード変更 */
    async changePassword({ commit }, payload) {
      try {
        var res = await axios.put('/api/changePassword', payload)
        if (res.status === 200) {
          return res.data
        } else {
          console.log(res.data)
          throw res.statusText
        }
      } catch(e) {
        commit('setDummy', null)
        this.dispatch('handleError', e)
      }
    },

  },
  plugins: [createPersistedState({storage: window.sessionStorage})]
})
<template>
  <div id="setting-btn" class="btn-group position-absolute top-0 end-0">
    <button class="btn btn-lg p-2 my-2 mx-4 fs-1 lh-1 rounded" :class="iconColor" type="button" id="setting" data-bs-toggle="dropdown" aria-expanded="false"
      aria-haspopup="true" >
      <font-awesome-icon :icon="['fas', 'bars']" />
    </button>
    <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end p-0" style="background-color:rgba(0, 0, 0, 0.85);"
      aria-labelledby="setting">
      <li v-if="showTopLink"><button class="dropdown-item" type="button" @click="$_topPage">プロジェクト一覧に戻る</button></li>
      <li v-if="showTopLink"><hr class="dropdown-divider m-0"></li>
      <li><button class="dropdown-item" type="button" @click="$_userManual">マニュアル</button></li>
      <li><hr class="dropdown-divider m-0"></li>
      <li v-if="!showTopLink && showUserLink"><button class="dropdown-item" type="button" @click="$_userPage">管理者メニュー</button></li>
      <li v-if="!showTopLink && showUserLink"><hr class="dropdown-divider m-0"></li>
      <li v-if="!showTopLink"><button class="dropdown-item" type="button" @click="$refs.modalChangePassword.init()">パスワード変更</button></li>
      <li v-if="!showTopLink"><hr class="dropdown-divider m-0"></li>
      <li><button class="dropdown-item" type="button" @click="$refs.modalTermsOfUse.init()">利用規約</button></li>
      <li><hr class="dropdown-divider m-0"></li>
      <li><button class="dropdown-item" type="button" @click="$_inquiryPage">問い合わせ</button></li>
      <li><hr class="dropdown-divider m-0"></li>
      <li><button class="dropdown-item" type="button" @click="$_logout">ログアウト</button></li>
    </ul>
    <!-- Modal -->
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="setting-btn" v-on:return-confirm-result="returnConfirmResult" />
    <modal-terms-of-use ref="modalTermsOfUse" />
    <modal-change-password ref="modalChangePassword" />
  </div>
</template>

<script>
import ModalConfirm from '@/views/common/ModalConfirm'
import ModalTermsOfUse from '@/views/common/ModalTermsOfUse'
import ModalChangePassword from '@/views/ModalChangePassword'
import { RESULT_CODE, FLAG } from '@/assets/js/const'

export default {
  name: "SettingBtn",
  props: {
    iconColor:  {
      type: String
    },
    showTopLink: {
      type: Boolean
    },
    showUserLink: {
      type: Boolean
    }
  },
  components: {
    ModalConfirm,
    ModalTermsOfUse,
    ModalChangePassword,
  },
  data() {
    return {
      confirmMessages: [],
      logout: FLAG.OFF,
      adminFlag: FLAG.OFF,
    }
  },
  methods: {
    /* [マニュアル]クリック */
    $_userManual(){
      // 設計システムのマニュアル
      if(this.$store.state.adminFlag === FLAG.OFF)
      window.open("./startup_manual.pdf",
      "_blank")
      // 管理者画面のマニュアル
      else{
      window.open("./admin_manual.pdf",
      "_blank")
      }
    },
    /* [プロジェクト一覧に戻る]クリック */
    $_topPage() {
      // 確認ダイアログを表示する
      this.confirmMessages = []
      this.confirmMessages.push('プロジェクト一覧に戻ります。', 'よろしいですか？')
      this.$store.commit("setAdminFlag", FLAG.OFF);
      this.$refs.modalConfirm.init()
    },
    /* [管理者メニュー]クリック */
    $_userPage() {
      this.$store.commit("setAdminFlag", FLAG.ON);
      this.$router.push({ path: 'userList' })
    },
    /* [問い合わせ]クリック */
    $_inquiryPage() {
      this.$router.push({ path: 'inquiry' })
    },
    /* [ログアウト]クリック */
    $_logout() {
      // 確認ダイアログを表示する
      this.confirmMessages = []
      this.confirmMessages.push('ログアウトします。', 'よろしいですか？')
      this.$refs.modalConfirm.init()
      this.logout = FLAG.ON
      this.$store.commit("setAdminFlag", FLAG.OFF);
    },
    returnConfirmResult(resultCode) {
      // [いいえ]クリック：
      if(resultCode === RESULT_CODE.NG) {
        this.logout = FLAG.OFF
        return
      }
      // [はい]クリック：
      // 共通処理-利用時間登録
      this.$store.dispatch('updateUsersUsageTimes')
      // 状態ストアを初期化
      // (a)楽ロジデータ, (b)ビューアURL, (c)リスト, (d)表示モード
      this.$store.dispatch('initParams')
      this.$store.dispatch('initViewerUrl')
      this.$store.dispatch('initLists')
      this.$store.dispatch('initSettingButtons')
      if (this.logout === FLAG.OFF) {
      // プロジェクト一覧画面へ遷移
        this.$router.push({ path: 'top' })
      } else {
        // 項目の初期化
        this.$store.commit('setUser', null)
        this.$store.commit('setCompany', null)
        this.$store.dispatch('initProjectName')
        this.$store.dispatch('initPrevPage')
        this.$store.commit('setShowModalProject', null)
        this.$store.commit('setAuthKey', null)
        this.$store.commit('setYoutoFlag', null)
        // ログイン画面へ遷移
        this.$router.push({ path: '/' })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
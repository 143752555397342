<template>
  <div class="h-100" id="warehouse-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">自動設計結果表示</p>
        <p class="d-block mb-0">物件管理ID：{{project.project_no}}</p>
        <p class="d-block mb-0">プロジェクト名称：{{project.project_name}}</p>
      </div>
      <div class="my-overflow-y p-2" v-if="settingButtons.warehouse===false">
        <div class="container">
          <div class="row">
            <div class="col-6">建物種類：</div>
            <div class="col px-0">{{buildingTypeText}}</div>
          </div>
          <div class="row">
            <div class="col-6">形状：</div>
            <div class="col px-0">{{buildingShapeText}}</div>
          </div>
          <div class="row">
            <div class="col-6">階数：</div>
            <div class="col px-0">{{buildingSummary.floor}}階</div>
          </div>
          <div class="row">
            <div class="col-6">容積率：</div>
            <div class="col px-0">{{$_decimalFormat(buildingSummary.capacity_rate, 2)}}%/{{$_decimalFormat(buildingSummary.plan_capacity, 2)}}%</div>
          </div>
          <div class="row">
            <div class="col-6">建蔽率：</div>
            <div class="col px-0">{{$_decimalFormat(buildingSummary.building_coverage_rate, 2)}}%/{{$_decimalFormat(buildingSummary.plan_coverage, 2)}}%</div>
          </div>
          <div class="row">
            <div class="col-6">倉庫面積：</div>
            <div class="col px-0">{{$_decimalFormat(buildingSummary.rental_warehouse_area, 2)}}㎡</div>
          </div>
          <div class="row">
            <div class="col-6">事務所面積：</div>
            <div class="col px-0">{{$_decimalFormat(buildingSummary.rental_office_area, 2)}}㎡</div>
          </div>
          <div class="row">
            <div class="col-6">延床面積：</div>
            <div class="col px-0">{{$_decimalFormat(buildingSummary.rental_floor_area, 2)}}㎡</div>
          </div>
          <div class="row" v-if="showMultipleUsageDesc">
            <div class="col m-1">敷地が複数の用途地域にある場合は敷地面積比の加重平均を建蔽率とします。</div>
          </div>
          <div class="row" v-if="showException">
            <div class="row" style="color:red; font-weight:bold;">&emsp;例外結果を表示しています。</div>
            <div class="row" style="color:red; font-size:small;">&emsp;＊自動計算にて正常な計算結果が1件もない場合</div>
            <div class="row" style="color:red; font-size:small;">&emsp;&emsp;例外結果が表示されます。</div>
            <div class="row" style="color:red; font-size:small;">&emsp;&emsp;（必要スパン数を確保できない等）</div>
          </div>
        </div>
        <div class="mt-4 container">
          <div class="row">
            <div class="col">法規チェック結果</div>
          </div>
          <hr class="my-1">
          <div class="row">
            <div class="col-6">斜線ボリューム：</div>
            <div class="col px-0" :class="{'text-danger': lawCheck.check.slant_line_volume.result !== true}">{{$_convertLawCheckResult(lawCheck.check.slant_line_volume.result)}}</div>
          </div>
          <div class="row">
            <div class="col-6">絶対高さ：</div>
            <div class="col px-0" :class="{'text-danger': lawCheck.check.absolute_height.result !== true}">{{$_convertLawCheckResult(lawCheck.check.absolute_height.result)}}</div>
          </div>
          <div class="row">
            <div class="col-6">日影規制：</div>
            <div class="col px-0" :class="{'text-danger': lawCheck.check.sun_shadow_regulations.result_message !== 'OK'}">{{lawCheck.check.sun_shadow_regulations.result_message}}</div>
          </div>
        </div>
      </div>
      <div class="my-overflow-y p-2" v-if="settingButtons.warehouse">
        <div class="d-grid gap-3">
          <button class="btn btn-primary" type="button"
            @click="$refs.modalBuildingSummary.init()">建物概要</button>
          <button class="btn btn-primary" type="button"
            @click="$_selectPlan">プラン選択</button>
          <button class="btn btn-primary" type="button"
            @click="$refs.modalChangeBaseSpan.init()">基準スパン変更</button>
          <button class="btn btn-primary" type="button"
            @click="$refs.modalAdjustFloor.init()">階高設定</button>
          <button class="btn btn-primary" type="button"
            @click="$refs.modalAdjustBuilding.init()">建物調整</button>
        </div>
      </div>
      <div class="mt-auto mx-2 d-grid gap-3" v-if="settingButtons.warehouse===false">
        <button class="btn btn-primary mt-3" type="button" @click="$_nextPage">成果物へ</button>
        <button class="btn btn-primary" type="button" @click="$_changeSettings">条件変更</button>
        <button type="button" class="btn btn-primary" @click="$_prevPage">戻る</button>
      </div>
      <div class="mt-auto mx-2 d-grid gap-3" v-if="settingButtons.warehouse">
        <button class="btn btn-primary mt-3" type="button" @click="$refs.modalLawCheck.init()">法規チェック</button>
        <button class="btn btn-primary" type="button" @click="$_showPrev">戻る</button>
      </div>
      <footer-section></footer-section>
      <!-- modal -->
      <modal-block-message ref="modalBlockMessage" parent-id="warehouse-input-section" :display-progress="true" :prop-percent="percent"/>
      <modal-building-summary ref="modalBuildingSummary" />
      <modal-change-base-span ref="modalChangeBaseSpan" @set-viewer-to-inputsection="setViewer" :prop-url="url"/> 
      <modal-adjust-floor ref="modalAdjustFloor" :parent-page="parentPage" :prop-floor-list="null" @set-viewer-to-inputsection="setViewer"/>
      <modal-adjust-building ref="modalAdjustBuilding" @set-viewer-to-inputsection="setViewer" :prop-url="url"/>
      <modal-law-check ref="modalLawCheck" />
    </div>
  </div>
</template>

<script>
  import ModalAdjustBuilding from '@/views/warehouse/ModalAdjustBuilding'
  import ModalChangeBaseSpan from '@/views/warehouse/ModalChangeBaseSpan'
  import ModalBuildingSummary from '@/views/warehouse/ModalBuildingSummary'
  import ModalLawCheck from '@/views/warehouse/ModalLawCheck'
  import ModalAdjustFloor from '@/views/warehouse/ModalAdjustFloor'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import HeaderSection from '@/views/common/HeaderSection'
  import FooterSection from '@/views/common/FooterSection'
  import { initBuildingSummary } from '@/assets/js/dto/E_building_summary'
  import { initLawCheck } from '@/assets/js/dto/E_law_check'
  import { buildingShapes, buildingTypes } from '@/assets/js/code'
  import { cmnDecimalFormat } from '@/assets/js/common'
  import { PLAN_STATUS, PAGE } from '@/assets/js/const'

  export default {
    name: 'InputSection',
    components: {
      ModalAdjustBuilding,
      ModalChangeBaseSpan,
      ModalBuildingSummary,
      ModalLawCheck,
      ModalAdjustFloor,
      ModalBlockMessage,
      HeaderSection,
      FooterSection
    },
    data() {
      return {
        project: this.$store.state.params.data,
        buildingSummary: initBuildingSummary(),
        lawCheck: initLawCheck(),
        settingButtons: this.$store.state.settingButtons,
        buildingTypeText: null,
        buildingShapeText: null,
        percent: 0,
        url: ''
      }
    },
    computed: {
      parentPage: function() {
        return PAGE.WAREHOUSE
      },
      showMultipleUsageDesc: function() {
        return this.project.site_planning.usage.count > 1
      },
      showException: function() {
        return this.buildingSummary.is_exception === 1
      }
    },
    mounted() {
      this.$_setData()
    },
    methods: {
      /* データ設定 */
      async $_setData() {
        this.percent = 0
        this.$refs.modalBlockMessage.init()
        await this.$store.dispatch('getBuildingSummary')
          .then((resData)=>{
            this.$store.dispatch('setViewerUrl', resData)
            this.url = resData.plan_view_url
            this.setViewer(resData.plan_view_url)
            this.buildingSummary = this.$store.state.params.building_check.building_summary
            this.$_convertBuildingTypes()
            this.$_convertBuildingShapes()
            this.percent = 50
            this.$store.dispatch('execLawCheck')
              .then(()=>{
                this.lawCheck = this.$store.state.params.building_check.law_check
                this.percent = 100
                this.$refs.modalBlockMessage.close()
              })
          })
          .catch(() => {
            this.$refs.modalBlockMessage.close()
          })
        await this.$store.dispatch('updateProjectStatus', { planStatus: PLAN_STATUS.WAREHOUSE })
      },
      /*建物種類変換*/
      $_convertBuildingTypes() {
        for(let i=0, len = buildingTypes.length; i<len; i++){
          if(this.buildingSummary.building_type === buildingTypes[i].code)
            this.buildingTypeText = buildingTypes[i].text
        }
      },
      /*形状変換*/
      $_convertBuildingShapes() {
        for(let i=0, len = buildingShapes.length; i<len; i++){
          if(this.buildingSummary.building_shape === buildingShapes[i].code)
            this.buildingShapeText = buildingShapes[i].text
        }
      },
      /* 数値のフォーマット */
      $_decimalFormat(siteArea, digit) {
        return cmnDecimalFormat(siteArea, digit)
      },
      /*法規チェック結果変換*/
      $_convertLawCheckResult(result) {
        if(result === true) return 'OK'
        else if(result === false) return 'NG'
        else if(result === null) return '未チェック'
      },
      /* 成果物へ */
      $_nextPage() {
        this.$router.push({ path: 'file-output' })
      },
      /* 条件変更 */
      $_changeSettings() {
        this.settingButtons.warehouse = true
        this.$store.commit('setSettingButtons', this.settingButtons)
      },
      /* 戻る */
      $_prevPage() {
        this.$store.dispatch('updateUsersUsageTimes')
        let params = this.$store.state.params
        if (params.auto_design !== null
            && params.auto_design.auto_design_result_list !== undefined
            && params.auto_design.auto_design_result_list !== null
            && params.auto_design.auto_design_result_list.length > 0)
          params.auto_design.auto_design_result_list = []
        this.$store.commit('setParams', params)
        let storePrevPage = this.$store.state.prevPage
        storePrevPage.roadAndEntrance = PAGE.WAREHOUSE
        this.$store.commit('setPrevPage', storePrevPage)
        this.settingButtons.warehouse = false
        this.$store.commit('setSettingButtons', this.settingButtons)
        this.$router.push({ path: 'road-and-entrance' })
      },
      /* 建物選択 */
      $_selectPlan() {
        this.$router.push({ path: 'warehouse-select-plan' })
      },
      /* メニューの戻る */
      $_showPrev() {
        this.settingButtons.warehouse = false
        this.$store.commit('setSettingButtons', this.settingButtons)
        this.$_setData()
      },
      /*ビューア再表示*/
      setViewer(url){
        this.$emit('set-viewer', url)
    }
    }
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .my-margin-auto {
    margin-left: auto;
  }
</style>
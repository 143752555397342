<template>
  <div id="modal-change-base-span-parent">
    <div class="modal" id="modal-change-base-span" tabindex="-1" data-bs-backdrop="false">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">基準スパン変更</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-header alert-primary py-1">基本設定</div>
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-4">実建築面積</div>
                  <div class="col-4">{{ $_fomatDecimalNumber(this.settingBaseSpan.building_area,2) }} ㎡ ({{ $_fomatDecimalNumber(this.settingBaseSpan.building_coverage_rate,2) }}%)</div>
                  <div class="col-2">残り</div>
                  <div class="col-2 text-end">{{ $_fomatDecimalNumber(this.settingBaseSpan.remaining_building_area,2) }} ㎡</div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">実容積対象面積</div>
                  <div class="col-4">{{ $_fomatDecimalNumber(this.settingBaseSpan.capacity_target_area,2) }} ㎡ ({{ $_fomatDecimalNumber(this.settingBaseSpan.capacity_rate,2) }}%)</div>
                  <div class="col-2">残り</div>
                  <div class="col-2 text-end">{{ $_fomatDecimalNumber(this.settingBaseSpan.remaining_capacity_target_area,2) }} ㎡</div>
                </div>
                <div class="row mt-1">
                  <div class="col-4">倉庫外周面積</div>
                  <div class="col-4">{{ $_fomatDecimalNumber(this.settingBaseSpan.warehouse_perimeter_area,2) }} ㎡</div>
                </div>
                <div class="row mt-1">
                  <div class="col-2">基準スパン</div>
                  <div class="col-6"><span class=" mx-4">X方向</span><span class="ms-5 ps-5">{{$_fomatDecimalNumber(this.settingBaseSpan.span_width,2)}} m</span></div>
                  <div class="col-2">長さ</div>
                  <div class="col-2 text-end">{{$_fomatDecimalNumber(this.settingBaseSpan.span_width_length,2)}} m</div>
                </div>
                <div class="row mt-1">
                  <div class="col-2"></div>
                  <div class="col-6"><span class=" mx-4">Y方向</span><span class="ms-5 ps-5">{{$_fomatDecimalNumber(this.settingBaseSpan.span_depth,2)}} m</span></div>
                  <div class="col-2">長さ</div>
                  <div class="col-2 text-end">{{$_fomatDecimalNumber(this.settingBaseSpan.span_depth_length,2)}} m</div>
                </div>
              </div>
            </div>
            <div class="card mt-2">
              <div class="card-header form-check alert-primary py-1 px-3">
                <input class="form-check-input ms-0 me-2" type="radio" name="radio-change-type" id="radio-change-type-adjust" v-model="changeType" value="0">
                <label class="form-check-label" for="radio-change-type-adjust">スパン調整</label></div>
              <div class="card-body p-2">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="radio-adjust-span-type" id="radio-adjust-span-type-width" v-model="adjustSpanType" value="0" :disabled="!isAdjustSpanDisabled">
                  <label class="form-check-label" for="radio-adjust-span-type-width">X方向スパン</label>
                </div>
                <div class="form-check form-check-inline ms-2">
                  <input class="form-check-input" type="radio" name="radio-adjust-span-type" id="radio-adjust-span-type-depth" v-model="adjustSpanType" value="1" :disabled="!isAdjustSpanDisabled">
                  <label class="form-check-label" for="radio-adjust-span-type-depth">Y方向スパン</label>
                </div>
                <div class="row ms-0 mt-1 align-items-center">
                  <div class="col-3 form-check">
                    <input class="form-check-input" type="radio" name="radio-change-span-type" id="radio-change-span-type-base" value="0" v-model="changeSpanType" checked :disabled="!isAdjustSpanDisabled">
                    <label class="form-check-label" for="radio-change-span-type-base">基準スパン変更</label>
                  </div>
                  <div class="col-2 pe-0 text-end">{{ changeSpanLength }}<span class="ms-2">m →</span></div>
                  <div class="col-2"><input class="form-control form-control-sm text-end" :disabled="!isAdjustBaseSpanLengthDisabled" v-model="adjustSpanLength" @blur="$_formatAdjustSpanLength"></div>
                  <span class="col-1 p-0">m</span>
                  <span class="col ms-3 p-0">※0.1m単位（範囲9.0～13.0）</span>
                </div>
                <div class="row ms-0 mt-1 align-items-center">
                  <div class="col-2 form-check pe-0">
                    <input class="form-check-input" type="radio" name="radio-change-span-type" id="radio-change-span-type-target" value="1" v-model="changeSpanType" :disabled="!isAdjustSpanDisabled">
                    <label class="form-check-label text-nowrap" for="radio-change-span-type-target">指定スパン変更</label>
                  </div>
                    <span class="col-1 pe-0 text-end">No</span>
                    <div class="col-2">
                      <select class="form-select form-select-sm" :disabled="!isAdjustTargetSpanLengthDisabled" v-model="adjustSpanNo">
                      <option v-for="item in adjustSpanNoList" :key="item.id" :id="item.id">
                        {{ item }}
                      </option>
                    </select>
                    </div>
                </div>
                <div class="row ms-0 mt-1 align-items-center">
                  <div class="col-3"></div>
                  <div class="col-2 pe-0 text-end">{{ changeSpanLength }}<span class="ms-2">m →</span></div>
                  <div class="col-2"><input class="form-control form-control-sm text-end" :disabled="!isAdjustTargetSpanLengthDisabled" v-model="designateSpanLength" @blur="$_formatDesignateSpanLength"></div>
                  <span class="col-1 p-0">m</span>
                  <span class="col ms-3 p-0">※0.1m単位（範囲9.0～13.0）</span>
                </div>
              </div>
            </div>
            <div class="card mt-2">
              <div class="card-header form-check alert-primary py-1 px-3">
                <input class="form-check-input ms-0 me-2" type="radio" name="radio-change-type" id="radio-change-type-insert" v-model="changeType" value="1">
                <label class="form-check-label" for="radio-change-type-insert">スパン挿入</label>
              </div>
              <div class="card-body p-2">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="radio-insert-span-type" id="radio-insert-span-type-width" v-model="insertSpanType" value="0" :disabled="isAdjustSpanDisabled">
                  <label class="form-check-label" for="radio-insert-span-type-width">X方向スパン</label>
                </div>
                <div class="form-check form-check-inline ms-2">
                  <input class="form-check-input" type="radio" name="radio-insert-span-type" id="radio-insert-span-type-depth" v-model="insertSpanType" value="1" :disabled="isAdjustSpanDisabled">
                  <label class="form-check-label" for="radio-insert-span-type-depth">Y方向スパン</label>
                </div>
                <div class="row mt-1 align-items-center">
                  <div class="col-2">スパン挿入</div>
                  <span class="col-1 pe-0 ms-2 text-end">No</span>
                  <div class="col-2">
                      <select class="form-select form-select-sm" :disabled="isAdjustSpanDisabled" v-model="insertSpanNo">
                      <option v-for="item in insertSpanNoList" :key="item.id" :id="item.id">
                        {{ item }}
                      </option>
                    </select>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show mx-auto mb-3" role="alert">
              <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
            </div>
            <button class="btn btn-sm btn-primary" @click="$_calc">計算</button>
            <button class="btn btn-sm btn-secondary" @click="$_cancel">閉じる</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-change-base-span-parent" />
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { cmnIsBlank, cmnCheckNumFormat, cmnChangeStrToNum, cmnDecimalFormat } from '@/assets/js/common'
  import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'

  export default {
    name: 'ModalChangeBaseSpan',
    components: {
      ModalBlockMessage
    },
    props: {
     propUrl: {
      type: String,
      default: ''
    }
  },
    data() {
      return {
        dragElement: null,
        project: this.$store.state.params.data,
        settingBaseSpan:{},
        myModal: null,
        changeType: null,
        changeSpanType: null,
        adjustSpanType:null,
        insertSpanType:null,
        adjustSpanNo: null,
        insertSpanNo: null,
        adjustSpanLength: null,
        designateSpanLength: null,
        errors: [],
        url: '',
        adjustSpanNoList: [],
        insertSpanNoList: [],
        changeSpanLength: null,
      }
    },
    watch: {
    propUrl: function(newVal) {
      this.url = newVal
    },
    adjustSpanType: function(type){
      this.$_changeAdjustSpanType(type)
    },
    insertSpanType: function(type){
      this.$_changeInsertSpanType(type)
    }
  },      
    computed: {
      isAdjustSpanDisabled: function () {
        return this.changeType === "0"
      },
      isAdjustBaseSpanLengthDisabled: function () {
        return this.changeType === "0" && this.changeSpanType === "0"
      },
      isAdjustTargetSpanLengthDisabled: function () {
        return this.changeType === "0" && this.changeSpanType === "1"
      }
    },
    mounted() {
      this.dragElement = document.getElementById('modal-change-base-span').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
    },
    beforeUnmount() {
      drgRemoveEvent(this.dragElement)
    },
    methods: {
      /* 初期化 */
      init() {
      Promise.all([
        this.$_setData()
      ])
      .then(() => {
        this.myModal = new Modal(document.getElementById('modal-change-base-span'), {})
        this.myModal.show()
        this.$emit('set-viewer-to-inputsection', this.url.replace('/ad/','/bw/'))
      })        
      },
      /* データ設定 */
      async $_setData() {
        const payload = {
          building_edit_calc_type: 0,
          change_base_span_calc_type: 0
        }
        this.$refs.modalBlockMessage.init()
        await this.$store.dispatch('getBaseSpan', payload).then((baseSpan)=>{
          this.settingBaseSpan = baseSpan
          this.$refs.modalBlockMessage.close()
        })
        .catch(() => {
          this.$refs.modalBlockMessage.init()
        })
        this.changeType= "0"
        this.changeSpanType= "0"
        this.adjustSpanType="0"
        this.insertSpanType="0"
        this.adjustSpanLength= this.changeSpanLength
        this.designateSpanLength= this.changeSpanLength
        this.$_formatAdjustSpanLength()
        this.$_formatDesignateSpanLength()
        this.$_changeAdjustSpanType(this.adjustSpanType)
        this.adjustSpanNo= null
        this.$_changeInsertSpanType(this.insertSpanType)
        this.insertSpanNo= null
        this.errors = []
      },
      /*小数の成形*/
      $_fomatDecimalNumber(num, digit) {
        return cmnDecimalFormat(num, digit)
      },
      /* スパン長さ（基準スパン）のフォーマット*/
      $_formatAdjustSpanLength() {
        this.adjustSpanLength= cmnDecimalFormat(cmnChangeStrToNum(this.adjustSpanLength), 1)
      },
      /* スパン長さ（指定スパン）のフォーマット*/
      $_formatDesignateSpanLength() {
        this.designateSpanLength = cmnDecimalFormat(cmnChangeStrToNum(this.designateSpanLength), 1)
      },
      /*変更タイプ型変換（文字列→数値)*/
      $_formatChangeType(){
        return cmnChangeStrToNum(this.changeType)
      },
      /*スパン変更タイプ型変換（文字列→数値)*/
      $_formatChangeSpanType(){
        return cmnChangeStrToNum(this.changeSpanType)
      },
      /*計算するスパンタイプ型変換（文字列→数値)して返還*/
      $_formatSpanType(){
        if(this.isAdjustSpanDisabled) return cmnChangeStrToNum(this.adjustSpanType)
        else return cmnChangeStrToNum(this.insertSpanType)
      },
      /*計算するスパンNo返還*/
      $_changeSpanNo() {
        if(this.isAdjustSpanDisabled && this.isAdjustTargetSpanLengthDisabled)  return this.adjustSpanNo.substring( 0 ,(this.adjustSpanNo.indexOf('～')))
        else if(!this.isAdjustSpanDisabled) return this.insertSpanNo.substring(0 ,(this.insertSpanNo.indexOf('～')))
        else return null
      },
      /*計算するスパン長さ返還*/
      $_changeSpanLength() {
        if( this.isAdjustBaseSpanLengthDisabled) return this.adjustSpanLength
        else if(this.isAdjustTargetSpanLengthDisabled) return this.designateSpanLength
        else return null
      },
      /* バリデーションチェック */
      $_validation() {
        this.errors = []
        if( this.isAdjustTargetSpanLengthDisabled ||this.$_formatChangeType()  === 1){
          if(cmnIsBlank(this.$_changeSpanNo())) this.errors.push("「スパンNo」を入力してください。")
          if(!cmnCheckNumFormat(this.$_changeSpanNo())) this.errors.push("「スパンNo」は数値で入力してください。")
        }
        if(this.isAdjustBaseSpanLengthDisabled || this.isAdjustTargetSpanLengthDisabled) {
          if(cmnIsBlank(this.$_changeSpanLength())) this.errors.push("「変更後スパン長さ」を入力してください。")
          else{
            if(!cmnCheckNumFormat(this.$_changeSpanLength())) this.errors.push("「変更後スパン長さ」は数値で入力してください。")
            if(this.$_changeSpanLength() < 9.0 || this.$_changeSpanLength() > 13.0) this.errors.push("「変更後スパン長さ」の範囲は9.0~13.0にしてください。")
          }
        }
        if (!this.errors.length) {
          return true
        }
        return false
      },
      /* スパンNo変更時再描画 */
      $_blurSpanNo(){
        /*
        if (!this.$_validation()) {
          return
        }
        let payload = {
          building_edit: {
            calc_type: 0,
            change_base_span: {
              calc_type: 2,
              change_type: this.$_formatChangeType(),
              span_type: this.$_formatSpanType()
            },
            floor_list: []
          }
        }
        payload.building_edit.change_base_span.change_span_no = this.$_changeSpanNo()
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('calcBaseSpan', payload).then((data) => {
          this.$emit('set-viewer-to-inputsection', data.plan_view_url)
          this.$refs.modalBlockMessage.close()
        })
          .catch(() => {
            this.$refs.modalBlockMessage.close()
          })
        */  
      },
      $_changeAdjustSpanType(type){
      if (type === "1"){
        this.adjustSpanNoList = this.settingBaseSpan.y_no_list;
        this.changeSpanLength = this.settingBaseSpan.span_depth;
        this.designateSpanLength = this.changeSpanLength;
        this.adjustSpanLength = this.changeSpanLength;
      }else{
        this.adjustSpanNoList = this.settingBaseSpan.x_no_list;
        this.changeSpanLength = this.settingBaseSpan.span_width;
        this.designateSpanLength = this.changeSpanLength;
        this.adjustSpanLength = this.changeSpanLength;
      }        
      },
      $_changeInsertSpanType(type){
      if (type === "1"){
        this.insertSpanNoList = this.settingBaseSpan.y_no_list;
      }else{
        this.insertSpanNoList = this.settingBaseSpan.x_no_list;
      }
      },
      /* 計算 */
      $_calc() {
        if (!this.$_validation()) {
          return
        }
        let payload = {
          building_edit: {
            calc_type: 0,
            change_base_span: {
              calc_type: 1,
              change_type: this.$_formatChangeType(),
              span_type: this.$_formatSpanType()
            },
            floor_list: []
          }
        }
        if (this.isAdjustSpanDisabled) {
          payload.building_edit.change_base_span.change_span_type = this.$_formatChangeSpanType()
          payload.building_edit.change_base_span.change_span_length = this.$_changeSpanLength()
        }
        if ((this.isAdjustTargetSpanLengthDisabled) || !this.isAdjustSpanDisabled)
          payload.building_edit.change_base_span.change_span_no = this.$_changeSpanNo()
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('calcBaseSpan', payload).then((data) => {
          this.$emit('set-viewer-to-inputsection', data.plan_view_url)
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          this.$refs.modalBlockMessage.close()
          this.$_setData()
        })
          .catch(() => {
            this.$refs.modalBlockMessage.close()
          })
      },
      /* Cancel */
      $_cancel() {
        this.myModal.hide()
        this.$emit('set-viewer-to-inputsection', this.url)
      },
      /* クローズ時の処理 */
      $_onClickCloseButton() {
        drgMouseUp()
        this.$emit('set-viewer-to-inputsection', this.url)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
/* 敷地情報 */
class E_site_planning {
  constructor() {
    this.center_latitude = 0
    this.center_longitude = 0
    this.zoom_level = 0
    this.site_line_list = []
    this.site_area = 0
    this.plan_coverage = 0
    this.plan_capacity = 0
    //this.image
    this.site_center = []
    this.picture_size_height = 0
    this.picture_size_width = 0
    this.entry_point = 0
    this.usage = null
    this.gis_usage = null
    this.height = null
    this.s_cond = null
    this.l_cond = null
    this.deregulation = null
    this.usage_range_list = []
  }
}
/* 用途地域 */
class E_usage {
  constructor() {
    this.count = 0
    this.usage_area_list = [] //todo 
  }
}
/* 高度地区 */
class E_height {
  constructor() {
    this.count = 0
    this.height_area_list = [] //todo 
  }
}
/* 日影規制 */
class E_s_cond {
  constructor() {
    this.count = 0
    this.latitude = 0
    this.longitude = 0
    this.s_cond_area_list = [] //todo
  }
}
/* 敷地境界線 */
class E_l_cond {
  constructor() {
    this.count = 0
    this.l_cond_data_list = [] //todo
  }
}
/* 規制緩和 */
class E_deregulation {
  constructor() {
    this.coverage_corner_relax = false
    this.coverage_corner_relax_text = ''
    this.coverage_fire_proof_building = false
    this.coverage_fire_proof_building_text = ''
    this.coverage_limit = false
    this.coverage_limit_rate = 0
  }
}

/* 敷地情報 初期化 */
let initSitePlanning = function() {
  let site_planning = new E_site_planning()
  site_planning.usage = new E_usage()
  site_planning.gis_usage = new E_usage()
  site_planning.height = new E_height()
  site_planning.s_cond = new E_s_cond()
  site_planning.l_cond = new E_l_cond()
  site_planning.deregulation = new E_deregulation()
  return site_planning 
}

export {
  initSitePlanning
}

<template>
  <div id="modal-law-check-parent">
    <div id="modal-law-check" class="modal" tabindex="-1"  data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">法規チェック確認</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
              <div>
                <span class="ps-1">建物条件</span>
                <table class="table table-bordered table-sm small mt-2" >
                  <thead>
                    <td class="border px-1" v-for="item in buildingRegulationFields" :key="item.key">{{ item.label }}</td>
                  </thead>
                  <tbody>
                    <tr class="border" v-for="item in buildingRegulationItems" :key="item.key" :class="{'text-danger': item.status === 2}">
                      <td class="text-center"><font-awesome-icon icon="check-circle" :class="[item.status ? 'text-info' : 'text-black-50']" /></td>
                      <td>{{ item.item }}</td>
                      <td>{{ item.value }}</td>
                      <td :class="{'text-danger': $_isChangeTextColor(item.supplement)}">{{ item.supplement }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <span class="ps-1">チェック</span>
              <table class="table table-bordered table-sm small mt-2" >
                <thead>
                  <td class="border px-1" v-for="item in checkFields" :key="item.key">{{ item.label }}</td>
                </thead>
                <tbody>
                  <tr class="border" v-for="item in checkItems" :key="item.key" :class="{'text-danger': item.result !== 'OK'}">
                    <td>{{ item.item }}</td>
                    <td>{{ item.result }}</td>
                    <td>{{ item.notes }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-auto progress" style="height: 40px;">
                <div class="progress-bar bg-warning text-black" role="progressbar" :style="{width: progressState + '%'}" style="font-size: 16px;" :aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{progressState}}%</div>
              </div>
            </div>
            <div class="mt-2 mb-1">
              敷地形状は地図情報より起こしているため、測量による変更が必要です。<br/>
              関連法規に関しての行政協議は未実施です。協議により計画変更の場合があります。
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-sm btn-primary" @click="$_ok">OK</button>
          </div>
        </div>
      </div>
    </div>
    <modal-block-message ref="modalBlockMessage" parent-id="modal-law-check-parent" />
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { initLawCheck } from '@/assets/js/dto/E_law_check'
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'

export default {
  name: 'ModalLawCheck',
  components: {
    ModalBlockMessage
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      project: this.$store.state.params,
      lawCheck: initLawCheck(),
      progressState: null,
      buildingRegulationItems: [],
      buildingRegulationFields: [
        { key: 'checked', label: '状態', thClass: 'font-weight-normal' },
        { key: 'regulation', label: '項目', thClass: 'font-weight-normal' },
        { key: 'val', label: '値', thClass: 'font-weight-normal' },
        { key: 'notes', label: '補足', thClass: 'font-weight-normal' }
      ],
      checkItems: [],
      checkFields: [
        { key: 'item', label: '項目', thClass: 'font-weight-normal' },
        { key: 'result', label: '結果', thClass: 'font-weight-normal' },
        { key: 'notes', label: '補足', thClass: 'font-weight-normal' }
      ]
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-law-check').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)

  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.$_setData()
      this.myModal = new Modal(document.getElementById('modal-law-check'), {})
      this.myModal.show()
    },
    /* データ設定 */
    $_setData() {
      this.progressState = 0
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch('execLawCheck')
        .then((data)=>{
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          this.lawCheck = data
          this.buildingRegulationItems = this.lawCheck.law_check.building_conditions_list
          const check = this.lawCheck.law_check.check
          this.checkItems = [
            { item: '斜線ボリューム', result: this.$_changeCheckResult(check.slant_line_volume.result), notes: check.slant_line_volume.message },
            { item: '絶対高さ', result: this.$_changeCheckResult(check.absolute_height.result), notes: check.absolute_height.message },
            { item: '日影規制', result: check.sun_shadow_regulations.result_message, notes: check.sun_shadow_regulations.message }
          ]
          this.progressState = 100
          this.$refs.modalBlockMessage.close()
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close()
        })
    },
    /* チェック結果変換 */
    $_changeCheckResult(result){
      if(result === true) return 'OK'
      else if(result === false) return 'NG'
      else return '未チェック'
    },
    /* 文字色変換 */
    $_isChangeTextColor(text){
      const itemText = /許容建築面積をオーバーしています|建物が敷地から出ています|許容容積対象面積をオーバーしています/
      return text.match(itemText) ? true : false
    },
    /* Cancel */
    $_ok() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .table>:not(:first-child) {
    border-top: 1;
    border-top-color: gainsboro;
  }

  my-item-center {
    align-items: center;
  }

  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
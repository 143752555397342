/* 定数 */
const PROJECT_MAX_COUNT = 100;
const CROSS_SECTION_LABEL_SUFFIX = '階倉庫（高床）'
const BERTH_TYPE_LABEL = {
  ONE_SIDE: '片面',
  BOTH_SIDE: '両面',
  L_SHAPE: 'Ｌ字',
  CENTER: 'センター',
  CENTER_ONE_SIDE: 'センター＋片面',
  CENTER_BOTH_SIDE: 'センター＋両面'
};
const CALC_TUBO_RATE = 0.3025;
const PLAN_STATUS = {
  MAP: 1,
  ROAD_AND_ENTRANCE: 2,
  WAREHOUSE: 3,
  FILE_OUTPUT: 4
};
const PAGE = {
  LOGIN: 1,
  TOP: 2,
  PROJECT: 3,
  MAP: 4,
  INPUT_ROAD_AND_ENTRANCE: 5,
  ROAD_AND_ENTRANCE: 6,
  VOLUME: 7,
  WAREHOUSE: 8,
  FILE_OUTPUT: 9
};
const DEFAULT_CROSS_SECTION = {
  HEIGHT: 6700,
  FLOOR_HEIGHT: 1000,
  UNDER_BEAMS_EFFECTIVE: 0,
  STRUCTURE: 'S造'
};
const GATE = {
  EAST_WEST: 0,
  SOUTH_NORTH: 1
};
const USAGE_NO = {
  DAI1_TEISO_JUKYO10: 0,	// 第１種低層住居専用地域 10m
  DAI1_TEISO_JUKYO12: 1,	// 第１種低層住居専用地域 12m
  DAI2_TEISO_JUKYO10: 2,	// 第２種低層住居専用地域 10m
  DAI2_TEISO_JUKYO12: 3,	// 第２種低層住居専用地域 12m
  DAI1_CHUKOSHO_JUKYO: 4,	// 第１種中高層住居専用地域
  DAI2_CHUKOSO_JUKYO: 5,	// 第２種中高層住居専用地域
  DAI1_JUKYO: 6,	// 第１種住居地域
  DAI2_JUKYO: 7,	// 第２種住居地域
  JUN_JUKYO: 8,	// 準住居地域
  KINRIN_SHOGYO: 9,	// 近隣商業地域
  SHOGYO: 10,	// 商業地域
  JUN_KOGYO: 11,	// 準工業地域
  KOGYO: 12,	// 工業地域
  KOGYO_SENYO: 13,	// 工業専用地域
  DENEN_JUKYO10: 14,	// 田園住居地域 10m
  DENEN_JUKYO12: 15,	// 田園住居地域 12m
  NONE: 16	// 指定なし
};
const RESULT_CODE = {
  OK: 1,
  NG: 0
};
const FLAG = {
  ON: 1,
  OFF: 0
}

const REGEXP = {
  EMAIL: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/,
  NUMBER: /^[0-9]+$/,
  PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/,  // 英大文字、小文字、数字のいずれかを1つ以上含む
  POSTAL_CODE_1: /^[0-9]{3}$/, // 3桁の数字
  POSTAL_CODE_2: /^[0-9]{4}$/,  // 4桁の数字
}

//const GMO_CREDIT_TOKEN_URL = 'https://stg.static.mul-pay.jp/ext/js/token.js'  // テスト環境用
const GMO_CREDIT_TOKEN_URL = 'https://static.mul-pay.jp/ext/js/token.js'  // 本番環境用

const CREDIT_CARD_LIMIT = 9999999

const UNSPECIFIED_TYPE = 99999
const UNREGULATED_TYPE = 99999

export {
  PROJECT_MAX_COUNT,
  CROSS_SECTION_LABEL_SUFFIX,
  BERTH_TYPE_LABEL,
  CALC_TUBO_RATE,
  PLAN_STATUS,
  PAGE,
  DEFAULT_CROSS_SECTION,
  GATE,
  USAGE_NO,
  RESULT_CODE,
  FLAG,
  REGEXP,
  GMO_CREDIT_TOKEN_URL,
  CREDIT_CARD_LIMIT,
  UNSPECIFIED_TYPE,
  UNREGULATED_TYPE
}
<template>
  <div id="modal-input-car-approach" class="modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modal-input-car-approach-label">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
      <div class="modal-content">
        <div class="modal-header drag-and-drop">
          <h5 class="modal-title" id="modal-input-car-approach-label">接車タイプ入力</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
        </div>
        <div class="modal-body">
          <div class="container small">
            <div class="row">
              <div class="col">
                <div class="form-check py-1">
                  <input class="form-check-input" type="checkbox" id="check-is-one-side" v-model="berthType.is_one_side">
                  <label class="form-check-label" for="check-is-one-side">{{ labelIsOneSide }}</label>
                </div>
              </div>
              <div class="col">
                <div class="form-check py-1">
                  <input class="form-check-input" type="checkbox" id="check-is-both-side" v-model="berthType.is_both_side">
                  <label class="form-check-label" for="check-is-both-side">{{ labelIsBothSide }}</label>
                </div>
              </div>
              <div class="col">
                <div class="form-check py-1">
                  <input class="form-check-input" type="checkbox" id="check-is-l-shape" v-model="berthType.is_l_shape">
                  <label class="form-check-label" for="check-is-l-shape">{{ labelIsLShape }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check py-1">
                  <input class="form-check-input" type="checkbox" id="check-is-center" v-model="berthType.is_center">
                  <label class="form-check-label" for="check-is-center">{{ labelIsCenter }}</label>
                </div>
              </div>
              <div class="col">
                <div class="form-check py-1">
                  <input class="form-check-input" type="checkbox" id="check-is-center-one-side" v-model="berthType.is_center_one_side">
                  <label class="form-check-label" for="check-is-center-one-side">{{ labelIsCenterOneSide }}</label>
                </div>
              </div>
              <div class="col">
                <div class="form-check py-1">
                  <input class="form-check-input" type="checkbox" id="check-is-center-both-side" v-model="berthType.is_center_both_side">
                  <label class="form-check-label" for="check-is-center-both-side">{{ labelIsCenterBothSide }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary" @click="$_ok">OK</button>
          <button type="button" class="btn btn-sm btn-secondary" @click="$_cancel">Cancel</button>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import cloneDeep from 'lodash/cloneDeep'
import { E_berth_type } from '@/assets/js/dto/E_berth_type'
import { BERTH_TYPE_LABEL } from '@/assets/js/const'
import { drgAddEvent, drgRemoveEvent, drgMouseUp  } from '@/assets/js/draggable'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalInputCarApproach',
  props: {
    propBerthType: {
      type: Object
    }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      berthType: new E_berth_type()
    }
  },
  computed: {
    labelIsOneSide: function() {
      return BERTH_TYPE_LABEL.ONE_SIDE
    },
    labelIsBothSide: function() {
      return BERTH_TYPE_LABEL.BOTH_SIDE
    },
    labelIsLShape: function() {
      return BERTH_TYPE_LABEL.L_SHAPE
    },
    labelIsCenter: function() {
      return BERTH_TYPE_LABEL.CENTER
    },
    labelIsCenterOneSide: function() {
      return BERTH_TYPE_LABEL.CENTER_ONE_SIDE
    },
    labelIsCenterBothSide: function() {
      return BERTH_TYPE_LABEL.CENTER_BOTH_SIDE
    }  
  },
  watch: {
    propBerthType: {
      handler(newPropBerthType){
      // console.log('new =' + newPropBerthType.is_one_side)
      this.berthType = cloneDeep(newPropBerthType)
      },
      deep: true
    },
  },
  updated() {
    this.$nextTick(function () {
      this.dragElement = document.getElementById('modal-input-car-approach').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
    })
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      // console.log(this.propBerthType)
      // this.$_setData()
      this.myModal = new Modal(document.getElementById('modal-input-car-approach'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* データ設定 */
    $_setData() {
    },
    /* [OK]クリック */
    $_ok() {
      this.$emit('set-approach-type', this.berthType)
      this.myModal.hide()
    },
    /* [Cancel]クリック */
    $_cancel() {
      this.berthType = cloneDeep(this.propBerthType)
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div class="my-container">
    <div class="my-side-contents text-black">
      <input-section></input-section>
    </div>
    <div class=my-main-contents>
      <output-section :prop-viewer-url="url"></output-section>
    </div>
  </div>
</template>

<script>
import InputSection from '@/views/roadAndEntrance/InputSection'
import OutputSection from '@/views/viewer/OutputSection'
  
export default {
  name: "RoadAndEntrance",
  components: {
    InputSection,
    OutputSection
  },
  data() {
    return {
      url: this.$store.state.viewerUrl.plan_view_url
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/style.scss";
  .my-main-contents {
    overflow: hidden;
  }
</style>
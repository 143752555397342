/* ユーザ(DB) */
class charge {
  constructor() {
    this.targetMonth = ''
    this.companyId = 0
    this.billingNumber = null
    this.paymentMethod = 0
    this.chargingPlanId = 0
    this.numberOfLicensesCharged = 0
    this.chargeExcludedTax = null
    this.chargeIncludedTax = null
    this.billingPostalCode = null
    this.billingAddress = null
    this.creditCardMembershipId = null
    this.creditErrorCode = null
    this.creditMessage = null
    this.processingStatus = null
    this.registDate = null
    this.updateDate = null
  }
}

export {
  charge
}

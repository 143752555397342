<template>
  <div class="h-100" id="excepted-area-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">敷地 > 範囲外領域の設定</p>
        <p class="d-block mb-0">物件管理ID：{{ paramsData.project_no}}</p>
        <p class="d-block mb-0">プロジェクト名称：{{ paramsData.project_name}} </p>
      </div>
      <div class="my-overflow-y p-2">
        <div class="d-flex justify-content-around align-items-center">
          <div class="col-2">
            <span>境界線</span>
          </div>
          <div class="col-6">
            <span class="">境界線からの距離(m)</span>
          </div>
        </div>
        <div
          v-for="(item, idx) in lCondList"
          :key="idx"
          v-bind:class="[idx !== 0 ? 'mt-2' : 'mt-3']"
          class="d-flex justify-content-around align-items-center"
        >
          <div class="col-2">
            <span>【{{ idx + 1 }}】</span>
          </div>
          <div class="col-6">
            <span
              ><input
                type="text"
                v-model="item.formatDistance"
                class="my-input-width form-control form-control-sm"
                @blur="$_formatDistance(idx)"
            /></span>
          </div>
        </div>
      </div>
      <div class="d-grid gap-3 mt-auto mx-2">
        <div v-if="errorFlag === 1" class="alert alert-danger d-flex align-items-center mt-3 mb-0" role="alert">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          数値で入力してください。
        </div>
        <button type="button" class="btn btn-primary" v-bind:class="[ errorFlag === 1 ? 'mt-1' : 'mt-3']" @click="$_applySetting">
          反映
        </button>
        <button type="button" class="btn btn-primary" @click="$_prevPage">
          戻る
        </button>
      </div>
      <footer-section></footer-section>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="excepted-area-input-section" />
  </div>
</template>

<script>
import HeaderSection from "@/views/common/HeaderSection"
import FooterSection from "@/views/common/FooterSection"
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { cmnCheckNumFormat, cmnChangeStrToNum, cmnDecimalFormat, cmnMultiply } from "@/assets/js/common"
import { FLAG } from "@/assets/js/const"

export default {
  name: "InputSection",
  components: {
    HeaderSection,
    FooterSection,
    ModalBlockMessage
  },
  data() {
    return {
      paramsData: this.$store.state.params.data,
      lCondList:[],
      errorFlag: FLAG.OFF
    }
  },
  mounted() {
    this.$_init()
  },
  methods: {
    /* 初期化 */
    $_init() {
      this.$_setData()
    },
    /* データ設定 */
    $_setData() {
      this.lCondList = this.paramsData.site_planning.l_cond.l_cond_data_list
      // 境界線からの距離(m)設定
      for (let i = 0, len = this.lCondList.length; i < len; i++)
        this.lCondList[i].formatDistance = cmnDecimalFormat(this.lCondList[i].excepted_area.width / 1000, 2);
    },
    /* [境界線からの距離]の成形 */
    $_formatDistance(idx) {
      const val = this.lCondList[idx].formatDistance;
      // console.log('val=' + val)
      if (!cmnCheckNumFormat(val)) return
      this.lCondList[idx].distance = cmnChangeStrToNum(val);
      this.lCondList[idx].formatDistance = cmnDecimalFormat(
        this.lCondList[idx].distance,2
      );
    },
    /* [反映]クリック */
    $_applySetting(e) {
      e.preventDefault();
      // 形式チェック
      this.errorFlag = FLAG.OFF
      for (let i = 0, len = this.lCondList.length; i < len; i++) {
        if (!cmnCheckNumFormat(this.lCondList[i].formatDistance)) {
          this.errorFlag = FLAG.ON
          break;
        }
      }
      if (this.errorFlag === FLAG.ON) return
      // 状態ストアに保存
      let params = this.$store.state.params
      let lCond = params.data.site_planning.l_cond
      for (let i = 0, len = this.lCondList.length; i < len; i++)
        this.lCondList[i].excepted_area.width = cmnMultiply(this.lCondList[i].formatDistance, 1000)
      params.data.site_planning.l_cond.l_cond_data_list = this.lCondList
      this.$store.commit('setParams', params)
      let payload = {
        l_cond: lCond
      }
      // 範囲外領域設定サービス
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("setExceptedArea", payload)
        .then(() => {
          this.$_updateCommon()
          this.$refs.modalBlockMessage.close()
          this.$router.push({ path: "road-and-entrance" });
        })
        .catch((e) => {
          console.log(e);
          this.$refs.modalBlockMessage.close()
        });
    },
    /* [戻る]クリック */
    $_prevPage() {
      this.$_updateCommon(1)
      this.$router.push({ path: "road-and-entrance" });
    },
    /* 共通処理 */
    $_updateCommon(num) {
      switch(num) {
        case 1:  // 利用時間登録
          this.$store.dispatch('updateUsersUsageTimes')
          break;
        default: // 利用時間登録, プランデータ更新日更新
          this.$store.dispatch('updateUsersUsageTimes')
          this.$store.dispatch('updateProjectUpdateDate')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-input-width {
  width: 140px;
}
.my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

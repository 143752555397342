/* バース種類 */
class E_berth_type {
  constructor() {
    this.is_one_side = false
    this.is_both_side = false
    this.is_l_shape = false
    this.is_center = false
    this.is_center_one_side = false
    this.is_center_both_side = false
  }
}

export {
  E_berth_type
}

/* ユーザ(DB) */
class company {
  constructor() {
    this.companyId = 0
    this.companyName = ''
    this.departmentName = ''
    this.specialNote = null
    this.numberOfLicenses = 0
    this.corporationType = 0
    this.approvedFlag = 0
    this.deletedFlag = 0
    this.registDate = null
    this.updateDate = null
  }
}

export {
  company
}

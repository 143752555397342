<template>
  <div class="my-container">
    <div class="my-side-contents text-black">
      <input-section ref="input" 
        @change-youto-visible="changeYoutoVisible"
        @click-draw-start="clickDrawStart"
        @click-draw-end="clickDrawEnd"
        @click-delete-draw-area="clickDeleteDrawArea"
        @build-around-map="buildAroundMap">
      </input-section>
    </div>
    <div class=my-main-contents>
      <output-section ref="output" 
        @set-parcel-no-list="setParcelNoList"
        @set-errors="setErrors">
      </output-section>
    </div>
  </div>
</template>

<script>
import InputSection from '@/views/map/InputSection'
import OutputSection from '@/views/map/OutputSection'

export default {
  name: "Map",
  components: {
    InputSection,
    OutputSection
  },
  data() {
    return {
      parcelNoList: []
    }
  },
  methods: {
    /* 用途地域表示切替 */
    changeYoutoVisible(isVisible) {
      this.$store.commit("setYoutoFlag", isVisible); // storeに保存
      this.$refs.output.changeYoutoVisible()
    },
    /*  */
    buildAroundMap(){
      this.$refs.output.buildAroundMap()
    },
    /* 手動入力開始 */
    clickDrawStart() {
      this.$refs.output.clickDrawStart()
    },
    /* 手動入力終了 */
    clickDrawEnd() {
      this.$refs.output.clickDrawEnd()
    },
    /* クリア */
    clickDeleteDrawArea() {
      this.$refs.output.clickDeleteDrawArea()
    },
    /* 地番情報のセット */
    setParcelNoList(parcelNoList) {
      this.$refs.input.setParcelNoList(parcelNoList)
    },
    /* エラーのセット */
    setErrors(errors) {
      this.$refs.input.setErrors(errors)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/style.scss";
  @import "../assets/style/ol.css";
</style>

/* 成果物出力 */
class E_file_output {
  constructor() {
    this.plan_figure_output = null
    this.area_table_output = null
    this.rook_plan_output = null
  }
}
/* プラン図出力 */
class E_plan_figure_output {
  constructor() {
    this.output_file_path = ''
  }
}
/* 面積表出力 */
class E_area_table_output {
  constructor() {
    this.area_table_output_file_path = ''
    this.notes_output_file_path = ''
    this.site_line_list = []
    this.center_points = []
  }
}
/* 物件出力 */
class E_rook_plan_output {
  constructor() {
    this.output_file_path = ''
  }
}
/* 成果物作成 初期化 */
let initFileOutput = function() {
  let fileOutput = new E_file_output()
  fileOutput.plan_figure_output = new E_plan_figure_output()
  fileOutput.area_table_output = new E_area_table_output()
  fileOutput.rook_plan_output = new E_rook_plan_output()
  return fileOutput
}

export {
  initFileOutput
}

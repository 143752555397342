<template>
  <div class="d-flex flex-column h-100" id="file-output-output-section">
    <div class="d-flex align-items-center" style="padding-right:90px;">
      <header-section></header-section>
      <div class="m-2">
        <h3 class="d-inline-flex border rounded bg-primary text-white px-4 py-2">プロジェクト名称：{{paramsData.project_name}}</h3>
      </div>
    </div>
    <div class="overflow-auto">
      <div class="container-fluid container-xxl px-4 px-lg-5 px-xxl-0">
        <div class="row mt-5">
          <div class="col-12 col-xxl-2">
            <h5>面積表</h5>
            <p class="mt-4 mb-0">容積率：{{ $_setDecimalFormat(paramsBuildingSummary.capacity_rate) }}%/{{ $_setDecimalFormat(paramsBuildingSummary.plan_capacity) }}%</p>
            <p class="mb-0">建蔽率：{{ $_setDecimalFormat(paramsBuildingSummary.building_coverage_rate) }}%/{{ $_setDecimalFormat(paramsBuildingSummary.plan_coverage) }}%</p>
          </div>
          <div class="col mt-xxl-0 mt-4">
            <ul class="nav nav-tabs">
              <li class="nav-item nav-link active">面積表</li>
            </ul>
            <div class="tab-content mt-2 table-responsive-md">
              <div id="tab1" class="tab-pane show active">
                <table class="table table-sm table-bordered align-middle" :class="{'my-table-style':isSmall}">
                  <thead>
                    <tr>
                      <td rowspan="5" class="text-center text-nowrap" style="min-width: 40px;">階</td>
                      <td rowspan="5" class="text-center text-nowrap" style="min-width: 40px;">階高</td>
                      <td colspan="13">施工床面積</td>
                    </tr>
                    <tr>
                      <td colspan="11" class="table-danger">法定延床面積</td>
                      <td rowspan="4" class="text-center" style="white-space: pre-wrap; min-width: 65px;">{{ $_setConstructionFloorArea1Name(paramsAreaTable.header_name.construction_floor_area1_name) }}</td>
                      <td rowspan="4" class="my-border-color text-center">合計</td>
                    </tr>
                    <tr>
                      <td colspan="8" class="table-success">容積対象面積</td>
                      <td rowspan="3" class="text-center" style="white-space: pre-wrap; min-width: 65px;">{{ $_setLawFloorArea1Name(paramsAreaTable.header_name.law_total_floor_area1_name) }}</td>
                      <td rowspan="3" class="text-center">{{ paramsAreaTable.header_name.law_total_floor_area2_name }}</td>
                      <td rowspan="3" class="my-border-color table-danger text-center">合計</td>
                    </tr>
                    <tr>
                      <td colspan="4" class="table-warning">貸床面積</td>
                      <td rowspan="2" class="text-center">{{ paramsAreaTable.header_name.capacity_target_area1_name }}</td>
                      <td rowspan="2" class="text-center">{{ paramsAreaTable.header_name.capacity_target_area2_name }}</td>
                      <td rowspan="2" class="text-center">{{ paramsAreaTable.header_name.capacity_target_area3_name }}</td>
                      <td rowspan="2" class="my-border-color table-success text-center">合計</td>
                    </tr>
                    <tr>
                      <td class="text-center">{{ paramsAreaTable.header_name.rental_floor_area1_name }}</td>
                      <td class="text-center">{{ paramsAreaTable.header_name.rental_floor_area2_name }}</td>
                      <td class="text-center">{{ paramsAreaTable.header_name.rental_floor_area3_name }}</td>
                      <td class="my-border-color table-warning text-center">小計</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in areaTableList" v-bind:key="item.index" class="text-end">
                      <td class="text-center">{{ item.floor }}階</td>
                      <td class="text-center">{{ item.height }}</td>
                      <td>{{ $_setDecimalFormat(item.rental_floor_area1) }}</td>
                      <td>{{ $_setDecimalFormat(item.rental_floor_area2) }}</td>
                      <td>{{ $_setDecimalFormat(item.rental_floor_area3) }}</td>
                      <td>{{ $_setDecimalFormat(item.rental_floor_area_total) }}</td>
                      <td>{{ $_setDecimalFormat(item.capacity_target_area1) }}</td>
                      <td>{{ $_setDecimalFormat(item.capacity_target_area2) }}</td>
                      <td>{{ $_setDecimalFormat(item.capacity_target_area3) }}</td>
                      <td>{{ $_setDecimalFormat(item.capacity_target_area_total) }}</td>
                      <td>{{ $_setDecimalFormat(item.law_total_floor_area1) }}</td>
                      <td>{{ $_setDecimalFormat(item.law_total_floor_area2) }}</td>
                      <td>{{ $_setDecimalFormat(item.law_total_floor_area_total) }}</td>
                      <td>{{ $_setDecimalFormat(item.construction_floor_area1) }}</td>
                      <td>{{ $_setDecimalFormat(item.construction_floor_area_total) }}</td>
                    </tr>
                    <tr class="text-end">
                      <td colspan="2" class="text-center">㎡</td>
                      <td>{{ $_setDecimalFormat(sumRentalFloorArea1) }}</td> <!-- 面積表.フッタ.貸床面積1（㎡）-->
                      <td>{{ $_setDecimalFormat(sumRentalFloorArea2) }}</td> <!-- 面積表.フッタ.貸床面積2（㎡）-->
                      <td>{{ $_setDecimalFormat(sumRentalFloorArea3) }}</td> <!-- 面積表.フッタ.貸床面積3（㎡）-->
                      <td>{{ $_setDecimalFormat(sumRentalFloorAreaTotal) }}</td> <!-- 面積表.フッタ.貸床面積小計（㎡）-->
                      <td>{{ $_setDecimalFormat(sumCapacityTargetArea1) }}</td> <!-- 面積表.フッタ.容積対象面積1（㎡）-->
                      <td>{{ $_setDecimalFormat(sumCapacityTargetArea2) }}</td> <!-- 面積表.フッタ.容積対象面積2（㎡）-->
                      <td>{{ $_setDecimalFormat(sumCapacityTargetArea3) }}</td> <!-- 面積表.フッタ.容積対象面積3（㎡）-->
                      <td>{{ $_setDecimalFormat(sumCapacityTargetAreaTotal) }}</td> <!-- 面積表.フッタ.容積対象面積合計（㎡）-->
                      <td>{{ $_setDecimalFormat(sumLawTotalFloorArea1) }}</td> <!-- 面積表.フッタ.法定延床面積1（㎡）-->
                      <td>{{ $_setDecimalFormat(sumLawTotalFloorArea2) }}</td> <!-- 面積表.フッタ.法定延床面積2（㎡）-->
                      <td>{{ $_setDecimalFormat(sumLawTotalFloorAreaTotal) }}</td> <!-- 面積表.フッタ.法定延床面積合計（㎡）-->
                      <td>{{ $_setDecimalFormat(sumConstructionFloorArea1) }}</td> <!-- 面積表.フッタ.施工床面積1（㎡）-->
                      <td>{{ $_setDecimalFormat(sumConstructionFloorAreaTotal) }}</td> <!-- 面積表.フッタ.施工床面積合計（㎡）-->
                    </tr>
                    <tr class="text-end">
                      <td colspan="2" class="text-center">坪</td>
                      <td>{{ $_calcTsubo(sumRentalFloorArea1) }}</td> <!-- 面積表.データ.貸床面積1（坪）-->
                      <td>{{ $_calcTsubo(sumRentalFloorArea2) }}</td> <!-- 面積表.データ.貸床面積2（坪）-->
                      <td>{{ $_calcTsubo(sumRentalFloorArea3) }}</td> <!-- 面積表.フッタ.貸床面積3（坪）-->
                      <td>{{ $_calcTsubo(sumRentalFloorAreaTotal) }}</td> <!-- 面積表.フッタ.貸床面積小計（坪）-->
                      <td>{{ $_calcTsubo(sumCapacityTargetArea1) }}</td> <!-- 面積表.フッタ.容積対象面積1（坪）-->
                      <td>{{ $_calcTsubo(sumCapacityTargetArea2) }}</td> <!-- 面積表.フッタ.容積対象面積2（坪）-->
                      <td>{{ $_calcTsubo(sumCapacityTargetArea3) }}</td> <!-- 面積表.フッタ.容積対象面積3（坪）-->
                      <td>{{ $_calcTsubo(sumCapacityTargetAreaTotal) }}</td> <!-- 面積表.フッタ.容積対象面積合計（坪）-->
                      <td>{{ $_calcTsubo(sumLawTotalFloorArea1) }}</td> <!-- 面積表.フッタ.法定延床面積1（坪）-->
                      <td>{{ $_calcTsubo(sumLawTotalFloorArea2) }}</td> <!-- 面積表.フッタ.法定延床面積2（坪）-->
                      <td>{{ $_calcTsubo(sumLawTotalFloorAreaTotal) }}</td> <!-- 面積表.フッタ.法定延床面積合計（坪）-->
                      <td>{{ $_calcTsubo(sumConstructionFloorArea1) }}</td> <!-- 面積表.フッタ.施工床面積1（坪）-->
                      <td>{{ $_calcTsubo(sumConstructionFloorAreaTotal) }}</td> <!-- 面積表.フッタ.施工床面積合計（坪）-->
                    </tr>
                  </tbody>
                </table>
              </div>                    
            </div>
          </div>
        </div>
        <div class="row row-cols-3 mt-5 pt-5">
          <div class="col-12 col-xxl-2"><h5>計画地地図</h5></div>
          <div class="col-10 col-xxl-8 mt-xxl-0 mt-5">
            <div id="frame-img" class="overflow-hidden">
              <div class="position-relative w-100" style="padding-top:71.5015%;">
                <img id="img1" :src="siteMapSrc" class="position-absolute w-100 h-100" style="top:0; left:0;"/>
              </div>
            </div>
          </div>
          <div class="col-2 mt-xxl-0 mt-5 text-center">
            <button type="button" class="btn btn-primary" @click="$refs.ModalSiteplan.init()">計画地地図</button>
          </div>
        </div>
        <div class="row mt-5 pt-5">
          <div class="col-12 col-xxl-2"><h5>プラン図</h5></div>
          <div class="col mt-xxl-0 mt-5">
            <div class="position-relative w-100" style="padding-top: 70.7142%">
              <div class="position-absolute pb-4" style="top:0; left:0;">
                <div class="border border-secondary">
                  <canvas class="w-100 h-100" v-for="page in pdfPages" :key="page" :id="'pdfCanvas' + page"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- END container -->
    </div>

    <div class="m-3 w-50">
      <button type="button" class="btn btn-primary me-4 w-25" @click="$_prevPage" style="min-width: 120px;">戻る</button>
      <button type="button" class="btn btn-primary w-25" @click="$_startDLfiles" style="min-width: 120px;">成果物出力</button>
    </div>
    <footer-section></footer-section>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="file-output-output-section" :display-progress="displayProgress" :prop-percent="percent"/>
    <modal-siteplan ref="ModalSiteplan" v-on:set-Image="setImage" />
    <setting-btn icon-color="text-secondary" :show-top-link="true"></setting-btn>
  </div>  
</template>

<script>
import ModalSiteplan from '@/views/fileOutput/ModalSiteplan'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import SettingBtn from '@/views/common/SettingBtn'
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'
import { initAreaTable, initBuildingSummary } from '../../assets/js/dto/E_building_summary'
import { initFileOutput } from '../../assets/js/dto/E_file_output'
import { CALC_TUBO_RATE, PLAN_STATUS } from '@/assets/js/const'
import { cmnDecimalFormat, cmnMultiply } from "@/assets/js/common"
import { fncDownloadFile } from '@/assets/js/function'

/* PDF表示 */
const pdfjsLib = require("pdfjs-dist/legacy/build/pdf")
pdfjsLib.GlobalWorkerOptions.workerSrc =
"https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.js"

export default {
  name: "OutputSection",
  components:{
    ModalSiteplan,
    ModalBlockMessage,
    SettingBtn,
    HeaderSection,
    FooterSection
  },
  data() {
    return {
      paramsData: this.$store.state.params.data,
      paramsBuildingSummary: initBuildingSummary(),
      paramsAreaTable: initAreaTable(),
      sumRentalFloorArea1: null,
      sumRentalFloorArea2: null,
      sumRentalFloorArea3: null,
      sumRentalFloorAreaTotal: null,
      sumCapacityTargetArea1: null,
      sumCapacityTargetArea2: null,
      sumCapacityTargetArea3: null,
      sumCapacityTargetAreaTotal: null,
      sumLawTotalFloorArea1: null,
      sumLawTotalFloorArea2: null,
      sumLawTotalFloorAreaTotal: null,
      sumConstructionFloorArea1: null,
      sumConstructionFloorAreaTotal: null,
      isSmall: false,
      siteMapSrc: null,
      zoomLevel: 15,  // 計画地地図作成用のzoomLevel
      pdfPages: [],  /* PDF表示 */
      pdfWidth: "",  /* PDF表示 */
      pdfSrc: "",    /* PDF表示 */
      pdfScale: 1.0, /* PDF表示 */
      displayProgress: true,
      percent: 0
    }
  },
  mounted() {
    window.addEventListener('resize', this.$_checkWindowSize)
    this.$_init()
  },
  computed: {
    areaTableList: function() {
       return this.paramsAreaTable.floor_list
    }
  },
  methods: {
    /* 初期化 */
    $_init() {
      this.$_setData()
    },
    /* データセット */
    async $_setData() {
      let params = this.$store.state.params
      if (params.file_output === null) {
        params.file_output = initFileOutput()
      }
      this.displayProgress = true
      this.percent = 0
      this.$refs.modalBlockMessage.init()
      // 建物概要取得サービス
      await this.$store.dispatch("getBuildingSummary")
      .catch(() => {
        this.$refs.modalBlockMessage.close()
      })
      this.percent = 33
      // プラン図出力サービス
      await this.$store.dispatch("outputPlanFigure")
      .catch(() => {
        this.$refs.modalBlockMessage.close()
      })
      this.percent = 66
      // 計画地敷地取得サービス
      await this.$store.dispatch("getSitePlan")
      .catch(() => {
        this.$refs.modalBlockMessage.close()
      })
      this.percent = 100
      this.$refs.modalBlockMessage.close()
      this.displayProgress = false
      // 計画地画像を作成
      await this.$_buildSitePlanImage()
      // 共通処理-プランステータス更新
      this.$_updateCommon(2)
      // 画面を初期表示
      this.paramsBuildingSummary = this.$store.state.params.building_check.building_summary
      this.paramsAreaTable = this.$store.state.params.building_check.building_summary.area_table
      this.$_sumAreaTable()
      this.$_checkWindowSize()
      this.$_getPdf()
    },
    /* 面積表.ヘッダ.法定延床面積1名 */
    $_setLawFloorArea1Name(name) {
      let n1 = name.slice(0, 2)
      let n2 = name.slice(2, 8)
      let n3 = name.slice(8, 11)
      return n1 + '\n' + n2 + '\n' + n3
    },
    /* 面積表.ヘッダ.施工床面積1名 */
    $_setConstructionFloorArea1Name(name) {
      let n1 = name.slice(0, 4)
      let n2 = name.slice(4, 8)
      let n3 = name.slice(8, 13)
      return n1 + '\n' + n2 + '\n' + n3
    },
    /* 数値フォーマット */
    $_setDecimalFormat(num) {
      return cmnDecimalFormat(num, 2)
    },
    /* 面積表（㎡）→（坪）変換 */
    $_calcTsubo(num) {
      return cmnDecimalFormat(cmnMultiply(num, CALC_TUBO_RATE), 2)
    },
    /* 面積表.フッタ.貸床面積1,2,3,合計（㎡）
    面積表.フッタ.容積対象面積1,2,3,合計（㎡）
    面積表.フッタ.法定延床面積1,2,3,合計（㎡）
    面積表.フッタ.施工床面積1,合計（㎡）*/
    $_sumAreaTable() {
      for (let i = 0, len = this.areaTableList.length; i < len; i++) {
        this.sumRentalFloorArea1 += this.areaTableList[i].rental_floor_area1
        this.sumRentalFloorArea2 += this.areaTableList[i].rental_floor_area2
        this.sumRentalFloorArea3 += this.areaTableList[i].rental_floor_area3
        this.sumRentalFloorAreaTotal += this.areaTableList[i].rental_floor_area_total
        this.sumCapacityTargetArea1 += this.areaTableList[i].capacity_target_area1
        this.sumCapacityTargetArea2 += this.areaTableList[i].capacity_target_area2
        this.sumCapacityTargetArea3 += this.areaTableList[i].capacity_target_area3
        this.sumCapacityTargetAreaTotal += this.areaTableList[i].capacity_target_area_total
        this.sumLawTotalFloorArea1 += this.areaTableList[i].law_total_floor_area1
        this.sumLawTotalFloorArea2 += this.areaTableList[i].law_total_floor_area2
        this.sumLawTotalFloorAreaTotal += this.areaTableList[i].law_total_floor_area_total
        this.sumConstructionFloorArea1 += this.areaTableList[i].construction_floor_area1
        this.sumConstructionFloorAreaTotal += this.areaTableList[i].construction_floor_area_total
      }
    },
    /* プラン図表示 */
    $_getPdf() {
      let pdfSrc = "/api/fileOutput/planPdf/?filePath=" + this.$store.state.params.file_output.plan_figure_output.output_file_path
      let loadingTask = pdfjsLib.getDocument(pdfSrc)
      loadingTask.promise.then(pdf => {
        this.pdfDoc = pdf
        this.pdfPages = pdf.numPages;
        this.$nextTick(() => {
          this.$_renderPage(1)
        })
      })
    },
    $_renderPage(num) {
      const that = this
      this.pdfDoc.getPage(num).then(page => {
        let canvas = document.getElementById("pdfCanvas" + num)
        let ctx = canvas.getContext("2d")
        let dpr = window.devicePixelRatio || 1
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1
        let ratio = dpr / bsr
        let viewport = page.getViewport({
          scale: this.pdfScale })
        canvas.width = viewport.width * ratio;
        canvas.height = viewport.height * ratio;
        canvas.style.width = viewport.width + "px"
        that.pdfWidth = viewport.width + "px"
        canvas.style.height = viewport.height + "px"
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport
        }
        page.render(renderContext)
        if (this.pdfPages > num) {
          this.$_renderPage(num + 1)
        }
      })
    },
    /* 計画地地図取得 */
    $_getSiteMapImage() {
      this.$store.dispatch('getSiteMapImage')
        .then((file) => {
          this.siteMapSrc = file
        })
    },
    /* 91.処理
    (a)計画地画像作成  */
    $_buildSitePlanImage() {
      const area_table_output = this.$store.state.params.file_output.area_table_output
      let payload = {
        site_line_list: area_table_output.site_line_list,
        center_points: area_table_output.center_points,
        zoom_level: this.zoomLevel
      }
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("buildSitePlanImage", payload)
        .then(() => {
          this.$refs.modalBlockMessage.close()
          this.$_getSiteMapImage()
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close()
        })
    },
    /* [戻る]クリック */
    $_prevPage() {
      // 状態ストアの表示モード更新
      let payload = this.$store.state.settingButtons
      payload.warehouse = false
      this.$store.commit('setSettingButtons', payload)
      // 自動設計結果表示画面へ遷移
      this.$router.push({ path: 'warehouse' })
    },
    /* [成果物出力]クリック */
    $_startDLfiles() {
      let path = this.$store.state.params.file_output.plan_figure_output.output_file_path
      let payload = {
        filePath: path
      }
      // プラン図をダウンロードする
      this.$store.dispatch("rookDownload", payload)
        .then((res) => {
          fncDownloadFile(res)
        })
        .catch((e) => {
          console.log(e);
          this.$refs.modalBlockMessage.close()
        });
      // 面積表出力サービス
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("outputAreaTable")
        .then((resultData) => {
          this.$_startDLareaTable(resultData) 
          // 共通処理 - 利用時間更新
          this.$_updateCommon(1)
          this.$refs.modalBlockMessage.close()
        })
        .catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
        })
    },
    /* 面積表をダウンロード */
    $_startDLareaTable(resultData) {
      let areaTableFilePath = resultData.area_table_output
      let payload = {
        filePath: areaTableFilePath.area_table_output_file_path
      }
      this.$store.dispatch("rookDownload", payload)
        .then((res) => {
          fncDownloadFile(res)
          })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 計画地地図の再作成＆表示 */
    setImage(level) {
      this.zoomLevel = level
      this.$_buildSitePlanImage()
      // 共通処理-利用時間登録, プランデータ更新日更新
          this.$_updateCommon()
    },
    /* 共通処理 */
    $_updateCommon(num) {
      switch(num) {
        case 1:  // 利用時間登録
          this.$store.dispatch('updateUsersUsageTimes')
          break;
        case 2:  // プランステータス更新
          this.$store.dispatch('updateProjectStatus', { planStatus: PLAN_STATUS.FILE_OUTPUT })
          break;
        default: // 利用時間登録, プランデータ更新日更新
          this.$store.dispatch('updateUsersUsageTimes')
          this.$store.dispatch('updateProjectUpdateDate')
      }
    },
    /* 面積表のサイズ調整 */
    $_checkWindowSize() {
      const wsize = window.matchMedia("(max-width: 1199px)");
      if (wsize.matches) {
        this.isSmall = true
      } else {
        this.isSmall = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.my-border-color {
  border-top: 1px solid var(--bs-table-bg);
}
.my-table-style{
  font-size: 0.875em;
}
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>

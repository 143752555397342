/* ユーザ(DB) */
class user {
  constructor() {
    this.userId = 0
    this.companyId = 0
    this.userName = ''
    this.userEmail = ''
    this.password = ''
    this.authority = 0
    this.license = 0
    this.deletedFlag = 0
    this.registDate = null
    this.updateDate = null
  }
}

export {
  user
}

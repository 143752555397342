/* 倉庫編集 */
class E_building_edit {
  constructor() {
    this.calc_type = 0
    this.change_base_span = null
    this.floor_list = []
  }
}
/* 基準スパン変更 */
class E_change_base_span {
  constructor() {
    this.calc_type = 0
    this.building_area = 0
    this.building_coverage_rate = 0
    this.remaining_building_area = 0
    this.capacity_target_area = 0
    this.capacity_rate = 0
    this.remaining_capacity_target_area = 0
    this.warehouse_perimeter_area = 0
    this.span_width = 0
    this.span_width_length = 0
    this.span_depth = 0
    this.span_depth_length = 0
    this.change_type = 0
    this.span_type = 0
    this.change_span_type = 0
    this.change_span_length = 0
    this.change_span_no = 0
  }
}

let initBuildingEdit = function() {
  let buildingEdit = new E_building_edit()
  buildingEdit.change_base_span = new E_change_base_span()
  return buildingEdit
}

export {
  initBuildingEdit
}

<template>
  <div id="modal-input-building-shape" class="modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modal-input-building-shape-label">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header drag-and-drop">
          <h5 class="modal-title" id="modal-input-building-shape-label">建物形状入力</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
        </div>
        <div class="modal-body">
          <div class="container small">
            <div class="row">
              <div class="col-3 col-lg-2 border alert-success d-flex align-items-center py-3">
                <div class="form-check px-0 mb-0 w-100 lh-lg">
                  <input class="form-check-input float-end p-2" type="checkbox" id="check-is-box" v-model="buildingShape.is_box">
                  <label class="form-check-label" for="check-is-box">BOX</label>
                </div>
              </div>
              <div class="col-9 py-3">
                <div class="row">
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input p-2 me-2 align-middle lh-1" type="checkbox" id="check-is-hisashi" v-model="buildingShape.box.is_hisashi" :disabled="isBoxDisabled">
                      <label class="form-check-label align-middle lh-1" for="check-is-hisashi">庇</label>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col col-sm-4">庇の出</div>
                  <div class="col col-sm-4 col-lg-3 d-flex align-items-center">
                    <select v-model="buildingShape.box.hisashi_length" :disabled="isHisashiDisabled" class="form-select form-select-sm" aria-label=".form-select-sm">
                      <option v-for="item in hisashiLengthsList" :key="item.length" :value="item.length">
                        {{ item.length }}
                      </option>
                    </select>
                    <span class="ms-2">ｍ</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 col-lg-2 border alert-success d-flex align-items-center py-3">
                <div class="form-check px-0 mb-0 w-100 lh-lg">
                  <input class="form-check-input float-end p-2" type="checkbox" id="check-is-slope" v-model="buildingShape.is_slope">
                  <label class="form-check-label" for="check-is-slope">スロープ</label>
                </div>
              </div>
              <div class="col-9 py-3 border border-white">
                <div class="row align-items-center">
                  <div class="col col-sm-4">
                    <div class="form-check mb-0">
                      <input class="form-check-input p-2 me-2 p-2 me-2 align-middle lh-1" type="checkbox" id="check-is-one-two" v-model="buildingShape.slope.is_one_two" :disabled="isSlopeDisabled">
                      <label class="form-check-label align-middle lh-1" for="check-is-one-two">1-2スロープ</label>
                    </div>
                  </div>
                  <div class="col col-sm-4">
                    <div class="form-check mb-0">
                      <input class="form-check-input p-2 me-2 align-middle lh-1" type="checkbox" id="check-is-one-three" v-model="buildingShape.slope.is_one_three" :disabled="isSlopeDisabled">
                      <label class="form-check-label align-middle lh-1" for="check-is-one-three">1-3スロープ</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 col-lg-2 border alert-success d-flex align-items-center py-3">
                <div class="form-check px-0 mb-0 w-100 lh-lg">
                  <input class="form-check-input float-end p-2" type="checkbox" id="check-is-ramp" v-model="buildingShape.is_ramp">
                  <label class="form-check-label" for="check-is-ramp">ランプ</label>
                </div>
              </div>
              <div class="col-9 py-3 border border-white">
                <div class="row">
                  <div class="col col-sm-4">
                    <div class="form-check mb-0">
                      <input class="form-check-input p-2 me-2 align-middle lh-1" type="checkbox" id="check-is-single" v-model="buildingShape.ramp.is_single" :disabled="isRampDisabled">
                      <label class="form-check-label align-middle lh-1" for="check-is-single">シングル</label>
                    </div>
                  </div>
                  <div class="col col-sm-4">
                    <div class="form-check mb-0">
                      <input class="form-check-input p-2 me-2 align-middle lh-1" type="checkbox" id="check-is-double" v-model="buildingShape.ramp.is_double" :disabled="isRampDisabled">
                      <label class="form-check-label align-middle lh-1" for="check-is-double">ダブル</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary" @click="$_ok">OK</button>
          <button type="button" class="btn btn-sm btn-secondary" @click="$_cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import cloneDeep from 'lodash/cloneDeep'
import { initBuildingShape } from '@/assets/js/dto/E_building_shape'
import { hisashiLengths } from '@/assets/js/code'
import { drgAddEvent, drgRemoveEvent, drgMouseUp  } from '@/assets/js/draggable'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalInputBuildingShape',
  props: {
    propBuildingShape : {
      type: Object
    }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      buildingShape: initBuildingShape()
    }
  },
  computed: {
    isBoxDisabled: function() {
      return !this.buildingShape.is_box
    },
    isHisashiDisabled: function() {
      return !(this.buildingShape.is_box && this.buildingShape.box.is_hisashi)
    },
    isSlopeDisabled: function() {
      return !this.buildingShape.is_slope
    },
    isRampDisabled: function() {
      return !this.buildingShape.is_ramp
    },
    hisashiLengthsList: function() {
        return hisashiLengths
      }
  },
  watch: {
    propBuildingShape: {
      handler: function(newPropBuildingShape) {
        this.buildingShape = cloneDeep(newPropBuildingShape)
      },
      deep : true,
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-input-building-shape').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      // console.log('init buildingShape'+this.propBuildingShape)
      this.myModal = new Modal(document.getElementById('modal-input-building-shape'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* OK */
    $_ok() {
      // console.log('ok buildingShape'+this.buildingShape)
      this.$emit('set-building-shape', this.buildingShape)
      this.buildingShape = cloneDeep(this.propBuildingShape)
      this.myModal.hide()
    },
    /* Cancel */
    $_cancel() {
      this.buildingShape = cloneDeep(this.propBuildingShape)
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div class="h-100 overflow-hidden" id="road-and-entrance-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">接道及び出入口設定の確認</p>
        <p class="d-block mb-0">物件管理ID：{{ this.projectName }}</p>
        <p class="d-block mb-0">プロジェクト名称： {{ this.projectNo }}</p>
        <p class="d-block mb-0">敷地面積： {{ this.project.site_planning.site_area }} ㎡</p>
      </div>
      <div class="my-overflow-y p-2" v-if="!settingButtons.roadAndEntrance">
        <div class="container">
          <div class="row row-cols-2">
            <div class="col-6 mb-2">
              <span>接道</span>
            </div>
            <div class="col text-center mb-2">
              <span>進入箇所</span>
            </div>
            <div class="col-6 d-flex align-items-center lh-base">
              <label class="text-nowrap">東側：</label>
              <span class="ms-2">{{ roadWEast }}</span>
              <span class="ms-2">m</span>
            </div>
            <div class="px-3 d-flex align-items-center justify-content-center lh-base">
              <span v-if="entryPoint === 0">●</span>
            </div>
            <div class="col-6 d-flex align-items-center lh-base">
              <label class="text-nowrap">南側：</label>
              <span class="ms-2">{{ roadWSouth }}</span>
              <span class="ms-2">m</span>
            </div>
            <div class="px-3 d-flex align-items-center justify-content-center lh-base">
              <span v-if="entryPoint === 1">●</span>
            </div>
            <div class="col-6 d-flex align-items-center lh-base">
              <label class="text-nowrap">西側：</label>
              <span class="ms-2">{{ roadWWest }}</span>
              <span class="ms-2">m</span>
            </div>
            <div class="px-3 d-flex align-items-center justify-content-center lh-base">
              <span v-if="entryPoint === 2">●</span>
            </div>
            <div class="col-6 d-flex align-items-center lh-base">
              <label class="text-nowrap">北側：</label>
              <span class="ms-2">{{ roadWNorth }}</span>
              <span class="ms-2">m</span>
            </div>
            <div class="px-3 d-flex align-items-center justify-content-center lh-base">
              <span v-if="entryPoint === 3">●</span>
            </div>
          </div>
          <div>
            <div class="row mt-3">
              <span>用途地域一覧</span>
            </div>
            <hr class="my-1">
            <div v-for="(item, index) in usageList.slice(0,4)" :key="item.no" class="small">
              <div class="row ps-2">
                <div class="col-4">
                  <span>No: {{ index + 1 }}</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-4">
                  <span>用途地域</span>
                </div>
                <div class="col">
                  <span>{{ item.usage_name }}/{{ item.coverage }}%/{{ item.capacity }}% </span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-4">
                  <span>高度地区</span>
                </div>
                <div class="col">
                  <span :class="{'text-danger': item.height_no === unspecifiedType}">{{
                    item.height_name
                    }}</span>
                </div>
              </div>
              <div class="row ps-2">
                <div class="col-4">
                  <span>日影規制</span>
                </div>
                <div class="col">
                  <span :class="{'text-danger': item.c_high_type === unregulatedType}">{{
                    item.c_high_type_name
                    }}</span>
                </div>
              </div>
              <hr class="my-1">
            </div>
            <div class="mt-3 small">集団規定は個別設定してください</div>
          </div>
        </div>
      </div>
      <div class="d-grid gap-3 mt-auto mx-2" v-if="!settingButtons.roadAndEntrance">
        <button type="button" class="btn btn-primary mt-3" @click="$_nextBtn">次へ</button>
        <button type="button" class="btn btn-primary" @click="$_showMenu">変更</button>
        <button type="button" class="btn btn-primary" @click="returnPrevPageConfirmResult">戻る</button>
      </div>
      <div class="d-grid gap-3 p-2 my-overflow-y" v-if="settingButtons.roadAndEntrance">
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-primary w-100" @click="$_borderSettingsPage">敷地辺情報設定</button>
          <span class="fs-5 text-info text-nowrap ps-2" data-bs-toggle="tooltip" data-bs-placement="right"
            title="隣地境界線、道路境界線、隅切辺、及び道路幅員を設定できます。">
            <font-awesome-icon icon="question-circle"/>
          </span>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-primary w-100" @click="$_regulationSettingsPage">集団規定一括設定</button>
          <span class="fs-5 text-info text-nowrap ps-2" data-bs-toggle="tooltip" data-bs-placement="right"
            title="	用途地域、高度地区、日影規制等の集団規定与条件を設定できます。">
            <font-awesome-icon icon="question-circle"/>
          </span>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-primary w-100" @click="$refs.modalDeregulationSettings.init()">規制緩和設定</button>
          <span class="fs-5 text-info text-nowrap ps-2" data-bs-toggle="tooltip" data-bs-placement="right"
            title="角敷地等緩和、耐火建築物緩和、建蔽率の制限を設定できます。">
            <font-awesome-icon icon="question-circle"/>
          </span>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-primary w-100" @click="$_exceptedAreaPage">範囲外領域の設定</button>
          <span class="fs-5 text-info text-nowrap ps-2" data-bs-toggle="tooltip" data-bs-placement="right"
            title="建物の設計範囲外の領域を設定できます。">
            <font-awesome-icon icon="question-circle"/>
          </span>
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-primary w-100" @click="$_volumePage">トラック出入口&thinsp;・&thinsp;バース向き設定</button>
          <span class="fs-5 text-info text-nowrap ps-2" data-bs-toggle="tooltip" data-bs-placement="right"
            title="敷地へのトラック出入口、トラックバースの位置を設定できます。">
            <font-awesome-icon icon="question-circle"/>
          </span>
        </div>
      </div>
      <div class="d-grid gap-3 mx-2 mt-auto" v-if="settingButtons.roadAndEntrance">
        <button type="button" class="btn btn-primary mt-3" @click="$_showPrev">戻る</button>
      </div>
      <footer-section></footer-section>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="road-and-entrance-input-section" />
    <modal-check-before-planning ref="modalCheckBeforePlanning" v-on:next="nextPage" />
    <modal-deregulation-settings ref="modalDeregulationSettings" />
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages"
      v-on:return-confirm-result="returnConfirmResult" />
  </div>
</template>

<script>
  import { Tooltip } from 'bootstrap'
  import ModalDeregulationSettings from '@/views/deregulation/ModalDeregulationSettings'
  import ModalCheckBeforePlanning from '@/views/volume/ModalCheckBeforePlanning'
  import ModalConfirm from '@/views/common/ModalConfirm'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import HeaderSection from '@/views/common/HeaderSection'
  import FooterSection from '@/views/common/FooterSection'
  import { directions, cdChangeArrayToHash } from '@/assets/js/code'
  import { cmnDivide,cmnDecimalFormat } from '@/assets/js/common'
  import { PAGE, PLAN_STATUS, UNSPECIFIED_TYPE, UNREGULATED_TYPE } from '@/assets/js/const'
  const PREF_CODE_HOKKAIDO = '01'

  export default {
    name: 'InputSection',

    components: {
      ModalCheckBeforePlanning,
      ModalDeregulationSettings,
      ModalConfirm,
      ModalBlockMessage,
      HeaderSection,
      FooterSection
    },

    data() {
      return {
        prevPage: this.$store.state.prevPage.roadAndEntrance,
        project: this.$store.state.params.data,
        settingButtons: this.$store.state.settingButtons,
        myTooltip: null,
        projectName: null,
        projectNo: null,
        entryPoint: null,
        lCondDataList: [],
        usageList: [],
        confirmMessages: []
      }
    },
    watch: {
      'settingButtons.roadAndEntrance': function(val) {
        if(val) {
          this.$nextTick(function() {
            this.myTooltip = Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
          })
        }
      }
    },
    mounted() {
      this.$_init()
    },
    computed: {
      unspecifiedType() {
        return UNSPECIFIED_TYPE
      },
      unregulatedType() {
        return UNREGULATED_TYPE
      },
      roadWEast() {
        let roadWEastList = this.lCondDataList.filter((item) => {
          return item.direction === directions[0].code
        })
        return roadWEastList.length === 0 ? 0 : cmnDecimalFormat(cmnDivide(Math.max.apply(null, roadWEastList.map(function (o) { return o.road_w; })), 1000, -2), 2)
      },
      roadWSouth() {
        let roadWSouthList = this.lCondDataList.filter((item) => {
          return item.direction === directions[1].code
        })
        return roadWSouthList.length === 0 ? 0 : cmnDecimalFormat(cmnDivide(Math.max.apply(null, roadWSouthList.map(function (o) { return o.road_w; })), 1000, -2), 2)
      },
      roadWWest() {
        let roadWWestList = this.lCondDataList.filter((item) => {
          return item.direction === directions[2].code
        })
        return roadWWestList.length === 0 ? 0 : cmnDecimalFormat(cmnDivide(Math.max.apply(null, roadWWestList.map(function (o) { return o.road_w; })), 1000, -2), 2)
      },
      roadWNorth() {
        let roadWNorthList = this.lCondDataList.filter((item) => {
          return item.direction === directions[3].code
        })
        return roadWNorthList.length === 0 ? 0 : cmnDecimalFormat(cmnDivide(Math.max.apply(null, roadWNorthList.map(function (o) { return o.road_w; })), 1000, -2), 2)
      },
    },
    methods: {
      /* 初期化 */
      $_init() {
        this.$_setData()
        this.myTooltip = Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))

      },
      /* データ設定 */
      async $_setData() {
        if (this.prevPage === PAGE.WAREHOUSE || this.prevPage === PAGE.TOP) {
          const projectNo = this.$store.state.params.data.project_no
          this.$refs.modalBlockMessage.init()
          await this.$store.dispatch('getBuildingSites', projectNo)
            .then(()=>{
              this.project = this.$store.state.params.data
              this.$refs.modalBlockMessage.close()
            })
            .catch(() => {
              this.$refs.modalBlockMessage.close()
            })
        }
        this.projectName = this.project.project_no
        this.projectNo = this.project.project_name
        this.entryPoint = this.project.site_planning.entry_point
        this.lCondDataList = this.project.site_planning.l_cond.l_cond_data_list
        let usageAreaList = this.project.site_planning.usage.usage_area_list
        let heightAreaList = this.project.site_planning.height.height_area_list
        let sCondList = this.project.site_planning.s_cond.s_cond_area_list
        let shadowCalcTypes = (this.project.address_code.substring(0, 2) === PREF_CODE_HOKKAIDO) ? this.$store.state.lists.shadow_calc_types[0] : this.$store.state.lists.shadow_calc_types[1]
        let shadowCalcTypeConvList = cdChangeArrayToHash(shadowCalcTypes, 'no', 'name')
        for (let i = 0, len = usageAreaList.length; i < len; i++) {
          this.usageList.push({
            usage_name: usageAreaList[i].usage_name,
            coverage: usageAreaList[i].coverage,
            capacity: usageAreaList[i].capacity,
            height_no: heightAreaList.length >= i ? heightAreaList[i].height_no : '',
            height_name: heightAreaList.length >= i ? heightAreaList[i].height_name : '',
            c_high_type: sCondList[i].c_high_type,
            c_high_type_name: !(sCondList.length >= i) ? '' : shadowCalcTypeConvList[sCondList[i].c_high_type] === undefined ? '未確認' : shadowCalcTypeConvList[sCondList[i].c_high_type]
          });
        }
        // console.log(this.usageList, shadowCalcTypes)
        this.$store.dispatch('updateProjectStatus', { planStatus: PLAN_STATUS.ROAD_AND_ENTRANCE })
      },
      /* 次へ */
      $_nextBtn() {
        this.$store.dispatch('updateUsersUsageTimes')
        this.$refs.modalCheckBeforePlanning.init()
      },
      /* 倉庫計画前の確認画面の計算後処理 */
      nextPage() {
        this.settingButtons.warehouse = false
        this.$store.commit('setSettingButtons', this.settingButtons)
        this.$router.push({ path: 'warehouse' })
      },
      /* 変更 */
      $_showMenu() {
        this.settingButtons.roadAndEntrance = true
        this.$store.commit('setSettingButtons', this.settingButtons)
      },
      /* 戻る */
      returnPrevPageConfirmResult() {
        this.confirmMessages = []
        this.confirmMessages.push('敷地や建物がすべて破棄されます。', 'よろしいですか？')
        this.$refs.modalConfirm.init()
      },
      /* 確認ポップアップを閉じた後の処理 */
      returnConfirmResult(resultCode) {
        if (resultCode === 1) {
          this.$store.dispatch('updateUsersUsageTimes')
          this.$store.dispatch('initParamsSitePlanning')
          this.$router.push({ path: 'map' })
        }
      },
      /* 敷地辺情報 */
      $_borderSettingsPage() {
        this.$router.push({ path: 'border-settings' })
      },
      /* 集団規定一括編集 */
      $_regulationSettingsPage() {
        this.$router.push({ path: 'regulation-settings' })
      },
      /* 範囲外領域設定 */
      $_exceptedAreaPage() {
        this.$router.push({ path: 'excepted-area' })
      },
      /* トラック出入口 */
      $_volumePage() {
        this.$router.push({ path: 'volume' })
      },
      /* メニューの戻る */
      $_showPrev() {
        this.settingButtons.roadAndEntrance = false
        this.$store.commit('setSettingButtons', this.settingButtons)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
<template>
  <div id="modal-check-before-planning-parent">
    <div id="modal-check-before-planning" class="modal" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">倉庫計画前の確認</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body" id="contents">
            <div class="row">
              <div class="col-3">倉庫種類</div>
              <div class="col">{{ buildingType }}</div>
            </div>
            <div class="row mt-1">
              <div class="col-3">想定断面</div>
              <div class="col">～ {{ $_setFloor() }} 階</div>
            </div>
            <div class="row mt-1">
              <div class="col-3">形状</div>
              <div class="col">{{ labelBuildingShape }}</div>
            </div>
            <div class="row mt-1">
              <div class="col-3">事務所比率</div>
              <div class="col">{{ paramsData.office_rate }} %～</div>
            </div>
            <div>
              <button class="btn btn-link mt-2 px-0 text-body" type="button" data-bs-toggle="collapse" data-bs-target="#details" aria-expanded="false" aria-controls="details" data-bs-parent="collapse">
                <font-awesome-icon :icon="isDetailsShown ? 'chevron-circle-up' : 'chevron-circle-down'" /> 詳細設定
              </button>
            </div>
            <div id="details" class="collapse" ref="details">
              <div>
                <div class="card-header bg-transparent border-bottom">建物計画変更</div>
                <div class="card-body p-3 small">
                  <div class="d-flex mb-2 align-items-center">
                <label class="col-6 ps-4">倉庫種類</label>
                <div class="col">
                  <select class="form-select form-select-sm ps-1 pe-3 text-truncate" v-model="paramsADBBS.building_type"
                    :class="{'alert-danger': isErrBuildingType}">
                    <option :value=null disabled>選択してください</option>
                    <option v-for="item in buildingTypeList" :key="item.code" :value="item.code">{{ item.text }}
                    </option>
                  </select>
                </div>
                  </div>
                  <div class="d-flex mb-2 align-items-center">
                    <label class="col-6 ps-4">バース種類</label>
                    <div class="col-4">{{ $_labelApproachType(paramsADBBS.car_approach_type) }}</div>
                    <button class="btn btn-sm btn-primary ms-auto" style="line-height:1rem; margin-left: 1rem;"
                    @click="$refs.modalInputCarApproach.init()">...</button>
                  </div> 
                  <div class="d-flex mb-2 align-items-center">
                    <label class="col-6 ps-4">階高設定</label>
                    <div class="col-4">
                      {{ $_setFloor() }} 階倉庫（高床）
                    </div>
                    <button class="btn btn-sm btn-primary ms-auto" style="line-height:1rem; margin-left: 1rem;" @click="$refs.modalAdjustFloor.init()">...</button>
                  </div>
                  <div class="d-flex mb-2 align-items-center">
                <label class="col-6 ps-4">形状</label>
                <div class="col">
                  <div class="row g-0">
                    <input type="text" class="form-control form-control-sm col ps-1 pe-3" v-model="labelBuildingShape" disabled
                      name="labelBuildingShape" placeholder="（計画地に合わせる）">
                    <button class="btn btn-primary col-auto px-2 ms-2" style="line-height: 1rem; margin-left: 1rem;"
                      @click="$refs.modalInputBuildingShape.init()">
                      ...
                    </button>
                  </div>
                </div>
              </div>
              </div>
                </div>
                <div>                  
                  <div class="card-header bg-transparent border-bottom">倉庫設計</div>
                  <div class="card-body p-3 small">                    
                  <div class="d-flex mb-2 align-items-center">
                    <label class="col-6 ps-4">基準スパン</label>
                    <div class="col">
                      <select class="form-select form-select-sm ps-1 pe-3 text-truncate" v-model="baseSpan">
                        <option v-for="item in baseSpanList" :key="item.name" :value='item.width + "_" + item.depth'>{{ item.name }}</option>
                      </select>
                    </div>
                  </div>               
                  <div class="d-flex mb-2 align-items-center">
                    <label class="col-6 ps-4">容積対象面積制限</label>
                    <div class="col-4"><input type="text" class="form-control form-control-sm col"
                        v-model="paramsADBBS.building_area" @blur="$_formatBuildingArea"></div>
                    <span class="ms-2">㎡</span>
                  </div>                  
                  <div class="row mb-2">
                    <div class="col">
                      <input class="form-check-input" type="checkbox" v-model="this.paramsADBBS.is_10t_truck">
                      <label class="form-check-label mx-2">10tトラックを配置する</label>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col">
                      <input class="form-check-input" type="checkbox" v-model="this.paramsADBBS.is_shape ">
                      <label class="form-check-label mx-2">倉庫を整形で設計する</label>
                    </div>
                  </div>
              </div>
              </div>
            </div>
            <div v-if="errors.length" id="calc-error" class="alert alert-danger d-flex align-items-center mb-0 mt-3" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div>
                <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-sm btn-primary" @click="$_startCalc">計算</button>
            <button class="btn btn-sm btn-secondary" @click="$_cancel">キャンセル</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-check-before-planning-parent" />
    <modal-input-car-approach ref="modalInputCarApproach" :prop-berth-type="paramsADBBS.car_approach_type" v-on:set-approach-type="setApproachType" />
    <modal-adjust-floor ref="modalAdjustFloor" @set-floor-list="setFloorList" :parent-page="parentPage" :prop-floor-list="paramsADBBS.floor_list" />
    <modal-check-auto-calc-status ref="modalCheckAutoCalcStatus" v-on:finishCalc="finishCalc"/>
    <modal-input-building-shape ref="modalInputBuildingShape" :prop-building-shape="paramsData.building_shape"
      v-on:set-building-shape="setBuildingShape" />    
  </div>
</template>
  
<script>
import { Modal } from 'bootstrap'
import ModalCheckAutoCalcStatus from '@/views/volume/ModalCheckAutoCalcStatus'
import ModalInputCarApproach from '@/views/volume/ModalInputCarApproach'
import ModalAdjustFloor from '@/views/warehouse/ModalAdjustFloor'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import ModalInputBuildingShape from '@/views/project/ModalInputBuildingShape'
import { initAutoDesign, initBuildingBaseSetting } from '@/assets/js/dto/E_auto_design'
import { buildingTypes, buildingShapes, cdChangeArrayToHash } from '@/assets/js/code'
import { cmnDecimalFormat, cmnChangeStrToNum, cmnCheckNumFormat, cmnIsBlank } from '@/assets/js/common'
import { BERTH_TYPE_LABEL, PAGE, RESULT_CODE, FLAG } from '@/assets/js/const'
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'

export default {
  name: 'ModalCheckBeforePlanning',
  components: {
    ModalCheckAutoCalcStatus,
    ModalInputCarApproach,
    ModalAdjustFloor,
    ModalBlockMessage,
    ModalInputBuildingShape,    
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      paramsData: this.$store.state.params.data,
      paramsAutoDesign: this.$store.state.params.auto_design,
      paramsADBBS: initBuildingBaseSetting(),
      baseSpan: null,
      baseSpanList: [],
      buildingShape: null,
      isDetailsShown: false,
      errors: [],
      labelBuildingShape: null
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-check-before-planning').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
    /* [詳細設計]クリック */
    this.$refs['details'].addEventListener('show.bs.collapse', this.$_changeDetailsIcon)
    this.$refs['details'].addEventListener('hide.bs.collapse', this.$_changeDetailsIcon)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  computed: {
    // 建物種類
    buildingType: function () {
      const buildingTypesHash = cdChangeArrayToHash(buildingTypes, 'code', 'text') 
      return buildingTypesHash[this.paramsADBBS.building_type]
    },
    isErrBuildingType: function () {
      return cmnIsBlank(this.paramsData.building_type)
    },       
    parentPage: function () {
      return PAGE.VOLUME
    },
    buildingTypeList: function () {
      return buildingTypes
    },    
  },
  methods: {
    /* 初期化 */
    init() {
      this.$_setData()
      this.myModal = new Modal(document.getElementById('modal-check-before-planning'), {})
      this.myModal.show()
    },
    /* データ設定 */
    $_setData() {
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("getAutoDesignList")
        .then(() => { 
          //リスト設定
          this.baseSpanList = this.$store.state.lists.base_spans
          if (this.paramsAutoDesign !== null
              && this.paramsAutoDesign !== undefined
              && this.paramsAutoDesign.building_base_setting.floor_list.length > 0) {
            // 状態ストア設定済み
            this.paramsADBBS = this.paramsAutoDesign.building_base_setting
            this.baseSpan = this.paramsADBBS.base_span.width + "_" + this.paramsADBBS.base_span.depth  // 倉庫設計.基準スパン
          } else {
            // 上記以外
            this.paramsADBBS.building_type = this.paramsData.building_type    // 倉庫設計.倉庫種類
            this.paramsADBBS.building_shape = this.paramsData.building_shape
            this.paramsAutoDesign = initAutoDesign()
            this.baseSpan = 11 + "_" + 11                                    // 倉庫設計.基準スパン
            this.paramsADBBS.car_approach_type = this.paramsData.berth_type  // 倉庫設計.バース種類
            this.paramsADBBS.building_area = cmnDecimalFormat(this.paramsData.building_area, 2)   // 倉庫設計.容積対象面積制限
            this.paramsADBBS.is_10t_truck = false                            // 倉庫設計.10tトラックを配置する
            this.paramsADBBS.is_shape = false                                // 倉庫設計.倉庫を整形で設計する
          }
          this.labelBuildingShape = this.setBuildingShape(this.paramsADBBS.building_shape)
          document.getElementsByName('labelBuildingShape')[0].placeholder=this.labelBuildingShape;
          this.$refs.modalBlockMessage.close()
        })
        .catch((e) => {
          console.log(e)
          this.$refs.modalBlockMessage.close()
        })
    },
    /* 想定断面 */
    $_setFloor() {
      this.paramsADBBS.floor_list = this.paramsData.floor_list
      return this.paramsData.floor_list.length === 0 ? null : Math.max.apply(null, this.paramsData.floor_list.map(function(o){return o.number;}))
    },
    /* 形状設定 */
      setBuildingShape(buildingShape) {
        let shapes = []
        if (buildingShape.is_box) shapes.push(buildingShapes[0].text)
        if (buildingShape.is_slope) shapes.push(buildingShapes[1].text)
        if (buildingShape.is_ramp) shapes.push(buildingShapes[2].text)        
        this.labelBuildingShape = shapes.join('/')
        this.paramsData.building_shape = buildingShape
        this.paramsADBBS.building_shape = buildingShape

        return shapes.join('/')
      },
    /* 接車タイプ(バース種類)の設定 */
    $_labelApproachType(berthTypes){
      let types = []
      if (berthTypes.is_one_side) types.push(BERTH_TYPE_LABEL.ONE_SIDE)
      if (berthTypes.is_both_side) types.push(BERTH_TYPE_LABEL.BOTH_SIDE)
      if (berthTypes.is_l_shape) types.push(BERTH_TYPE_LABEL.L_SHAPE)
      if (berthTypes.is_center) types.push(BERTH_TYPE_LABEL.CENTER)
      if (berthTypes.is_center_one_side) types.push(BERTH_TYPE_LABEL.CENTER_ONE_SIDE)
      if (berthTypes.is_center_both_side) types.push(BERTH_TYPE_LABEL.CENTER_BOTH_SIDE)
      return types.join('/')
    },
    /* 接車タイプ(バース種類)の再設定 */
    setApproachType(berthTypes) {
      this.paramsADBBS.car_approach_type = berthTypes
    },
    /* 容積対象面積制限フォーマット */
    $_formatBuildingArea(){
      this.paramsADBBS.building_area = cmnDecimalFormat(cmnChangeStrToNum(this.paramsADBBS.building_area), 2)
    },
    /* 階高設定 */
    setFloorList(floors){
      this.paramsData.floor_list = floors
      this.$_setFloor()
    },
    /* 詳細アイコン切り替え */
    $_changeDetailsIcon() {
      this.isDetailsShown === true ? this.isDetailsShown = false : this.isDetailsShown = true
    },
    /* [計算]クリック */
    $_startCalc() {
      if (!this.$_validation()) {
        return
      }
      // 画面の入力値を状態ストアに保存する
      let params = this.$store.state.params
      params.building_shape = this.paramsADBBS.building_shape
      params.building_types = this.paramsADBBS.building_types
      params.auto_design = this.paramsAutoDesign
      params.auto_design.building_base_setting = this.paramsADBBS
      let spanWidth = this.baseSpan.substr(0, this.baseSpan.indexOf('_'))
      let spanDepth = this.baseSpan.substr(this.baseSpan.indexOf('_') + 1) 
      this.paramsADBBS.base_span.width = spanWidth // 倉庫設計.基準スパン（間口）
      this.paramsADBBS.base_span.depth = spanDepth // 倉庫設計.基準スパン（奥行）    
      this.$store.commit('setParams', params)
      
      // 自動設計結果保存サービス
      let payload = {
        building_types: this.paramsADBBS.building_type,
        building_shape: this.paramsData.building_shape,
        office_rate: this.paramsData.office_rate,
        auto_design: params.auto_design
      }
      // console.log(payload)
      // console.log(params.auto_design.building_base_setting.floor_list)
      this.$store.dispatch("startAutoDesign", payload)
        .then((resultData) => {  
          if(resultData === RESULT_CODE.OK)
            this.$refs.modalCheckAutoCalcStatus.init()
        })
        .catch((e) => {
          console.log(e);
        });
    },
     /* バリデーションチェック */
    $_validation() {
      this.errors = []
      if(!cmnCheckNumFormat(this.paramsADBBS.building_area)) this.errors.push("容積対象面積制限は数値で入力してください")
      if (!this.errors.length) {
        return true
      }
      return false
    },
    /* [キャンセル]クリック */
    $_cancel() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    },
    /* 計算完了 */
    finishCalc (flag){
      if (flag === FLAG.ON) {
        this.errors = []
        this.errors.push("自動計算処理が異常終了しました。", "再度、計算ボタンより実行してください。")
        this.$nextTick(() => {
          const contents = document.getElementById('contents')
          contents.scrollTop = contents.scrollHeight
        })
        return
      }
      this.$emit('next')
      this.myModal.hide()
    }
  }
}
</script>
  
<style lang="scss" scoped>
  .my-col-p-0 {
    div[class*="col"] {
      padding: 0;
    }
  }

  .my-text-align {
    text-align: right;
  }

  .form-select {
    background-position: right 0.20rem center;
  }

  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }

  .border {
    border-bottom: 1;
    border-bottom-color: gainsboro;
  }
</style>
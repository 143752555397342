<template>
    <div class="my-container">
      <div class="my-side-contents text-black">
        <input-section  @set-viewer="setViewer"></input-section>
      </div>
      <div class=my-main-contents>
        <output-section ref="outputSection" :prop-viewer-url="url"></output-section>
      </div>
    </div>
</template>
  
  <script>
  import InputSection from '@/views/warehouse/InputSection'
  import OutputSection from '@/views/viewer/OutputSection'
  
  export default {
    name: 'Warehouse',
    components: {
      InputSection,
      OutputSection
    },
    data() {
      return {
        viewUrl: this.$store.state.viewerUrl.plan_view_url
      }
    },
    computed: {
    url: function() {
      return this.viewUrl
    }
  },
    methods: {
    setViewer(newUrl){
      this.viewUrl = newUrl
      this.$refs.outputSection.reloadViewer()
    }
  }
  }
  </script>
  
  <style lang="scss" scoped>
    @import "../assets/style/style.scss";
  .my-main-contents {
    overflow: hidden;
  }
  </style>
<template>
  <div class="my-container">
    <div class="my-side-contents">
      <menu-section show-password-btn="true"></menu-section>
    </div>
    <div class="my-main-contents">
      <main-section></main-section>
    </div>
  </div>
</template>

  <script>
  import MenuSection from '@/views/UserMenuSection'
  import MainSection from '@/views/userList/MainSection'

  export default {
    name: "UserList",
    components: {
      MenuSection,
      MainSection
    },


  }
</script>
    
<style lang="scss" scoped>
  @import "../assets/style/style.scss";

</style>
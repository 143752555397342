<template>
  <div class="my-container d-flex align-items-center flex-column">
    <div class="d-flex flex-wrap align-items-center w-100">
      <header-section></header-section>
      <h3 class="d-inline-flex px-4 py-2 m-0">特定商取引法に基づく表記</h3>
    </div>
    <div class="container my-5">
      <p>「特定商取引に関する法律」&ensp;第11条（通信販売についての広告）に基づき以下に明示いたします。</p>
      <div class="row row-cols-sm-2 align-items-start border-bottom mt-5">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">事業者名称</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">株式会社&nbsp;フクダ&thinsp;・&thinsp;アンド&thinsp;・&thinsp;パートナーズ</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">代表者または運営統括責任者名</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">代表取締役社長&emsp;福田&nbsp;哲也</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">所在地</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">〒103-0016&emsp;東京都中央区日本橋小網町7-2&nbsp;ぺんてるビル8F</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">電話番号</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">03-5623-5788（対応時間:&nbsp;土日祝祭日&thinsp;・&thinsp;年末年始を除く&nbsp;10:00〜17:00）</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">お問い合わせ先</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">弊社サポート窓口(<a href="mailto:support@rakulogi.net">support@rakulogi.net</a>)へご連絡ください</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">価格</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">Webサイトに記載</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">送料&thinsp;・&thinsp;手数料など付帯費用</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">消費税<br>振込手数料（振込でお支払いいただく場合に限ります）</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">代金の支払い時期および方法</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">銀行振込：ご利用の翌月末まで<br>クレジットカード決済：ご利用月末日にて決済を行い、クレジットカード会社が定める支払期日</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">商品の受け渡し時期および方法</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">お申し込みいただいた後、当社の承認後すぐにご利用になれます。</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">返品の取り扱い条件、解約条件</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">商品の特性上、返品が不可能であるため、購入確定後のキャンセル&thinsp;・&thinsp;返金についてはお受けできません。<br>
        Webサイト上にて、解約申請を行っていただきます。<br>解約月のご利用料金については、日割りはせず、満額でご請求いたします。</span>
      </div>
      <div class="row row-cols-sm-2 align-items-start border-bottom">
        <span class="col-sm-12 col-lg-3 py-1 fw-bold">不具合&thinsp;・&thinsp;問題発生時の対応</span>
        <span class="col-sm-12 col-lg-9 pb-1 pt-sm-0 pt-lg-1">弊社サポート窓口(<a href="mailto:support@rakulogi.net">support@rakulogi.net</a>)へご連絡ください</span>
      </div>
    </div>
    <footer-section class="my-footer mt-auto"></footer-section>
  </div>
</template>

<script>
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'

export default {
  name: 'SpecifiedCommercialTransactions',
  components: {
    HeaderSection,
    FooterSection
  }
}
</script>

<style lang="scss" scoped>
.my-container {
  min-height: 100%;
  .my-footer::before,
  .my-footer::after{
    content: "";
    display: block;
    flex-grow: 1;
    height: 25px;
    width: 100%;
  }
}
</style>
<template>
  <footer class="text-center" v-bind:class="[isLoginPage===true ? 'small' : 'my-font-size', isOutputPage===true ? 'my-footer-position' : '']" v-bind:style="[isLoginPage===true ? 'margin-top: 100px' : '']">© 2022 F&P All Rights Reserved.</footer>
</template>

<script>
export default {
  name: 'HeaderSection',
  computed: {
    isLoginPage: function(){
      //  console.log(this.$route.path)
      if(this.$route.path === '/') return true
      return false
    },
    isOutputPage: function(){
      if(this.$route.path === '/file-output') return true
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.my-font-size {
  font-size: 0.7rem;
}
.my-footer-position {
  position: absolute;
  bottom: 24px;
  right: 60px;
}
</style>
<template>
  <div class="my-container">
    <!-- <header class="p-2 alert-secondary"><span class="fw-bold">トップ > マスタテンプレート管理</span></header> -->
    <div class="my-side-contents text-black">
      <input-section></input-section>
    </div>
    <div class="my-main-contents">
      <output-section></output-section>
    </div>
  </div>
</template>

<script>
import InputSection from '@/views/masterMaintenance/InputSection'
import OutputSection from '@/views/masterMaintenance/OutputSection'

export default {
  name: "MasterMaintenance",
  components: {
    InputSection,
    OutputSection
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/style.scss";
  // #app .my-container .my-side-contents,
  // #app .my-container .my-main-contents
  // {
  //   height: calc(100vh - 40px);
  // }
  // header {
  //   grid-column: 1/3;
  // }
  // header + div {
  //   padding-top: 0.5rem;
  // }

</style>
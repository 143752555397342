<template>
  <div class="text-center mx-4">
    <header-section></header-section>
    <div class="alert my-alert-success fade show mb-3" role="alert">
      <span class="d-block lh-base" >登録申請が完了しました。</span>
    </div>
    <footer-section></footer-section>
  </div>
</template>

<script>
  import HeaderSection from '@/views/common/HeaderSection'
  import FooterSection from '@/views/common/FooterSection'
  export default {
    name: 'Registered',
    components: {
      HeaderSection,
      FooterSection
    },

  }
</script>

<style lang="scss" scoped>

  .my-alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
</style>
<template>
  <div class="my-container d-flex align-items-center flex-column">
    <div class="d-flex flex-wrap align-items-center w-100">
      <header-section></header-section>
      <h3 class="d-inline-flex px-4 py-2 m-0">問い合わせ</h3>
    </div>
    <form class="my-form rounded bg-info p-5 mt-5">
      <h4 class="fw-normal lh-1 text-center text-success">問い合わせフォーム</h4>
      <span class="my-sub-title d-block fw-6 text-center text-info mb-4"> Inquiry </span>
      <div v-if="!completed" class="p-5 text-body bg-body rounded">
        <p v-if="!confirm" class="text-center mb-4">下記に必要事項を入力して「確認ボタン」をおしてください。</p>
        <p v-if="confirm"  class="text-center mb-4">入力内容をご確認のうえ、「送信ボタン」をおしてください。</p>
        <div class="container-fluid">
          <div class="row row-cols-sm-2 align-items-center">
            <span class="col-sm-4 py-1 fw-bold border-bottom">会社名</span>
            <span class="col-sm-8 py-1 border-bottom" >{{ company.companyName }}</span>
            <span class="col-sm-4 py-1 fw-bold border-bottom">名前</span>
            <span class="col-sm-8 py-1 border-bottom" >{{ user.userName }}</span>
            <span class="col-sm-4 py-1 fw-bold border-bottom">メールアドレス</span>
            <span class="col-sm-8 py-1 border-bottom" >{{ user.userEmail }}</span>
          </div>
          <div class="row row-cols-sm-2 align-items-center mt-3">
            <span class="col-sm-4 py-1 my-bg-color fw-bold">問い合わせ種別<span
                      class="badge bg-danger lh-sm ms-3">必須</span></span>
            <span class="col-sm-8 py-1">
              <select v-if="!confirm" class="col-sm-9 py-1 form-select form-select-sm w-50" v-model="inquiryType">
                <option value="">選択してください</option>
                <option v-for="item in inquiryTypeList" :key="item.code" :value="item.text">
                  {{ item.text }}
                </option>
              </select>
              <span v-if="confirm">{{ inquiryType }}</span>
            </span>
          </div>
          <div class="row row-cols-sm-2 align-items-start mt-3">
            <span class="col-sm-4 py-1 my-bg-color fw-bold align-items-top">問い合わせ内容<span
                      class="badge bg-danger lh-sm ms-3">必須</span></span>
            <span class="col-sm-8 py-1" >
              <textarea v-if="!confirm" class="form-control form-control-sm" placeholder="問い合わせ内容を記入してください（1000文字以内でご入力ください。）" required
                style="height: 20rem;" v-model="inquiryDetail" maxlength="1000"/>
              <span v-if="!confirm" class="small mt-2">{{ inquiryDetail == null ? 0 : inquiryDetail.length }}&thinsp;/&thinsp;1000 文字</span>
              <span v-if="confirm" style="white-space: pre-line;">{{ inquiryDetail }}</span>
            </span>
          </div>
          <div v-if="errors.length" class="alert alert-danger fade show mt-4 w-50 mx-auto" role="alert">
            <div><span class="d-block lh-base text-center" v-for="error in errors" :key="error.index">{{ error }}</span></div>
          </div>
          <div v-if="!confirm" class="row align-items-center justify-content-center" v-bind:class="[errors.length ? 'mt-4' : 'mt-5']">
            <button class="btn btn-lg btn-primary btn-block" @click="$_confirm">確認</button>
          </div>
          <div  v-if="confirm" class="row row-cols-sm-2 align-items-center justify-content-evenly mt-5">
            <button class="btn btn-lg btn-secondary btn-block" @click="$_backToForm">戻る</button>
            <button class="btn btn-lg btn-primary btn-block" type="button" @click="$_submit">送信</button>
          </div>
        </div>
      </div>
      <div v-if="completed" class="p-5 text-body bg-body rounded">
        <div class="container-fluid text-center">
          <p class="fs-5">お問い合わせありがとうございます。</p>
          <p>送信が完了いたしました。<br>３営業日以内に、担当者からメールでご連絡いたしますので今しばらくお待ちくださいませ。</p>
        </div>
      </div>
    </form>
    <footer-section class="my-footer"></footer-section>
    <setting-btn icon-color="text-secondary" :show-top-link="true"></setting-btn>
  </div>
</template>

<script>
import HeaderSection from '@/views/common/HeaderSection'
import FooterSection from '@/views/common/FooterSection'
import SettingBtn from '@/views/common/SettingBtn'
import { inquiryTypes } from '@/assets/js/code'
import { RESULT_CODE } from '@/assets/js/const'

export default {
  name: 'Inquiry',
  components: {
    HeaderSection,
    FooterSection,
    SettingBtn
  },
  data() {
    return {
      user: this.$store.state.user,
      company: this.$store.state.company,
      inquiryType: null,
      inquiryDetail: null,
      errors: [],
      confirm: false,
      completed: false
    }
  },
  mounted() {
    this.$_init()
  },
  computed: {
    /* 問い合わせ種別List */
    inquiryTypeList: function () {
      return inquiryTypes
    }
  },
  methods: {
    /* 初期化 */
    $_init() {
      this.completed = false
      this.$_setData()
    },
    /* データ設定 */
    $_setData() {
      this.inquiryType = ""
    },
    /* バリデーションチェック */
    $_validation() {
      this.errors = []
      if (this.inquiryType === "") {
        this.errors.push('問い合わせ種別を選択してください');
      }
      if (!this.inquiryDetail) {
        this.errors.push('問い合わせ内容を入力してください');
      }
      if(!this.errors.length) {
        return true
      }
      return false
    },
    /* 確認ボタンクリック */
    $_confirm(e) {
      e.preventDefault();
      if (this.$_validation()){
        this.confirm = true
      }
    },
    /* 戻るボタンクリック */
    $_backToForm() {
      this.confirm = false
    },
    /* 送信ボタンクリック */
    $_submit() {
      let payload = {
        company: this.company,
        user: this.user,
        inquiry_type: this.inquiryType,
        inquiry_detail: this.inquiryDetail
      }
      // 問い合わせ送信サービス
      this.$store.dispatch('sendInquiry', payload)
        .then((resultcode) => {
          if(resultcode === RESULT_CODE.OK) {
            this.completed = true
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.my-container {
  min-height: 100%;
  .my-footer::before,
  .my-footer::after{
    content: "";
    display: block;
    flex-grow: 1;
    height: 25px;
    width: 100%;
  }
  .my-form {
    display: block;
    width: 80%;
    max-width: 1100px;
    margin: auto;
    flex-shrink: 0;
    background: linear-gradient(180deg,#f6fafd, #2c90bf);
    .my-sub-title{
      &::before, &::after {
      content: " - ";
      display: inline-block;
      }
    }
    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      &:focus {
        z-index: 2;
      }
    }
    button {
      max-width: 200px;
    }
  }
}
</style>
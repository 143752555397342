<template>
  <div name="modal-confirm" class="modal" tabindex="-1" data-bs-keyboard="false" aria-labelledby="modal-confirm">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <span class="d-block lh-base" v-for="(message, index) in messages" :key="index">{{ message }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary" @click="$_yes" ref="yesBtn">はい</button>
          <button type="button" class="btn btn-sm btn-secondary" @click="$_no" ref="noBtn">いいえ</button>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { cmnIsBlank } from '@/assets/js/common'
import { RESULT_CODE } from '@/assets/js/const'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalConfirm',
  props: {
    confirmMessages: {
      type: Array
    },
    parentId: {
      type: String,
      default: ''
    },
    focusNoBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myModal: null,
      messages: []
    }
  },
  watch: {
    confirmMessages: {
      handler: function(newVal) {
        this.messages = newVal
      },
      deep : true,
    }
  },
  methods: {
    /* 初期化 */
    init() {
      if (cmnIsBlank(this.parentId))
        this.myModal = new Modal(document.getElementsByName('modal-confirm')[0], {})
      else
        this.myModal = new Modal(document.getElementById(this.parentId).querySelector(':scope > div[name="modal-confirm"]'), {})
      this.myModal.show()
      fncAdjustModalLayer()
      this.$_focusBtn()
    },
    /* [はい]or[いいえ]にフォーカス */
    $_focusBtn() {
      if(this.focusNoBtn) {
        this.$refs.noBtn.focus()
      } else {
        this.$refs.yesBtn.focus()
      }
    },
    /* [はい]クリック */
    $_yes() {
      this.$emit('return-confirm-result', RESULT_CODE.OK)
      this.$_hide()
    },
    /* [いいえ]クリック */
    $_no() {
      this.$emit('return-confirm-result', RESULT_CODE.NG)
      this.$_hide()
    },
    /* モーダルクローズ */
    $_hide() {
      this.myModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
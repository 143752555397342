<template>
  <div class="text-center" v-bind:class="[isLoginPage===true ? 'mb-4' : 'm-3']">
    <img src="../../assets/images/logo.png" v-bind:style="[isLoginPage===true ? 'width: 230px' : 'width: 130px']">
  </div>
</template>

<script>
export default {
  name: 'HeaderSection',
  computed: {
    isLoginPage: function(){
      //  console.log(this.$route.path)
      if(this.$route.path === '/') return true
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
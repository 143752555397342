<template>
  <div id="registration-info-check-main" class="my-overflow-y mx-sm-3 mx-5 mt-4 mb-5">
    <h4 class="mb-4">登録情報確認・修正</h4>
    <div class="container">
      <div class="text-end">
        <p>登録日: {{ formatRegistDate }}</p>
      </div>
      <messages ref="messages"></messages>
      <basic-user-info @paymentMethodChanged="setPaymentMethod" ref="basicUserInfo" :isSignUp="false"></basic-user-info>
      <div v-show="paymentMethod === paymentMethodCredit" class="container mt-2" style="max-width:1020px;">
        <div class="row ms-xl-auto">
          <div class="form-check my-2 col-8 offset-4">
            <input class="form-check-input" type="checkbox" id="changeCredit" v-model="isCreditCardChanged" :disabled="forcesCreditCardChange" />
            <label class="form-check-label" for="changeCredit">クレジットカード情報を変更する</label>
          </div>
        </div>
        <div class="mt-2 row" v-show="isCreditCardChanged">
          <div class="col-xl-8 offset-xl-4">
            <credit-info ref="creditInfo"></credit-info>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5 justify-content-center">
        <div class="col-sm-3 col-xl-3 border py-2 my-bg-color fw-bold">ステータス</div>
        <div class="col-sm-8 col-xl-6 border py-2">{{ status }}</div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-3 col-xl-3 border border-top-0 py-2 my-bg-color fw-bold">会員状況</div>
        <div class="col-sm-8 col-xl-6 border border-top-0 py-2">{{ resignedFlag }}</div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-3 col-xl-3 border border-top-0 py-2 my-bg-color fw-bold">ユーザ数</div>
        <div class="col-sm-8 col-xl-6 border border-top-0 py-1"><button class="btn btn-link btn-sm py-1" type="link"
            @click="moveToUserList()">{{ userCount }}
        </button></div>
      </div>
      
      <div class="row mt-4 justify-content-center p-2">
        <button class="col-5 col-xl-2 py-2 btn btn-primary btn-lg" @click="$_registrationBtn()">更新</button>
      </div>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="registration-info-check-main" />
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" @return-confirm-result="returnConfirmResult" :focus-no-btn="true" />
    <modal-confirm-with-charge ref="modalConfirmWithCharge" :confirm-messages="confirmMessagesWithCharge" :payment-method="paymentMethod" @return-confirm-result="returnConfirmResult" :focus-no-btn="true" />
    <setting-btn icon-color="text-secondary" :show-top-link="true"></setting-btn>
  </div>
</template>

<script>
  import BasicUserInfo from '@/views/registrationInfoCheck/BasicUserInfo'
  import CreditInfo from '@/views/registrationInfoCheck/CreditInfo'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import ModalConfirm from '@/views/common/ModalConfirm'
  import ModalConfirmWithCharge from '@/views/registrationInfoCheck/ModalConfirmWithCharge'
  import Messages from '@/views/common/Messages'
  import SettingBtn from '@/views/common/SettingBtn'
  import { ref } from 'vue'
  import { cmnDevidePostalCode, cmnHasBlank, cmnChangeFormatDate, cmnInsertDelimiter } from '@/assets/js/common'
  import { paymentMethodList, statusList, resignedFlagList, codeToText, textToCode} from '@/assets/js/code'
  import { RESULT_CODE, REGEXP, GMO_CREDIT_TOKEN_URL } from '@/assets/js/const'
  import { company } from '@/assets/js/dto/company'
  import { charge } from '@/assets/js/dto/charge'

  const PAYMENT_METHOD_CREDIT = textToCode(paymentMethodList, 'クレジットカード')
  const PAYMENT_METHOD_BILL = textToCode(paymentMethodList, '請求書払い')

  export default {
    name: 'RegistrationInfoCheck',

    components: {
      BasicUserInfo,
      CreditInfo,
      ModalBlockMessage,
      ModalConfirm,
      ModalConfirmWithCharge,
      Messages,
      SettingBtn,
    },

    setup () {
      const basicUserInfo = ref(null)
      const creditInfo = ref(null)
      const modalConfirm = ref(null)
      const modalConfirmWithCharge = ref(null)

      return {
        basicUserInfo,
        creditInfo,
        modalConfirm,
        modalConfirmWithCharge
      }
    },

    data() {
      return {
        registDate: null,
        Company: new company(),
        Charge: new charge(),
        saved: {
          licenseNum: null,
          paymentMethod: null
        },
        isSignUp: false,
        paymentMethod: PAYMENT_METHOD_CREDIT,
        isCreditCardChanged: false,
        forcesCreditCardChange: true,
        status: null,
        resignedFlag: null,
        userCount: null,
        priceExcludedTax: null,
        priceIncludedTax: null,
        notShowSctLink: false,
        confirmMessagesWithCharge: [],
        confirmMessages: [],
      }
    },

    computed: {
      paymentMethodCredit: function() {
        return PAYMENT_METHOD_CREDIT
      },

      paymentMethodBill: function() {
        return PAYMENT_METHOD_BILL
      },

      formatRegistDate: function() {
        return cmnChangeFormatDate(new Date (Date.parse(this.Company.registDate)), 'date', 'YYYY/MM/DD')
      },

      enabledModalConfirmWithCharge: function() {
        return (this.saved.licenseNum !== this.basicUserInfo.info.licenseNum
            || this.saved.paymentMethod !== this.basicUserInfo.info.paymentMethod)
      }

    },

    mounted() {
      this.$_init()
      this.$_importGmoScript()
      .then(this.$_getShopId)
      .then(shopId => {
          window.Multipayment.init(shopId)
      })
      
    },

    beforeUnmount() {
      // 読み込んだ外部スクリプトを削除する
      const script = document.getElementById('script_gmo')
      if (script) {
        script.parentNode.removeChild(script)
      }
    },

    methods: {
      setPaymentMethod(val) {
        this.paymentMethod = val
      },

      /* 初期化 */
      $_init() {
        this.$refs.modalBlockMessage.init()
        let payload = {
          company_id: this.$store.state.user.companyId
        }
        
        this.$store.dispatch('getRegistrationInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            this.$_setRegistrationInfo(res.customerDataList[0])
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          }) 
      },
      
      /* 取得した値のセット */
      $_setRegistrationInfo(res) {
        this.Company = res.company
        this.Charge = res.charge
        this.priceExcludedTax = res.priceExcludedTax
        this.priceIncludedTax = res.priceIncludedTax
        this.userCount = res.userCount

        this.registDate = this.Company.registDate

        this.basicUserInfo.info = {
          companyName: this.Company.companyName,
          departmentName: this.Company.departmentName,
          supervisorName: res.supervisorName,
          supervisorEmail: res.supervisorEmail,
          telephoneNumber: this.Company.telephoneNumber,
          billingPostalCode1: cmnDevidePostalCode(this.Charge.billingPostalCode)[0],
          billingPostalCode2: cmnDevidePostalCode(this.Charge.billingPostalCode)[1],
          billingAddress: this.Charge.billingAddress,
          licenseNum: this.Company.numberOfLicenses,
          priceExcludedTax: cmnInsertDelimiter((this.priceExcludedTax).toString(), ',', 3),
          priceIncludedTax: cmnInsertDelimiter((this.priceIncludedTax).toString(), ',', 3),
          paymentMethod: this.Charge.paymentMethod,
        }
        
        this.basicUserInfo.chargeInfo.info = {
          year: this.Charge.targetMonth.slice(0, 4),
          month: Number(this.Charge.targetMonth.slice(4, 6)),
          caption: '現在',
          numberOfLicensesCharged: this.Charge.numberOfLicensesCharged,
          chargeIncludedTax: this.Charge.chargeIncludedTax,
          chargeExcludedTax: this.Charge.chargeExcludedTax,
        }

        this.paymentMethod = this.Charge.paymentMethod
        
        this.status = codeToText(statusList, this.Company.approvedFlag)
        this.resignedFlag = codeToText(resignedFlagList, this.Company.resignedFlag)

        // ライセンス数と決済手段を退避
        this.saved.licenseNum = this.Company.numberOfLicenses
        this.saved.paymentMethod = this.Charge.paymentMethod
        
        if(this.basicUserInfo.info.paymentMethod === this.paymentMethodCredit) {
          this.forcesCreditCardChange = false
        } else {
          this.isCreditCardChanged = true
          this.forcesCreditCardChange = true
        }
      },

      /* クレジット決済用GMOスクリプト読み込み */
      $_importGmoScript() {
        return new Promise((resolve, reject) => {
          try{
            const script = document.createElement('script')
            script.setAttribute('src', GMO_CREDIT_TOKEN_URL) 
            script.setAttribute('id', 'script_gmo')
            document.head.appendChild(script)

            // スクリプトの読み込みが完了したらresolve
            script.addEventListener('load', () => { resolve() })
          } catch(e) {
            reject(e)
          }
        })
      },

      /* GMOスクリプトのためのショップIDの取得 */
      async $_getShopId() {
        return await this.$store.dispatch('getShopId')
      },

      /* 更新ボタン */
      $_registrationBtn() {
        this.$refs.messages.clearAllMsg()
        if(this.$_validation()){
          window.scrollTo(0, 0)
          return
        }


        if(this.enabledModalConfirmWithCharge) {
          this.confirmMessagesWithCharge = []
          this.confirmMessagesWithCharge.push('登録情報を修正します。ライセンス数を変更した場合、下記より変更後の請求額をご確認ください。')
          this.modalConfirmWithCharge.caption = '変更後'
          this.modalConfirmWithCharge.companyId = this.$store.state.user.companyId
          this.modalConfirmWithCharge.numberOfLicenses = this.basicUserInfo.info.licenseNum

          /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
          this.$refs.modalConfirmWithCharge.init()
        } else {
          this.confirmMessages = []
          this.confirmMessages.push('登録情報を修正します。よろしいですか？')

          /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
          this.$refs.modalConfirm.init()
        }
        
      },
      
      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.OK) {
          this.$_registration()
        }
      },

      /* 登録 */
      $_registration() {
        this.$refs.modalBlockMessage.init()
        if(this.paymentMethod === this.paymentMethodCredit && this.isCreditCardChanged) {
          const cardInfo  = {
            cardno: this.creditInfo.info.cardNumber,
            expire: this.creditInfo.info.year + this.creditInfo.info.month,
            securitycode: this.creditInfo.info.securityCode,
            holdername: this.creditInfo.info.holderName,
            tokennumber: '1',
          }
          this.$_getCreditToken(cardInfo)
        } else {
          this.$_executeRegistration()
        }
      },

      /* クレジットカード用トークンの取得 */
      $_getCreditToken(cardInfo) {
        // getTokenのコールバック関数でこのインスタンスを参照するため変数に格納
        const thisInstance = this
        // getTokenでは第2引数でグローバル関数をコールバックする必要があるので、グローバル無名関数を用意してその中でローカル関数を呼ぶ
        window.Multipayment.getToken(
          cardInfo, 
          function(res){ thisInstance.$_executeRegistration(res) 
        })
      },

      /* トークンがあれば受け取り＆登録情報修正の実行 */
      $_executeRegistration(response){
        let payload = {
          company: this.Company,
          charge: this.Charge,
          supervisorEmail: this.basicUserInfo.info.supervisorEmail,
          supervisorName: this.basicUserInfo.info.supervisorName,
          creditToken: null,
        }
        if(response) {
          // クレジットカード払いのときのみ
          if(response.resultCode !== '000') {
            window.scrollTo(0, 0)
            this.$refs.messages.addErrorMsg(`クレジットカードのトークン取得に失敗しました（エラーコード：${ response.resultCode }）。`)
            this.$refs.messages.addErrorMsg('クレジットカード情報が正しいかどうかご確認ください。')
            this.$refs.modalBlockMessage.close()
            return
          }
          payload.creditToken = response.tokenObject.token[0]
        }

        this.$_formatRegistartionInfo()

        this.$store.dispatch('modifyRegistrationInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) {
              window.scrollTo(0, 0)
              return this.$refs.messages.addErrorMsg(res.message)
            }
            this.$_init()
            window.scrollTo(0, 0)
            this.$refs.messages.addSuccessMsg('登録情報を修正しました。')
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          }) 
      },

      /* 顧客情報を整形 */
      $_formatRegistartionInfo() {
        this.Company.companyName = this.basicUserInfo.info.companyName
        this.Company.departmentName = this.basicUserInfo.info.departmentName
        this.Company.telephoneNumber = this.basicUserInfo.info.telephoneNumber
        this.Company.numberOfLicenses = this.basicUserInfo.info.licenseNum

        this.Charge.billingPostalCode = this.basicUserInfo.info.billingPostalCode1 + '' + this.basicUserInfo.info.billingPostalCode2
        this.Charge.billingAddress = this.basicUserInfo.info.billingAddress
        this.Charge.paymentMethod = this.basicUserInfo.info.paymentMethod
        this.Charge.numberOfLicensesCharged = this.basicUserInfo.info.licenseNum
      },

      /* ユーザ一覧へ遷移 */
      moveToUserList() {
        this.$router.push({ path: '/userList', name: 'UserList' })
      },

      /* バリデーション */
      $_validation() {
        let invalid = false
        
        /* 必須チェック */
        const basicUserInfoKeysRequired = ['companyName', 'departmentName', 'supervisorName', 'supervisorEmail', 'telephoneNumber', 'billingPostalCode1', 'billingPostalCode2', 'billingAddress', 'licenseNum', 'paymentMethod']
        const creditInfoKeysRequired = ['cardNumber','month', 'year', 'cardHoldersName', 'securityCode']

        if(this.paymentMethod === this.paymentMethodCredit && this.isCreditCardChanged) {
          invalid = cmnHasBlank(this.basicUserInfo.info, basicUserInfoKeysRequired) ||
            cmnHasBlank(this.creditInfo.info, creditInfoKeysRequired)
        } else {
          invalid = cmnHasBlank(this.basicUserInfo.info, basicUserInfoKeysRequired) 
        }
        
        if(invalid) {
          this.$refs.messages.addErrorMsg('未入力の必須項目があります。')
          return invalid
        }
        
        /* 電話番号文字チェック */
        invalid = !REGEXP.NUMBER.test(this.basicUserInfo.info.telephoneNumber)
        if (invalid) {
          this.$refs.messages.addErrorMsg('電話番号は数字で入力してください。')
          return invalid
        }

        /* ライセンス数文字チェック */
        invalid = !REGEXP.NUMBER.test(this.basicUserInfo.info.licenseNum)
        if (invalid) {
          this.$refs.messages.addErrorMsg('ライセンス数は数字で入力してください')
          return invalid
        }

        /* ライセンス数チェック */
        invalid = (this.basicUserInfo.info.licenseNum < 1 || this.basicUserInfo.info.licenseNum > 999)
        if(invalid) {
          this.$refs.messages.addErrorMsg('ライセンス数は1以上999以下の数字を入力してください。')
          return invalid
        }
        /* 郵便番号チェック */
        invalid = (
          !REGEXP.POSTAL_CODE_1.test(this.basicUserInfo.info.billingPostalCode1) ||
          !REGEXP.POSTAL_CODE_2.test(this.basicUserInfo.info.billingPostalCode2)
        )
        if (invalid) {
          this.$refs.messages.addErrorMsg('郵便番号が正しく入力されていません。')
          return invalid
        }

        if(this.paymentMethod === this.paymentMethodCredit && this.isCreditCardChanged) {
          /* クレジットカード番号チェック */
          invalid = (
            !REGEXP.NUMBER.test(this.creditInfo.info.cardNumber) ||
            !REGEXP.NUMBER.test(this.creditInfo.info.securityCode)
          )

          if (invalid) {
            this.$refs.messages.addErrorMsg('カード番号、セキュリティコードは数字で入力してください。')
            return invalid
          }
        }
        
        return invalid
      },
    }
  }
</script>
<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 1320px;
  }
  .my-bg-color {
    background: rgba(128, 128, 128, 0.3);
  }
</style>
/* プロジェクト(DB) */
class project {
  constructor() {
    this.projectNo = 0
    this.projectId = null
    this.companyId = 0
    this.userId = 0
    this.projectName = ''
    this.addressCode = ''
    this.address = ''
    this.buildingImage = null
    this.bookmark = 0
    this.projectStatus = 0
    this.projectUpdateDate = null
    this.deletedFlag = 0
    this.registDate = null
    this.updateDate = null
  }
}

export {
  project
}

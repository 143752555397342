<template>
  <div id="modal-system-error" class="modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modal-system-error">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
      <div class="modal-content">
        <div class="modal-header" v-if="isSystemError">
          <h5 class="modal-title fs-5" id="modal-input-rename">システムエラー</h5>
        </div>
        <div class="modal-body">
          <div class="container alert alert-danger mb-0">
            <div class="d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div>
                <span class="d-block lh-base" v-if="isSystemError">システムエラーが発生しました</span>
                <span class="d-block lh-base" v-if="isSystemError">管理者にお問い合わせください</span>
                <span class="d-block lh-base">{{ errorMessageDetail }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" @click="$_close">閉じる</button>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalSystemError',
  props: {
     errorMessages: {
      type: Array
    }
  },
  data() {
    return {
      myModal: null,
      isSystemError: true
    }
  },
  watch: {
    messages: {
      handler: function(newVal) {
        this.messages = newVal
      },
      deep : true,
    },
    errorCode: function(newVal) {
      if (newVal === 'failure') {
        this.isSystemError = true
        this.myModal.show()
        fncAdjustModalLayer()
      } else if (newVal === 'warning') {
        this.isSystemError = false
        this.myModal.show()
        fncAdjustModalLayer()
      }
      else {
        this.isSystemError = true
        this.$store.commit('setErrorCode', null)
        this.$store.commit('setErrorMessageDetail', null)
        this.myModal.hide()
      }
    }
  },
  mounted() {
      this.myModal = new Modal(document.getElementById('modal-system-error'), {})
      
  },
  computed: {
    errorStatus() {
      return this.$store.state.errorStatus
    },
    errorCode() {
      return this.$store.state.errorCode
    },
    errorMessageDetail() {
      return this.$store.state.errorMessageDetail
    }
  },
  methods: {    
    /* [閉じる]クリック */
    $_close() {
      this.isSystemError = true
      this.$store.commit('setErrorCode', null)
      this.$store.commit('setErrorMessageDetail', null)
      if (this.errorStatus === 401 || this.errorStatus === 409) {


        // 状態ストアを初期化
        this.$store.dispatch('initParams')
        this.$store.dispatch('initViewerUrl')
        this.$store.dispatch('initLists')
        this.$store.dispatch('initSettingButtons')
        this.$store.commit('setUser', null)
        this.$store.commit('setCompany', null)
        this.$store.commit('setUser', null)
        this.$store.commit('setCompany', null)
        this.$store.dispatch('initProjectName')
        this.$store.dispatch('initPrevPage')
        this.$store.commit('setShowModalProject', null)
        this.$store.commit('setAuthKey', null)
        this.$store.commit('setYoutoFlag', null)
        this.$store.commit('setShowModalProject', null)
        this.$store.commit('setAuthKey', null)
        this.$store.commit('setYoutoFlag', null)
        this.$store.commit('setErrorStatus', null)
        this.$router.push({ path: '/' })

        // ログイン画面へ遷移後にモーダルレイヤが残っていれば削除
        const modalLayers = document.getElementsByClassName('modal-backdrop show')
        while(modalLayers.length) modalLayers.item(0).remove()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
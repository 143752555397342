<template>
  <div class="h-100" id="border-settings-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">敷地 > 敷地辺情報設定</p>
        <p class="d-block mb-0">物件管理ID：{{ this.project.project_no }}</p>
        <p class="d-block mb-0">プロジェクト名称：{{ this.project.project_name }}</p>
        <p class="d-block mb-0">敷地面積：{{ this.project.site_planning.site_area }} ㎡</p>
      </div>
      <div class="my-overflow-y p-2">
        <div class="d-flex align-items-center">
          <div class="col-5">
            <span class="ms-3">境界線位置</span>
            <span class="ms-4">（方位）</span>
          </div>
          <div class="col-5">
            <select v-model="direction" class="form-select form-select-sm" aria-label=".form-select-sm">
              <option value="">すべて</option>
              <option v-for="item in directionList" :key="item.code" :value="item.code">
                {{ item.text }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <div class="d-flex align-items-baseline  ">
            <div class="col-5">
              <span class="ms-3">境界線種</span>
            </div>
            <div class="col-5 mt-2">
              <select v-model="borderType" class="form-select form-select-sm" aria-label=".form-select-sm">
                <option v-for="border in borderTypeList" :key="border.no" :value="border.no">
                  {{ border.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="borderType === 0" class="d-flex align-items-center">
            <div class="col-5">
              <span class="mt-1 ms-3">道路幅員(m)</span>
            </div>
            <div class="d-flex flex-wrap col-5">
              <input type="text" class="form-control form-control-sm mt-1" v-model="roadWidth" @blur="$_formatBatchRoadW">
            </div>
          </div>
          <div class="d-flex mb-2 mt-2">
            <div class="col-5"></div>
            <div class="col-5 d-flex"><button class="btn btn-primary btn-sm mt-1 flex-fill"
                @click="$_setBorderType">一括設定</button></div>
          </div>
        </div>
        <hr class="my-1">
        <div v-for="(item, index) in itemList" :key="index">
          <div class="mt-2" v-if="this.direction === '' || item.direction === this.direction">
            <div class="d-flex align-items-center">
              <div class="col-5 pe-0">
                <span class="ms-3">【{{ index + 1 }}】</span>
              </div>
              <div class="col-5 p-0">
                <select v-model="item.l_cond_type" class="form-select form-select-sm" aria-label=".form-select-sm">
                  <option v-for="border in borderTypeList" :key="border.no" :value="border.no">
                    {{ border.name }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="item.l_cond_type === 0" class="d-flex align-items-center">
              <div class="col-5">
                <span class="mt-1 ms-3">道路幅員(m)</span>
              </div>
              <div class="d-flex flex-wrap col-5">
                <input type="text" class="form-control form-control-sm mt-1" v-model="item.road_w" @blur="$_formatListRoadW($event, index)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-grid gap-3 mt-auto mx-2 pt-3">
        <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show mx-auto mb-3 px-3" role="alert">
          <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
        </div>
        <button type="button" class="btn btn-primary" @click="$_apply">反映</button>
        <button type="button" class="btn btn-primary" @click="$_prevPage">戻る</button>
      </div>
      <footer-section></footer-section>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="border-settings-input-section"/>
  </div>
</template>

<script>
  import HeaderSection from '@/views/common/HeaderSection'
  import FooterSection from '@/views/common/FooterSection'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { directions } from '@/assets/js/code'
  import { cmnMultiply, cmnDivide, cmnDecimalFormat, cmnCheckNumFormat, cmnChangeStrToNum } from '@/assets/js/common'

  export default {
    name: 'InputSection',
    components: {
      HeaderSection,
      FooterSection,
      ModalBlockMessage
    },
    data() {
      return {
        project: this.$store.state.params.data,
        borderTypeList: [],
        borderType: null,
        itemList: [],
        direction: null,
        roadWidth: null,
        errors: [],
      }
    },
    mounted() {
      this.$_init()
      this.$_getBorderTypeList()
    },
    computed: {
      /* 方位のlistbox取得 */
      directionList: function () {
        return directions
      }
    },
    methods: {
      /* 初期化 */
      $_init() {
        this.$_setData()
      },
      /* データ設定 */
      $_setData() {
        let lCondDataList = this.project.site_planning.l_cond.l_cond_data_list
        for (let i = 0, len = lCondDataList.length; i < len; i++) {
          this.itemList.push({
            l_cond_type: lCondDataList[i].l_cond_type,
            road_w: cmnDecimalFormat(cmnDivide(lCondDataList[i].road_w, 1000, -2), 2),
            direction: lCondDataList[i].direction
          })
        }
        this.borderType = 1
        this.direction = ''
      },
      /* 境界線種のlistbox取得 */
      $_getBorderTypeList() {
        this.borderTypeList = this.$store.state.lists.l_cond_types
      },
      /* 一括設定 */
      $_setBorderType() {
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          if (this.direction === '' || this.itemList[i].direction === this.direction) {
            this.itemList[i].l_cond_type = this.borderType
            if(this.borderType === 0) this.itemList[i].road_w = this.roadWidth
          }
        }
      },
       /*一括設定の道路幅員のロストフォーカス*/
       $_formatBatchRoadW() {
        if (!cmnCheckNumFormat(this.roadWidth)) return
        this.roadWidth = cmnDecimalFormat(cmnChangeStrToNum(this.roadWidth), 2)
      },
      /*一覧の道路幅員のロストフォーカス*/
      $_formatListRoadW(evt, index) {
        let val = this.itemList[index].road_w
        if (!cmnCheckNumFormat(val)) return
        let num = cmnChangeStrToNum(val)
        this.itemList[index].road_w = cmnDecimalFormat(num, 2)
      },
      /* 反映 */
      $_apply() {
        if (!this.$_validation()) {
          return
        }
        let params = this.$store.state.params
        let l_cond = params.data.site_planning.l_cond
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          l_cond.l_cond_data_list[i].l_cond_type = this.itemList[i].l_cond_type
          l_cond.l_cond_data_list[i].n_hei = 0
          if (this.itemList[i].l_cond_type !== 0 || this.itemList[i].road_w === '')
            l_cond.l_cond_data_list[i].road_w = 0
          else
            l_cond.l_cond_data_list[i].road_w = cmnMultiply(this.itemList[i].road_w, 1000)
          l_cond.l_cond_data_list[i].road_h1 = 0
          l_cond.l_cond_data_list[i].road_h3 = 0
          l_cond.l_cond_data_list[i].park_w = 0
          l_cond.l_cond_data_list[i].water_w = 0
          l_cond.l_cond_data_list[i].mtgr_w = 70000
          l_cond.l_cond_data_list[i].s_flag = false
        }
        this.$store.commit('setParams', params)
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('updateBuildingSiteLCode', { l_cond: l_cond }).then(() => {
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          this.$refs.modalBlockMessage.close()
          this.$router.push({ path: 'road-and-entrance' })
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close()
        })
      },
      /* 戻る */
      $_prevPage() {
        this.$store.dispatch('updateUsersUsageTimes')
        this.$router.push({ path: 'road-and-entrance' })
      },
      /* バリデーションチェック */
      $_validation() {
        this.errors = []
        let errorMessage = false
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          if (this.itemList[i].l_cond_type === 0) {
            if (!cmnCheckNumFormat(this.itemList[i].road_w))
              errorMessage = true
          }
        }
        if (errorMessage) {
          this.errors.push("道路幅員項目は数値で入力してください。")
          return false
        }
        return true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
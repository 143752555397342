/* ユーザ別利用時間管理 (DB) */
class usersUsageTime {
  constructor() {
    this.targetMonth = ''
    this.userId = 0
    this.userTime = 0
    this.registDate = null
    this.updateDate = null
  }
}

export {
  usersUsageTime
}

<template>
  <div class="my-overflow-y container" id="pre-registration">
    <div class="card border-light text-center mx-4 mt-5">
      <div class="card-header">
        <header-section></header-section>
      </div>
      <div class="card-body">
        <h4 class="card-title mt-2 mb-4">仮登録申請</h4>
        <p class="card-text">
          {{ notice }}
        </p>
        <messages ref="messages"></messages>
        <div class="d-flex flex-row align-items-center  justify-content-center my-4" v-if="!preRegistered">
          <span class="badge bg-danger me-3 p-2">必須</span>
          <input type="email" class="form-control form-control p-1 m-0" style="max-width:600px;" 
              v-model="userEmail" maxlength="64" placeholder="メールアドレス"/>
        </div>
        <div class="my-4 text-center">
          <button class="btn btn-primary btn-md" @click="$_preRegistrationBtn()" v-if="!preRegistered">仮登録</button>
        </div>
      </div>
      <div class="card-footer">
        <footer-section></footer-section>
      </div>
    </div>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="pre-registration" />
    <modal-confirm ref="modalConfirm" :confirm-messages="confirmMessages" parent-id="pre-registration" @return-confirm-result="returnConfirmResult" />
  </div>
</template>

<script>
  import HeaderSection from '@/views/common/HeaderSection'
  import FooterSection from '@/views/common/FooterSection'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import ModalConfirm from '@/views/common/ModalConfirm'
  import Messages from '@/views/common/Messages'
  import { RESULT_CODE, REGEXP } from '@/assets/js/const'

  export default {
    name: 'PreRegistration',

    components: {
      HeaderSection,
      FooterSection,
      ModalBlockMessage,
      ModalConfirm,
      Messages,
    },

    data() {
      return {
        preRegistered: false,
        userEmail: null,
        confirmMessages: [],
      }
    },

    computed: {
      notice: function() {
        const noticeList = [
          '下記フォームに担当者様のメールアドレスを入力してください。',
          'メールアドレスに本登録申請用のリンクが送信されます。',
          '本登録から5営業日以内に、申請結果をお知らせします。',
          '',
          '※メールが届かない場合、迷惑メールフォルダに自動振り分けされている可能性がありますので、',
          'こちらもご確認いただきますようお願いいたします。'
        ]
        let noticeString = ''
        for(const item of noticeList) {
          noticeString += item
          noticeString += '\n'
        }

        return noticeString
      }
    },
    
    methods: {
      /* 仮登録ボタン */
      $_preRegistrationBtn() {
        this.$refs.messages.clearAllMsg()
        if(this.$_validation()) return

        this.confirmMessages = []
        this.confirmMessages.push('仮登録申請します。', 'よろしいですか？')
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirm.init()
      },

      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.NG) return
        this.$_preRegistration()
      },

      /* 仮登録 */
      $_preRegistration() {
        this.$refs.modalBlockMessage.init()
        let payload = {
          email: this.userEmail
        }
        this.$store.dispatch('preRegistration', payload)
        .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) return this.$refs.messages.addErrorMsg(res.message)
            this.preRegistered = true
            this.$refs.messages.addSuccessMsg('仮登録申請しました。')
            this.$refs.messages.addSuccessMsg('入力したメールアドレス宛に送信されたメールをご覧ください。')
          })
        .catch((e) => {
          this.$refs.modalBlockMessage.close()
          console.log(e)
        }) 
        
      },

      /* バリデーション */
      $_validation() {
        let invalid = false

        /* 必須チェック */
        if(!this.userEmail) invalid = true
        if(invalid) {
          this.$refs.messages.addErrorMsg('未入力の必須項目があります。')
          return invalid
        }
        
        /* メールアドレス形式チェック */
        invalid = !REGEXP.EMAIL.test(this.userEmail)
        if(invalid) {
          this.$refs.messages.addErrorMsg('メールアドレスを正しい形式で入力してください。')
          return invalid
        }
        return invalid
      }

    },
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 1320px;
  }
  .my-bg-color {
    background: rgba(128, 128, 128, 0.3);
  }
  .card-text {
    white-space: pre;
  }
</style>
<template>
  <div class="card">
    <div class="card-body" style="font-size: 15px;">
      <h6 class="card-title">
        {{ info.year }}年{{ info.month }}月度 {{ info.caption }}のご請求（予定）
      </h6>
      <div class="container">
        <div class="card-text d-flex my-1">
          <div style="width: 120px;">サービス利用期間</div>： {{ info.year }}年{{ info.month }}月1日～{{ info.month }}月末日
          <strong class="text-info" v-if="info.isFreeTrial">＜初月無料期間＞</strong>
        </div>
        <div class="card-text d-flex my-1">
          <div style="width: 120px;">課金ライセンス数</div>： {{ info.numberOfLicensesCharged }} 
        </div>
        <div class="card-text d-flex my-1 align-items-end">
          <div style="width: 120px;">お支払金額</div>： {{ displayedChargeIncludedTax }}円<span class="small">（本体価格：{{ displayedChargeExcludedTax }}円、消費税：{{ tax }}円）</span>
        </div>
        <div class="card-text d-flex my-1">
          <div style="width: 120px;">ご請求日</div>： {{ info.year }}年{{ info.month }}月末日
        </div>
        <div class="card-text" v-if="showSctLink">
          ※詳細については、<span><button class="btn btn-link p-0 mb-1" style="font-size: 14px;" @click="$_MoveToSctPage">特定商取引法に基づく表記</button></span>をご覧ください。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { cmnInsertDelimiter } from '@/assets/js/common.js'

  export default {
    name: 'ChargeInfo',
    props: {
      showSctLink: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        info: {
          year: null,
          month: null,
          caption: null,
          numberOfLicensesCharged: 0,
          chargeIncludedTax: 0,
          chargeExcludedTax: 0,
          isFreeTrial: false,
        },
      }
    },
    mounted() {
    },
    computed: {
      tax: function() {
        const result = cmnInsertDelimiter((this.info.chargeIncludedTax - this.info.chargeExcludedTax).toString(), ',', 3)
        return result
      },

      displayedChargeIncludedTax: function() {
        const result = cmnInsertDelimiter(this.info.chargeIncludedTax.toString(), ',', 3)
        return result
      },

      displayedChargeExcludedTax: function() {
        const result = cmnInsertDelimiter(this.info.chargeExcludedTax.toString(), ',', 3)
        return result
      }
    },

    methods: {
      /* 特定商取引法に基づく表記 */
      $_MoveToSctPage() {
        const route = this.$router.resolve({ path: 'specified-commercial-transactions' })
        window.open(route.href, '_blank', 'noreferrer')
      },
    }
  }
</script>
<style lang="scss" scoped>

</style>
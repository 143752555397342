<template>
  <div id="modal-input-road-and-entrance-parent">
    <div id="modal-input-road-and-entrance" class="modal" tabindex="-1"  data-bs-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">接道の道路幅員と出入口登録</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row row-cols-2 justify-content-center">
                <div class="ps-0 col-md-6 col-lg-4">
                  <span class="fs-5">接道の道路幅員</span>
                </div>
                <div class="text-center">
                  <span class="fs-5">進入箇所（どれか１つ）</span>
                </div>
              </div>  
              <div
                v-for="(item, idx) in roadList"
                :key="idx"
                v-bind:class="[idx !== 0 ? 'mt-2' : 'mt-3']"
                class="row row-cols-2 justify-content-center"
              >
                <div class="px-3 col-md-6 col-lg-4 d-flex align-items-center">
                  <label class="col-2">{{ item.text }}</label>
                  <input type="text" v-model="item.formatDistance" @blur="$_formatDistance(idx)" class="my-input-width form-control form-control-sm ms-2 text-end">
                  <span class="ms-2">m</span>
                </div>
                <div class="px-3 d-flex align-items-center justify-content-center">
                  <input type="radio" id="idx" :value="idx" v-model="entryPoint" class="form-check-input mt-0">
                </div>
              </div>
              <div class="row mt-3 justify-content-center">※出入口が想定位置に設定されなかった場合、次の「接道及び出入口設定の確認」で変更してください。</div>
            </div>
          </div>
          <div class="modal-footer flex-column">
            <div v-if="errors.length" class="alert alert-danger d-flex align-items-center m-0 mb-2" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div><span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span></div>
            </div>
            <div class="d-flex mx-auto justify-content-center">
              <button class="btn btn-primary btn-lg w-100 col-3" @click="$_next">次へ</button>
              <button class="btn btn-secondary btn-lg w-100 col-3 ms-4" @click="$_cancel">キャンセル</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-block-message ref="modalBlockMessage" parent-id="modal-input-road-and-entrance-parent" />
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { initVolume } from '@/assets/js/dto/E_volume'
import ModalBlockMessage from '@/views/common/ModalBlockMessage'
import { directions } from '@/assets/js/code'
import { cmnCheckNumFormat, cmnChangeStrToNum, cmnDecimalFormat, cmnIsBlank, cmnMultiply } from "@/assets/js/common"
import { PAGE } from "@/assets/js/const"
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'

export default {
  name: 'ModalInputRoadAndEntrance',
  components:{
    ModalBlockMessage
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      entryPoint: null,
      paramsData: this.$store.state.params.data,
      listsLcond: this.$store.state.lists.l_cond_types,
      errors: [],
      roadList: []
    }
  },
  computed: {
    directionList: function () {
      return directions
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-input-road-and-entrance').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.errors = []
      this.$_setData()
      this.myModal = new Modal(document.getElementById('modal-input-road-and-entrance'), {})
      this.myModal.show()
    },
    /* データの設定 */
    $_setData() {
      this.roadList = this.directionList
      this.entryPoint = 0 // 進入箇所: 東側
      for (let i = 0, len = this.roadList.length; i < len; i++) {
        this.roadList[i].formatDistance = '0.00'
      }
    },
    /* 接道の成形 */
    $_formatDistance(idx) {
      const val = this.roadList[idx].formatDistance
      if (!cmnCheckNumFormat(val)) return
      this.roadList[idx].formatDistance = cmnDecimalFormat(cmnChangeStrToNum(val), 2)
    },
    /* 形式チェック, 業務チェック */
    $_validation() {
      // 形式チェック
      this.errors = []
      for (let i = 0, len = this.roadList.length; i < len; i++) {
        if (!cmnCheckNumFormat(this.roadList[i].formatDistance)) { 
          this.errors.push('数値で入力してください')
          break;
        }
      }
      // 業務チェック
      if(cmnIsBlank(this.roadList[this.entryPoint].formatDistance) || this.roadList[this.entryPoint].formatDistance === '0.00') {
        this.errors.push('進入箇所を設定した方位に接道を設置してください')
      } else {
        for (let i = 0, len = this.roadList.length; i < len; i++) {
          if (this.roadList[i].formatDistance < 0) { 
            this.errors.push('進入箇所を設定した方位に接道を設置してください')
            break;
          }
        }
      }
      if(!this.errors.length) {
        return true
      }
      return false
    },
    /* 状態ストア 接道設定 */
    $_setRoadW(direction){
      let val = cmnChangeStrToNum(this.roadList[direction].formatDistance)
      return val === null ? 0 : cmnMultiply(val, 1000)
    },
    /* [次へ]クリック */
    $_next(e) {
      e.preventDefault();
      // 形式チェック, 業務チェック
      if(!this.$_validation()) return
      // 画面の入力値を状態ストア
      let params = this.$store.state.params
      let lCondList = params.data.site_planning.l_cond.l_cond_data_list
      for (let i = 0, len = lCondList.length; i < len; i++) {
        let roadW = this.$_setRoadW(lCondList[i].direction)
        lCondList[i].l_cond_type = roadW !== 0 ? this.listsLcond[0].no : this.listsLcond[1].no
        lCondList[i].n_hei = 0
        lCondList[i].road_w = roadW
        lCondList[i].road_h1 = 0
        lCondList[i].road_h3 = 0
        lCondList[i].park_w = 0
        lCondList[i].water_w = 0
        lCondList[i].mtgr_w = 70000
        lCondList[i].s_flag = false
      }
      // console.log(lCondList)
      params.data.site_planning.entry_point = this.entryPoint
      this.$store.commit('setParams', params)
      // 接道と出入口登録サービス
      let volumes = this.$store.state.params.volume
      volumes = initVolume()
      volumes.calc_type = 0
      volumes.direction = this.entryPoint
      let payload = {
        l_cond: params.data.site_planning.l_cond,
        volume: volumes
      }
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch("setLCondGate", payload)
        .then((resultData) => { 
          if(resultData.status_code === "210200") {
            //計算完了
            let params = this.$store.state.params
            params.data.site_planning.deregulation = resultData.deregulation
            this.$store.commit('setParams', params)
            this.$store.dispatch('setViewerUrl', resultData)
            this.$store.dispatch('setListsForSite', resultData)
            // 共通処理
            this.$_updateCommon()
            // 状態ストア 遷移元画面を更新
            let prevPage = this.$store.state.prevPage
            prevPage.roadAndEntrance = PAGE.INPUT_ROAD_AND_ENTRANCE
            this.$store.commit('setPrevPage', prevPage)
            // モーダル閉じる、遷移処理
            this.myModal.hide()
            this.$refs.modalBlockMessage.close()
            this.$emit('next')
          } 
          if(resultData.status_code === "210299"){
            this.errors.push('登録処理が異常終了しました。')
            const messages = resultData.message.split('\n')
            const errMessage = messages.slice(1)
            for (let i = 0,len = errMessage.length;i < len;i ++) {
              this.errors.push(errMessage[i])
            }
            this.$refs.modalBlockMessage.close()
          }
          else {
            // 異常終了
            this.errors.push('登録処理が異常終了しました。')
            this.errors.push('再度、次へボタンより実行してください。')
            this.$refs.modalBlockMessage.close()
          }
        })
        .catch((e) => {
          console.log(e);
          this.$refs.modalBlockMessage.close()
        });
    },
    /* [キャンセル]クリック */
    $_cancel() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    },
    /* 共通処理 */
    $_updateCommon() {
      // プランデータ更新日更新, 利用時間登録
      this.$store.dispatch('updateProjectUpdateDate')
      this.$store.dispatch('updateUsersUsageTimes')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-input-width {
  width: 140px;
}
.drag-and-drop {
cursor: move;
z-index: 2000;
}
.drag {
z-index: 2001;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '../scss/layout.scss' 

//fontawesomeを利用
// todo あとで
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, far)
// Vue.component('font-awesome-icon', FontAwesomeIcon)

//createApp(App).use(store).use(router).use(VueAxios, axios).mount('#app')
createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')

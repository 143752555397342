<template>
  <div class="h-100">
    <div class="h-100 d-flex flex-column">
      <!-- <div class="m-2">
        <p class="fw-bold">トップ > マスタテンプレート管理</p>
      </div> -->
      <div class="d-grid p-2 mt-2">
        <button class="btn btn-primary" type="button">マスタテンプレート管理</button>
      </div>
    </div>
  </div>     
</template>

<script>
export default {
  name: 'InputSection'
}
</script>

<style lang="scss" scoped>
</style>
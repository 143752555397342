<template>
  <div id="modal-building-summary-parent">
    <div id="modal-building-summary" class="modal" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">建物概要</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-header alert-primary py-1">物件諸元</div>
              <div class="card-body p-2">
                <div class="container small">
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">プロジェクト名</div>
                    <div class="col-8 col-lg col-xl-7 border border-bottom-0 py-1">{{ planName }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">住所</div>
                    <div class="col-8 col-lg col-xl-7 border py-1">{{ address }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">法定延床面積</div>
                    <div class="col-4 col-lg-2 border-end text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.law_total_floor_area, 2) }} ㎡</div>
                    <div class="col-4 col-lg-2 border-end text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.law_total_floor_area * calcTuboRate, 2) }} 坪</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border border-bottom-0 alert-success py-1">容積対象面積</div>
                    <div class="col-4 col-lg col-xl-2 border border-bottom-0 text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.capacity_target_area, 2) }} ㎡</div>
                    <div class="col-4 col-lg col-xl-2 border border-bottom-0 text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.capacity_target_area * calcTuboRate, 2) }} 坪</div>
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">実容積率</div>
                    <div class="col-8 col-lg-2 col-xl-1 border border-bottom-0 text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.capacity_rate, 2) }} ％</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border border-bottom-0 alert-success py-1">建築面積</div>
                    <div class="col-4 col-lg col-xl-2 border border-bottom-0 text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.building_area, 2) }} ㎡</div>
                    <div class="col-4 col-lg col-xl-2 border border-bottom-0 text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.building_area * calcTuboRate, 2) }} 坪</div>
                    <div class="col-4 col-lg-3 col-xl-2 border border-bottom-0 alert-success py-1">実建蔽率</div>
                    <div class="col-8 col-lg-2 col-xl-1 border border-bottom-0 text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.building_coverage_rate, 2) }} ％</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">階数／高さ</div>
                    <div class="col-8 col-lg col-xl-7 border py-1">地上{{buildingSummary.floor}}階建 建物の高さ：GL+{{ $_fomatDecimalNumber(this.buildingSummary.height, 2) }}m</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">貸床面積</div>
                    <div class="col-4 col-lg-2 border text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.rental_floor_area, 2) }} ㎡</div>
                    <div class="col-4 col-lg-2 border text-end py-1">{{ $_fomatDecimalNumber(this.buildingSummary.rental_floor_area * calcTuboRate, 2) }} 坪</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">レンタブル比</div>
                    <div class="col-4 border py-1">{{ $_fomatDecimalNumber(this.buildingSummary.rental_floor_area, 2) }}㎡ / {{ $_fomatDecimalNumber(this.buildingSummary.construction_floor_area, 2) }}㎡ = {{ $_fomatDecimalNumber(this.buildingSummary.rentable_rate_rate, 2) }}%</div>
                    <div class="col col-xl-auto py-1">※レンタブル比 (%) = 賃貸可能面積 / 施工床面積</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">トラック台数合計</div>
                    <div class="col-2 col-xl-1 border-end text-end py-1">{{ buildingSummary.truck.count }} 台</div>
                    <div class="col-2 col-xl-1 border-end lh-lg d-flex align-items-center"><button
                        class="btn btn-sm btn-primary lh-1 w-100" @click="$refs.modalCarList.init()">詳細</button></div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">倉庫：トラック比率</div>
                    <div class="col-4 col-xl-4 border py-1">{{ $_fomatDecimalNumber(this.buildingSummary.rental_warehouse_area, 2) }}㎡ / {{ buildingSummary.truck.count }}台 = {{ $_fomatDecimalNumber(this.buildingSummary.truck_rate, 2) }}㎡/台</div>
                    <div class="col col-xl-auto py-1">※倉庫：トラック比率 (㎡/台) = 賃貸可能倉庫面積 / トラック台数合計（台）</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">事務所：倉庫比率</div>
                    <div class="col-4 border border-bottom-0 py-1">{{ $_fomatDecimalNumber(this.buildingSummary.rental_office_area, 2) }}㎡ / {{ $_fomatDecimalNumber(this.buildingSummary.rental_warehouse_area, 2) }}㎡ = {{ $_fomatDecimalNumber(this.buildingSummary.office_rate_by_warehouse, 2) }}%</div>
                    <div class="col col-xl-auto py-1">※事務所：倉庫比率 (%) = 賃貸可能事務所面積 / 賃貸可能倉庫面積</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">事務所：敷地面積比率</div>
                    <div class="col-4 border py-1">{{ $_fomatDecimalNumber(this.buildingSummary.rental_office_area, 2) }}㎡ / {{ $_fomatDecimalNumber(this.buildingSummary.site_area, 2) }}㎡ = {{ $_fomatDecimalNumber(this.buildingSummary.office_rate_by_site, 2) }}%</div>
                    <div class="col col-xl-auto py-1">※事務所：敷地面積比率 (%) = 賃貸可能事務所面積 / 敷地面積</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">空地：敷地面積比率</div>
                    <div class="col-4 border border-bottom-0 py-1">{{ $_fomatDecimalNumber(this.buildingSummary.open_space_area, 2) }}㎡ / {{ $_fomatDecimalNumber(this.buildingSummary.site_area, 2) }}㎡ = {{ $_fomatDecimalNumber(this.buildingSummary.open_space_rate, 2) }}%</div>
                    <div class="col col-xl-auto py-1">※空地：敷地面積比率 (%) = 空地 / 敷地面積</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border border-bottom-0 alert-success py-1">乗用車駐車場</div>
                    <div class="col-8 col-xl-4 border border-bottom-0 py-1">{{ fixedSentence }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border border-bottom-0 alert-success py-1">トラック待機駐車場</div>
                    <div class="col-8 col-xl-4 border border-bottom-0 py-1">{{ fixedSentence }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">垂直搬送機</div>
                    <div class="col-8 col-xl-4 border py-1">{{ fixedSentence }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">荷物用ELV</div>
                    <div class="col-8 col-xl-4 border py-1">{{ fixedSentence }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">緑化面積</div>
                    <div class="col-8 col-xl-4 border py-1">{{ fixedSentence }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">駐車場緑化面積</div>
                    <div class="col-8 col-xl-4 border py-1">{{ fixedSentence }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-3 col-xl-2 border alert-success py-1">不足緑化面積</div>
                    <div class="col-8 col-xl-4 border py-1">{{ fixedSentence }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-2">
              <div class="card-header alert-primary py-1">出力情報</div>
              <div class="card-body p-2">
                <span>{{buildingSummary.bluesky_slope}}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-sm btn-primary" @click="ok">OK</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-building-summary-parent" />
    <modal-car-list ref="modalCarList" :car="buildingSummary.truck"/>
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import ModalCarList from '@/views/warehouse/ModalCarList'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { initBuildingSummary } from '@/assets/js/dto/E_building_summary'
  import { cmnDecimalFormat } from '@/assets/js/common'
  import { CALC_TUBO_RATE } from '@/assets/js/const'
  import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'
  
  export default {
    name: 'ModalBuildingSummary',
    components: {
      ModalCarList,
      ModalBlockMessage
    },
    data() {
      return {
        project: this.$store.state.params.data,
        planName: null,
        address: null,
        buildingSummary: initBuildingSummary(),
        calcTuboRate: null,
        fixedSentence: null,
        dragElement: null
      }
    },
    mounted() {
      this.dragElement = document.getElementById('modal-building-summary').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
    },
    beforeUnmount() {
      drgRemoveEvent(this.dragElement)
    },
    methods: {
      /* 初期化 */
      init() {
        this.$_setData()
        this.myModal = new Modal(document.getElementById('modal-building-summary'), {})
        this.myModal.show()
      },
      /* データ設定 */
      async $_setData() {
        this.$refs.modalBlockMessage.init()
        await this.$store.dispatch('getBuildingSummary')
          .then((data)=>{
            this.buildingSummary = data.building_summary
            this.$refs.modalBlockMessage.close()
          })
          .catch(() => {
            this.$refs.modalBlockMessage.close()
          })
        this.planName = this.project.project_name
        this.address = this.project.address_pref + this.project.address_city + this.project.address_ooaza + this.project.address_aza
        this.calcTuboRate = CALC_TUBO_RATE
        this.fixedSentence = 'サブスク版では対応しておりません。'
      },
      /*小数の成形*/
      $_fomatDecimalNumber(num, digit) {
        return cmnDecimalFormat(num, digit)
      },
      /* OK */
      ok() {
        this.myModal.hide()
      },
      /* クローズ時の処理 */
      $_onClickCloseButton() {
        drgMouseUp()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table>:not(:first-child) {
    border-top: 0;
  }
  .row+.row {
    margin-top: -1px;
  }
  .row>div:not(.alert-success) {
    border-left: 0 !important;
  }
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }
  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div class="h-100">
    <output-section></output-section>
  </div>
</template>

<script>
import OutputSection from '@/views/fileOutput/OutputSection'

export default {
  name: "FileOutput",
  components: {
    OutputSection
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/style.scss";
</style>

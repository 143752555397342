<template>
  <div class="modal" id="modal-car-list" tabindex="-1" data-bs-backdrop="static" >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header drag-and-drop">
          <h5 class="modal-title">台数</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-sm" small>
            <thead>
              <tr>
                <th v-for="item in tableFields" :key="item.key" scope="col">{{ item.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tableItems" :key="index">
                <td>{{ item.type }}</td>
                <td>{{ item.count }}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sm btn-primary" @click="ok">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import { cmnIsNotBlank } from '@/assets/js/common'
  import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'
  import { fncAdjustModalLayer } from '@/assets/js/function'

  export default {
    name: 'ModalCarList',
    props: {
      car: {
        type: Object
      }
    },
    data() {
      return {
        dragElement: null,
        tableItems: [],
        tableFields: [
          { key: 'type', label: 'タイプ', thClass: 'font-weight-normal' },
          { key: 'count', label: '台数', thClass: 'font-weight-normal' }
        ]
      }
    },
    mounted() {
      this.dragElement = document.getElementById('modal-car-list').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
    },
    beforeUnmount() {
      drgRemoveEvent(this.dragElement)
    },
    methods: {
      /* 初期化 */
      init() {
        this.tableItems = []
        this.$_setData()
        this.myModal = new Modal(document.getElementById('modal-car-list'), {})
        this.myModal.show()
        fncAdjustModalLayer()
      },
      /* データ設定 */
      $_setData() {
        if(cmnIsNotBlank(this.car.type)) this.tableItems.push(this.car)
      },
      /* OK */
      ok() {
        this.myModal.hide()
      },
      /* クローズ時の処理 */
      $_onClickCloseButton() {
        drgMouseUp()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
<template>
  <div class="h-100">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">プラン選択</p>
        <p class="d-block mb-0">物件管理ID：{{ paramsData.project_no}}</p>
        <p class="d-block mb-0">プロジェクト名称：{{ paramsData.project_name}}</p>
      </div>
      <div class="my-overflow-y p-2">
        <p class="mt-3">プランを選択してください。</p>
      </div>
      <div class="mt-auto mx-2 d-grid gap-3">
        <button class="btn btn-primary mt-3" type="button" @click="$_prevPage">
          戻る
        </button>
      </div>
      <footer-section></footer-section>
    </div>
  </div>
</template>

<script>
import HeaderSection from "@/views/common/HeaderSection"
import FooterSection from "@/views/common/FooterSection"

export default {
  name: "InputSection",
  components: {
    HeaderSection,
    FooterSection,
  },
  data() {
    return {
      paramsData: this.$store.state.params.data,
    }
  },
  mounted() {
    this.$_init()
  },
  methods: {
    /* 初期化 */
    $_init() {
    },
    /* データ設定 */
    $_setData() {
    },
    /* [戻る]クリック */
    $_prevPage() {
      // 共通処理
      this.$_updateCommon()
      // 状態ストアの表示モード更新
      let payload = this.$store.state.settingButtons
      payload.warehouse = true
      this.$store.commit('setSettingButtons', payload)
      // 自動設計結果表示画面（条件変更モード）へ遷移
      this.$router.push({ path: "warehouse" });
    },
    /* 共通処理 */
    $_updateCommon() {
      // 利用時間登録
      this.$store.dispatch('updateUsersUsageTimes')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

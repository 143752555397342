<template>
  <div class="my-overflow-y container" id="user-sign-up">
    <div class="my-4">
      <img src="../assets/images/logo.png" style="width: 130px">
    </div>
    <h3 class="my-4 text-center">登録申請</h3>
    <messages ref="messages"></messages>
    <div class="container" v-show="hasValidPreRegistToken">
      <basic-user-info @paymentMethodChanged="setPaymentMethod" ref="basicUserInfo" :isSignUp="true"></basic-user-info>
      <div class="container mt-4" style="max-width:1020px;">
        <div v-if="paymentMethod === paymentMethodCredit" class="row">
          <div class="col-4"></div>
          <div class="col-8">
            <credit-info ref="creditInfo"></credit-info>
          </div>
        </div>
        <div class="row align-items-center mt-4">
          <div class="col-sm-4 ps-xl-5"><span>その他<br>特記事項など</span></div>
          <div class="col-sm-8"><textarea class="form-control form-control-sm p-1 m-0"
              style="height: 6rem;" v-model="specialNote" maxlength="512"/></div>
        </div>
      </div>
    
      <div class="mt-5 mx-auto text-center alert alert-success" style="max-width:750px;">
        <div class="align-items-center">
          <span>楽ロジのご利用には、</span>
          <button type="button" class="p-0 btn btn-link fw-bold" @click="$refs.modalTermsOfUse.init()">利用規約</button>
          <span>(クリックすると詳細が開きます)に同意していただく必要があります。</span>
        </div>
        <div>上記の内容をお読みいただき、同意いただける場合は「同意する」にチェックを入れて登録ボタンを押してください。</div>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-center">
        <div>利用規約に同意する</div>
        <input class="form-check-input ms-2" type="checkbox" value="" aria-label="..." v-model="checkedTerms" :disabled="!isCheckedTerms" style="padding:0.65rem;">
      </div>
      <div class="text-center my-5">
        
        <button class="btn btn-primary btn-lg" @click="$_registrationBtn()" :disabled="!checkedTerms">登録</button>
      </div>
    </div>
    <footer-section></footer-section>
    <!-- Modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="user-sign-up" />
    <modal-confirm-with-charge ref="modalConfirmWithCharge" :confirm-messages="confirmMessages" parent-id="user-sign-up" :payment-method="paymentMethod" @return-confirm-result="returnConfirmResult" :focus-no-btn="true" />
    <modal-terms-of-use ref="modalTermsOfUse" :is-agree-button-shown="true" @confirm-terms="$_confirmTerms" />
  </div>
</template>

<script>
  import FooterSection from '@/views/common/FooterSection'
  import BasicUserInfo from '@/views/registrationInfoCheck/BasicUserInfo'
  import CreditInfo from '@/views/registrationInfoCheck/CreditInfo'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import ModalConfirmWithCharge from '@/views/registrationInfoCheck/ModalConfirmWithCharge'
  import Messages from '@/views/common/Messages'
  import ModalTermsOfUse from '@/views/common/ModalTermsOfUse'
  import { ref } from 'vue'
  import { cmnHasBlank, cmnInsertDelimiter } from '@/assets/js/common'
  import { paymentMethodList, authorityList, textToCode} from '@/assets/js/code'
  import { RESULT_CODE, REGEXP, GMO_CREDIT_TOKEN_URL } from '@/assets/js/const'
  import { company } from '@/assets/js/dto/company'
  import { user } from '@/assets/js/dto/user'
  import { charge } from '@/assets/js/dto/charge'

  const PAYMENT_METHOD_CREDIT = textToCode(paymentMethodList, 'クレジットカード')
  const PAYMENT_METHOD_BILL = textToCode(paymentMethodList, '請求書払い')
  const CORPORATION_TYPE = 1
  const CHARGING_PLAN_ID = 1
  const PROCESSING_STATUS = 0

  export default {
    name: 'UserSignUp',

    components: {
      FooterSection,
      BasicUserInfo,
      CreditInfo,
      ModalBlockMessage,
      ModalConfirmWithCharge,
      Messages,
      ModalTermsOfUse
    },

    setup () {
      const basicUserInfo = ref(null)
      const creditInfo = ref(null)
      const modalConfirmWithCharge = ref(null)

      return {
        basicUserInfo,
        creditInfo,
        modalConfirmWithCharge
      }
    },

    data() {
      return {
        hasValidPreRegistToken: false,
        specialNote: '',
        paymentMethod: PAYMENT_METHOD_CREDIT,
        confirmMessages: [],
        isCheckedTerms:false,
        checkedTerms: false
      }
    },

    mounted() {
      const preRegistToken = this.$route.query.token
      this.$_getPreRegisteredEmail(preRegistToken)
      this.$_getPricesOfLicense()
      this.$_importGmoScript()
      .then(this.$_getShopId)
      .then(shopId => {
          window.Multipayment.init(shopId)
      })
    },

    beforeUnmount() {
      // 読み込んだ外部スクリプトを削除する
      const script = document.getElementById('script_gmo')
      if (script) {
        script.parentNode.removeChild(script)
      }
    },

    computed: {
      paymentMethodCredit: function() {
        return PAYMENT_METHOD_CREDIT
      },
      paymentMethodBill: function() {
        return PAYMENT_METHOD_BILL
      }
    },

    methods: {
      /* 仮登録トークンからメールアドレスを取得 */
      $_getPreRegisteredEmail(preRegistToken) {
        this.$refs.modalBlockMessage.init()
        const payload = {
          token: preRegistToken
        }
        this.$store.dispatch('validateEmail', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) {
              this.$refs.messages.addErrorMsg(res.message)
              this.hasValidPreRegistToken = false
              return
            }
            this.hasValidPreRegistToken = true
            this.$_setPreRegisteredEmail(res.email)
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          }) 
      },

      /* 取得した認証済みメールアドレスのセット */
      $_setPreRegisteredEmail(email) {
        this.basicUserInfo.info.supervisorEmail = email
      },

      /* ライセンス単価の取得 */
      $_getPricesOfLicense() {
        const payload = {
          charging_plan_id: CHARGING_PLAN_ID
        }
        this.$store.dispatch('getPricesOfLicense', payload)
          .then((res) => {
            if(res.message) {
              this.$refs.messages.addErrorMsg(res.message)
              return
            }
            this.$_setPricesOfLicense(res.priceExcludedTax, res.priceIncludedTax)
          })
          .catch((e) => {
            console.log(e)
          }) 
      },
      
      /* 取得したライセンス単価のセット */
      $_setPricesOfLicense(priceExcludedTax, priceIncludedTax) {
        this.basicUserInfo.info.priceExcludedTax = cmnInsertDelimiter((priceExcludedTax).toString(), ',', 3)
        this.basicUserInfo.info.priceIncludedTax = cmnInsertDelimiter((priceIncludedTax).toString(), ',', 3)
      },
      
      /* クレジット決済用GMOスクリプト読み込み */
      $_importGmoScript() {
        return new Promise((resolve, reject) => {
          try{
            const script = document.createElement('script')
            script.setAttribute('src', GMO_CREDIT_TOKEN_URL) 
            script.setAttribute('id', 'script_gmo')
            document.head.appendChild(script)

            // スクリプトの読み込みが完了したらresolve
            script.addEventListener('load', () => { resolve() })
          } catch(e) {
            reject(e)
          }
        })

      },

      /* GMOスクリプトのためのショップIDの取得 */
      async $_getShopId() {
        return await this.$store.dispatch('getShopId')
      },

      /* 決済手段の変更 */
      setPaymentMethod(val) {
        this.paymentMethod = val
      },
      
      /* 利用規約モーダル */
      $_confirmTerms(resultCode) {
        if(resultCode === RESULT_CODE.OK) {
          this.isCheckedTerms = true
          this.checkedTerms = true
        }
      },

      /* 登録ボタン */
      $_registrationBtn() {
        this.$refs.messages.clearAllMsg()
        if(this.$_validation()) {
          window.scrollTo(0, 0)
          return
        }

        this.confirmMessages = []
        this.confirmMessages.push('登録申請します。よろしいですか？')
        this.modalConfirmWithCharge.supervisorEmail = this.basicUserInfo.info.supervisorEmail
        this.modalConfirmWithCharge.numberOfLicenses = this.basicUserInfo.info.licenseNum
        this.modalConfirmWithCharge.caption = '申請後'
        
        /* モーダル表示の後にreturnConfirmResult()が呼ばれる */
        this.$refs.modalConfirmWithCharge.init()
      },
      
      /* 確認モーダル */
      returnConfirmResult(resultCode) {
        if(resultCode === RESULT_CODE.NG || this.checkedTerms === false) return
        this.$_registration()
      },

      /* 登録処理 */
      $_registration() {
        this.$refs.modalBlockMessage.init()
        if(this.paymentMethod === textToCode(paymentMethodList, 'クレジットカード')) {
          const cardInfo = {
            cardno: this.creditInfo.info.cardNumber,
            expire: this.creditInfo.info.year + this.creditInfo.info.month,
            securitycode: this.creditInfo.info.securityCode,
            holdername: this.creditInfo.info.holderName,
            tokennumber: '1',
          }
          this.$_getCreditToken(cardInfo)
        } else {
          this.$_executeRegistration()
        }
      },

      /* クレジットカード用トークンの取得 */
      $_getCreditToken(cardInfo) {
        // getTokenのコールバック関数でこのインスタンスを参照するため変数に格納
        const thisInstance = this
        // getTokenでは第2引数でグローバル関数をコールバックする必要があるので、グローバル無名関数を用意してその中でローカル関数を呼ぶ
        window.Multipayment.getToken(
          cardInfo, 
          function(res){ thisInstance.$_executeRegistration(res) 
        })
      },

      /* トークンがあれば受け取り＆登録申請の実行 */
      $_executeRegistration(response) {
        let payload = {
          company: new company,
          user: new user,
          charge: new charge,
          creditToken: null,
        }
        if(response) {
          // クレジットカード払いのときのみ
          if(response.resultCode !== '000') {
            window.scrollTo(0, 0)
            this.$refs.messages.addErrorMsg(`クレジットカードのトークン取得に失敗しました（エラーコード：${ response.resultCode }）。`)
            this.$refs.messages.addErrorMsg('クレジットカード情報が正しいかどうかご確認ください。')
            this.$refs.modalBlockMessage.close()
            return
          }
          payload.creditToken = response.tokenObject.token[0]
        }
        this.$_formatRegistartionInfo(payload.company, payload.user, payload.charge)

        this.$store.dispatch('sendRegistrationInfo', payload)
          .then((res) => {
            this.$refs.modalBlockMessage.close()
            if(res.message) {
              window.scrollTo(0, 0)
              return this.$refs.messages.addErrorMsg(res.message)
            }
            this.$_moveToRegistered()
          })
          .catch((e) => {
            this.$refs.modalBlockMessage.close()
            console.log(e)
          }) 
      },

      /* 登録情報を整形 */
      $_formatRegistartionInfo(company, user, charge) {
        company.companyName = this.basicUserInfo.info.companyName
        company.departmentName = this.basicUserInfo.info.departmentName
        company.supervisorName = this.basicUserInfo.info.supervisorName
        company.supervisorEmail = this.basicUserInfo.info.supervisorEmail
        company.telephoneNumber = this.basicUserInfo.info.telephoneNumber
        company.specialNote = this.specialNote
        company.numberOfLicenses = this.basicUserInfo.info.licenseNum
        company.corporationType = CORPORATION_TYPE
        
        user.userName = this.basicUserInfo.info.supervisorName
        user.userEmail = this.basicUserInfo.info.supervisorEmail
        user.password = this.basicUserInfo.info.password
        user.authority = textToCode(authorityList, '管理者')
        
        charge.paymentMethod = this.basicUserInfo.info.paymentMethod
        charge.billingPostalCode = this.basicUserInfo.info.billingPostalCode1 + '' + this.basicUserInfo.info.billingPostalCode2
        charge.billingAddress = this.basicUserInfo.info.billingAddress
        charge.chargingPlanId = CHARGING_PLAN_ID
        charge.numberOfLicensesCharged = this.basicUserInfo.info.licenseNum
        charge.chargeExcludedTax = 0
        charge.chargeIncludedTax = 0
        charge.processingStatus = PROCESSING_STATUS
      },

      /* 登録申請完了画面へ遷移 */
      $_moveToRegistered() {
				this.$router.push({ path: '/registered', name: 'Registered' })
			},

      /* バリデーション */
      $_validation() {
        let invalid = false
        
        /* 必須チェック */
        const basicUserInfoKeysRequired = ['companyName', 'departmentName', 'supervisorName', 'supervisorEmail', 'password', 'passwordConfirmation', 'telephoneNumber', 'billingPostalCode1', 'billingPostalCode2', 'billingAddress', 'licenseNum', 'paymentMethod']
        const creditInfoKeysRequired = ['cardNumber', 'month', 'year', 'holdersName', 'securityCode']
        if(this.paymentMethod === textToCode(paymentMethodList, 'クレジットカード')) {
          invalid = cmnHasBlank(this.basicUserInfo.info, basicUserInfoKeysRequired) ||
              cmnHasBlank(this.creditInfo.info, creditInfoKeysRequired)
          
        } else if(this.paymentMethod === textToCode(paymentMethodList, '請求書払い')) {
          invalid = cmnHasBlank(this.basicUserInfo.info, basicUserInfoKeysRequired)
        }
        
        if(invalid) {
          this.$refs.messages.addErrorMsg('未入力の必須項目があります。')
          return invalid
        }

        /* パスワード一致チェック */
        invalid = this.basicUserInfo.info.password !== this.basicUserInfo.info.passwordConfirmation
        if(invalid) {
          this.$refs.messages.addErrorMsg('パスワードが一致しません。')
          return invalid
        }

        /* パスワード文字数チェック */
        invalid = (this.basicUserInfo.info.password.length < 8 || this.basicUserInfo.info.password.length > 60)
        if (invalid) {
          this.$refs.messages.addErrorMsg('パスワードは8文字以上60文字以下に設定してください。')
          return invalid
        }

        /* パスワード要件チェック */
        invalid = !REGEXP.PASSWORD.test(this.basicUserInfo.info.password)
        if (invalid) {
          this.$refs.messages.addErrorMsg('パスワードには英大文字, 小文字, 数字の各文字種を1文字以上含むように設定してください。')
          return invalid
        }

        /* 電話番号文字チェック */
        invalid = !REGEXP.NUMBER.test(this.basicUserInfo.info.telephoneNumber)
        if (invalid) {
          this.$refs.messages.addErrorMsg('電話番号は数字で入力してください。')
          return invalid
        }

        /* ライセンス数文字チェック */
        invalid = !REGEXP.NUMBER.test(this.basicUserInfo.info.licenseNum)
        if (invalid) {
          this.$refs.messages.addErrorMsg('ライセンス数は数字で入力してください。')
          return invalid
        }

        /* ライセンス数チェック */
        invalid = (this.basicUserInfo.info.licenseNum < 1 || this.basicUserInfo.info.licenseNum > 999)
        if(invalid) {
          this.$refs.messages.addErrorMsg('ライセンス数は1以上999以下の数字を入力してください。')
          return invalid
        }

        /* 郵便番号チェック */
        invalid = (
          !REGEXP.POSTAL_CODE_1.test(this.basicUserInfo.info.billingPostalCode1) ||
          !REGEXP.POSTAL_CODE_2.test(this.basicUserInfo.info.billingPostalCode2)
        )
        if (invalid) {
          this.$refs.messages.addErrorMsg('郵便番号が正しく入力されていません。')
          return invalid
        }

        if(this.paymentMethod === textToCode(paymentMethodList, 'クレジットカード')) {
          /* クレジットカード番号チェック */
          invalid = (
            !REGEXP.NUMBER.test(this.creditInfo.info.cardNumber) ||
            !REGEXP.NUMBER.test(this.creditInfo.info.securityCode)
          )

          if (invalid) {
            this.$refs.messages.addErrorMsg('カード番号、セキュリティコードは数字で入力してください')
            return invalid
          }
        } 
        return invalid
      },
    }
  }
</script>

<style lang="scss" scoped>
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 1320px;
  }
  .my-bg-color {
    background: rgba(128, 128, 128, 0.3);
  }
</style>
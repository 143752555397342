/* 建物概要 */
class E_building_summary {
  constructor() {
    this.plan_capacity = 0
    this.plan_coverage = 0
    this.building_type = 0
    this.building_shape = 0
    this.law_total_floor_area = 0
    this.capacity_target_area = 0
    this.capacity_rate = 0
    this.building_area = 0
    this.building_coverage_rate = 0
    this.floor = 0
    this.height = 0
    this.rental_floor_area = 0
    this.rentable_rate_rate = 0
    this.construction_floor_area = 0
    this.truck = null
    this.truck_rate = 0
    this.rental_warehouse_area = 0
    this.office_rate_by_warehouse = 0
    this.rental_office_area = 0
    this.office_rate_by_site = 0
    this.site_area = 0
    this.open_space_rate = 0
    this.open_space_area = 0
    this.passenger_car_parking = null
    this.truck_waiting_parking = 0
    this.vertical_car_rier_machine = 0
    this.for_luggate_elv = 0
    this.green_area = null
    this.parking_green_area = 0
    this.is_parking_green = false
    this.parking_green_rate = 0
    this.lack_green_area = 0
    this.is_rooftop_wall_green = false
    this.rooftop_wall_green_area = 0
    this.bluesky_slope = ''
    this.area_table = null
    this.is_exception = 0
  }
}
/* 台数 */
class E_car_count {
  constructor() {
    this.type = ''
    this.count = 0;
  }
}
/* 緑化面積 */
class E_green_area {
  constructor() {
    this.area = 0
    this.rate = 0
    this.requied_green_area = 0
    this.requied_green_rate = 0
    this.green1 = 0
    this.green2 = 0
    this.green3 = 0
    this.parking_green = 0
    this.rooftop_wall_green = 0
    this.green1_multiply_rate = 0
    this.green2_multiply_rate = 0
  }
}
/* 面積表 */
class E_area_table {
  constructor() {
    this.header_name = null;
    this.floor_list = [];
  }
}
/* 面積表の各面積のヘッダ名 */
class E_area_table_header_name {
  constructor() {
    this.rental_floor_area1_name = ''
    this.rental_floor_area2_name = ''
    this.rental_floor_area3_name = ''
    this.capacity_target_area1_name = ''
    this.capacity_target_area2_name = ''
    this.capacity_target_area3_name = ''
    this.law_total_floor_area1_name = ''
    this.law_total_floor_area2_name = ''
    this.construction_floor_area1_name = ''
  }
}

/* 建物概要 初期化 */
let initBuildingSummary = function() {
  let buildingSummary = new E_building_summary()
  buildingSummary.truck = new E_car_count()
  buildingSummary.passenger_car_parking = new E_car_count()
  buildingSummary.green_area = new E_green_area()
  buildingSummary.area_table = initAreaTable()
  return buildingSummary
}

/* 面積表 初期化 */
let initAreaTable = function() {
  let areaTable = new E_area_table()
  areaTable.header_name = new E_area_table_header_name()
  return areaTable
}

export {
  initBuildingSummary,
  initAreaTable
}

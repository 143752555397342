<template>
  <div class="container" style="max-width:1020px;">
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>会社名</div>
      <div class="col-sm-8"><input class="form-control" type="text" maxlength="64"
          v-model="info.companyName"  /></div>
    </div>
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>部署名</div>
      <div class="col-sm-8"><input class="form-control" type="text" maxlength="64"
          v-model="info.departmentName"  /></div>
    </div>
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>担当者名</div>
      <div class="col-sm-8"><input class="form-control" type="text" maxlength="64"
          v-model="info.supervisorName"  />
      </div>
    </div>
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span :class="{invisible: isSignUp}" class="badge bg-danger me-3 p-2">必須</span>メールアドレス</div>
      <div  v-if="isSignUp" class="col-sm-8">
        <div class="col-4 p-0">{{  info.supervisorEmail }}</div>
      </div>
      <div v-else class="col-sm-8">
        <input class="form-control" type="email" maxlength="64" :disabled="isSignUp"
          v-model="info.supervisorEmail"  />
      </div>
    </div>
    <div v-if="isSignUp">
      <div class="row align-items-center justify-content-start mt-4">
        <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>パスワード</div>
        <div class="col-sm-8"><input class="p-1 m-0 form-control" type="password"
            v-model="info.password"  /></div>
      </div>
      <div class="row align-items-center justify-content-start mt-4">
        <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>パスワード確認</div>
        <div class="col-sm-8"><input class="p-1 m-0 form-control" type="password"
            v-model="info.passwordConfirmation"  /></div>
      </div>
    </div>
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>電話番号（ハイフンなし）</div>
      <div class="col-sm-3"><input class="form-control" type="text" maxlength="15"
          v-model="info.telephoneNumber"  />
      </div>
    </div>
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-4 ps-xl-5"><span><span class="badge bg-danger me-3 p-2">必須</span>郵便番号</span></div>
      <div class="col-4">
        <div class="d-flex align-items-center">
          <div class="pe-2"><input type="tel" class="form-control" v-model="info.billingPostalCode1" maxlength="3" style="width: 60px;"/></div>
          <div class="text-center"><span> - </span></div>
          <div class="ps-2"><input type="tel" class="form-control" v-model="info.billingPostalCode2" maxlength="4" style="width: 80px;"/></div>
        </div>
      </div>
      
    </div>
    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>住所</div>
      <div class="col-sm-8"><input class="form-control" type="text" maxlength="128"
          v-model="info.billingAddress"  />
      </div>
    </div>

    <div class="row align-items-center justify-content-start mt-4">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>ライセンス数</div>
      <div class="col-sm-8 d-flex align-items-center">
        <div class="me-3">
          <input class="form-control" style="width: 80px;" type="number" min="1" max="999"
              v-model="info.licenseNum" @change="$_showLicenseAlert()" />
        </div>
        <div style="font-size: 14px;">
          <div>月額 {{ info.priceExcludedTax }} 円（税込価格{{ info.priceIncludedTax }}円）/ 1ライセンス（初月無料）</div>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-start">
      <div class="col-sm-8 offset-4 my-2" style="font-size: 14px;">
        <span><button class="btn btn-link p-0 mb-1" v-if="isSignUp" style="font-size: 14px;" @click="$_moveToSctPage">特定商取引法に基づく表記</button></span>
      </div>
    </div>
    <div class="row align-items-center justify-content-start">
      <div class="col-xl-8 offset-xl-4" v-if="!isSignUp && showLicenseAlert">
        <div class="p-2 alert alert-warning text-dark" style="font-size:14px;">
        <div class= "my-1 ms-3">ライセンスを追加する場合は、<b>本日</b>から適用されます。料金は日割りされません。</div>
        <div class= "my-1 ms-3">ライセンスを減らす場合は、<b>来月</b>から適用されます。先にユーザのライセンスの解除を行ってください。</div>
        <div class= "my-1 ms-3">※ライセンスを減らした場合でも、当月登録した最大のライセンス数で課金されます。</div>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-start">
      <div class="col-xl-8 offset-xl-4 mb-2" v-if="!isSignUp">
        <charge-info ref="chargeInfo"></charge-info>
      </div>
    </div>
    <div class="row align-items-center justify-content-start mt-2">
      <div class="col-sm-4 ps-xl-5"><span class="badge bg-danger me-3 p-2">必須</span>決済手段</div>
      <div class="col-sm-8">
        <select class="form-select" v-model="info.paymentMethod" @change="$_changePaymentMethod()"  >
          <option v-for="item in paymentMethods" :key="item.code" :value="item.code">{{ item.text }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import ChargeInfo from '@/views/registrationInfoCheck/ChargeInfo'
  import { ref } from 'vue'
  import { paymentMethodList, textToCode } from '@/assets/js/code.js'

  export default {
    name: 'basic-user-info',

    components: {
      ChargeInfo,
    },
    props: {
      isSignUp: {
        type: Boolean,
      }
    },
    setup () {
      const chargeInfo = ref(null)

      return {
        chargeInfo,
      }
    },
    data() {
      return {
        info: {
          companyName: null,
          departmentName: null,
          supervisorName: null,
          supervisorEmail: null,
          telephoneNumber: null,
          billingPostalCode1: null,
          billingPostalCode2: null,
          billingAddress: null,
          password: null,
          passwordConfirmation: null,
          licenseNum: null,
          priceExcludedTax: null,
          priceIncludedTax: null,
          paymentMethod: textToCode(paymentMethodList, 'クレジットカード'),
        },
        showLicenseAlert: false,
      }
    },

    computed: {
      /* 決済手段のlistbox取得 */
      paymentMethods: function() {
        return paymentMethodList
      }
    },

    methods: {
      /* 決済手段の変更を親コンポーネントに伝える */
      $_changePaymentMethod() {
        this.$emit('paymentMethodChanged', this.info.paymentMethod)
      },

      /* ライセンス数のアラート表示 */
      $_showLicenseAlert() {
        this.showLicenseAlert = true
      },

      $_moveToSctPage() {
        const route = this.$router.resolve({ path: 'specified-commercial-transactions' })
        window.open(route.href, '_blank', 'noreferrer')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .invisible {
    visibility: hidden;
  }
</style>
<template>
  <div class="my-container">
    <div class="my-side-contents text-black">
      <input-section :prop-cnt="prjCnt" @set-show-type="setShowType"></input-section>
    </div>
    <div class="my-main-contents">
      <output-section ref="outputSection" @set-prj-cnt="setPrjCnt"></output-section>
    </div>
  </div>
</template>

<script>
import InputSection from '@/views/top/InputSection'
import OutputSection from '@/views/top/OutputSection'

export default {
  name: "Top",
  components: {
    InputSection,
    OutputSection
  },
  data() {
    return {
      prjCnt: null
    }
  },
  methods: {
    /* [登録件数]セット */
    setPrjCnt (cnt) {
      this.prjCnt = cnt
    },
    /* [すべてを表示]クリック, [お気に入りを表示]クリック */
    setShowType (payload) {
      this.$refs.outputSection.getPrjList(payload)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/style.scss";
.my-main-contents {
  overflow-y: auto;
}
</style>

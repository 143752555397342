<template>
  <div class="my-container">
    <div class="my-side-contents text-black">
      <input-section-select-plan></input-section-select-plan>
    </div>
    <div class=my-main-contents>
      <output-section-select-plan></output-section-select-plan>
    </div>
  </div>
</template>

<script>
import InputSectionSelectPlan from '@/views/warehouse/InputSectionSelectPlan'
import OutputSectionSelectPlan from '@/views/warehouse/OutputSectionSelectPlan'

export default {
  name: "WarehouseSelectPlan",
  components: {
    InputSectionSelectPlan,
    OutputSectionSelectPlan
  },
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/style.scss";

  .my-main-contents {
    overflow-y: auto;
  }
</style>

<template>
  <div name="modal-confirm-with-charge" class="modal" tabindex="-1" data-bs-keyboard="false" aria-labelledby="modal-confirm-with-charge">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="d-flex p-4 border-bottom" v-for="(message, index) in messages" :key="index">
          {{ message }}
        </div>
        <div class="modal-body">
          <div class="container">
            <messages ref="messages"></messages>
            <div class="mx-auto" style="max-width:600px;">
              <charge-info class="my-1" ref="chargeInfoCurrentMonth" :show-sct-link="falseValue"></charge-info>
              <charge-info class="my-1" ref="chargeInfoNextMonth" :show-sct-link="falseValue"></charge-info>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary" @click="$_yes" ref="yesBtn" :disabled="isDisabledYesBtn">はい</button>
          <button type="button" class="btn btn-sm btn-secondary" @click="$_no" ref="noBtn">いいえ</button>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { ref } from 'vue'
import ChargeInfo from '@/views/registrationInfoCheck/ChargeInfo'
import Messages from '@/views/common/Messages'
import { cmnIsBlank, cmnInsertDelimiter } from '@/assets/js/common'
import { RESULT_CODE, CREDIT_CARD_LIMIT } from '@/assets/js/const'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalConfirmWithCharge',

  components: {
    ChargeInfo,
    Messages,
  },

  props: {
    paymentMethod: {
      type: Number,
      default: 0,
    },
    confirmMessages: {
      type: Array,
    },
    parentId: {
      type: String,
      default: ''
    },
    focusNoBtn: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const chargeInfoCurrentMonth = ref(null)
    const chargeInfoNextMonth = ref(null)

    return {
      chargeInfoCurrentMonth,
      chargeInfoNextMonth
    }
  },
  data() {
    return {
      myModal: null,
      falseValue: false,
      companyId: null,
      caption: '',
      supervisorEmail: null,
      numberOfLicenses: null,
      isOverLimitForCreditCard: false,
      isDisabledYesBtn: false,
      messages: []
    }
  },
  watch: {
    confirmMessages: {
      handler: function(newVal) {
        this.messages = newVal
      },
      deep : true,
    }
  },
  methods: {
    /* 初期化 */
    init() {
      if (cmnIsBlank(this.parentId))
        this.myModal = new Modal(document.getElementsByName('modal-confirm-with-charge')[0], {})
      else
        this.myModal = new Modal(document.getElementById(this.parentId).querySelector('div[name="modal-confirm-with-charge"]'), {})

      this.$refs.messages.clearAllMsg()
      const payload = {
        company_id: this.companyId,
        supervisor_email: this.supervisorEmail,
        number_of_licenses: this.numberOfLicenses,
      }
      this.$store.dispatch('confirmChargeInfo', payload)
      .then((res) => {
        if(res.message) return this.$refs.messages.addErrorMsg(res.message)
        this.$_setChargeInfo(res)
        this.$_showOverLimitError()
        this.myModal.show()
        fncAdjustModalLayer()
        this.$_focusBtn()
      })
    },

    /* 情報のセット */
    $_setChargeInfo(chargeInfo) {
      const current = chargeInfo.chargeCurrentMonth
      const next = chargeInfo.chargeNextMonth

      this.chargeInfoCurrentMonth.info = {
        year: current.targetMonth.slice(0, 4),
        month: Number(current.targetMonth.slice(4, 6)),
        caption: this.caption,
        numberOfLicensesCharged: current.numberOfLicensesCharged,
        chargeIncludedTax: current.chargeIncludedTax,
        chargeExcludedTax: current.chargeExcludedTax,
        isFreeTrial: current.isFreeTrial,
      }

      this.chargeInfoNextMonth.info = {
        year: next.targetMonth.slice(0, 4),
        month: Number(next.targetMonth.slice(4, 6)),
        caption: this.caption,
        numberOfLicensesCharged: next.numberOfLicensesCharged,
        chargeIncludedTax: next.chargeIncludedTax,
        chargeExcludedTax: next.chargeExcludedTax,
        isFreeTrial: next.isFreeTrial,
      }

      // クレジット決済できる上限額を超えているかどうか判定
      this.isOverLimitForCreditCard = (
        current.chargeIncludedTax > CREDIT_CARD_LIMIT || 
        next.chargeIncludedTax > CREDIT_CARD_LIMIT)
    },

    /* クレジットカード払いでかつ上限額を超えている場合の処理 */
    $_showOverLimitError() {
      if(this.isOverLimitForCreditCard && this.paymentMethod === 1) {
        const chargeStr = cmnInsertDelimiter((CREDIT_CARD_LIMIT + 1).toString(), ',', 3)
        this.$refs.messages.addErrorMsg(`クレジットカードでは${chargeStr} 円以上の決済ができません。`)
        this.$refs.messages.addErrorMsg('ライセンス数を減らすか、請求書払いを選択してください。')

        this.isDisabledYesBtn = true
      } else {
        this. isDisabledYesBtn = false
      }
    },


    /* [はい]or[いいえ]にフォーカス */
    $_focusBtn() {
      if(this.focusNoBtn) {
        this.$refs.noBtn.focus()
      } else {
        this.$refs.yesBtn.focus()
      }
    },
    /* [はい]クリック */
    $_yes() {
      this.$emit('return-confirm-result', RESULT_CODE.OK)
      this.$_hide()
    },
    /* [いいえ]クリック */
    $_no() {
      this.$emit('return-confirm-result', RESULT_CODE.NG)
      this.$_hide()
    },
    /* モーダルクローズ */
    $_hide() {
      this.myModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
//-----------------------------------------------
// ドラッグ＆ドロップ
//-----------------------------------------------
let _drg_x = 0;
let _drg_y = 0;

//-----------------------------------------------
// モーダルヘッダでマウスが押された際の関数
//-----------------------------------------------
function drgModalHeaderMouseDown (e)  {
  const modal_elem = e.target.closest('.modal');
  if (modal_elem === null) return;

  //クラス名に .drag を追加
  modal_elem.classList.add("drag")

  //タッチデイベントとマウスのイベントの差異を吸収
  var event = null;
  if(e.type === "mousedown") {
      event = e;
  } else {
      event = e.changedTouches[0];
  }

  //要素内の相対座標を取得
  _drg_x = event.pageX - modal_elem.offsetLeft;
  _drg_y = event.pageY - modal_elem.offsetTop;
  //console.log("y=" + this.y)
  //ムーブイベントにコールバック
  document.body.addEventListener("mousemove", drgMouseMove, false);
  document.body.addEventListener("touchmove", drgMouseMove, false);
}

//-----------------------------------------------
//  マウスカーソルが動いたとき
//-----------------------------------------------
function drgMouseMove(e) {

  //ドラッグしている要素を取得
  var drag = document.getElementsByClassName("drag")[0];

  //同様にマウスとタッチの差異を吸収
  var event = null;
  if(e.type === "mousemove") {
      event = e;
  } else {
      event = e.changedTouches[0];
  }

  //フリックしたときに画面を動かさないようにデフォルト動作を抑制
  e.preventDefault();

  //マウスが動いた場所に要素を動かす
  drag.style.top = event.pageY - _drg_y + "px";
  drag.style.left = event.pageX - _drg_x + "px";

  //マウスボタンが離されたとき、またはカーソルが外れたとき発火
  document.body.addEventListener("mouseleave", drgMouseUp, false);
  document.body.addEventListener("touchleave", drgMouseUp, false);
  drag.addEventListener("mouseup", drgMouseUp, false);
  drag.addEventListener("touchend", drgMouseUp, false);
  
}

//-----------------------------------------------
//  マウスボタンが上がったとき
//-----------------------------------------------
function drgMouseUp() {
  const drag = document.getElementsByClassName("drag")[0];

  //イベントハンドラの消去
  document.body.removeEventListener("mousemove", drgMouseMove, false);
  document.body.removeEventListener("touchmove", drgMouseMove, false);
  document.body.removeEventListener("mouseleave", drgMouseUp, false);
  document.body.removeEventListener("touchleave", drgMouseUp, false);
  if( drag != null ){
    drag.removeEventListener("mouseup", drgMouseUp, false);
    drag.removeEventListener("touchend", drgMouseUp, false);
  }

  //クラス名 .drag も消す
  if( drag != null ){
    drag.classList.remove("drag");
  }
}


//-----------------------------------------------
// ドラッグイベントの作成
//-----------------------------------------------
function drgAddEvent (element)  {
  if (!element) return
  //マウスが要素内で押されたとき、又はタッチされたとき発火
  element.addEventListener("mousedown", drgModalHeaderMouseDown, false)
  element.addEventListener("touchstart", drgModalHeaderMouseDown, false)
}


//-----------------------------------------------
// ドラッグイベントの削除
//-----------------------------------------------
function drgRemoveEvent (element)  {
  _drg_x = 0
  _drg_y = 0
  if (!element) return
  element.removeEventListener("mousedown", drgModalHeaderMouseDown, false)
  element.removeEventListener("touchstart", drgModalHeaderMouseDown, false)
}

export { 
  drgModalHeaderMouseDown,
  drgMouseUp,
  drgAddEvent,
  drgRemoveEvent
}


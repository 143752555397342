<template>
  <div>
    <div id="modal-site-plan" class="modal" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content h-100">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">計画地地図</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body h-100">
            <div id="map" class="w-100 h-100"></div>
            <div>
              <img class="my-building-image" src="方位画像.png" />
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-sm btn-secondary" @click="$_changeSitePlan">計画地の変更</button>
            <button class="btn btn-sm btn-secondary" @click="$_cancel">キャンセル</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import Map from "ol/Map";
import View from "ol/View";
import { transform } from "ol/proj";
import Tile from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { Fill, Stroke, Style } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Select, Translate } from "ol/interaction";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom";
import {ScaleLine} from 'ol/control';
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'
import { cmnExRound } from '@/assets/js/common.js'

const WGS84 = 'EPSG:4326'  // WGS84緯度経度
const WEB_MERCATOR = 'EPSG:3857' //Web MercatorWeb Mercator

export default {
  name: "ModalSitePlan",
  data() {
    return {
      dragElement: null,
      map: null
    };
  },
  mounted() {
    this.dragElement = document.getElementById('modal-site-plan').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.myModal = new Modal(document.getElementById("modal-site-plan"), {});
      this.myModal.show();
      Promise.all([
        this.$_getCdsApiKey()
      ])
      .then(() => {
        this.$_getMaps(this.$store.state.authKey);
      })
    },
    /* 外部API(CDSAPI)認証キー取得 */
    $_getCdsApiKey() {
      return new Promise((resolve, reject) => {
        // Mapの認証キーを取得
         this.$store.dispatch('getCdsApiKey')
          .then(() => {
            resolve()
          })
          .catch((e) => {
            console.log(e)
            reject()
          })
      })
    },
    /* Map読み込み */
    $_getMaps(authkey) {
      document.getElementById('map').innerHTML = ''
      const level = 15; // Zoomレベル

      // 初期位置 - プロジェクトの住所から設定
      const centerPoints = this.$store.state.params.file_output.area_table_output.center_points
      const lon = centerPoints[0]
      const lat = centerPoints[1]
      
      // OpenLayersマップ
      this.map = new Map({
        target: "map",
        controls: [new ScaleLine],
        interactions: [],
        view: new View({
          center: transform([lon, lat], WGS84, WEB_MERCATOR),
          zoom: level,
        }),
      });

      let mouseWheelInt = new MouseWheelZoom();
      this.map.addInteraction(mouseWheelInt);

      // 地図
      let road = this.$_getRoad(authkey);
      for (var i = 0; i < road.length; i++) {
        this.map.addLayer(road[i]);
      }
      // 計画地
      var siteLayer = this.$_makeSiteLayer();
      this.map.addLayer(siteLayer)

      // 計画地移動
      const select = new Select({
        layers: [siteLayer],
        wrapX: false
      })
      var translate = new Translate({
        features: select.getFeatures(),
      })
      this.map.addInteraction(select)
      this.map.addInteraction(translate)
    },
    /* 地図の取得 */
    $_getRoad(authKey) {
      /* CDS URL及び基本パラメータ */
      const geospaceUrl = "http://cds.geospace.jp/images/bing/"
      const bingParam = "{z}/{y}/{x}?key=" + authKey

      /* 電子地図 */
      let roadTile = new Tile({
        title: "電子地図",
        type: "base",
        visible: true,
        source: new XYZ({
          url: geospaceUrl + "road/" + bingParam,
          crossOrigin: "Anonymous"
        })
      })
      return [roadTile]
    },
    /* 敷地レイヤー作成 */
    $_makeSiteLayer() {
      const siteLineList = this.$store.state.params.file_output.area_table_output.site_line_list
      
      let features = []
      for (let i = 0, len = siteLineList.length; i < len; i++) {
        features.push(
          {
            type: 'Feature',
            geometry: {
              type: 'MultiPolygon',
              coordinates: [
                [
                  siteLineList[i].points
                ]
              ]
            }
          }
        )
      }

      const GeojsonObject = {
        type: 'FeatureCollection',
        features: features
      };
      
      var Feature = new GeoJSON({
        featureProjection: WEB_MERCATOR
      }).readFeatures(GeojsonObject);

      var siteLayer = new Vector({
        visible: true,
        title: "siteLayer",
        source: new VectorSource({
          features: Feature
        }),
        style: vectorStyle
      });

      return siteLayer
    },
    /* 計画地の変更 */
    $_changeSitePlan() {
      const zoom_level = cmnExRound(this.map.getView().getZoom())
      
      // 敷地レイヤーのfeature取得
      let features = [];
      this.map.getLayers().forEach((layer) => {
        if(layer.get('title') === 'siteLayer'){
          features = layer.getSource().getFeatures()
          return false
        }
      });
      
      // ポリゴンを取得
      const geometry = features[0].getGeometry()
      const coordinates = geometry.getCoordinates()
      const polygons = coordinates[0]
      let siteLineList = []
      for (let i = 0, len = polygons.length; i < len; i++) {
        let points = []
        for (let j = 0, len2 = polygons[i].length; j < len2; j++) {
          points.push(transform(polygons[i][j], WEB_MERCATOR, WGS84))
        }
        siteLineList.push( {
          count: points.length,
          points: points
        })
      }
      //console.log('siteLineList', siteLineList)

      // ストアに保存
      let params = this.$store.state.params
      params.file_output.area_table_output.site_line_list = siteLineList;
      this.$store.commit('setParams', params)
      
      // 計画地地図作成処理を実行（再作成＆表示）
      this.$emit('set-image', zoom_level)

      this.myModal.hide()
    },
    /* Cancel */
    $_cancel() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}

// ポリゴンのスタイル
const vectorStyle = [
  new Style({
    stroke: new Stroke({
      color: "rgba(255, 0, 0, 1.0)",
      width: 4
    }),
    fill: new Fill({
      color: "rgba(193, 239, 193, 0.6)"
    })
  })
]
</script>

<style lang="scss" scoped>
#map {
  min-height: 60vh;
}
.my-building-image {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 80px;
  height: 80px;
}
.drag-and-drop {
cursor: move;
z-index: 2000;
}
.drag {
z-index: 2001;
}
</style>

<template>
  <div id="modal-adjust-floor-parent">
    <div class="modal" id="modal-adjust-floor" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header drag-and-drop">
            <h5 class="modal-title">断面調整</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
          </div>
          <div class="modal-body d-flex flex-column flex-lg-row container justify-content-lg-around">
            <div class="col-12 col-xl-4 col-lg-4 my-2">
              <div class="col-12">
                <select v-model="crossSection" class="form-select form-select-sm" @change="$_onChangeCrossSection">
                  <option v-for="item in crossSectionList" :key="item.no" :value="item.no">{{ item.name }}</option>
                </select>
              </div>
              <div class="mt-2 d-flex align-items-center">
                <div class="col-4">地上 :</div>
                <div class=" input-group input-group-sm">
                  <input v-model="number" class="form-control" type="text" data-old-value @focus="$_setOldValue" @blur="$_onBlurNumber"/>
                </div>
                <div class="col-2 ps-2">階</div>
              </div>
              <div class="mt-2 d-flex align-items-center">
                <div class="col-4">基準階高 :</div>
                <div class=" input-group input-group-sm">
                  <input v-model="height" class="form-control" type="text" data-old-value @focus="$_setOldValue" @blur="$_onBlurHeight" :disabled="isSetAllDisabled"/>
                </div>
                <div class="col-2 ps-2">mm</div>
              </div>
              <div class="mt-2 d-flex align-items-center">
                <div class="col-4">1階床高 :</div>
                <div class=" input-group input-group-sm">
                  <input v-model="floorHeight" class="form-control" type="text" data-old-value @focus="$_setOldValue" @blur="$_onBlurFloorHeight" :disabled="isSetAllDisabled"/>
                </div>
                <div class="col-2 ps-2">mm</div>
              </div>
              <div class="mt-2 d-flex align-items-center">
                <div class="col-4">梁下有効 :</div>
                <div class=" input-group input-group-sm">
                  <input v-model="underBeamsEffective" class="form-control" type="text" data-old-value @focus="$_setOldValue" @blur="$_onBlurUnderBeamsEffective" :disabled="isSetAllDisabled"/>
                </div>
                <div class="col-2 ps-2">mm</div>
              </div>
              <div class="mt-2">
                <div class="form-check">
                  <input class="form-check-input py-1" v-model="isSetAll" type="checkbox" id="check-set-all">
                  <label class="form-check-label" for="check-set-all">階高・梁下一括変更</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-6 col-lg-7 mt-4 mt-lg-2 mb-2">
              <table class="small table table-sm table-bordered align-middle mb-0">
                <thead>
                  <tr>
                    <td v-for="field in floorListFields" :key="field.key">{{ field.label }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in floorList" :key="item.number">
                    <td class=" my-w-10">{{ item.number }}</td>
                    <td class=" my-w-20"><input type="text" class="form-control form-control-sm p-1" v-model="item.height"
                      data-old-value @focus="$_setOldValue" @blur="$_onBlurFloorsHeightOrFloorHeight($event, index, 'height')" /></td>
                    <td class=" my-w-20"><input type="text" class="form-control form-control-sm p-1" v-model="item.under_beams_effective"
                      data-old-value @focus="$_setOldValue" @blur="$_onBlurFloorsUnderBeamsEffectiveOrHarisei($event, index, 'under-beams-effective')" /></td>
                    <td class=" my-w-20"><input type="text" class="form-control form-control-sm p-1" v-model="item.harisei"
                      data-old-value @focus="$_setOldValue" @blur="$_onBlurFloorsUnderBeamsEffectiveOrHarisei($event, index, 'harisei')" /></td>
                    <td class=" my-w-20"><input type="text" class="form-control form-control-sm p-1" v-model="item.floor_height"
                      data-old-value @focus="$_setOldValue" @blur="$_onBlurFloorsHeightOrFloorHeight($event, index, 'floor-height')" :disabled="item.number !== 1" /></td>
                    <td class=" my-w-10">{{ structure }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer flex-column">
            <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show w-lg-50 mx-auto mb-3 d-flex align-items-center" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div><span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span></div>
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-primary btn-sm mx-2" @click="$_ok">OK</button>
              <button class="btn btn-secondary btn-sm mx-2" @click="$_cancel">キャンセル</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-adjust-floor-parent" />
  </div>
</template>

<script>
  import { Modal } from 'bootstrap'
  import cloneDeep from 'lodash/cloneDeep'
  import { initBuildingEdit } from '@/assets/js/dto/E_building_edit'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { cmnAdd, cmnChangeNumToStr, cmnExFloor, cmnCheckNumFormat, cmnIsBlank, cmnIsNumValue } from '@/assets/js/common'
  import { DEFAULT_CROSS_SECTION, PAGE } from '@/assets/js/const'
  import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'
  import { fncAdjustModalLayer } from '@/assets/js/function'
  
export default {
  name: 'ModalAdjustFloor',
  components: {
      ModalBlockMessage
  },
  props: {
    parentPage: { type: Number },
    propFloorList: { type: Array }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      project: this.$store.state.params.data,
      crossSectionList: [],
      crossSection: null,
      number: null,
      height: null,
      floorHeight: null,
      underBeamsEffective: null,
      isSetAll: false,
      floorList: [],
      floorListFields: [
        { key: 'number', label: '階', thClass: 'font-weight-normal' },
        { key: 'height', label: '階高', thClass: 'font-weight-normal' },
        { key: 'under_beams_effective', label: '梁下有効', thClass: 'font-weight-normal' },
        { key: 'harisei', label: '梁成', thClass: 'font-weight-normal' },
        { key: 'floor_height', label: '床高', thClass: 'font-weight-normal' },
        { key: 'structure', label: '構造', thClass: 'font-weight-normal' }
      ],
      errors: []
    }
  },
  computed: {
    structure: function() {
      return DEFAULT_CROSS_SECTION.STRUCTURE
    },
    isSetAllDisabled: function() {
      return !this.isSetAll
    }
  },
  watch: {
    propFloorList: {
      handler: function(newPropFloorList) {
        this.floorList = cloneDeep(newPropFloorList)
      },
      deep : true,
    }
  },
  updated() {
    this.$nextTick(function () {
      this.dragElement = document.getElementById('modal-adjust-floor').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
    })
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      if (this.parentPage === PAGE.WAREHOUSE) {
        this.$_getAutoDesignList()
      }
      else {
        this.$_setData()
      }
      this.myModal = new Modal(document.getElementById('modal-adjust-floor'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* 自動設計リスト取得 */
    $_getAutoDesignList() {
      this.$refs.modalBlockMessage.init()
      this.$store.dispatch('getAutoDesignList')
        .then(() => {
          this.$_setData()
          this.$refs.modalBlockMessage.close()
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close()
        })
    },
    /* データ設定 */
    $_setData() {
      this.errors = []
      // 階高設定のリスト（先頭に空欄行あり）
      this.crossSectionList = [{ no: '', name: '', floor_list: []}].concat(this.$store.state.lists.cross_sections)
      // 初期値設定
      this.$_setDefaultValue()
      if (this.propFloorList !== null)
        this.floorList = cloneDeep(this.propFloorList)
      else
        this.floorList = cloneDeep(this.project.floor_list)
      // 数値の整形
      this.$_formatFloorListValue()
      this.number = Math.max.apply(null, this.floorList.map((item) => { return item.number }))
      const searchWord = this.number + '階'
      const findCrossSection = this.crossSectionList.find((item) => { return item.name.substring(0, searchWord.length) === searchWord})
      if (typeof findCrossSection === 'undefined')
        this.crossSection = ''
      else 
        this.crossSection = findCrossSection.no
    },
    /* 数値の整形 */
    $_formatFloorListValue(){
      for (let i = 0, len = this.floorList.length; i < len; i++) {
        this.floorList[i].height = cmnChangeNumToStr(this.floorList[i].height, 0, 2)
        this.floorList[i].under_beams_effective = cmnChangeNumToStr(this.floorList[i].under_beams_effective, 0, 2)
        this.floorList[i].harisei = cmnChangeNumToStr(this.floorList[i].harisei, 0, 2)
        this.floorList[i].floor_height = cmnChangeNumToStr(this.floorList[i].floor_height, 0, 2)
      }
    },
    // 一括設定部分の初期値設定 */
    $_setDefaultValue() {
      this.height = cmnChangeNumToStr(DEFAULT_CROSS_SECTION.HEIGHT, 0, 2)
      this.floorHeight = cmnChangeNumToStr(DEFAULT_CROSS_SECTION.FLOOR_HEIGHT, 0, 2)
      this.underBeamsEffective = cmnChangeNumToStr(DEFAULT_CROSS_SECTION.UNDER_BEAMS_EFFECTIVE, 0, 2)
    },
    /* 現在のテキストボックスの値をdata-valueにセット */
    $_setOldValue(evt) {
      const elem = evt.target
      elem.dataset.oldValue = elem.value
    },
    /* [階高設定]変更 */
    $_onChangeCrossSection() {
      if (cmnIsBlank(this.crossSection))
        return
      // 選択した階高設定で画面を再設定
      const selectedCrossSection = this.crossSectionList.find((item) => { return item.no === this.crossSection})
      this.number = selectedCrossSection.floor_list.length
      this.$_setDefaultValue()
      this.floorList = selectedCrossSection.floor_list
      this.$_formatFloorListValue()
    },
    /* [地上]変更 */
    $_onBlurNumber(evt) {
      const elem = evt.target
      if (elem.dataset.oldValue === elem.value) return
      // 1～30の整数値のみＯＫ（小数を入力された場合は切り捨てる）
      if (!cmnIsNumValue(this.number, 1, 30, false)) {
        this.number = elem.dataset.oldValue
        return
      }
      this.number = cmnExFloor(this.number)
      // [階リスト]の再作成
      this.$_makeFloorList()      
    },
    /* [階リスト]の作成*/
    $_makeFloorList() {
      if (this.floorList.length > this.number) {
        // [地上]の階が表示中の階数より小さいとき、不要な階を削除
        this.floorList.splice(0, this.floorList.length - this.number)
      } else if (this.floorList.length < this.number) {
        // [地上]の階が表示中の階数より大きいとき、不足している階を追加
        let addFloors = []
        const topFloorNumber = this.floorList.length
        // 最上階の値をコピーして階を追加
        for (let i = this.number; i > topFloorNumber; i--) {
          let floor = cloneDeep(this.floorList[0])
          floor.number = i
          if (this.isSetAll) {
          // 一括設定が有効な場合は追加の階の梁下有効と梁成は0 に書き換える
            floor.under_beams_effective = 0
            floor.harisei = 0
          }
          addFloors.push(floor)
        }
        this.floorList = addFloors.concat(this.floorList)
      }
      // 一括設定が有効な場合は[階リスト]-[階高]の一括設定
      if (this.isSetAll) {
        this.$_setFloorsHeight()
      }
      // 床高を再計算する
      this.$_calcFloorsFloorHeightAll()
    },
    // [基準階高]変更 */
    $_onBlurHeight(evt) {
      const elem = evt.target
      if (elem.dataset.oldValue === elem.value) return
      // 0～15000の数値のみＯＫ
      if (!cmnIsNumValue(this.height, 0, 15000, false)) {
        this.height = elem.dataset.oldValue
        return
      }
      this.height = cmnChangeNumToStr(this.height, 0, 2)
      // [階リスト]-[階高]の一括設定
      this.$_setFloorsHeight()
      // 床高を再計算する
      this.$_calcFloorsFloorHeightAll()
    },
    /* [階リスト]-[階高]の一括設定 */
    $_setFloorsHeight() {
      this.floorList.forEach((item) => {
        return item.height = this.height
      })
    },
    /* [1階床高]変更 */
    $_onBlurFloorHeight(evt) {
      const elem = evt.target
      if (elem.dataset.oldValue === elem.value) return
      // 0～3000の数値のみＯＫ
      if (!cmnIsNumValue(this.floorHeight, 0, 3000, false)) {
        this.floorHeight = elem.dataset.oldValue
        return
      }
      this.floorHeight = cmnChangeNumToStr(this.floorHeight, 0, 2)

      // 1階の[階リスト]-[床高]に [1階床高]の値をセット
      this.floorList[this.floorList.length - 1].floor_height = this.floorHeight
      // 床高を再計算する
      this.$_calcFloorsFloorHeightAll()
    },
    /* [梁下有効]変更 */
    $_onBlurUnderBeamsEffective(evt) {
      const elem = evt.target
      if (elem.dataset.oldValue === elem.value) return
      // 0～15000の数値のみＯＫ
      if (!cmnIsNumValue(this.underBeamsEffective, 0, 15000, false)) {
        this.underBeamsEffective = elem.dataset.oldValue
        return
      }
      this.underBeamsEffective = cmnChangeNumToStr(this.underBeamsEffective, 0, 2)
      
      // 1階～最上階の[階リスト]-[梁下有効] に [梁下有効]の値をセット
      this.floorList.forEach((item) => {
        return item.under_beams_effective = this.underBeamsEffective
      })
      // 1階～最上階の[階リスト]-[階高]を再計算
      for(let i = 0, len = this.floorList.length; i < len; i++)
        this.floorList[i].height = this.$_calcFloorsHeight(i)
      // 床高を再計算する
      this.$_calcFloorsFloorHeightAll()
    },
    /* [階リスト]-[階高]変更・[階リスト]-[床高]変更 */
    $_onBlurFloorsHeightOrFloorHeight(evt, idx, col) {
      const elem = evt.target
      if (elem.dataset.oldValue === elem.value) return
      if (cmnIsBlank(elem.value)) elem.value = 0
      if (col === 'height')
        this.floorList[idx].height = cmnChangeNumToStr(elem.value, 0, 2)
      else
        this.floorList[idx].floor_height = cmnChangeNumToStr(elem.value, 0, 2)
      // 床高を再計算する
      this.$_calcFloorsFloorHeightAll()
    },
    /* [階リスト]-[梁下有効]変更・[階リスト]-[梁成]変更 */
    $_onBlurFloorsUnderBeamsEffectiveOrHarisei(evt, idx, col) {
      const elem = evt.target
      if (elem.dataset.oldValue === elem.value) return
      if (cmnIsBlank(elem.value)) elem.value = 0
      if (col === 'under-beams-effective')
        this.floorList[idx].under_beams_effective = cmnChangeNumToStr(elem.value, 0, 2)
      else
        this.floorList[idx].harisei = cmnChangeNumToStr(elem.value, 0, 2)
      // n階に対して、処理-階高算出を実行
      this.floorList[idx].height = this.$_calcFloorsHeight(idx)
      // 床高を再計算する
      this.$_calcFloorsFloorHeightAll()
    },
    /* [階リスト]-[階高]算出 */
    $_calcFloorsHeight(idx) {
      // [階高]（n階） = [梁下有効]（n階）+ [梁成]（n階）
      if (!cmnCheckNumFormat(this.floorList[idx].under_beams_effective) || !cmnCheckNumFormat(this.floorList[idx].harisei))
        return null 
      return cmnChangeNumToStr(cmnAdd(Number(this.floorList[idx].under_beams_effective),  Number(this.floorList[idx].harisei)), 0, 2)
    },
    
    /* [階リスト]-[床高]算出 */
    $_calcFloorsFloorHeightAll() {
      // 2階～最上階の床高を再計算する
      // [床高]（n階） = [床高]（n-1階）+ [階高]（n-1階）
      for (let i = this.floorList.length - 2; i >= 0; i--) {
        if (!cmnCheckNumFormat(this.floorList[i + 1].floor_height) || !cmnCheckNumFormat(this.floorList[i + 1].height))  {
          this.floorList[i].floor_height = null
          continue;
        }
        this.floorList[i].floor_height = cmnChangeNumToStr(cmnAdd(Number(this.floorList[i + 1].floor_height),  Number(this.floorList[i + 1].height)), 0, 2)
      }
    },
    /* OK */
    $_ok() {
      if (!this.$_validation()) return

      if (this.parentPage === PAGE.WAREHOUSE) {
        // 自動設計結果表示画面からの起動時はengineへの登録処理
        let payload = { building_edit: initBuildingEdit() }
        payload.building_edit.calc_type = 1
        payload.building_edit.floor_list = this.floorList
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('setFloor', payload)
          .then((data) => {
            this.$emit('set-viewer-to-inputsection', data.plan_view_url)
            this.$store.dispatch('updateProjectUpdateDate')
            this.$store.dispatch('updateUsersUsageTimes')
            this.$refs.modalBlockMessage.close()
            this.myModal.hide()
          })
          .catch(() => {
            this.$refs.modalBlockMessage.close()
          })
      } else {
        // 元の画面へ階リストを返す
        this.$emit('set-floor-list', this.floorList)
        this.myModal.hide()
      }
    },
    /* Cancel */
    $_cancel() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    },
    /* バリデーション */
    $_validation() {
      this.errors = []
      let errorFlag = { height: false, under_beams_effective: false, harisei: false, floor_heifht: false}
      // 形式チェック
      for (let i = 0, len = this.floorList.length; i < len; i++) {
        if (!errorFlag.height && !cmnCheckNumFormat(this.floorList[i].height)) {
          this.errors.push('階高は数値で入力してください')
          errorFlag.height = true
        } 
        if (!errorFlag.under_beams_effective && !cmnCheckNumFormat(this.floorList[i].under_beams_effective)) {
          this.errors.push('梁下有効は数値で入力してください')
          errorFlag.under_beams_effective = true
        }
        if (!errorFlag.harisei && !cmnCheckNumFormat(this.floorList[i].harisei)) {
          this.errors.push('梁成は数値で入力してください')
          errorFlag.harisei = true
        }
        if (!errorFlag.floor_height && i === len - 1 && !cmnCheckNumFormat(this.floorList[i].floor_height)) {
          this.errors.push('床高は数値で入力してください')
          errorFlag.floor_height = true
        }
      }
      // 範囲チェック
      for (let i = 0, len = this.floorList.length; i < len; i++) {
        if (!errorFlag.height && !cmnIsNumValue(this.floorList[i].height, 0, 15000, false)) {
          this.errors.push('階高は0~15000の範囲で入力してください')
          errorFlag.height = true
        } 
        if (!errorFlag.under_beams_effective && !cmnIsNumValue(this.floorList[i].under_beams_effective, 0, 15000, false)) {
          this.errors.push('梁下有効は0~15000の範囲で入力してください')
          errorFlag.under_beams_effective = true
        }
        if (!errorFlag.harisei && !cmnIsNumValue(this.floorList[i].harisei, 0, 3000, false)) {
          this.errors.push('梁成は0~3000の範囲で入力してください')
          errorFlag.harisei = true
        }
        if (!errorFlag.floor_height && i === len - 1 && !cmnIsNumValue(this.floorList[i].floor_height, 0, 3000, false)) {
          this.errors.push('床高は0~3000の範囲で入力してください')
          errorFlag.floor_height = true
        }
      }
      return this.errors.length === 0
    }

  }
}
</script>

<style lang="scss" scoped>

td:nth-child(1),
td:nth-child(6) {
  text-align: center;
}
.my-w-10 {
  width: 10%;
}
.my-w-20 {
  width: 20%;
}
.drag-and-drop {
cursor: move;
z-index: 2000;
}
.drag {
z-index: 2001;
}
</style>

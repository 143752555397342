/* コード */
const baseSpanChangeTypes = [
  { code: 0, text: 'スパン調整' },
  { code: 1, text: 'スパン挿入' }
];
const buildingShapes = [
  { code: 0, text: 'BOX' },
  { code: 1, text: 'スロープ' },
  { code: 2, text: 'ランプ' }
];
const buildingTypes = [
  { code: 0, text: 'BTS' },
  { code: 1, text: 'マルチ' }
];
const changeSpanTypes = [
  { code: 0, text: '基準スパン変更' },
  { code: 1, text: '指定スパン変更' }
];
const directions = [
  { code: 0, text: '東側', gate: 0 }, 
  { code: 1, text: '南側', gate: 1 },
  { code: 2, text: '西側', gate: 0 },
  { code: 3, text: '北側', gate: 1 }
];
const hisashiLengths = [
  { length: 3 },
  { length: 4 },
  { length: 5 },
  { length: 10 },
  { length: 12 },
  { length: 0 }
];
const officeRates = [
  { rate: 5 },
  { rate: 6 },
  { rate: 7 },
  { rate: 8 },
  { rate: 9 },
  { rate: 10 }
];
const sortTypes = [
  { code: 1, text: '物件管理ID' },
  { code: 2, text: '更新日時' },
  { code: 3, text: 'お気に入り' }
];
const spanTypes = [
  { code: 0, text: '間口' },
  { code: 1, text: '奥行' }
];
const inquiryTypes = [
  { code: 1, text: '仕様確認' },
  { code: 2, text: '退会処理' },
  { code: 3, text: '契約に関して' }
];

/* コード(顧客管理用) */
const authorityList = [
  { code: 0, text: '一般'},
  { code: 1, text: '管理者'}
]

const paymentMethodList = [
  { code: 1, text: 'クレジットカード' },
  { code: 2, text: '請求書払い' }
]

const processingStatusList = [
  { code: 0, text: '未確定'},
  { code: 1, text: '完了'},
  { code: 9, text: '決済失敗'}
]

const resignedFlagList = [
  { code: 0, text: '会員' },
  { code: 1, text: '翌月退会' },
  { code: 2, text: '退会済'}
]

const statusList = [
  { code: 0, text: '未承認' },
  { code: 1, text: '承認済' },
  { code: 2, text: '変更承認待ち' },
  { code: 3, text: '否認' }
]

const supervisorFlagList = [
  { code: 0, text: '非担当者' },
  { code: 1, text: '担当者'}
]




//===============================================
// 2次元配列を指定した項目のハッシュに変換
//===============================================
//------------------------------------------
// 配列変換
// arr : 元の配列
// key : 生成した配列のkeyになる項目
// value : 生成した配列のvalueになる項目
//------------------------------------------
function cdChangeArrayToHash(arr, key, value) {
  var list = new Array();
  if (arr instanceof Array && arr.length > 0) {
  for (var i = 0, len = arr.length; i < len; i++)
  list[arr[i][key]] = arr[i][value];
  } else {
  list[''] = '';
  }
  return list;
  }

//===============================================
// codeからtextを取得
//===============================================
//------------------------------------------
// list: codeとtextの対応を定義するオブジェクト
// code: textに変換したいcodeの値
//------------------------------------------
function codeToText(list, code) {
  const found = list.find(elm => code === elm.code)
  return found.text
}

//===============================================
// textからcodeを取得
//===============================================
//------------------------------------------
// list: codeとtextの対応を定義するオブジェクト
// text: codeに変換したいtextの値
//------------------------------------------
function textToCode(list, text) {
  const found = list.find(elm => text === elm.text)
  return found.code
}

export {
  baseSpanChangeTypes,
  buildingShapes,
  buildingTypes,
  changeSpanTypes,
  directions,
  hisashiLengths,
  officeRates,
  sortTypes,
  spanTypes,
  inquiryTypes,
  
  authorityList,
  paymentMethodList,
  processingStatusList,
  resignedFlagList,
  statusList,
  supervisorFlagList,
  
  cdChangeArrayToHash,
  codeToText,
  textToCode,
}

<template>
  <div class="h-100 overflow-hidden" id="regulation-input-section">
    <div class="h-100 d-flex flex-column">
      <header-section></header-section>
      <div class="m-2">
        <p class="fw-bold">敷地 > 集団規定一括設定</p>
        <p class="d-block mb-0">物件管理ID：{{ this.project.project_no }}</p>
        <p class="d-block mb-0">プロジェクト名称：{{ this.project.project_name }}</p>
      </div>
      <div class="my-overflow-y p-2">
        <span>※の項目は個別設定</span>
        <div v-for="(item, index) in itemList.slice(0,4)" :key="item.no" class="mt-2">
          <span>【用途地域 {{ index + 1 }} 】</span>
          <hr class="my-1">
          <div class="container my-font px-0">
            <div class="row align-items-center">
              <div class="col-3 pe-0 me-2">用途地域</div>
              <div class="col-3 p-0 me-2">用途地域種</div>
              <div class="col-5 p-0 pe-1">
                <select v-model="item.usage.usage_no" class="form-select form-select-sm ps-1 pe-3 text-truncate"
                  @change="$_onChangeUsageParams( $event , index )">
                  <option v-for="usageCode in usageParams" :key="usageCode.usage_no" :value="usageCode.usage_no">
                    {{ usageCode.usage_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3"></div>
              <div class="col-3 p-0 mx-2">建蔽率</div>
              <div class="col-5 p-0 pe-1">
                <select v-model="item.usage.coverage" class="form-select form-select-sm ps-1 pe-3 text-truncate">
                  <option v-for="coverage in item.coverageList" :key="coverage" :value="coverage">
                    {{ $_decimalFormat(coverage, 2) }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3"></div>
              <div class="col-3 p-0 mx-2">容積率</div>
              <div class="col-5 p-0 pe-1">
                <select v-model="item.usage.capacity" class="form-select form-select-sm ps-1 pe-3 text-truncate">
                  <option v-for="capacity in item.capacityList" :key="capacity" :value="capacity">
                    {{ $_decimalFormat(capacity, 2) }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3"></div>
              <div class="col-3 p-0 mx-2">更新年</div>
              <div class="col-5 p-0 pe-1">
                <input type="text" class="w-100 form-control form-control-sm" v-model="item.usage.update_year" @blur="$_formatUpdateYear(index)">
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3"></div>
              <div class="col-3 p-0 mx-2">防火地域※</div>
              <div class="col-5 p-0 pe-1">
                <select v-model="item.usage.fire_no" class="form-select form-select-sm ps-1 pe-3 text-truncate" @change="$_onChangeFireTypes(index)">
                  <option v-for="fireType in fireTypes" :key="fireType.no" :value="fireType.no">
                    {{ fireType.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3 pe-0 me-2">高度地区</div>
              <div class="col-3 p-0 me-2">高度地区種※</div>
              <div class="col-5 p-0 pe-1">
                <select v-model="item.height.height_no" class="form-select form-select-sm ps-1 pe-3 text-truncate"
                  @change="$_onChangeHeightParams($event , index)">
                  <option v-for="height in heightParams" :key="height.height_no" :value="height.height_no">
                    {{ height.height_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3"></div>
              <div class="col-3 p-0 mx-2">更新日※</div>
              <div class="col-5 p-0 pe-1">
                <input type="text" class="w-100 form-control form-control-sm" v-model="item.formatUpdateDate" disabled>
              </div>
            </div>
            <div class="row mt-1 align-items-center">
              <div class="col-3 pe-0 me-2">日影規制</div>
              <div class="col-3 p-0 me-2">規制時間※</div>
              <div class="col-5 p-0 pe-1">
                <select v-model="item.sCond.c_high_type" class="form-select form-select-sm ps-1 pe-3 text-truncate">
                  <option v-for="shadow in shadowCalcTypes" :key="shadow.no" :value="shadow.no">
                    {{ shadow.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <hr class="my-1">
        </div>
      </div>
      <div class="d-grid gap-3 mt-auto mx-2 pt-3">
        <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show mx-auto mb-3" role="alert">
          <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
        </div>
        <button type="button" class="btn btn-primary" @click="$_apply">反映</button>
        <button type="button" class="btn btn-primary" @click="$_prevPage">戻る</button>
      </div>
      <footer-section></footer-section>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="regulation-input-section" :is-show-close-btn="false"/>
  </div>
</template>

<script>
  import HeaderSection from '@/views/common/HeaderSection'
  import FooterSection from '@/views/common/FooterSection'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { cmnChangeFormatDate, cmnIsBlank, cmnIsNumStr, cmnDecimalFormat, cmnChangeStrToNum } from '@/assets/js/common'
  const PREF_CODE_HOKKAIDO = '01'

  export default {
    name: 'InputSection',
    components: {
      HeaderSection,
      FooterSection,
      ModalBlockMessage
    },
    data() {
      return {
        project: this.$store.state.params.data,
        usageParams: [],
        fireTypes: [],
        heightParams: [],
        shadowCalcTypes: [],
        itemList: [],
        errors: [],
        message: []
      }
    },
    mounted() {
      this.$_init()
    },
    methods: {
      /* 初期化 */
      async $_init() {
        await this.$_getUsageParams()
        this.$_getFireTypes()
        this.$_getHeightParams()
        this.$_getShadowCalcTypes()
        this.$_setData()
      },
      /* 用途地域種のlistbox取得 */
      $_getUsageParams() {
        this.usageParams = this.$store.state.lists.usage_params
      },
      /* 防火地域のlistbox取得 */
      $_getFireTypes() {
        this.fireTypes = this.$store.state.lists.fire_types
      },
      /* 高度地区種のlistbox取得 */
      $_getHeightParams() {
        this.heightParams = this.$store.state.lists.height_params
      },
      /* 日影規制時間のlistbox取得 */
      $_getShadowCalcTypes() {
        if (this.project.address_code.substring(0, 2) === PREF_CODE_HOKKAIDO)
          this.shadowCalcTypes = this.$store.state.lists.shadow_calc_types[0]
        else
          this.shadowCalcTypes = this.$store.state.lists.shadow_calc_types[1]
      },
      /* データ設定 */
      $_setData() {
        let usageAreaList = this.project.site_planning.usage.usage_area_list
        let heightAreaList = this.project.site_planning.height.height_area_list
        let sCondAreaList = this.project.site_planning.s_cond.s_cond_area_list
        for (let i = 0, len = usageAreaList.length; i < len; i++) {
          let usageParam = this.usageParams.find((item)=> {
            return item.usage_no === usageAreaList[i].usage_no
          })
          let heightType = this.heightParams.find((item) => {
              return item.height_no === heightAreaList[i].height_no
            })
          this.itemList.push({
            usage: usageAreaList[i],
            height: heightAreaList[i],
            sCond: sCondAreaList[i],
            coverageList: usageParam !== null ? usageParam.coverage_list : [],
            capacityList: usageParam !== null ? usageParam.capacity_list : [],
            formatUpdateDate: heightType !== null ? this.$_formatHeightParamUpdateDate(heightType.update_date): null
          })
        }
      },
      /* 数値のフォーマット */
      $_decimalFormat(siteArea, digit) {
        return cmnDecimalFormat(siteArea, digit)
      },
      /* 更新年のフォーマット */
      $_formatUpdateYear(itemIndex){
        this.itemList[itemIndex].usage.update_year = cmnIsBlank(this.itemList[itemIndex].usage.update_year) ? 0 : this.itemList[itemIndex].usage.update_year
      },
      /* [用途地域種]変更 */
      $_onChangeUsageParams(evt, itemIndex) {
        const usageIndex = evt.target.selectedIndex
        this.itemList[itemIndex].usage.usage_name = this.usageParams[usageIndex].usage_name
        this.itemList[itemIndex].coverageList = this.usageParams[usageIndex].coverage_list
        this.itemList[itemIndex].usage.coverage = this.usageParams[usageIndex].coverage
        this.itemList[itemIndex].capacityList = this.usageParams[usageIndex].capacity_list
        this.itemList[itemIndex].usage.capacity = this.usageParams[usageIndex].capacity
        this.itemList[itemIndex].usage.capacity_reduction = this.usageParams[usageIndex].capacity_reduction
        this.itemList[itemIndex].usage.road_slant_line_slope = this.usageParams[usageIndex].road_slant_line_slope
        this.itemList[itemIndex].usage.adjust_slant_line_slope = this.usageParams[usageIndex].adjust_slant_line_slope
        this.itemList[itemIndex].usage.adjust_slant_line_height = this.usageParams[usageIndex].adjust_slant_line_height
      },
      /* [用途地域]-[防火地域※]変更 */
      $_onChangeFireTypes(itemIndex) {
        let fireName = this.fireTypes.find((item)=>{ if(this.itemList[itemIndex].usage.fire_no === item.no) return item.name })
        this.itemList[itemIndex].usage.fire_name = fireName.name
      },
      /* [高度地区種]変更 */
      $_onChangeHeightParams(evt, itemIndex) {
        const heightIndex = evt.target.selectedIndex
        this.itemList[itemIndex].formatUpdateDate = this.$_formatHeightParamUpdateDate(this.heightParams[heightIndex].update_date)
        this.itemList[itemIndex].height.height_name = this.heightParams[heightIndex].height_name
        this.itemList[itemIndex].height.h_type = this.heightParams[heightIndex].h_type
        this.itemList[itemIndex].height.h_line = this.heightParams[heightIndex].h_line
        this.itemList[itemIndex].height.h_case = this.heightParams[heightIndex].h_case
        this.itemList[itemIndex].height.a1 = this.heightParams[heightIndex].a1
        this.itemList[itemIndex].height.a2 = this.heightParams[heightIndex].a2
        this.itemList[itemIndex].height.b1 = this.heightParams[heightIndex].b1
        this.itemList[itemIndex].height.b2 = this.heightParams[heightIndex].b2
        this.itemList[itemIndex].height.b3 = this.heightParams[heightIndex].b3
        this.itemList[itemIndex].height.c1 = this.heightParams[heightIndex].c1
        this.itemList[itemIndex].height.c2 = this.heightParams[heightIndex].c2
        this.itemList[itemIndex].height.max_height = this.heightParams[heightIndex].max_height
      },
      /* [高度地区]-[更新日]のフォーマット */
      $_formatHeightParamUpdateDate(update_date) {
        if (cmnIsBlank(update_date))
          return '-'
        return cmnChangeFormatDate(new Date(Date.parse(update_date)), 'date', 'YYYY/MM/DD')
      },
      /* 反映 */
      $_apply() {
        if (!this.$_validation()) {
          return
        }
        let params = this.$store.state.params
        const c_period = params.data.address_code.substring(0, 2) === PREF_CODE_HOKKAIDO ? 6 : 8
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          params.data.site_planning.usage.usage_area_list[i] = this.itemList[i].usage
          params.data.site_planning.height.height_area_list[i] = this.itemList[i].height
          params.data.site_planning.s_cond.s_cond_area_list[i] = this.itemList[i].sCond
          params.data.site_planning.s_cond.s_cond_area_list[i].c_period = c_period
        }
        this.$store.commit('setParams', params)
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch('updateBuildingSite', { data: params.data })
        .then((returnData) => {
          let params = this.$store.state.params
          params.data.site_planning = returnData.data.site_planning
          this.$store.commit('setParams', params)
          this.$store.dispatch('updateProjectUpdateDate')
          this.$store.dispatch('updateUsersUsageTimes')
          this.$refs.modalBlockMessage.close()
          this.$router.push({ path: 'road-and-entrance' })
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close()
        })
      },
      /* 戻る */
      $_prevPage() {
        this.$store.dispatch('updateUsersUsageTimes')
        this.$router.push({ path: 'road-and-entrance' })
      },
      /* バリデーションチェック */
      $_validation() {
        this.errors = []
        let errorMessage = false
        for (let i = 0, len = this.itemList.length; i < len; i++) {
          if (!cmnIsNumStr(this.itemList[i].usage.update_year, 1, 4) && cmnChangeStrToNum(this.itemList[i].usage.update_year) !== 0) 
            errorMessage = true
        }
        if (errorMessage) {
          this.errors.push("更新年は数字の4桁で入力してください")
          return false
        }
        return true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-font {
    font-size: 14px;
  }
  .my-overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .form-select {
    background-position: right 0.20rem center;
  }
</style>
<template>
  <div class="output-section" style="width:100%;height:100%;">
    <iframe id="if-viewer" :src="propViewerUrl" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" style="border:none; width:100%; height:100%;" />
    <setting-btn setting-btn icon-color="text-white" :show-top-link="true"></setting-btn>
 </div>
</template>

<script>
import SettingBtn from '@/views/common/SettingBtn'

export default {
  name: "OutputSection",
  components: {
    SettingBtn
  },
  props: {
    propViewerUrl: {
      type: String
    }
  },
  methods: {
    /* viewer のリロード */
    reloadViewer() {
      document.getElementById('if-viewer').contentWindow.location.reload()
      
    }
  }
} 
</script>

<style lang="scss" scoped>
</style>

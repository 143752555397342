/* プロジェクト */
class E_project {
  constructor() {
    this.project_name = ''
    this.project_id = null
    this.project_no = 0
    this.template_no = 0
    this.is_site_area = false
    this.site_area = 0
    this.submission_date = null
    this.comment = ''
    this.program = ''
    this.version = ''
    this.address_code = ''
    this.address_pref = ''
    this.address_city = ''
    this.address_ooaza = ''
    this.address_aza = ''
    this.address_chiban = ''
    this.latitude = 0
    this.longitude = 0
    this.program_data_setting_item = null
    this.building_type = 0
    this.building_area = 0
    this.berth_type = null
    this.floor_list = []
    this.building_shape = null
    this.office_rate = 0
    this.site_planning = null
  }
}

export {
  E_project
}

<template>
  <div id="modal-project-parent">
    <div class="modal" id="modal-project" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex justify-content-between border-bottom drag-and-drop">
              <h5 class="pb-3 mb-0 lh-base">敷地概要</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$_onClickCloseButton"></button>
            </div>
            <div class="container mt-3">
              <div class="row align-items-center">
                <label class="col-12 col-lg-4 col-xl-3 lh-lg py-1">所在地<span
                    class="badge bg-danger lh-sm ms-3">必須</span></label>
                <div class="col-11 col-lg-7 col-xl-8">
                  <div class="row align-items-end">
                    <div class="col col-lg col-xl px-0 me-2">
                      <select class="form-select" v-model="prefCode" @change="$_onPrefChange"
                        :class="{'alert-danger': isErrPref}">
                        <option disabled value="">選択してください</option>
                        <option v-for="item in prefList" :key="item.adcd" :value="item.adcd">{{ item.kanjiPref }}
                        </option>
                      </select>
                    </div>
                    <div class="col col-lg col-xl px-0 me-xl-2">
                      <select class="form-select" v-model="cityCode" @change="$_onCityChange"
                        :class="{'alert-danger': isErrCity}">
                        <option disabled value="">選択してください</option>
                        <option v-for="item in cityList" :key="item.adcd" :value="item.adcd">{{ item.kanjiCity }}
                        </option>
                      </select>
                    </div>
                    <div class="col-12 col-xl px-0 mt-xl-0 mt-1">
                      <select class="form-select" v-model="azaCode" @change="$_onAzaChange">
                        <option value=""></option>
                        <option v-for="item in azaList" :key="item.adcd" :value="item.adcd">{{ item.kanjiOoaza + item.kanjiAza }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left"
                  title="リストより計画する住所を設定します。">
                    <font-awesome-icon icon="question-circle"/>
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-lg-3 mt-2">
                <label class="col-12 col-lg-4 col-xl-3 lh-lg py-1">敷地面積<span
                    class="badge bg-secondary lh-sm ms-3">任意</span></label>
                <div class="col-11 col-lg-7 col-xl-8 px-0">
                  <input type="number" class="form-control" v-model="project.site_area" placeholder="（計画地より算出）" @blur="$_formatSiteArea">
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-html="true"
                   title="顧客より受領した敷地面積を入力します。<br>「（計画地より算出）」とした場合、作成した敷地の面積を使用します。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-lg-3 mt-2">
                <label class="col-12 col-lg-4 col-xl-3 lh-lg py-1">プロジェクト名称<span
                    class="badge bg-secondary lh-sm ms-3">任意</span></label>
                <div class="col-11 col-lg-7 col-xl-8 px-0">
                  <input type="text" class="form-control" v-model="project.project_name" placeholder="（計画地より算出）" autocomplete="off" id="project-name" name="project-name">
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-html="true"
                  title="「（計画地より算出）」とした場合、所在地より名称を作成します。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
            </div>
            <h5 class="pb-3 lh-base border-bottom mt-4">建物計画要望</h5>
            <div class="container mt-3">
              <div class="row align-items-center">
                <label class="col-6 col-lg-4 col-xl-3 lh-lg">倉庫種類<span
                    class="badge bg-danger lh-sm ms-3">必須</span></label>
                <div class="col-5 col-lg-7 col-xl-8 px-0">
                  <select class="form-select" v-model="project.building_type"
                    :class="{'alert-danger': isErrBuildingType}">
                    <option :value=null disabled>選択してください</option>
                    <option v-for="item in buildingTypeList" :key="item.code" :value="item.code">{{ item.text }}
                    </option>
                  </select>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left" 
                  title="設計する倉庫の種類（BTS/マルチ）を設定します。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-3" v-if="isShowArea">
                <label class="col-6 col-lg-4 col-xl-3 lh-lg">面積<span
                    class="badge bg-secondary lh-sm ms-3">任意</span></label>
                <div class="col-5 col-lg-7 col-xl-8 pe-0">
                  <div class="row align-items-center">
                    <div class="col col-lg-2 px-0">
                      <input type="number" min="0" class="form-control col col-lg col-xl"
                        v-model="project.building_area" @blur="$_setBuildingAreaSizeTsubo" placeholder="0.00">
                    </div>
                    <span class="col-auto">㎡</span>
                    <div class="col col-lg-2 ms-2 px-0">
                      <input type="number" min="0" class="form-control col col-lg col-xl" v-model="buildingAreaTsubo"
                        @blur="$_setBuildingAreaSize" placeholder="0.00">
                    </div>
                    <span class="col-auto">坪</span>
                  </div>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" id="tooltip-building-area" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-html="true"
                  title="倉庫の容積対象面積を設定します。<br>倉庫種類がBTSのときのみ設定可能です。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <label class="col-6 col-lg-4 col-xl-3 lh-lg">バース種類<span
                    class="badge bg-secondary lh-sm ms-3">任意</span></label>
                <div class="col-5 col-lg-7 col-xl-8">
                  <div class="row">
                    <input type="text" class="form-control col col-lg col-xl" v-model="labelBerthType" disabled>
                    <button class="btn btn-primary col-auto col-lg-1 px-2 ms-2"
                      @click="$refs.modalInputCarApproach.init()">
                      ...
                    </button>
                  </div>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left" title="トラックの接車タイプを設定します。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <label class="col-6 col-lg-4 col-xl-3 lh-lg">階高設定<span
                    class="badge bg-secondary lh-sm ms-3">任意</span></label>
                <div class="col-5 col-lg-7 col-xl-8">
                  <div class="row">
                    <input type="text" class="form-control col col-lg col-xl" v-model="labelSection" disabled>
                    <button class="btn btn-primary col-auto col-lg-1 px-2 ms-2" @click="$refs.modalAdjustFloor.init()">
                      ...
                    </button>
                  </div>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left" title="倉庫の階数や階高を設定します。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <label class="col-6 col-lg-4 col-xl-3 lh-lg">形状<span class="badge bg-secondary lh-sm ms-3">任意</span>
                </label>
                <div class="col-5 col-lg-7 col-xl-8">
                  <div class="row">
                    <input type="text" class="form-control col col-lg col-xl" v-model="labelBuildingShape" disabled
                      placeholder="（計画地に合わせる）">
                    <button class="btn btn-primary col-auto col-lg-1 px-2 ms-2"
                      @click="$refs.modalInputBuildingShape.init()">
                      ...
                    </button>
                  </div>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-html="true"
                  title="倉庫の形状（BOX/スロープ/ランプ）を設定します。<br>「（計画地に合わせる）」とした場合、すべての形状が計算されます。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <label class="col-6 col-lg-4 col-xl-3 lh-lg">事務所比率（目安）<span
                    class="badge bg-secondary lh-sm ms-3">任意</span></label>
                <div class="col-5 col-lg-7 col-xl-8">
                  <div class="row align-items-center">
                    <div class="col col-lg-2 px-0">
                      <input type="text" class="form-control" list="officeRatioList" v-model="project.office_rate" placeholder="5" autocomplete="off" name="office-rate">
                      <datalist id="officeRatioList">
                        <option v-for="item in officeRatioList" :key="item.rate" :value="item.rate"></option>
                      </datalist>
                    </div>
                    <span class="col">%</span>
                  </div>
                </div>
                <div class="col-1 ps-3">
                  <span class="fs-5 text-info text-nowrap" data-bs-toggle="tooltip" data-bs-placement="left" title="倉庫に占める事務所の割合を設定します。">
                    <font-awesome-icon icon="question-circle" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-block">
            <div v-if="errors.length" class="alert alert-danger alert-dismissible fade show w-50 mx-auto mb-3"
              role="alert">
              <span class="d-block lh-base" v-for="error in errors" :key="error.index">{{ error }}</span>
            </div>
            <div class="col-4 mx-auto">
              <button type="button" class="btn btn-primary btn-lg w-100" @click="$_ok">始める</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-block-message ref="modalBlockMessage" parent-id="modal-project-parent" />
    <!-- <modal-search-address ref="modalSearchAddress" :prop-address="address" v-on:set-address="setAddress" /> -->
    <modal-input-car-approach ref="modalInputCarApproach" :prop-berth-type="project.berth_type"
      v-on:set-approach-type="setApproachType" />
    <modal-adjust-floor ref="modalAdjustFloor" @set-floor-list="setFloorList" 
      :parent-page="parentPage" :prop-floor-list="project.floor_list" />
    <modal-input-building-shape ref="modalInputBuildingShape" :prop-building-shape="project.building_shape"
      v-on:set-building-shape="setBuildingShape" />
  </div>
</template>

<script>
  import { Modal, Tooltip } from 'bootstrap'
  // import ModalSearchAddress from '@/views/project/ModalSearchAddress'
  import ModalInputCarApproach from '@/views/volume/ModalInputCarApproach'
  import ModalAdjustFloor from '@/views/warehouse/ModalAdjustFloor'
  import ModalInputBuildingShape from '@/views/project/ModalInputBuildingShape'
  import ModalBlockMessage from '@/views/common/ModalBlockMessage'
  import { E_berth_type } from '@/assets/js/dto/E_berth_type'
  import { initBuildingShape } from '@/assets/js/dto/E_building_shape'
  import { E_project } from '@/assets/js/dto/E_project'
  import { initSitePlanning } from '@/assets/js/dto/E_site_planning'
  import { project } from '@/assets/js/dto/project'
  import { buildingTypes, officeRates, buildingShapes } from '@/assets/js/code'
  import { cmnDecimalFormat, cmnIsBlank, cmnCheckNumFormat, cmnMultiply, cmnDivide, cmnChangeStrToNum } from '@/assets/js/common'
  import { CALC_TUBO_RATE, PAGE, BERTH_TYPE_LABEL, CROSS_SECTION_LABEL_SUFFIX } from '@/assets/js/const'
  import { drgAddEvent, drgRemoveEvent, drgMouseUp  } from '@/assets/js/draggable'
  import { fncMakeProjectName } from '@/assets/js/function'

  export default {
    name: 'ModalProject',
    components: {
      // ModalSearchAddress,
      ModalInputCarApproach,
      ModalAdjustFloor,
      ModalInputBuildingShape,
      ModalBlockMessage
    },
    data() {
      return {
        myModal: null,
        dragElement: null,
        myTooltip: null,
        project: {},
        prefList: [],
        cityList: [],
        azaList: [],
        prefCode: null,
        cityCode: null,
        azaCode: null,
        selectedPref: null,
        selectedCity: null,
        selectedAza: null,
        buildingAreaTsubo: null,
        labelBerthType: null,
        labelSection: null,
        labelBuildingShape: null,
        errors: [],
        isCreate: false,
      }
    },
    computed: {
      address: function () {
        return {
          pref: !this.selectedPref || this.selectedPref === null ? '' : this.selectedPref.kanjiPref,
          city: !this.selectedCity || this.selectedCity === null ? '' : this.selectedCity.kanjiCity,
          aza: !this.selectedAza || this.selectedAza === null ? '' : this.selectedAza.kanjiOoaza + this.selectedAza.kanjiAza
        }
      },
      buildingTypeList: function () {
        return buildingTypes
      },
      officeRatioList: function () {
        return officeRates
      },
      parentPage: function () {
        return PAGE.PROJECT
      },
      isErrBuildingType: function () {
        return cmnIsBlank(this.project.building_type)
      },
      isShowArea: function () {
        return this.project.building_type === 0
      },
      isErrPref: function () {
        return cmnIsBlank(this.prefCode)
      },
      isErrCity: function () {
        return cmnIsBlank(this.cityCode)
      }
    },
    watch: {
      isShowArea: function(val) {
        if(val) {
          this.$nextTick(function() {
            this.myTooltip = new Tooltip(document.getElementById('tooltip-building-area'))
          })
        }
      }
    },
    mounted() {
      this.dragElement = document.getElementById('modal-project').querySelector('.drag-and-drop')
      drgAddEvent(this.dragElement)
      this.$_getPrefList()
    },
    beforeUnmount() {
      drgRemoveEvent(this.dragElement)
    },
    methods: {
      /* 初期化 */
      init() {
        this.$_setData()
        this.myTooltip = Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
        this.myModal = new Modal(document.getElementById('modal-project'), {})
        this.myModal.show()
      },
      /* 住所1の取得 */
      $_getPrefList() {
        let params = { adcd: null, getLevel: 1 }
        this.$store.dispatch('addressDirectory', params)
          .then((list) => {
            this.prefList = list
          })
      },
      /* データの設定 */
      async $_setData() {
        this.project = this.$store.state.params.data
        if (this.project === null) {
          this.project = new E_project()
          this.project.building_type = null
          this.project.berth_type = new E_berth_type()
          this.project.berth_type.is_one_side = true
          const crossSections = this.$store.state.lists.cross_sections
          const defaultCrossSection = crossSections.find((item) => {
            return item.floor_list.length === 4
          })
          this.project.floor_list = defaultCrossSection.floor_list
          this.project.building_shape = initBuildingShape()
          this.project.office_rate = ''
          this.isCreate = true
          this.prefCode = ''
          this.cityCode = ''
          this.azaCode = ''
          const addr = {
            prefCode: this.prefCode,
            cityCode: this.cityCode,
            azaCode: this.azaCode
          }
          this.setAddress(addr)
        } else {
          const addr = {
            prefCode: this.project.address_code.substring(0, 2),
            cityCode: this.project.address_code.substring(0, 5),
            azaCode: this.project.address_code.length > 5 ? this.project.address_code : ''
          }
          this.setAddress(addr)
        }
        this.project.site_planning = initSitePlanning()
        this.$_formatSiteArea()
        if(this.project.building_area === 0) this.project.building_area = null
        this.setApproachType(this.project.berth_type)
        this.setFloorList(this.project.floor_list)
        this.setBuildingShape(this.project.building_shape)
        this.errors = []
      },
      /* 都道府県変更 */
      async $_onPrefChange() {
        this.cityCode = ''
        this.azaCode = ''
        this.azaList = []
        if (cmnIsBlank(this.prefCode)) {
          this.cityList = []
          this.selectedPref = null
        } else {
          let params = { adcd: this.prefCode, getLevel: 2 }
          this.$refs.modalBlockMessage.init()
          await this.$store.dispatch('addressDirectory', params)
            .then((list) => {
              this.cityList = list
              this.$refs.modalBlockMessage.close()
            })
          this.selectedPref = this.prefList.find((item) => {
            return item.adcd === this.prefCode
          })
        }
      },
      /* 市区町村変更 */
      async $_onCityChange() {
        this.azaCode = ''
        if (cmnIsBlank(this.cityCode)) {
          this.azaList = []
          this.selectedCity = null
        } else {
          let params = { adcd: this.cityCode, getLevel: 3 }
          this.selectedCity = this.cityList.find((item) => {
            return item.adcd === this.cityCode
          })
          this.selectedAza = null
          this.$refs.modalBlockMessage.init()
          await this.$store.dispatch('addressDirectory', params)
            .then((list) => {
              this.azaList = list
              this.$refs.modalBlockMessage.close()
            })
        }
      },
      /* 住所3変更 */
      $_onAzaChange() {
        if (cmnIsBlank(this.azaCode))
          this.selectedAza = null
        else {
          this.selectedAza = this.azaList.find((item) => {
            return item.adcd === this.azaCode
          })
        }
      },
      /* 住所の設定 */
      async setAddress(addr) {
        this.prefCode = addr.prefCode
        await this.$_onPrefChange()
        this.cityCode = addr.cityCode
        await this.$_onCityChange()
        this.azaCode = addr.azaCode
        await this.$_onAzaChange()
      },
      /* 敷地面積フォーマット */
      $_formatSiteArea() {
        this.project.site_area = this.project.site_area === 0 ? '' : cmnDecimalFormat(cmnChangeStrToNum(this.project.site_area), 2)
      },
      /* BTS面積の設定 */
      $_setBuildingAreaSize() {
        this.project.building_area = cmnDivide(this.buildingAreaTsubo, CALC_TUBO_RATE, -2)
        this.buildingAreaTsubo = cmnDecimalFormat(this.buildingAreaTsubo, 2)
      },
      $_setBuildingAreaSizeTsubo() {
        this.project.building_area = cmnDecimalFormat(this.project.building_area, 2)
        this.buildingAreaTsubo = cmnDecimalFormat(cmnMultiply(this.project.building_area, CALC_TUBO_RATE), 2)
      },
      /* 接車タイプの設定 */
      setApproachType(berthTypes) {
        let types = []
        if (berthTypes.is_one_side) types.push(BERTH_TYPE_LABEL.ONE_SIDE)
        if (berthTypes.is_both_side) types.push(BERTH_TYPE_LABEL.BOTH_SIDE)
        if (berthTypes.is_l_shape) types.push(BERTH_TYPE_LABEL.L_SHAPE)
        if (berthTypes.is_center) types.push(BERTH_TYPE_LABEL.CENTER)
        if (berthTypes.is_center_one_side) types.push(BERTH_TYPE_LABEL.CENTER_ONE_SIDE)
        if (berthTypes.is_center_both_side) types.push(BERTH_TYPE_LABEL.CENTER_BOTH_SIDE)
        this.labelBerthType = types.join('/')
        this.project.berth_type = berthTypes
      },
      /*階高設定*/
      setFloorList(floors) {
        this.project.floor_list = floors
        this.labelSection = floors.length + CROSS_SECTION_LABEL_SUFFIX
      },
      /* 建物形状の設定 */
      setBuildingShape(buildingShape) {
        let shapes = []
        if (buildingShape.is_box) shapes.push(buildingShapes[0].text)
        if (buildingShape.is_slope) shapes.push(buildingShapes[1].text)
        if (buildingShape.is_ramp) shapes.push(buildingShapes[2].text)
        this.labelBuildingShape = shapes.join('/')
        this.project.building_shape = buildingShape
      },
      /* OK */
      $_ok() {
        if (!this.$_validation()) {
          return
        }
        if (this.selectedAza === null) {
          this.project.address_code = this.selectedCity.adcd
          this.project.address_pref = this.selectedCity.kanjiPref
          this.project.address_city = this.selectedCity.kanjiCity
          this.project.address_ooaza = ''
          this.project.address_aza = ''
          // latitude, longitude はバックエンドにて設定
        } else {
          this.project.address_code = this.selectedAza.adcd
          this.project.address_pref = this.selectedAza.kanjiPref
          this.project.address_city = this.selectedAza.kanjiCity
          this.project.address_ooaza = this.selectedAza.kanjiOoaza
          this.project.address_aza = this.selectedAza.kanjiAza
          // latitude, longitude はバックエンドにて設定
        }
        const addressName = this.project.address_pref + this.project.address_city + this.project.address_ooaza + this.project.address_aza
        if (cmnIsBlank(this.project.project_name))
          this.project.project_name = fncMakeProjectName(addressName)
        if (!this.project.building_shape.is_box && !this.project.building_shape.is_slope && !this.project.building_shape.is_ramp) {
          this.project.building_shape.is_box = true
          this.project.building_shape.box.is_hisashi = false
          this.project.building_shape.box.hisashi_length = 10
          this.project.building_shape.is_slope = true
          this.project.building_shape.slope.is_one_two = true
          this.project.building_shape.slope.is_one_three = true
          this.project.building_shape.is_ramp = true
          this.project.building_shape.ramp.is_single = true
          this.project.building_shape.ramp.is_double = true
        }

        const user = this.$store.state.user
        this.project.template_no = 1
        this.project.is_site_area = cmnIsBlank(this.project.site_area)
        this.project.submission_date = new Date()
        if(cmnIsBlank(this.project.site_area)) this.project.site_area = 0
        if (this.project.building_type !== 0) this.project.building_area = 0
        else if(cmnIsBlank(this.project.building_area)) this.project.building_area = 0
        if(cmnIsBlank(this.project.office_rate)) this.project.office_rate = 5
        else this.project.office_rate = cmnChangeStrToNum(this.project.office_rate)
        let eData = { data: this.project }
        let dbData = new project()
        dbData.companyId = user.companyId
        dbData.userId = user.userId
        dbData.projectName = this.project.project_name
        dbData.addressCode = this.project.address_code
        dbData.address = addressName
        const url = this.isCreate ? "createProjectData" : "changeProjectData"
        if(!this.isCreate) dbData.projectNo = this.project.project_no
        let payload = {
          engine: eData,
          db: dbData
        }
        this.$refs.modalBlockMessage.init()
        this.$store.dispatch(url, payload)
          .then(() => {
            // 共通処理
            this.$_updateCommon()
            this.$refs.modalBlockMessage.close()
            this.$emit('next-page')
            this.myModal.hide()
          }).catch((e) => {
            console.log(e)
            this.$refs.modalBlockMessage.close()
          })
      },
      /* バリデーションチェック */
      $_validation() {
        this.errors = []
        if (cmnIsBlank(this.prefCode) || cmnIsBlank(this.cityCode) || cmnIsBlank(this.project.building_type)) {
          this.errors.push("必須項目を選択してください")
        }
        if(!cmnCheckNumFormat(this.project.site_area)) this.errors.push("敷地面積は数値で入力してください")
        if (this.isShowArea) {
          if (!cmnCheckNumFormat(this.project.building_area) || !cmnCheckNumFormat(this.buildingAreaTsubo)) {
            this.errors.push("面積は数値で入力してください")
          }
        }
        if(!cmnCheckNumFormat(this.project.office_rate)) this.errors.push("事務所比率（目安）は数値で入力してください")
        if(!cmnIsBlank(this.project.office_rate)) {
          if (cmnChangeStrToNum(this.project.office_rate) < 1 || cmnChangeStrToNum(this.project.office_rate) > 20) {
            this.errors.push("事務所比率（目安）は1から20の間で入力してください")
          }
        }
        if (!this.errors.length) {
          return true
        }
        return false
      },
      /* Cancel */
      $_cancel() {
        this.myModal.hide()
      },
      /* クローズ時の処理 */
      $_onClickCloseButton() {
        drgMouseUp()
      },
      /* 共通処理 */
      $_updateCommon() {
        // プランデータ更新日更新
        this.$store.dispatch('updateProjectUpdateDate')
        // 利用時間登録
        this.$store.dispatch('updateUsersUsageTimes')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .text-danger.small+* {
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }

  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
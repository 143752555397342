import { E_berth_type } from '@/assets/js/dto/E_berth_type'

/* 倉庫設計 */
class E_auto_design {
  constructor() {
    this.building_base_setting = null;
    this.auto_design_data = null;
    this.auto_design_result_list = [];
    this.adjust_building = null;
  }
}
/* 建物基本設定 */
class E_building_base_setting {
  constructor() {
    this.building_type = null;
    this.base_span = null;
    this.car_approach_type = null;
    this.floor_list = [];
    this.building_shape = null;
    this.is_10t_truck = false;
    this.is_shape = false;
    this.building_area = null;
  }
}
/* 自動設計データ */
class E_auto_design_data {
  constructor() {
    this.base_span_list = []
    this.cross_section_list = []
  }
}
/* 自動設計結果 */
class E_auto_design_result {
  constructor() {
    this.no = 0;
    this.floor = 0;
    this.truck = 0;
    this.capacity_digest_rate = 0;
    this.office_rate = 0;
    this.remarks = '';
    this.conditions = '';
    this.building_info = '';
  }
}
/* 建物調整 */
class E_adjust_building {
  constructor() {
    this.calc_type = 0;
    this.change_building_scale = null;
    this.change_doorway = null;
    this.move_building = null;
  }
}
/* 基準スパン */
class E_base_span {
  constructor() {
    this.name = '';
    this.width = 0;
    this.depth = 0;
  }
}
/* 建物規模の変更 */
class E_change_building_scale	{
  constructor() {
    this.calc_type = 0;
    this.floor = 0;
    this.is_shape = false;
  }
}
/* 出入口の変更 */
class E_change_doorway {
  constructor() {
    this.calc_type = 0;
    this.is_road_rounding = false;
    this.gate_l_cond_no = 0;
    this.gate_l_cond_direction = 0;
    this.is_maintain_berth_direction = false;
  }
}
/* 建物移動 */
class E_move_building {
  constructor() {
    this.direction = 0;
    this.move_distance = 0;
  }
}

/* 倉庫設計 初期化 */
let initAutoDesign = function() {
  let autoDesign = new E_auto_design()
  let buildingBaseSetting = initBuildingBaseSetting()
  autoDesign.building_base_setting = buildingBaseSetting
  autoDesign.auto_design_data = new E_auto_design_data()
  autoDesign.adjust_building = initAdjustBuilding()
  return autoDesign
}

let initBuildingBaseSetting = function() {
  let buildingBaseSetting = new E_building_base_setting()
  buildingBaseSetting.base_span = new E_base_span()
  buildingBaseSetting.car_approach_type = new E_berth_type()
  return buildingBaseSetting
}

let initAdjustBuilding = function() {
  let adjustBuilding = new E_adjust_building()
  adjustBuilding.change_building_scale = new E_change_building_scale()
  adjustBuilding.change_doorway = new E_change_doorway()
  adjustBuilding.move_building = new E_move_building()
  return adjustBuilding
}

export {
  initAutoDesign,
  initBuildingBaseSetting,
  initAdjustBuilding,
  E_auto_design_result
}

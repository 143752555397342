<template>
  <div>
    <div id="modal-check-auto-calc-status" class="modal" tabindex="-1" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm">
        <div class="modal-content">
          <div class="modal-body d-flex flex-column mt-2 text-center">
            <div>
              <p class="mb-1">自動計算中…</p>
            </div>
            <p class="mb-2">{{ this.percent }} ボリューム計算中</p>
            <div class="progress">
              <div class="progress-bar" role="progressbar" ref="elaspedTime" aria-valuenow="0" aria-valuemin="0"
                aria-valuemax="100"></div>
            </div>
            <div class="my-2 mx-2">
              <button class="btn btn-primary mt-2 btn-sm" type="button" :disabled="percent==='100%'" @click="$_cancel">中止</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { FLAG } from '@/assets/js/const'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalCheckAutoCalcStatus',
  data() {
    return {
      myModal: null,
      percent: null
    }
  },
  methods: {
    /* 初期化 */
    init() {
      this.myModal = new Modal(document.getElementById('modal-check-auto-calc-status'), {})
      this.myModal.show()
      fncAdjustModalLayer()
      this.$_getResult()
    },
    /* データ設定 */
    $_setData() {
    },
    /* 自動設計実行結果取得サービス */
    $_getResult() {
      this.$store.dispatch("getAutoDesignResult")
        .then((resultData) => {
          let et = this.$refs.elaspedTime
          et.style.width = resultData.progress_rate + '%'
          this.percent = et.style.width 
          if (resultData.status_code === "302200"){
            //計算完了
            let params = this.$store.state.params
            params.auto_design.auto_design_result_list = resultData.auto_design_result_list
            this.$store.commit('setParams', params)
            this.$_saveResult(params.auto_design.auto_design_result_list)
          } else if (resultData.status_code === "302299") {
            //異常終了
            this.$emit('finishCalc', FLAG.ON)
            this.myModal.hide()
          } else {
            //計算中
            setTimeout(() => this.$_getResult(), 2000)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 自動設計結果保存サービス */
    async $_saveResult(resultList) {
      let max =  Math.max.apply(null, resultList.map(function(o){return o.capacity_digest_rate;}))
      let result = resultList.find(v => v.capacity_digest_rate === max)
      await this.$store.dispatch("saveAutoDesignResult", { no: result.no })
        .then(() => {
          this. $_updateCommon()
          this.$emit('finishCalc', FLAG.OFF)
          this.myModal.hide()
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* [中止]クリック */
    $_cancel() {
      this.$store.dispatch("cancelAutoDesign")
        .then(() => {
          this. $_updateCommon()
          this.myModal.hide()
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 共通処理 */
    $_updateCommon() {
      // 利用時間登録, プランデータ更新日更新
      this.$store.dispatch('updateUsersUsageTimes')
      this.$store.dispatch('updateProjectUpdateDate')
    }
  }
}
</script>
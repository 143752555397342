<template>
  <div class="my-container">
    <div class="my-side-contents text-black">
      <input-section @set-viewer="setViewer"></input-section>
    </div>
    <div class=my-main-contents>
      <output-section ref="outputSection" :prop-viewer-url="url"></output-section>
    </div>
  </div>
</template>

<script>
import InputSection from '@/views/volume/InputSection'
import OutputSection from '@/views/viewer/OutputSection'

export default {
  name: "Volume",
  components: {
    InputSection,
    OutputSection
  },
  data() {
    return {
      planViewUrl: this.$store.state.viewerUrl.plan_view_url
    }
  },
  computed: {
    url: function() {
      // console.log(this.planViewUrl)
      return this.planViewUrl
    }
  },
  methods: {
    setViewer(newUrl){
      this.planViewUrl = newUrl
      this.$refs.outputSection.reloadViewer()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/style/style.scss";
  .my-main-contents {
    overflow: hidden;
  }
</style>

<template>
  <div id="modal-terms-of-use" class="modal" tabindex="-1" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header drag-and-drop border-0">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  @click="$_onClickCloseButton"></button>
        </div>
        <div class="modal-body px-5 py-4">
          <div>
            <h5 class="text-center">楽ロジ&emsp;利用規約</h5>
            <p class="mt-3">楽ロジとは、株式会社フクダ&thinsp;・&thinsp;アンド&thinsp;・&thinsp;パートナーズ（以下、「当社」といいます）の提供する、日本国内におけるボリューム図作成システムおよびその他コンテンツの統合型サービスです。本サービスを利用するためには、以下に定める楽ロジ利用規約（以下、「本規約」といいます。）への同意ならびに当社所定の手続による利用申し込みが必要となります。ご利用を希望される場合は、本規約をご確認のうえ申込手続きを行ってください。</p>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第１章&emsp;総則</h5>
            <p class="mt-3 mb-2">第 1 条（適用範囲）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">本規約は、利用者等（次条において定義されます。以下同じ）に対し適用されます。利用者等は、本規約に同意のうえ、本サービスを利用します。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、事前に契約者の承諾を得ることなく、本規約を変更することがあります。本規約の変更にあたって重要な変更がある場合には、当社の運営する本サービスのWebサイト［https://www.rakulogi.net（以下「本Webサイト」といいます。）］への表示するものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社が本サービスの円滑な運用を図るために定める本サービスの利用に関する諸規定、注意事項及びガイドライン等は、本規約の一部を構成するものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 2 条（定義）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">本規約において用いられる次の用語は、それぞれ次の意味で使用します。</li>
              <li class="list-group-item border-0 d-flex p-0">
                <table class="table table-sm table-bordered align-middle mt-2">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">用語</th>
                      <th scope="col" class="text-center">用語の意味</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>本サービス</td>
                      <td>当社が提供するボリューム図作成システムおよびその他コンテンツの統合型サービスをいいます。</td>
                    </tr>
                    <tr>
                      <td>利用契約</td>
                      <td>当社から本サービスの提供を受けるための契約をいいます。</td>
                    </tr>
                    <tr>
                      <td>契約者</td>
                      <td>当社と本契約を締結している法人または個人をいいます。</td>
                    </tr>
                    <tr>
                      <td>利用者</td>
                      <td>契約者による指定及び許諾を受けて、本サービスを利用する者をいいます。</td>
                    </tr>
                    <tr>
                      <td>利用者等</td>
                      <td>契約者と利用者を総称したものをいいます。</td>
                    </tr>
                    <tr>
                      <td>利用申込者</td>
                      <td>本サービスの利用を希望する者をいいます。</td>
                    </tr>
                    <tr>
                      <td>利用申込</td>
                      <td>利用申込者による本サービスの利用申込をいいます。</td>
                    </tr>
                    <tr>
                      <td>登録情報</td>
                      <td>登録申請にあたって当社に提供された情報をいいます。</td>
                    </tr>
                    <tr>
                      <td>利用契約日</td>
                      <td>利用契約の成立日をいい、当社が利用申込を承諾し、必要なユーザーＩＤ&thinsp;・&thinsp;パスワードを通知した日をいいます。</td>
                    </tr>
                    <tr>
                      <td>利用料金等</td>
                      <td>本サービスを利用するうえで生じる料金の総称をいいます。</td>
                    </tr>
                    <tr>
                      <td>営業日</td>
                      <td>当社営業日をいいます。</td>
                    </tr>
                    <tr>
                      <td>通知等</td>
                      <td>当社から契約者へ発する通知、連絡等をいいます。</td>
                    </tr>
                    <tr>
                      <td>情報端末</td>
                      <td>利用者等が本サービスを利用するために使用するパーソナルコンピュータまたはスマートデバイス等をいいます。</td>
                    </tr>
                    <tr>
                      <td>知的財産権</td>
                      <td>特許権、実用新案権、意匠権、著作権（著作権法27条及び28条の権利を含みます）その他の知的財産に関して法令により定められた権利（特許を受ける権利、実用新案登録を受ける権利、意匠登録を受ける権利その他知的財産権の設定を受ける権利を含みます）の総称をいいます。</td>
                    </tr>
                    <tr>
                      <td>本件データ</td>
                      <td>本サービスを構成する各種データをいいます。</td>
                    </tr>
                    <tr>
                      <td>登録データ</td>
                      <td>利用者等による本サービスの利用を通じて登録されたデータをいいます。</td>
                    </tr>
                    <tr>
                      <td>作成データ</td>
                      <td>利用者等による本サービスの利用を通じて作成されたデータをいいます。</td>
                    </tr>
                    <tr>
                      <td>派生データ</td>
                      <td>登録データに分析統計処理を施し、特定の個人ないし法人、団体を識別できないよう技術的に復元困難な加工等を施した情報をいいます。</td>
                    </tr>
                    <tr>
                      <td>加工等</td>
                      <td>改変、追加、削除、組合せ、分析、編集及び統合等をいいます。</td>
                    </tr>
                    <tr>
                      <td>個人情報</td>
                      <td>「個人情報の保護に関する法律」（平成 15 年 5 月 30 日法律第 57 号）に定める「個人情報」をいいます。</td>
                    </tr>
                    <tr>
                      <td>学術&thinsp;・&thinsp;研究機関</td>
                      <td>「学校教育法」（昭和二十二年法律第二十六号）第１条に定める大学その他各学校を含みますが、これに限られず、また、公設&thinsp;・&thinsp;私設の双方を含みます。</td>
                    </tr>
                    <tr>
                      <td>反社会的勢力</td>
                      <td>暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者の総称をいいます。</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
            <p class="mt-3 mb-2">第 3 条（通知方法）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">当社は、通知等を、書面の郵送、又は電子メールの送信その他当社がその都度任意に選択する方法により行うことができるものとします。</li>
            </ul>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第２章&emsp;利用契約</h5>
            <p class="mt-3 mb-2">第 4 条（利用の申し込み）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">利用申込者は、本規約を承諾のうえ、当社が定める利用申込書又は注文書に必要事項を記入し、当社に提出することで、利用申込を行うものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、当社の基準に従い利用申込の可否を判断し、当社が申込内容を認める場合には、利用申込受領の日から５営業日を目安に、その旨を利用申込者へ電子メール（利用申込時に登録した利用申込者の電子メールアドレスを使用します）にて通知します。なお、本サービスの利用に必要なＩＤおよびパスワードは、前項の通知と同時に発行されます。</li>
              <li class="list-group-item border-0 d-flex flex-wrap p-0">
                <span class="my-width-calc">契約者が下記各号のいずれかに該当する場合、当社は利用申込を承諾しない場合があります。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">本規約に違反するおそれがあると当社が判断した場合</li>
                  <li class="list-group-item border-0 d-flex p-0">登録申請にあたって当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
                  <li class="list-group-item border-0 d-flex p-0">過去に当社サービスの利用の登録を取り消された者である場合</li>
                  <li class="list-group-item border-0 d-flex p-0">未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</li>
                  <li class="list-group-item border-0 d-flex p-0">反社会的勢力等である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合</li>
                  <li class="list-group-item border-0 d-flex p-0">その他、当社が登録を適当でないと判断した場合</li>
                </ol>
              </li>
              <li class="list-group-item border-0 d-flex p-0">契約者は、利用者に対し、本約款の内容を遵守させるものとします。万一本約款に違反する利用がなされた場合、当社は当該契約者の利用資格を取り消すことができるものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 5 条（利用契約の成立）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">利用契約は利用契約日をもって成立するものとします。</li>
            </ul>
            <p class="mt-3 mb-2">第 6 条（申込情報の変更等）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex flex-wrap p-0">
                <span class="my-width-calc">契約者が以下の事項を利用契約成立後に変更した場合、契約者は関係資料を添えて当該変更の内容を書面その他当社がその都度指定する方法によって当社に対して届け出るものとします。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">契約者の商号&thinsp;・&thinsp;名称、本店所在地、電話番号又はファクシミリ番号、電子メールアドレス</li>
                  <li class="list-group-item border-0 d-flex p-0">契約者の代表者又はこれに準じる者</li>
                  <li class="list-group-item border-0 d-flex p-0">その他本申込書等に記入した事項</li>
                </ol>
              </li>
              <li class="list-group-item border-0 d-flex p-0">本サービスに関連する当社から通知等が、第 4 条 第 1 項 に基づいて当社に届け出た契約者の連絡先（前項に基づき連絡先変更の届出がなされた場合にあっては変更後の連絡先）へ宛てて発信された場合、当該通知等は当該連絡先へ通常到達すべき時に到達したものとみなします。</li>
            </ol>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第３章&emsp;本サービスの内容</h5>
            <p class="mt-3 mb-2">第 7 条（本サービスの内容）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex flex-wrap p-0">
                <span class="my-width-calc">当社が利用者等に提供するサービスは、以下の通りとします。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">ボリューム図（面積表、プラン図）、物件データ作成サービス</li>
                  <li class="list-group-item border-0 d-flex p-0">その他、それらに付随するサービスの提供</li>
                </ol>
              </li>
            </ul>
            <p class="mt-3 mb-2">第 8 条（本サービスの利用）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">利用者等は、当社から発行されたユーザーＩＤ及びパスワードを用いて当社の管理&thinsp;・&thinsp;運営するサーバーにアクセスすることにより、情報端末上にて本サービスを利用することができます。ただし、同一のユーザーＩＤ及びパスワードを用いて同時にログインすること（複数の情報端末上にて同時に利用することを含みます）はできません。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、業務の遂行上必要がある場合、契約者の承諾無しにサービスの内容及びシステムの仕様を変更できるものとします。</li>
            </ol>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第４章&emsp;利用料金及び支払方法</h5>
            <p class="mt-3 mb-2">第 9 条（最低利用期間）</p>
            <ul class="list-group"><li class="list-group-item border-0 d-flex p-0">本サービスを利用するうえで、最低利用期間はありません。</li></ul>
            <p class="mt-3 mb-2">第 10 条（初期費用）</p>
            <ul class="list-group"><li class="list-group-item border-0 d-flex p-0">本サービスの導入に要する初期費用は発生しません。ただし、契約者の要請&thinsp;・&thinsp;事情等による個別のサービス改修はこの限りではなく、その場合は別途見積もりとします。</li></ul>
            <p class="mt-3 mb-2">第 11 条（利用料金及び支払方法）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">本サービスに係る月額利用料金の額及び支払い方法は、本Webサイトに記載のとおりとします。ご契約初月（利用契約日の属する月をいいます）は無料とし､ご契約月の翌月1日より1ヶ月単位で課金されます｡</li>
              <li class="list-group-item border-0 d-flex p-0">本サービスの利用開始以降において、契約者の要請&thinsp;・&thinsp;事情等による個別のサービス改修は前条の定めを準用するものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">法令の改正により消費税等の税率が変更された場合、変更後の税率の適用日以降における消費税等の額は変更後の税率により計算された額とします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は契約者への予告無く、前条の初期費用及び本条の利用料と支払方法を変更することがあります。</li>
            </ol>
            <p class="mt-3 mb-2">第 12 条（遅延損害金）</p>
            <ul class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">契約者が本規約に定める利用料金その他の債務の支払いを遅延したときは、支払期日の翌日から支払済みに至るまで年率 14.5 ％の割合による遅延損害金を支払うものとします。</li>
            </ul>
            <p class="mt-3 mb-2">第 13 条（料金の返還）</p>
            <ul class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">利用料金等は、いかなる理由があっても返却しないものとします。</li>
            </ul>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第５章&emsp;利用者等の遵守&thinsp;・&thinsp;禁止事項</h5>
            <p class="mt-3 mb-2">第 14 条（ユーザーID&thinsp;・&thinsp;パスワードの管理）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">ユーザーＩＤ及びパスワードの使用は、利用者各個人に限定するものとします。利用者等は、発行されたユーザーID及びパスワードを第三者（当社指定のパスワード発行者を除き、以下同じ）に知られないよう厳重に管理し、パスワードの盗用を防止する措置を利用者等の責任において行わなければならないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、利用者等において前項の定めに違反することで生じた損害、またはユーザーＩＤ及びパスワードが盗まれ、第三者により不正に利用され、その結果生じた損害について一切責任を負わないものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 15 条（利用範囲）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">利用者等は、その他本規約に定められた範囲においてのみ、本サービスを企業&ensp;商用&ensp;非営利に関わらず利用することができるものとします。ただし第 19 条各号に該当する行為は除きます。
              </li>
            </ul>
            <p class="mt-3 mb-2">第 16 条（本件データの使用許諾）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex flex-wrap p-0"><span class="my-width-calc">当社は、利用者等に対して、本件データに対する以下の権利を非独占的に許諾します。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">本サービスを利用してブラウザ上で本件データを閲覧すること。</li>
                  <li class="list-group-item border-0 d-flex p-0">本サービスに予め備えられた機能等を利用して、本サービス仕様の範囲内において作成データを当社指定の形式で情報端末または記憶媒体にダウンロード及び保存し、当該保存した作成データを本規約に定める範囲内で使用すること。</li>
                </ol></li>
              <li class="list-group-item border-0 d-flex p-0">利用者等は、作成データおよびを本規約に定められた範囲以外の目的をもって複製、改変、頒布等してはならないものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 17 条（著作権等）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">本件データおよび作成データの知的財産権は、当社もしくは株式会社コンピュータシステム研究所及びエヌ&thinsp;・&thinsp;ティ&thinsp;・&thinsp;ティ&thinsp;・&thinsp;インフラネット株式会社（これらの者が第三者から当該第三者における知的財産権の使用許諾を受けているときは、当該第三者を含みます）に帰属するものとし、本規約に定める本サービスの利用許諾は、本サービスに関する知的財産権を契約者へ譲渡することを意味するものではありません。</li>
            </ul>
            <p class="mt-3 mb-2">第 18 条（権利の譲渡&thinsp;・&thinsp;貸与の禁止）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">契約者は、本規約に基づくサービスの提供を受ける権利を、契約者が属する法人または団体等と別人格の第三者に譲渡&thinsp;・&thinsp;貸与&thinsp;・&thinsp;質入もしくは担保提供することはできません。</li>
            </ul>
            <p class="mt-3 mb-2">第 19 条（禁止事項）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex flex-wrap p-0">
                <span class="my-width-calc">利用者等は、本サービスの利用にあたって、以下の行為を行ってはならないものとします。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">本件データおよび作成データの著作権表示及び商標を除去する等、当社もしくは第三者の知的財産権その他の権利を侵害する行為、または侵害するおそれのある行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">本サービスのソフトウェアまたはソフトウェア複製物の改変、リバースエンジニアリング、逆コンパイル、逆アセンブル、プロテクトの解除または回避等に該当する行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">当社もしくは第三者のプライバシーもしくは財産を侵害する行為、または侵害するおそれのある行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">当社もしくは第三者に不利益もしくは損害を与える行為、またはそれらのおそれのある行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">公序良俗に反する行為、違法行為、アダルトサイト、反社会的勢力のための利用、犯罪的行為もしくは犯罪的行為に結びつく、またはそれらのおそれのある行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">当社の事前承諾なく、本サービスを利用してまたは本サービスに関連して営利を目的として行う行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">本サービスの運営を妨げる行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">当社または本サービスの信用を毀損する行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">ユーザーID及びパスワードを不正に使用する行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">コンピュータウィルス等有害なプログラムを使用する行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">法令または本規約に違反する、または違反するおそれのある行為。</li>
                  <li class="list-group-item border-0 d-flex p-0">当社の事前承諾なしに本サービスの全部または一部を利用した商品または製品の有償での販売、頒布、譲渡、貸与、利用権の許諾その他の商用利用。</li>
                  <li class="list-group-item border-0 d-flex p-0">その他当社が不適切と認める行為。</li>
                </ol>
              </li>
            </ul>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第６章&emsp;当社の免責</h5>
            <p class="mt-3 mb-2">第 20 条（通信設備の変更）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">当社は、利用者等が情報端末ならびに周辺機器の通信設備等を変更したことにより、本サービスが利用できなくなったとしても、当社が本サービスに提供するサーバー、ソフトウェアその他の通信設備等を変更する義務を負わないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、当社が本サービスに供するサーバー、ソフトウェアその他の通信設備等を変更、追加等したことにより、利用者等が本サービスを利用できなくなった場合には、利用者等は、本サービスを利用するために必要な情報端末ならびにその周辺機器の通信設備等を自己の費用と負担において準備するものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 21 条（免責事項）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">当社は、本サービス（本件データを含みます）に事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます）がないことを明示的にも黙示的にも保証しておりません。当社は、契約者に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。</li>
              <li class="list-group-item border-0 d-flex p-0">当社のウェブサイト、サーバーその他の本サービスに必要なシステム上に、エラーその他の不具合、瑕疵、ウィルス等の有害な内容が含まれていないことおよび、本サービスの利用が、第三者の権利を侵害するものではないことを保証しません。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、天災地変、防御水準を超えた不正アクセスインターネットの通信障害、本サービスの利用不能またはデータの未着および混乱に関連する事由に起因し、または関連して生じた損害につき一切の責任を負わないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、本サービスに関する内容の真偽、正確性、最新性、有用性、信頼性、適法性、および第三者の権利の不侵害について、本規約においていかなる保証も行わないものとし、本サービスの利用に関して損害が生じた場合であっても、当社は一切の責任を負わないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社の重大な過失によるものを除き、本サービスの利用に起因する利用者等の損害について、当社はその責めを負わないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">利用者等による本サービスの利用に関連して、利用者等に対する責任を負う場合には、利用者等に現実に生じた通常かつ直接の範囲の損害に限り、これを賠償するものとし、特別な事情から生じた損害（損害の発生を予見し、または、予見し得た場合を含みます。）については、責任を負わないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">本サービスを利用することによって、利用者等が他の利用者または第三者に損害を与えた場合、契約者は自らの責任と負担において問題を解決する義務を負います。なお、第三者から当社に責任を追及され、損害を賠償した場合には、契約者に求償できるものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社の重大な過失によるものを除き、利用者等の本件データの消失等による損害について、当社はその責めを負わないものとします。データのバックアップは利用者側で頻繁に行っていただくことを原則とします。</li>
              <li class="list-group-item border-0 d-flex p-0">当社は、本サービスにより利用者等の希望に沿ったサービスの提供を実現することを保証するものではありません。</li>
            </ol>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第７章&emsp;利用契約の解除等</h5>
            <p class="mt-3 mb-2">第 22 条（当社による本サービスの中断&thinsp;・&thinsp;中止）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex flex-wrap p-0">
                <span class="my-width-calc">当社は、次の各号のいずれかに該当する場合には、事前に契約者に通知する義務を負うことなく、一時的に本サービスの一部または全部を中断する場合があります。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">本サービスに供する通信設備等の定期または緊急保守を行う場合</li>
                  <li class="list-group-item border-0 d-flex p-0">停電、通信設備の故障などにより本サービスの提供ができなくなった場合</li>
                  <li class="list-group-item border-0 d-flex p-0">地震、火災、洪水その他不測の事態により本サービスの提供ができなくなった場合</li>
                  <li class="list-group-item border-0 d-flex p-0">その他当社の責によらない事由により本サービスの提供ができなくなった場合</li>
                </ol>
              </li>
              <li class="list-group-item border-0 d-flex p-0">当社は、本サービスをやむを得ず継続して提供することができなくなった場合は、当社の所定する方法によりその旨を通知するものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 23 条（契約者による利用契約の解除）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">本サービスの解約は利用契約日より可能です。</li>
              <li class="list-group-item border-0 d-flex p-0">契約者が本サービスの利用停止を希望する場合は、下記事項について予め承諾のうえ、５営業日前までに、契約者から当社宛に退会申出の連絡をすることで退会することができます。ただし、契約者は、月の途中で解約がなされた場合であっても当社における退会手続完了の時点から本サービスのすべてのサービスを受けることが出来なくなること、および退会の時期に関わらず当月の残りの日数に相応する日割りの使用料は一切返還を受けることができないことを予め承諾するものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">退会後、再度本サービスの利用を希望する場合は、再度第4条に定める登録⼿続を実施する必要があります。なお、利⽤者は、本条に係る再登録⼿続後、退会前のデータが引き継がれないことを予め承諾するものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">前項にかかる再登録手続以降の利用料金は、第4条の定めにかかわらず利用開始初月から発生するものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 24 条（契約者に対するサービスの停止および強制解約）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex flex-wrap p-0"><span class="my-width-calc">契約者が次の各項に該当する場合、当社は契約者に通知の上、当該契約者に対する本サービスの一部、または全部の提供を停止することができ、また当社の判断により当該契約者との利用契約の全部又は一部を強制解除することができます。なお、本条に基づき当社の判断でサービスの停止または利用契約が解除されたことにより契約者が損害を蒙ったとしても、当社は何らの責任を負わないものとします。</span>
              <ol class="list-group my-list-group-numbered">
                <li class="list-group-item border-0 d-flex p-0">利用の申し込み時に虚偽の申告を行った場合</li>
                <li class="list-group-item border-0 d-flex p-0">契約者またはその関係者が過去に本規約違反等により、本サービス利用の停止または次条に掲げる強制解約を受けていることが判明した場合</li>
                <li class="list-group-item border-0 d-flex p-0">本規約に定める債務の履行を怠ったとき</li>
                <li class="list-group-item border-0 d-flex p-0">本規約に違反し、当社がかかる違反の是正を催告した後 5 日以内に是正されない場合。</li>
                <li class="list-group-item border-0 d-flex p-0">利用申込書に虚偽の記載をしたことが明らかになったとき</li>
                <li class="list-group-item border-0 d-flex p-0">利用者等のアカウントが外部からの攻撃などで本サービスの運用に支障があると判断されるとき</li>
                <li class="list-group-item border-0 d-flex p-0">登録したメールアドレスが、長期間送達不能である場合</li>
                <li class="list-group-item border-0 d-flex p-0">失踪宣告を受けまたは死亡した場合。</li>
                <li class="list-group-item border-0 d-flex p-0">監督官庁から営業許可の取消、停止等の処分を受けた場合。</li>
                <li class="list-group-item border-0 d-flex p-0">解散、営業の全部または重要な一部の譲渡等の決議をした場合。</li>
                <li class="list-group-item border-0 d-flex p-0">前各号の一が発生するおそれがある場合。</li>
              </ol>
              </li>
              <li class="list-group-item border-0 d-flex p-0">前項に基づき本サービスを停止する場合は、原則としてその契約者に事前に連絡をしますが、緊急の場合は事前連絡をしないこともあります。</li>
              <li class="list-group-item border-0 d-flex p-0">本条第1項に基づき本サービスを停止した場合、契約の解除がない限り所定の利用料金を申し受けます。</li>
              <li class="list-group-item border-0 d-flex p-0">本条第1項に基づき本サービスを停止した場合、当社が把握する契約者の連絡先に通知した場合、当該通知は契約者に到達したものとみなします。</li>
              <li class="list-group-item border-0 d-flex p-0">本条第1項に基づき、利用契約の全部又は一部が解除された場合、当社は、契約者がすでに当社に支払った利用料金等を、契約者に返還する義務を負わないものとします。なお、本項は、当該契約者に対する当社の損害賠償請求権（強制解約作業に伴う所定の事務手数料を含みますが、これに限りません）の行使を妨げるものではありません。</li>
              <li class="list-group-item border-0 d-flex p-0">ある契約者が本条第1項第2号に該当するものとして、複数のアカウントにつき利用契約を解除された場合、当社は、当該アカウントに関連するアカウント（当該契約者の保有するアカウントに限りません）に関しても、当該アカウントの契約者との利用契約の解除を行うことができるものとします。この場合における、解除処分を受けた契約者の既払い金および当該契約者に対する損害賠償請求については、本条第5項を準用するものとします。</li>
            </ol>
          </div>
          <div class="mt-5">
            <h5 class="text-center">第８章（附則）</h5>
            <p class="mt-3 mb-2">第 25 条（情報の取扱い）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">当社は、個人情報を、法令に則り、善良な管理者の注意をもって適切に管理するものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">契約者は、本サービスを申込に際し契約者の社員、従業員その他の個人情報が必要となる場合は、個人情報保護法および関連する法令等を順守して適正に取得されたものであること（個人情報保護法における個人情報の主体たる本人（以下「本人」といいます）からの同意取得を含む）を保証するとともに、当社に個人情報の取扱いを委託または提供することについて本人に対して責任を負うものとします。</li>
              <li class="list-group-item border-0 d-flex flex-wrap p-0"><span class="my-width-calc">当社は、以下の目的のために本サービスに登録された情報を利用します。ただし契約者から利用中止の申し出があったとき、当社は業務、運営上支障がない範囲でこれを中止するものとします。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">本サービスの使用状況の調査&thinsp;・&thinsp;分析のため</li>
                  <li class="list-group-item border-0 d-flex p-0">本サービスの障害&thinsp;・&thinsp;不具合時の調査&thinsp;・&thinsp;対応のため</li>
                  <li class="list-group-item border-0 d-flex p-0">本サービスの新機能開発、マーケティング活動又は新しいサービスの開発&thinsp;・&thinsp;研究&thinsp;・&thinsp;サービス向上を目的とした統計分析を行うため</li>
                </ol>
              </li>
              <li class="list-group-item border-0 d-flex p-0">当社は、本人から提供個人情報等に含まれる個人データ等の開示、訂正、追加または削除の請求を受けた場合、あるいは行政機関、司法機関等から個人データ等の提供を要請された場合、速やかに契約者に通知するものとします。この場合、当社は本人の請求または要請に直接応じる義務を負わず、契約者が自己の費用と責任をもって対応するものとします。</li>
              <li class="list-group-item border-0 d-flex flex-wrap p-0"><span class="my-width-calc">契約者と当社は、相手方から本サービス利用契約の履行に関して、秘密情報（当サービスに関連して相手方から秘密に扱うことを指定して開示された情報およびお客さまが当サービス上にアップロードしたコンテンツをいいます。）について善管注意義務をもって扱い、相手方の事前の承諾なくして第三者に開示しないものとします。ただし、以下の各号の場合は除きます。</span>
                <ol class="list-group my-list-group-numbered">
                  <li class="list-group-item border-0 d-flex p-0">公的機関から法令に基づき開示を求められたとき</li>
                  <li class="list-group-item border-0 d-flex p-0">当社のウェブサイト、サーバーその他の本サービスに必要なシステムに対する復旧または保守作業を要し、当該作業を外部業者へ委託する際、当該委託先に対して必要な範囲で開示するとき</li>
                  <li class="list-group-item border-0 d-flex p-0">契約者の依頼による場合、もしくは事前に許可を得た場合</li>
                </ol>
              </li>
              <li class="list-group-item border-0 d-flex p-0">前項の定めに関わらず、当社は、派生データを、当社における本サービスの充実や新企画、商品、営業活動、および新たなサービス開発の参考、当社または学術&thinsp;・&thinsp;研究機関において当該加工後の情報を総合&thinsp;・&thinsp;集団的に分析し、その結果を編集&thinsp;・&thinsp;複製するなどして第三者に配布&thinsp;・&thinsp;公開することがあります。</li>
            </ol>
            <p class="mt-3 mb-2">第 26 条（反社会的勢力の排除）</p>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item border-0 d-flex p-0">契約者は、過去、現在および将来にわたり、反社会的勢力に該当しないことを保証し、および暴力的行為、詐術&thinsp;・&thinsp;脅迫行為、業務妨害行為等、法令に抵触する行為またはそのおそれのある行為を行わないものとします。</li>
              <li class="list-group-item border-0 d-flex p-0">契約者が前項の規定に違反した場合には、当社は事前に通告することなく契約者の本サービス利用を停止し、または登録を削除する等の措置を講じることができるものとします。これにより契約者に何らの不利益または損害が生じたとしても、当社は一切の責任を負わないものとします。</li>
            </ol>
            <p class="mt-3 mb-2">第 27 条（分離可能性）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">本規約のいずれかの条項またはその一部が無効または執行不能と判断された場合であっても、本規約の残りの条項および一部が無効または執行不能と判断された条項の残りの部分は、継続して完全に効力を有するものとします。</li>
            </ul>
            <p class="mt-3 mb-2">第 28 条（管轄裁判所）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">契約者と当社間で紛争が生じた場合は、東京地方裁判所を専属的合意管轄裁判所とします。</li>
            </ul>
            <p class="mt-3 mb-2">第 29 条（準拠法）</p>
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-0">本規約の解釈に関しては、日本法が適用されるものとします。</li>
            </ul>
            <p class="mt-4 mb-2">制定日：2022年6月1日</p>
            <p class="mt-4 mb-2 text-end">以上</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" v-show="isAgreeButtonShown" @click="$_agree">同意する</button>
          <button type="button" class="btn btn-secondary" @click="$_close">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { RESULT_CODE } from '@/assets/js/const'
import { drgAddEvent, drgRemoveEvent,drgMouseUp } from '@/assets/js/draggable' 
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalTermsOfUse',
  props: {
    isAgreeButtonShown: {
      type: Boolean
    }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-terms-of-use').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.myModal = new Modal(document.getElementById('modal-terms-of-use'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* [同意する]クリック */
    $_agree() {
      this.$emit('confirm-terms', RESULT_CODE.OK)
      this.myModal.hide()
    },
    /* [閉じる]クリック */
    $_close() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-and-drop {
  cursor: move;
  z-index: 2000;
}
.drag {
  z-index: 2001;
}
.list-group {
  li::before {
    margin-right: 0.8rem;
    width: 13px;
  }
  &:not(.list-group-numbered) > li::before {
    content: "";
  }
  &.my-list-group-numbered {
    padding-left: calc(13px + 1rem);
    counter-reset : item;
    li::before {
      content: "(" counters(item, ".") ") ";
      counter-increment: item;
      width: 26px;
      flex-shrink: 0;
    }
  }
  .my-width-calc {
    width: calc(100% - 13px - 1rem);
  }
}
.table td:first-child {
  white-space: nowrap;
}
</style>
/* ボリューム情報 */
class E_volume {
  constructor() {
    this.l_cond_list = [];
    this.gate_l_cond_no = 0;
    this.gate_l_cond_direction = 0;
    this.truckberth_l_cond_no = 0;
    this.truckberth_l_cond_direction = 0;
    this.calc_type = 0;
    this.direction = 0;
    this.volume_result = null
    
  }
}
/* トラック出入口実行結果 */
class E_volume_result {
  constructor() {
    this.floor = 0;
    this.area_by_floor = [];
    this.total_area = 0;
    this.coverage_digest_rate = 0;
    this.capacity_digest_rate = 0;
  }
}

/* ボリューム作成 初期化 */
let initVolume = function() {
  let volume = new E_volume()
  volume.volume_result= new E_volume_result()
  return volume
}

export {
  initVolume
}

<template>
  <router-view v-slot="{ Component }">
    <transition>
      <component :is="Component" />
    </transition>
    <modal-system-error />
  </router-view>
</template>

<script>
// var timer;
import ModalSystemError from '@/views/common/ModalSystemError'

export default {
  name: 'App',
  components: {
    ModalSystemError
  },
  data() {
    return {
      // transitionName: 'fade',
      // mode: 'out-in',
    }
  },
  mounted() {
    this.$store.commit('setErrorCode', null)
    this.$store.commit('setErrorStatus', null)
    const ua = navigator.userAgent
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 || ua.indexOf('Safari') > 0 && typeof document.ontouchstart !== 'undefined') {
      // alert("ipad確認用")
      window.addEventListener('load', this.onResize)
    }
    else {
      window.addEventListener('load', this.onResize)
      window.addEventListener('resize', this.onResize)
    }
  },
  methods: {
    onResize() {
      const vh = window.innerHeight
      document.getElementById('app').style.height=vh+'px'
    }
  },
  
  beforeCreate(){
    //ログイン判定
    this.$router.beforeEach((to, from, next) => {
      var isLogin = true
      var isAdmin = true
      if(this.$store.state.user === null){
        isLogin = false
        isAdmin = false
      }else if(!this.$store.state.user.userId){
        isLogin = false
        isAdmin = false
      } else if(this.$store.state.user.authority === 0) {
        isAdmin = false
      }

      // ログインしているか判定
      // 以下のリストないのページはログイン判定不要
      const ignorePath = ['/', '/userSignUp', '/preRegistration', '/specified-commercial-transactions', '/registered']

      // 管理者権限が必要なページ
      const requiresAdminPath = ['/userList', '/registrationInfoCheck', '/billingInfo']

      if (to.matched.some(p => ignorePath.indexOf(p.path) === -1)  && !isLogin) {
        // ログイン判定不要なページ以外にアクセスしようとしていて、ログインしていなければログイン画面に戻す
        next({ path: '/' })
      } else if(to.matched.some(p => requiresAdminPath.indexOf(p.path) !== -1) && !isAdmin) {
        // 管理者権限が必要なページにアクセスしようとしていて、管理者でなければトップ画面に戻す
        next({ path: '/top' })
      } else {
        next()
      }
    })
  },
}

</script>

<style lang="scss">
  #app {
    max-height: 100vh;
  }
</style>

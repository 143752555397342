<template>
  <div class="modal" id="modal-parcel-no-list" tabindex="-1" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header drag-and-drop">
          <h5 class="modal-title">選択地番</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  @click="$_onClickCloseButton"></button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-sm small">
            <thead>
              <tr>
                <td v-for="field in parcelNoFields" :key="field.key">{{ field.label }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in parcelNoList" :key="index" @click="$_onRowSelected(index)" :class="{'my-bg-color': index === selectedIndex}">
                <td>{{ index + 1 }}</td>
                <td>{{ item.parcelNoName }}</td>
                <td class="text-end">{{ $_decimalFormat(item.siteArea, 2) }} ㎡</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-sm" @click="$_ok">OK</button>
          <button class="btn btn-secondary btn-sm" @click="$_cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'
import { cmnDecimalFormat } from '@/assets/js/common'
import { drgAddEvent, drgRemoveEvent, drgMouseUp } from '@/assets/js/draggable'
import { fncAdjustModalLayer } from '@/assets/js/function'

export default {
  name: 'ModalParcelNoList',
  props: {
    propParcelNoList: {
      type: Array
    }
  },
  data() {
    return {
      myModal: null,
      dragElement: null,
      parcelNoList: [],
      parcelNoFields: [
        { key: 'no', label: 'No', thClass: 'font-weight-normal' },
        { key: 'parcelNoName', label: '地番名', thClass: 'font-weight-normal' },
        { key: 'siteArea', label: '地番面積', thClass: 'font-weight-normal' }
      ],
      selectedIndex: null
    }
  },
  watch: {
    propParcelNoList: {
      handler: function(newVal) {
        this.parcelNoList = newVal
      },
      deep : true,
    }
  },
  mounted() {
    this.dragElement = document.getElementById('modal-parcel-no-list').querySelector('.drag-and-drop')
    drgAddEvent(this.dragElement)
  },
  beforeUnmount() {
    drgRemoveEvent(this.dragElement)
  },
  methods: {
    /* 初期化 */
    init() {
      this.myModal = new Modal(document.getElementById('modal-parcel-no-list'), {})
      this.myModal.show()
      fncAdjustModalLayer()
    },
    /* 行選択 */
    $_onRowSelected(idx) {
      console.log('idx', idx)
      if (idx === this.selectedIndex) {
        this.selectedIndex = null
      } else {
        this.selectedIndex = idx
      }
    },
    /* 数値のフォーマット */
    $_decimalFormat(siteArea, digit) {
      return cmnDecimalFormat(siteArea, digit)
    },
    /* OK */
    $_ok() {
      if (this.selectedIndex === null || this.selectedIndex < 0) return
      // 選択データのみを返す
      const data = this.parcelNoList[this.selectedIndex]
      this.$emit('set-parcel-no', data)
      this.myModal.hide()
    },
    /* Cancel */
    $_cancel() {
      this.myModal.hide()
    },
    /* クローズ時の処理 */
    $_onClickCloseButton() {
      drgMouseUp()
    }
  }
}
</script>

<style lang="scss" scoped>
  .table>:not(:first-child) {
    border-top: 1;
    border-top-color: gainsboro;
  }

  .my-bg-color {
    background-color: #ced4da;
  }

  .drag-and-drop {
    cursor: move;
    z-index: 2000;
  }

  .drag {
    z-index: 2001;
  }
</style>
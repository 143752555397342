/* 法規チェック */
class E_law_check {
  constructor() {
    this.building_conditions_list = []
    this.check = null
  }
}
/* 法規チェック項目 */
class E_law_check_item {
  constructor() {
    this.slant_line_volume = null
    this.absolute_height = null
    this.sun_shadow_regulations = null
  }
}
/* チェック結果 */
class E_check_result {
  constructor() {
    this.result = null
    this.result_message = ''
    this.message = ''
  }
}

/* 法規チェック 初期化 */
let initLawCheck = function() {
  let lawCheck = new E_law_check()
  lawCheck.check = new E_law_check_item()
  lawCheck.check.slant_line_volume = new E_check_result()
  lawCheck.check.absolute_height = new E_check_result()
  lawCheck.check.sun_shadow_regulations = new E_check_result()
  return lawCheck
}

export {
  initLawCheck
}
